import React, { useRef, useState, useReducer, useEffect } from 'react'

import {
  View,
  StyleSheet,
  ScrollView,
  TextInput,
  StyleProp,
  ViewStyle,
} from 'react-native'

import { TouchableOpacity } from 'components/Shared/Touchables'

import useLocationParams from 'modules/useLocationParams'
import LinearGradient from 'react-native-linear-gradient'

import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import Link from 'components/Shared/Link'
import logo from 'static/logo.png'

import { useHover } from 'react-native-web-hooks'
import useSearchParams from 'modules/useSearchParams'
import loadable from '@loadable/component'

import { range, padStart } from 'lodash'

import { moment } from 'modules/time'

import usePrevious from 'modules/usePrevious'

import UserButton from './UserButton'
import MessageButton from './MessageButton'
import MoreButton from './MoreButton'

import CommonHeader from './CommonHeader'

import * as actions from 'actions'
import { t } from 'modules/i18n'
import dayjs from 'modules/dayjs'
import useClickOutside from 'modules/useClickOutside'
import FilterBar from 'components/Home/FilterBar'
import { useRouteMatch } from 'react-router-dom'

import { Text } from '@hello-ai/ar_shared'

const SearchSuggestsView = loadable(() => import('./SearchSuggestsView'))
const DatePanel = loadable(() => import('components/Header/DatePanel'))

function HeaderLink({ to, leftIcon = null, children, active = false }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  const color = active ? Constants.PRIMARY_COLOR : Constants.LIGHT_BLACK_COLOR

  return (
    <Link
      to={to}
      ref={ref}
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
          textDecorationLine: 'none',
        },
        isHovered && {
          textDecorationLine: 'none',
        },
      ]}
    >
      {leftIcon}
      <Text
        style={[
          { fontSize: 14, color },
          active && { fontWeight: '600' },
          isHovered && {
            color: active ? Constants.PRIMARY_COLOR : Constants.BLACK_COLOR,
          },
        ]}
      >
        {children}
      </Text>
    </Link>
  )
}

interface HeaderMenuListProps {
  marginLeft?: number
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}

function HeaderMenuList({
  children,
  style,
  marginLeft = 24,
}: HeaderMenuListProps) {
  return (
    <View
      accessibilityRole="listitem"
      style={StyleSheet.compose(
        {
          marginLeft,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
        },
        style
      )}
    >
      {children}
    </View>
  )
}

function HeaderMenu({ children }) {
  return (
    <View
      accessibilityRole="list"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {children}
    </View>
  )
}

export const PC_SEARCH_BAR_HEIGHT = 42

function PCSearchBar({
  inputRef,
  keyword,
  setKeyword,
  search,
  setSelectionPosition,
  dispatch,
  selectedPanel,
}) {
  const rootDispatch = useDispatch()

  const params = useLocationParams()

  const searchParams = useSearchParams()

  const prevSearchParams = usePrevious(searchParams, searchParams)

  const dateTime = searchParams.reserved_at

  const [date, setDate] = useState(
    'reserved_at' in params
      ? searchParams.reserved_at.format('YYYY-MM-DD')
      : null
  )

  const [partySize, setPartySize] = useState(
    'party_size' in params ? searchParams.party_size : null
  )

  const [time, setTime] = useState(
    'reserved_at' in params ? searchParams.reserved_at.format('HH:mm') : null
  )

  const [partySizePage, setPartySizePage] = useState(1)

  const [lunchTimePage, setLunchTimePage] = useState(7)

  const [dinnerTimePage, setDinnerTimePage] = useState(10)

  const [searchBarWidth, setSearchBarWidth] = useState(0)

  useEffect(() => {
    if (
      prevSearchParams.reserved_at.format() !==
      searchParams.reserved_at.format()
    ) {
      setDate('reserved_at' in params ? dateTime.format('YYYY-MM-DD') : null)
      setTime('reserved_at' in params ? dateTime.format('HH:mm') : null)
    }

    if (prevSearchParams.party_size !== searchParams.party_size) {
      setPartySize('party_size' in params ? searchParams.party_size : null)
    }
  }, [
    date,
    dateTime,
    prevSearchParams.reserved_at,
    searchParams.reserved_at,
    params,
    prevSearchParams.party_size,
    searchParams.party_size,
  ])

  const updatePartySizePage = (type) => {
    if (partySizePage === 1 && type === 'back') {
      return
    }
    if (partySizePage === 2 && type === 'next') {
      return
    }
    if (type === 'back') {
      setPartySizePage(partySizePage - 1)
    } else if (type === 'next') {
      setPartySizePage(partySizePage + 1)
    }
  }

  const updateLunchTimePage = (type) => {
    if (lunchTimePage === 1 && type === 'back') {
      return
    }

    if (lunchTimePage === 12 && type === 'next') {
      return
    }
    if (type === 'back') {
      setLunchTimePage(lunchTimePage - 1)
    } else if (type === 'next') {
      setLunchTimePage(lunchTimePage + 1)
    }
  }

  const updateDinnerTimePage = (type) => {
    if (dinnerTimePage === 1 && type === 'back') {
      return
    }

    if (dinnerTimePage === 12 && type === 'next') {
      return
    }
    if (type === 'back') {
      setDinnerTimePage(dinnerTimePage - 1)
    } else if (type === 'next') {
      setDinnerTimePage(dinnerTimePage + 1)
    }
  }

  const timer = useRef(null)
  const [selection, setSelection] = useState({
    start: 0,
    end: 0,
  })

  return (
    <View
      style={[
        GeneralStyles.inlineAlignCenter,
        {
          borderWidth: 1,
          borderColor: Constants.LIGHT_GRAY_COLOR,
          borderRadius: 2,
        },
      ]}
      onLayout={(e) => {
        setSearchBarWidth(e.nativeEvent.layout.width)
      }}
    >
      <View
        style={[
          GeneralStyles.container,
          GeneralStyles.inlineAlignCenter,
          {
            height: PC_SEARCH_BAR_HEIGHT,
            flex: 2,
            borderBottomWidth: 2,
            borderBottomColor:
              selectedPanel === 'search'
                ? Constants.ACCENT_COLOR
                : 'transparent',
          },
        ]}
      >
        <FontAwesome5Pro
          name="search"
          size={16}
          color={keyword ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)'}
          style={{ paddingLeft: 16 }}
          regular
        />
        <TextInput
          ref={inputRef}
          value={keyword}
          onChangeText={(text) => {
            setKeyword(text)
            timer.current && window.clearTimeout(timer.current)
            timer.current = window.setTimeout(() => {
              rootDispatch(actions.getSearchSuggests(text))
            }, 300)
          }}
          onSubmitEditing={() => {
            if (date && time && partySize) {
              search(
                keyword,
                moment(`${date} ${time}`, `YYYY-MM-DD HH:mm`),
                partySize
              )
            } else {
              search(keyword, null, null)
            }
            dispatch({ type: 'CLOSE_PANEL' })
          }}
          style={[
            GeneralStyles.container,
            {
              marginLeft: 10,
              minWidth: 300,
              paddingVertical: 14,
              color: Constants.BLACK_COLOR,
            },
          ]}
          onSelectionChange={(e) => {
            setSelectionPosition(e.nativeEvent.selection.start)
            // WORKAROUND: controlledにしないとonSelectionChangeが呼ばれない
            // https://github.com/necolas/react-native-web/issues/1538#issuecomment-627115832

            setSelection(e.nativeEvent.selection)
          }}
          selection={selection}
          placeholder={t('店名、ジャンル、最寄駅などから探す')}
          placeholderTextColor={'rgba(68, 68, 68, 0.4)'}
          onFocus={() => {
            if (selectedPanel !== 'search') {
              rootDispatch(actions.getSearchSuggests(keyword))
              dispatch({
                type: 'OPEN_PANEL',
                payload: {
                  selectedPanel: 'search',
                },
              })
            }
          }}
        />
      </View>
      <Separator />

      <TouchableOpacity
        style={[
          {
            height: PC_SEARCH_BAR_HEIGHT,
            width: 157,
            borderBottomWidth: 2,
            borderBottomColor:
              selectedPanel === 'date' ? Constants.ACCENT_COLOR : 'transparent',
            paddingLeft: 12,
            paddingVertical: 14,
          },
        ]}
        onPress={() => {
          dispatch({
            type: 'TOGGLE_PANEL',
            payload: {
              selectedPanel: 'date',
            },
          })
        }}
        onPressMinInterval={0}
      >
        <View
          style={[GeneralStyles.container, GeneralStyles.inlineAlignCenter]}
        >
          <FontAwesome5Pro
            name="calendar-alt"
            size={16}
            color={date ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)'}
            regular
          />
          <Text
            style={{
              marginLeft: 10,
              fontSize: 14,
              color: date ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)',
            }}
          >
            {date ? dayjs(date, 'YYYY-MM-DD').format('ll') : t('日付')}
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          {
            height: PC_SEARCH_BAR_HEIGHT,
            width: 157,

            borderBottomWidth: 2,
            borderBottomColor:
              selectedPanel === 'time' ? Constants.ACCENT_COLOR : 'transparent',

            paddingLeft: 12,
            paddingVertical: 14,
          },
        ]}
        onPress={() => {
          dispatch({
            type: 'TOGGLE_PANEL',
            payload: {
              selectedPanel: 'time',
            },
          })
        }}
        onPressMinInterval={0}
      >
        <View
          style={[GeneralStyles.container, GeneralStyles.inlineAlignCenter]}
        >
          <FontAwesome5Pro
            name="clock"
            size={16}
            color={time ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)'}
            regular
          />
          <Text
            style={{
              marginLeft: 10,
              fontSize: 14,
              color: time ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)',
              flex: 1,
            }}
          >
            {time || t('時間')}
          </Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          {
            height: PC_SEARCH_BAR_HEIGHT,
            width: 157,
            borderBottomWidth: 2,
            borderBottomColor:
              selectedPanel === 'partySize'
                ? Constants.ACCENT_COLOR
                : 'transparent',

            paddingLeft: 12,
            paddingVertical: 14,
          },
        ]}
        onPress={() => {
          dispatch({
            type: 'TOGGLE_PANEL',
            payload: {
              selectedPanel: 'partySize',
            },
          })
        }}
        onPressMinInterval={0}
      >
        <View
          style={[GeneralStyles.container, GeneralStyles.inlineAlignCenter]}
        >
          <FontAwesome5Pro
            name="user-friends"
            size={16}
            color={partySize ? Constants.BLACK_COLOR : 'rgba(68, 68, 68, 0.4)'}
            regular
          />
          <Text
            style={{
              marginLeft: 10,
              fontSize: 14,

              color: partySize
                ? Constants.BLACK_COLOR
                : 'rgba(68, 68, 68, 0.4)',
              flex: 1,
            }}
          >
            {partySize ? t('{{count}}人', { count: partySize }) : t('人数')}
          </Text>
        </View>
      </TouchableOpacity>

      {date || time || partySize ? (
        <TouchableOpacity
          style={{
            position: 'absolute',
            right: 12,
          }}
          hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
          onPress={() => {
            setDate(null)
            setTime(null)
            setPartySize(null)

            if ('party_size' in params || 'reserved_at' in params) {
              search(keyword, null, null)
            }
            dispatch({ type: 'CLOSE_PANEL' })
          }}
        >
          <FontAwesome5Pro
            name="times-circle"
            style={{
              color: 'rgba(68, 68, 68, 0.4)',
            }}
            solid
            size={12}
          />
        </TouchableOpacity>
      ) : null}

      {selectedPanel === 'partySize' && (
        <View
          style={{
            position: 'absolute',
            borderWidth: 1,
            borderColor: Constants.LIGHT_GRAY_COLOR,
            top: PC_SEARCH_BAR_HEIGHT,
            left: -1,
            width: searchBarWidth,

            height: 80,
            backgroundColor: 'white',
            justifyContent: 'center',
            paddingHorizontal: 16,
          }}
        >
          <View
            style={[
              GeneralStyles.inlineAlignCenter,
              { justifyContent: 'space-between' },
            ]}
          >
            <TouchableOpacity
              style={{
                height: 40,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: Constants.LIGHT_GRAY_COLOR,
                textAlign: 'center',
                width: 28,
                justifyContent: 'center',
              }}
              onPress={() => updatePartySizePage('back')}
              onPressMinInterval={0}
            >
              <FontAwesome5Pro
                name="caret-left"
                solid
                size={12}
                style={{
                  position: 'relative',
                  left: -1,
                }}
                color={Constants.BLACK_COLOR}
              />
            </TouchableOpacity>
            <View
              style={[
                GeneralStyles.inlineAlignCenter,
                { flexGrow: 2, marginLeft: 10 },
              ]}
            >
              {range(9 * (partySizePage - 1) + 1, 9 * partySizePage + 1).map(
                (n, index) => (
                  <TouchableOpacity
                    key={`${n}`}
                    style={{
                      height: 40,

                      flexBasis: 80,
                      lineHeight: 40,
                      borderRadius: 4,
                      borderWidth: 1,
                      borderColor: 'rgba(0, 0, 0, 0.16)',
                      textAlign: 'center',
                      flex: 1,
                      marginLeft: index === 0 ? 0 : 4,
                      ...(n === partySize && {
                        borderColor: Constants.ACCENT_COLOR,
                        borderWidth: 2,
                      }),
                      justifyContent: 'center',
                    }}
                    onPress={() => {
                      setPartySize(n)

                      if (date && time) {
                        search(
                          keyword,
                          moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm'),
                          n
                        )
                      }

                      if (!date) {
                        dispatch({
                          type: 'OPEN_PANEL',
                          payload: {
                            selectedPanel: 'date',
                          },
                        })
                        return
                      }

                      if (!time) {
                        dispatch({
                          type: 'OPEN_PANEL',
                          payload: {
                            selectedPanel: 'time',
                          },
                        })
                        return
                      }

                      dispatch({
                        type: 'CLOSE_PANEL',
                      })
                    }}
                  >
                    <Text>{n}</Text>
                  </TouchableOpacity>
                )
              )}
            </View>
            <TouchableOpacity
              style={{
                marginLeft: 10,
                height: 40,
                lineHeight: 40,
                borderRadius: 4,
                borderWidth: 1,
                borderColor: Constants.LIGHT_GRAY_COLOR,
                textAlign: 'center',
                justifyContent: 'center',

                width: 28,
              }}
              onPress={() => updatePartySizePage('next')}
              onPressMinInterval={0}
            >
              <FontAwesome5Pro
                name="caret-right"
                solid
                size={12}
                style={{
                  position: 'relative',
                  left: 4 / 2, // 幅/2分減らしてセンタリングしてるように見せる
                }}
                color={Constants.BLACK_COLOR}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {selectedPanel === 'date' && (
        <View
          style={[
            {
              position: 'absolute',
              borderWidth: 1,
              borderColor: Constants.LIGHT_GRAY_COLOR,
              top: PC_SEARCH_BAR_HEIGHT,
              left: -1,
              width: searchBarWidth,

              backgroundColor: 'white',
              // paddingHorizontal: 16,
              flex: 1,
            },
          ]}
        >
          <DatePanel
            searchBarWidth={searchBarWidth}
            dateTime={dateTime}
            date={date}
            onDayPress={(day) => {
              setDate(day.dateString)

              if (time && partySize) {
                search(
                  keyword,
                  moment(`${day.dateString} ${time}`, 'YYYY-MM-DD HH:mm'),
                  partySize
                )
              }

              if (!time) {
                dispatch({
                  type: 'OPEN_PANEL',
                  payload: {
                    selectedPanel: 'time',
                  },
                })
                return
              }

              if (!partySize) {
                dispatch({
                  type: 'OPEN_PANEL',
                  payload: {
                    selectedPanel: 'partySize',
                  },
                })
                return
              }

              dispatch({
                type: 'CLOSE_PANEL',
              })
            }}
          />
        </View>
      )}
      {selectedPanel === 'time' && (
        <View
          style={[
            {
              position: 'absolute',
              borderWidth: 1,
              borderColor: Constants.LIGHT_GRAY_COLOR,
              top: PC_SEARCH_BAR_HEIGHT,
              left: -1,
              width: searchBarWidth,

              height: 80,
              backgroundColor: 'white',
              justifyContent: 'center',
              paddingHorizontal: 16,
              flex: 1,
            },
          ]}
        >
          <View
            style={[GeneralStyles.inlineAlignCenter, GeneralStyles.container]}
          >
            <View style={GeneralStyles.container}>
              <View
                style={[
                  GeneralStyles.inlineAlignCenter,
                  { justifyContent: 'space-between' },
                ]}
              >
                <FontAwesome5Pro
                  name="sun"
                  light
                  size={20}
                  color={Constants.BLACK_COLOR}
                />

                <TouchableOpacity
                  style={{
                    marginLeft: 12,
                    height: 40,
                    lineHeight: 40,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: Constants.LIGHT_GRAY_COLOR,
                    textAlign: 'center',
                    width: 28,
                    justifyContent: 'center',
                  }}
                  onPress={() => updateLunchTimePage('back')}
                  onPressMinInterval={0}
                >
                  <FontAwesome5Pro
                    name="caret-left"
                    solid
                    size={12}
                    style={{
                      position: 'relative',
                      left: -1,
                    }}
                    color={Constants.BLACK_COLOR}
                  />
                </TouchableOpacity>
                <View
                  style={[GeneralStyles.inlineAlignCenter, { flexGrow: 2 }]}
                >
                  {range(4 * (lunchTimePage - 1), 4 * lunchTimePage).map(
                    (n, index) => {
                      const t = 12 * 60 + n * 30 - 12 * 60

                      const hours = Math.floor(t / 60)
                      const minutes = t - hours * 60

                      const timeStr = `${padStart(
                        String(hours),
                        2,
                        '0'
                      )}:${padStart(String(minutes), 2, '0')}`

                      return (
                        <TouchableOpacity
                          key={`${timeStr}`}
                          style={{
                            height: 40,
                            flexBasis: 72,
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: Constants.LIGHT_GRAY_COLOR,
                            textAlign: 'center',
                            flex: 1,
                            marginLeft: index === 0 ? 8 : 4,
                            ...(time === timeStr && {
                              borderWidth: 2,
                              borderColor: Constants.ACCENT_COLOR,
                            }),
                            justifyContent: 'center',
                          }}
                          onPress={() => {
                            setTime(timeStr)

                            if (date && partySize) {
                              search(
                                keyword,
                                moment(
                                  `${date} ${timeStr}`,
                                  'YYYY-MM-DD HH:mm'
                                ),
                                partySize
                              )
                            }

                            if (!date) {
                              dispatch({
                                type: 'OPEN_PANEL',
                                payload: {
                                  selectedPanel: 'date',
                                },
                              })
                              return
                            }

                            if (!partySize) {
                              dispatch({
                                type: 'OPEN_PANEL',
                                payload: {
                                  selectedPanel: 'partySize',
                                },
                              })
                              return
                            }

                            dispatch({
                              type: 'CLOSE_PANEL',
                            })
                          }}
                        >
                          <Text>{timeStr}</Text>
                        </TouchableOpacity>
                      )
                    }
                  )}
                </View>
                <TouchableOpacity
                  style={{
                    height: 40,
                    lineHeight: 40,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: Constants.LIGHT_GRAY_COLOR,
                    textAlign: 'center',
                    justifyContent: 'center',

                    width: 28,
                    marginLeft: 8,
                  }}
                  onPress={() => updateLunchTimePage('next')}
                  onPressMinInterval={0}
                >
                  <FontAwesome5Pro
                    name="caret-right"
                    solid
                    size={12}
                    style={{
                      position: 'relative',
                      left: 4 / 2, // 幅/2分減らしてセンタリングしてるように見せる
                    }}
                    color={Constants.BLACK_COLOR}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={[GeneralStyles.container, { marginLeft: 30 }]}>
              <View
                style={[
                  GeneralStyles.inlineAlignCenter,
                  { justifyContent: 'space-between' },
                ]}
              >
                <FontAwesome5Pro
                  name="moon"
                  light
                  size={20}
                  color={Constants.BLACK_COLOR}
                />

                <TouchableOpacity
                  style={{
                    marginLeft: 12,
                    height: 40,
                    lineHeight: 40,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: Constants.LIGHT_GRAY_COLOR,
                    textAlign: 'center',
                    width: 28,
                    justifyContent: 'center',
                  }}
                  onPress={() => updateDinnerTimePage('back')}
                  onPressMinInterval={0}
                >
                  <FontAwesome5Pro
                    name="caret-left"
                    solid
                    size={12}
                    style={{
                      position: 'relative',
                    }}
                    color={Constants.BLACK_COLOR}
                  />
                </TouchableOpacity>
                <View
                  style={[GeneralStyles.inlineAlignCenter, { flexGrow: 2 }]}
                >
                  {range(4 * (dinnerTimePage - 1), 4 * dinnerTimePage).map(
                    (n, index) => {
                      const t = 12 * 60 + n * 30 - 12 * 60

                      const hours = Math.floor(t / 60)
                      const minutes = t - hours * 60

                      const timeStr = `${padStart(
                        String(hours),
                        2,
                        '0'
                      )}:${padStart(String(minutes), 2, '0')}`

                      return (
                        <TouchableOpacity
                          key={`${timeStr}`}
                          style={{
                            height: 40,
                            lineHeight: 40,
                            flexBasis: 72,
                            borderRadius: 4,
                            borderWidth: 1,
                            borderColor: Constants.LIGHT_GRAY_COLOR,
                            textAlign: 'center',
                            flex: 1,
                            marginLeft: index === 0 ? 8 : 4,
                            ...(time === timeStr && {
                              borderWidth: 2,
                              borderColor: Constants.ACCENT_COLOR,
                            }),
                            justifyContent: 'center',
                          }}
                          onPress={() => {
                            setTime(timeStr)

                            if (date && partySize) {
                              search(
                                keyword,
                                moment(
                                  `${date} ${timeStr}`,
                                  'YYYY-MM-DD HH:mm'
                                ),
                                partySize
                              )
                            }

                            if (!date) {
                              dispatch({
                                type: 'OPEN_PANEL',
                                payload: {
                                  selectedPanel: 'date',
                                },
                              })
                              return
                            }

                            if (!partySize) {
                              dispatch({
                                type: 'OPEN_PANEL',
                                payload: {
                                  selectedPanel: 'partySize',
                                },
                              })
                              return
                            }

                            dispatch({
                              type: 'CLOSE_PANEL',
                            })
                          }}
                        >
                          <Text>{timeStr}</Text>
                        </TouchableOpacity>
                      )
                    }
                  )}
                </View>
                <TouchableOpacity
                  style={{
                    height: 40,
                    lineHeight: 40,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: Constants.LIGHT_GRAY_COLOR,
                    textAlign: 'center',
                    justifyContent: 'center',

                    width: 28,
                    marginLeft: 8,
                  }}
                  onPress={() => updateDinnerTimePage('next')}
                  onPressMinInterval={0}
                >
                  <FontAwesome5Pro
                    name="caret-right"
                    solid
                    size={12}
                    style={{
                      position: 'relative',
                      left: 4 / 2, // 幅/2分減らしてセンタリングしてるように見せる
                    }}
                    color={Constants.BLACK_COLOR}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

function Separator() {
  return (
    <View
      style={{
        width: 1,
        backgroundColor: Constants.LIGHT_GRAY_COLOR,
        height: 28,
      }}
    />
  )
}

function NewIcon() {
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1.0, y: 0 }}
      colors={['#ff2f3e', '#ffb100']}
      style={{
        borderRadius: 2,
        height: 12,
        width: 32,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 4,
      }}
    >
      <Text
        style={{
          fontSize: 8,
          color: 'white',
          fontWeight: '600',
        }}
      >
        NEW
      </Text>
    </LinearGradient>
  )
}

function LoggedInHeaderMenu() {
  const user = useSelector((state) => state.entity.users[state.auth.id])

  const isTimeline = !!useRouteMatch({
    path: '/timeline',
  })
  const isDelivery = !!useRouteMatch({
    path: '/delivery',
  })
  const isOnlineStore = !!useRouteMatch({
    path: '/online_store',
  })

  return (
    <HeaderMenu>
      <HeaderMenuList marginLeft={0}>
        <HeaderLink to="/delivery" active={isDelivery}>
          {t('デリバリー')}
        </HeaderLink>
      </HeaderMenuList>
      <HeaderMenuList marginLeft={16}>
        <HeaderLink to="/online_store" active={isOnlineStore}>
          {t('お取り寄せ')}
        </HeaderLink>
      </HeaderMenuList>
      {false && (
        <HeaderMenuList marginLeft={16}>
          <HeaderLink to="/timeline" active={isTimeline}>
            {t('タイムライン')}
          </HeaderLink>
        </HeaderMenuList>
      )}
      <HeaderMenuList marginLeft={16}>
        <UserButton user={user} />
      </HeaderMenuList>
      <View
        style={{
          flex: 1,
          marginLeft: 24,
          width: 1,
          height: 20, // TODO: 50%で指定したい
          backgroundColor: Constants.LIGHT_GRAY_COLOR,
        }}
      />
      <HeaderMenuList marginLeft={24}>
        <MessageButton />
      </HeaderMenuList>
      <HeaderMenuList marginLeft={24}>
        <MoreButton />
      </HeaderMenuList>
    </HeaderMenu>
  )
}

function NoLoggedInHeaderMenu() {
  const location = useLocation()
  const redirectTo = location.pathname + location.search + location.hash
  const isDelivery = !!useRouteMatch({
    path: '/delivery',
  })
  const isOnlineStore = !!useRouteMatch({
    path: '/online_store',
  })

  return (
    <HeaderMenu>
      <HeaderMenuList>
        <HeaderLink to="/delivery" active={isDelivery}>
          {t('デリバリー')}
        </HeaderLink>
      </HeaderMenuList>
      <HeaderMenuList marginLeft={16}>
        <HeaderLink to="/online_store" active={isOnlineStore}>
          {t('お取り寄せ')}
        </HeaderLink>
      </HeaderMenuList>
      <View
        style={{
          flex: 1,
          marginLeft: 24,
          width: 1,
          height: 20, // TODO: 50%で指定したい
          backgroundColor: Constants.LIGHT_GRAY_COLOR,
        }}
      />
      <HeaderMenuList>
        <HeaderLink
          to={{
            pathname: '/sign_up',
            search: `?redirect_to=${encodeURIComponent(redirectTo)}`,
            state: {
              modal: true,
            },
          }}
        >
          {t('登録')}
        </HeaderLink>
      </HeaderMenuList>
      <HeaderMenuList>
        <HeaderLink
          to={{
            pathname: '/sign_in',
            search: `?redirect_to=${encodeURIComponent(redirectTo)}`,
            state: {
              modal: true,
            },
          }}
        >
          {t('ログイン')}
        </HeaderLink>
      </HeaderMenuList>
      <HeaderMenuList
        style={{
          width: 1,
          height: '50%',
          backgroundColor: Constants.LIGHT_GRAY_COLOR,
        }}
      />
      <HeaderMenuList>
        <MoreButton />
      </HeaderMenuList>
    </HeaderMenu>
  )
}

function PCHeader({
  inputRef,
  keyword,
  setKeyword,
  search,
  showFilterBar,
  onLayout,
  headerAndBannerRef,
  headerAndBannerHeight,
  filterBarHeight,
  setFilterBarHeight,
  backgroundColor = 'white',
  showHeaderBorderBottom = true,
}) {
  const ref = useRef(null)
  const isLoggedIn = useSelector((state) => !!state.auth.id)

  const [selectionPosition, setSelectionPosition] = useState(0)

  const [selectedPanel, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'OPEN_PANEL':
        return action.payload.selectedPanel
      case 'TOGGLE_PANEL':
        return state === action.payload.selectedPanel
          ? null
          : action.payload.selectedPanel
      case 'CLOSE_PANEL':
        return null
      default:
        console.error(`Error: no action type ${action.type} exists.`)
        return state
    }
  }, null)

  useClickOutside(ref, () => {
    dispatch({ type: 'CLOSE_PANEL' })
  })

  return (
    <View
      style={{
        paddingVertical: 20,
        flex: 1,
        position: 'relative',
        borderBottomWidth: 0.5,
        borderBottomColor: showFilterBar
          ? 'transparent'
          : Constants.BORDER_COLOR,
      }}
      onLayout={onLayout}
    >
      <CommonHeader>
        <View
          style={[GeneralStyles.container, GeneralStyles.inlineAlignCenter]}
        >
          <Link to="/">
            <img src={logo} alt="" height="32px" />
          </Link>
          <View
            ref={ref}
            style={{
              marginLeft: 32,
              flex: 1,
              maxWidth: 868,
            }}
          >
            <PCSearchBar
              inputRef={inputRef}
              keyword={keyword}
              setKeyword={setKeyword}
              search={search}
              setSelectionPosition={setSelectionPosition}
              dispatch={dispatch}
              selectedPanel={selectedPanel}
            />
            {selectedPanel === 'search' && (
              <ScrollView
                style={{
                  position: 'absolute',
                  zIndex: 100,
                  top: PC_SEARCH_BAR_HEIGHT + 1,
                  width: '100%',
                  maxHeight: 620,
                  backgroundColor: 'white',

                  borderWidth: 1,
                  borderColor: Constants.LIGHT_GRAY_COLOR,
                }}
              >
                <SearchSuggestsView
                  inputRef={inputRef}
                  text={keyword}
                  setText={setKeyword}
                  search={search}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_PANEL' })
                  }}
                  selectionPosition={selectionPosition}
                  setSelectionPosition={setSelectionPosition}
                />
              </ScrollView>
            )}
          </View>
        </View>
        {isLoggedIn ? <LoggedInHeaderMenu /> : <NoLoggedInHeaderMenu />}
      </CommonHeader>

      {showFilterBar && headerAndBannerHeight > 0 && (
        <View
          style={{
            paddingLeft: 64 + 8,
            top: headerAndBannerHeight,
            paddingTop: 3,
            width: '100%',
            position: 'absolute',
            paddingBottom: 20,
            borderBottomWidth: 0.5,
            borderBottomColor: Constants.BORDER_COLOR,
            backgroundColor: 'white',
          }}
          onLayout={(event) => {
            setFilterBarHeight(event.nativeEvent.layout.height)
          }}
        >
          <FilterBar
            headerAndBannerRef={headerAndBannerRef}
            headerAndBannerHeight={headerAndBannerHeight + filterBarHeight}
            hidePanel={!!selectedPanel}
          />
        </View>
      )}
    </View>
  )
}

export default PCHeader
