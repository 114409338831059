import * as ActionTypes from 'ActionTypes'

interface AuthState {
  id?: number
  token?: string
  latitude?: number
  longitude?: number
  isAppLocationAccurate: boolean
}

const initialState: AuthState = {
  id: null,
  token: null,

  // TODO: 本当はここに置くのはよくないが、とりあえずwebでクライアントで取得した位置情報をここで保管する
  latitude: null,
  longitude: null,
  isAppLocationAccurate: false,
}

export default (state = initialState, action): AuthState => {
  switch (action.type) {
    case ActionTypes.SYNC_USER_SUCCEEDED:
      return {
        ...state,
        id: action.payload.id,
      }
    case ActionTypes.SIGN_UP_SUCCEEDED:
    case ActionTypes.SIGN_IN_SUCCEEDED:
      return {
        ...state,
        id: action.payload.id,
        token: action.payload.token,
      }
    case ActionTypes.SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        id: null,
        token: null,
      }
    case ActionTypes.UPDATE_CURRENT_LOCATION_SUCCEEDED:
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        isAppLocationAccurate: action.payload.isAppLocationAccurate,
      }
    default:
      return state
  }
}
