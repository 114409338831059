import React from 'react'
import useLocationParams from './useLocationParams'

function useRedirectTo(defaultPath = '/') {
  const params = useLocationParams()

  let redirectTo = params.redirect_to || null

  if (!(redirectTo && /^\/[^/]/.test(redirectTo))) {
    redirectTo = defaultPath
  }

  return redirectTo
}

export interface WithRedirectToProps {
  redirectTo: string
}

export function withRedirectTo(Component) {
  return (props) => {
    const redirectTo = useRedirectTo()

    return <Component {...props} redirectTo={redirectTo} />
  }
}

export default useRedirectTo
