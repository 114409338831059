import * as ActionTypes from 'ActionTypes'
import * as H from 'history'

export const openRequestReservationModal = (reservationStatus) => {
  return {
    type: ActionTypes.OPEN_REQUEST_RESERVATION_MODAL,
    reservationStatus,
  } as const
}

export const getRequestReservationForm = (reservationStatus) => {
  return {
    type: ActionTypes.GET_REQUEST_RESERVATION_FORM,
    reservationStatus,
  } as const
}

export const selectSeatRequestReservation = (params) => {
  return {
    type: ActionTypes.SELECT_SEAT_REQUEST_RESERVATION,
    params,
  } as const
}

export const selectCourseRequestReservation = (params) => {
  return {
    type: ActionTypes.SELECT_COURSE_REQUEST_RESERVATION,
    params,
  } as const
}

export const reserveRequestReservation = (params, history: H.History<any>) => {
  return {
    type: ActionTypes.RESERVE_REQUEST_RESERVATION,
    params,
    history,
  } as const
}

export const verifyDeviceUser = (params, from) => {
  return {
    type: ActionTypes.VERIFY_DEVICE_USER,
    params,
    from,
  } as const
}
