import { schema } from 'normalizr'

import review from './review'
import reservationUser from './reservationUser'
import restaurantList from './restaurantList'

const feed = new schema.Union(
  {
    reservation_user: reservationUser,
    review: review,
    restaurant_list: restaurantList
  },
  'type'
)

export default feed
