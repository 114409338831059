import { find } from 'lodash'

import * as ActionTypes from 'ActionTypes'

import { parseReservationTime } from 'modules/time'

interface RequestReservationState {
  state: 'start' | 'progress' | 'end' | 'error'
  error: any
  loading: boolean
  status?: null
  restaurantSeat: any
  restaurantCourse: any
  seats: any[]
  courses: null | any[]
  cancelPolicies: any[]
  reserveType: null | any
  reservation: null | any
  reservationElement: null | any
  willCallAtStr: string | null
  willCallAtReasonStr?: string | null
  allowReservationTimeRange?: number[]
  isFirstReservation?: boolean
  isFirstReservationRequest: boolean
  canReconfirm?: boolean
  reconfirm?: boolean
  memo?: string
  reservationNotice?: string
  isCardAlwaysRequired?: boolean
  reservationAgencyFee?: number
  durationBeforeRange: number[]
  durationAfterRange: number[]

  restaurantId: number | null
  partySize: number | null
  reservedAt: any | null
}

const initialState: RequestReservationState = {
  state: 'start', // start: リクエスト画面, progress: リクエスト中, end: リクエスト終了
  error: null,
  loading: true,
  restaurantSeat: null,
  restaurantCourse: null,
  seats: [],
  courses: null,
  cancelPolicies: [],
  reserveType: null,
  reservation: null,
  reservationElement: null,
  willCallAtStr: null,
  durationBeforeRange: [],
  durationAfterRange: [],
  isCardAlwaysRequired: false,
  reservationAgencyFee: 0,

  isFirstReservationRequest: false,

  restaurantId: null,
  partySize: null,
  reservedAt: null,
}

export default (state = initialState, action): RequestReservationState => {
  const response = { ...state }
  switch (action.type) {
    case ActionTypes.OPEN_REQUEST_RESERVATION_MODAL:
      return {
        ...initialState,
        restaurantId: action.reservationStatus.restaurant_id,
        partySize: action.reservationStatus.party_size,
        reservedAt: parseReservationTime(action.reservationStatus.reserved_at),
      }
    case ActionTypes.GET_REQUEST_RESERVATION_SEATS:
    case ActionTypes.GET_REQUEST_RESERVATION_COURSES:
      return {
        ...state,
        loading: true,
      }

    case ActionTypes.GET_REQUEST_RESERVATION_SEATS_SUCCEEDED: {
      const responseSeats = action.payload

      return {
        ...state,
        seats: responseSeats,
        restaurantSeat: responseSeats[0],
      }
    }

    case ActionTypes.GET_REQUEST_RESERVATION_SEATS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    case ActionTypes.GET_REQUEST_RESERVATION_COURSES_SUCCEEDED: {
      const responseCourses = action.payload

      return {
        ...state,
        loading: false,
        courses: responseCourses,
        restaurantCourse: responseCourses[0],
      }
    }

    case ActionTypes.GET_REQUEST_RESERVATION_CANCEL_POLICIES_SUCCEEDED:
      return {
        ...state,
        cancelPolicies: action.payload,
      }

    case ActionTypes.SELECT_SEAT_REQUEST_RESERVATION:
      return {
        ...state,
        status: null,
        restaurantSeat: find(state.seats, {
          id: action.params.restaurant_seat_id,
        }),
        restaurantCourse: null,
        courses: null,
      }
    case ActionTypes.SELECT_COURSE_REQUEST_RESERVATION:
      return {
        ...state,
        status: null,
        restaurantCourse: find(state.courses, {
          id: action.params.restaurant_course_id,
        }),
      }

    case ActionTypes.RESERVE_REQUEST_RESERVATION:
      return {
        ...state,
        state: 'progress',
      }
    case ActionTypes.RESERVE_REQUEST_RESERVATION_ERROR:
      return {
        ...state,
        state: 'error',
        loading: false,
      }
    case ActionTypes.RESERVE_REQUEST_RESERVATION_SUCCEEDED:
      response.state = 'end'
      response.reserveType = action.payload.type
      if (response.reserveType === 'reservation') {
        response.reservation = action.payload.reservation
      } else if (response.reserveType === 'reservation_element') {
        response.reservationElement = action.payload.reservation_element
      }
      return response

    case ActionTypes.GET_REQUEST_RESERVATION_FORM_SUCCEEDED: {
      const payload = action.payload
      return {
        ...state,
        willCallAtStr: payload.will_call_at_str,
        willCallAtReasonStr: payload.will_call_at_reason_str,
        allowReservationTimeRange: payload.allow_reservation_time_range,
        durationBeforeRange: payload.duration_before_range,
        durationAfterRange: payload.duration_after_range,
        isFirstReservation: payload.is_first_reservation,
        isFirstReservationRequest: payload.is_first_reservation_request,
        canReconfirm: payload.can_reconfirm,
        reconfirm: payload.reconfirm,
        memo: payload.memo,
        reservationNotice: payload.reservation_notice,
        isCardAlwaysRequired: payload.is_card_always_required,
        reservationAgencyFee: payload.reservation_agency_fee,
      }
    }

    default:
      return state
  }
}
