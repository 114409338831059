import * as ActionTypes from 'ActionTypes'

export const sync = () => {
  return {
    type: ActionTypes.SYNC,
  } as const
}

export const syncUser = () => {
  return {
    type: ActionTypes.SYNC_USER,
  } as const
}

export const signIn = (phoneNumber, password) => {
  return {
    type: ActionTypes.SIGN_IN,
    payload: {
      phoneNumber,
      password,
    },
  } as const
}

export const signInWithToken = ({
  token,
  silent = false,
  isSignUp = false,
}) => {
  return {
    type: ActionTypes.SIGN_IN_WITH_TOKEN,
    payload: { token, silent, isSignUp },
  } as const
}

export const signUp = (params, redirectTo, history, from = '') => {
  return {
    type: ActionTypes.SIGN_UP,
    params: params,
    redirectTo,
    history,
    from,
  } as const
}

export const signOut = ({ redirectTo, history }) => {
  return {
    type: ActionTypes.SIGN_OUT,
    payload: { redirectTo },
    history,
  } as const
}
