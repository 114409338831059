import React, { useEffect, useRef, useCallback } from 'react'

import { throttle } from 'lodash'

function useThrottleEventCallback(callback, wait) {
  const savedCallback = useRef<(...args: any) => void>()
  const savedThrottledCallback = useRef<Function>((...args: any) => {
    throw new Error('Cannot call an event handler while rendering.')
  })

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // remember the latest throttled callback which calls the saved callback
  useEffect(() => {
    savedThrottledCallback.current = throttle(
      (...args) => {
        savedCallback.current(...args)
      },
      wait,
      { trailing: false }
    )
  }, [wait])

  return useCallback((...args) => {
    const fn = savedThrottledCallback.current
    return fn(...args)
  }, [])
}

export default useThrottleEventCallback
