import * as ActionTypes from 'ActionTypes'

interface Prefecture {
  id: string
  name: string
}

interface TransportationType {
  key: string
  value: string
}

interface State {
  prefectures: Prefecture[]
  transportationTypes: TransportationType[]
}

const initialState = {
  prefectures: [],
  transportationTypes: [],
}

export default (state: State = initialState, action): State => {
  switch (action.type) {
    case ActionTypes.GET_CREW_REQUEST_SUCCEEDED:
      return {
        ...state,
        prefectures: action.payload.prefectures,
        transportationTypes: action.payload.transportationTypes,
      }
    default:
      return state
  }
}
