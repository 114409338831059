import React, { useState } from 'react'
import { View } from 'react-native'

import MultiSlider from '@ptomasroos/react-native-multi-slider'

import useParamsUpdater from 'modules/useParamsUpdater'

import useSearchParams from 'modules/useSearchParams'

import { Text } from '@hello-ai/ar_shared'

function DistanceFilter() {
  const params = useSearchParams()

  const maxDistance = params.max_distance

  const [value, setValue] = useState(maxDistance || 1.6)

  const [sliderLength, setSliderLength] = useState(0)

  const updateParams = useParamsUpdater()

  return (
    <View
      style={{ flex: 1, marginTop: 16 }}
      onLayout={(event) => {
        setSliderLength(event.nativeEvent.layout.width)
      }}
    >
      <MultiSlider
        containerStyle={{
          flex: 1,
        }}
        values={[value]}
        sliderLength={sliderLength}
        onValuesChange={(values) => {
          setValue(values[0])
        }}
        onValuesChangeFinish={(values) => {
          setValue(values[0])
          updateParams({
            max_distance: values[0],
          })
        }}
        trackStyle={{ height: 8, borderRadius: 12 }}
        unselectedStyle={{
          backgroundColor: Constants.LIGHT_GRAY_COLOR,
        }}
        selectedStyle={{
          backgroundColor: Constants.ACCENT_COLOR,
          opacity: 0.3,
        }}
        markerContainerStyle={{
          position: 'absolute',
          width: 48,
          height: 53,
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        min={0}
        max={10.0}
        step={0.1}
        customMarker={() => {
          const maxDistanceText = value.toFixed(1) + 'km'
          return (
            <View
              style={{
                width: 24,
                height: 24,
                borderRadius: 12,
                backgroundColor: Constants.ACCENT_COLOR,
              }}
            >
              <Text
                style={{
                  backgroundColor: 'white',
                  position: 'relative',
                  height: 12,
                  textAlign: 'center',
                  left: -50 + 12,
                  width: 100,
                  fontWeight: '600',
                  fontSize: 12,
                  lineHeight: 12,
                  bottom: 12 + 8,
                }}
              >
                {maxDistanceText}
              </Text>
            </View>
          )
        }}
      />
    </View>
  )
}

export default React.memo(DistanceFilter)
