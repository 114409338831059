import { fromPairs } from 'lodash'
import { useLocation } from 'react-router'

interface Dictionary<T> {
  [index: string]: T
}

function useLocationParams(): Dictionary<string> {
  const location = useLocation()

  return fromPairs([...new URLSearchParams(location.search).entries()])
}

export default useLocationParams
