import { Platform } from 'react-native'

const isStaging = window.location.hostname === 'staging.autoreserve.com'

function getDefaultApiUrl() {
  if (process.env.NODE_ENV === 'development') {
    if (Platform.OS === 'android') {
      return 'http://10.0.2.2:5100/api'
    }
    return 'http://api.lvh.me:5100/api'
  }

  if (isStaging) {
    return 'https://api.staging.autoreserve.com'
  }

  return 'https://api.autoreserve.com'
}

interface ConfigObject {
  apiUrl?: string
  dryReserve?: boolean
}

class Config {
  static defaultConfig: ConfigObject = {
    apiUrl: getDefaultApiUrl(),
    dryReserve: false,
  }

  config: ConfigObject

  static STORAGE_KEY = 'AutoReserve:config'

  constructor() {
    this.config = {}
  }

  load() {
    let str
    try {
      str = localStorage.getItem(Config.STORAGE_KEY)
    } catch (e) {
      console.error(e)
    }

    if (!str) return
    try {
      this.config = JSON.parse(str)
    } catch (e) {
      console.error(e)
    }
  }

  setItem(key, value) {
    this.config[key] = value
    this.save()
  }

  save() {
    const value = JSON.stringify(this.config)
    localStorage.setItem(Config.STORAGE_KEY, value)
  }

  get apiUrl() {
    return this.config.apiUrl || this.defaultConfig.apiUrl
  }

  get tableApiUrl() {
    const { hostname, ...rest } = new URL(this.apiUrl)

    if (hostname === 'api.staging.autoreserve.com') {
      return 'https://api-table.staging.autoreserve.com'
    } else if (['api.lvh.me', 'lvh.me', '10.0.2.2'].includes(hostname)) {
      return 'http://api-table.lvh.me:5100'
    }

    return 'https://api-table.autoreserve.com'
  }

  getWebSocketUrl(token) {
    if (
      process.env.NODE_ENV === 'production' ||
      this.apiUrl === 'https://api.autoreserve.com' ||
      this.apiUrl === 'https://api.staging.autoreserve.com'
    ) {
      return `wss://ws.autoreserve.com/cable?token=${token}`
    } else {
      return `ws://lvh.me:5100/cable?token=${token}`
    }
  }

  get dryReserve() {
    return (
      this.isTest || this.config.dryReserve || this.defaultConfig.dryReserve
    )
  }

  get isExperimental() {
    return process.env.NODE_ENV === 'development' || isStaging
  }

  get isDevOnly() {
    return process.env.NODE_ENV === 'development' || isStaging
  }

  get defaultConfig() {
    return Config.defaultConfig
  }

  get isTest() {
    // return true
    return typeof window.Cypress !== 'undefined'
  }

  get isStaging() {
    return isStaging
  }
}

const config = new Config()
config.load()

export default config
