import * as ActionTypes from 'ActionTypes'

export function displayToastError(message, duration = 4000) {
  return {
    type: ActionTypes.UI_TOAST_DISPLAY_ERROR,
    payload: {
      message,
      type: 'error',
      duration,
    },
  } as const
}

export function displayToastWarning(message, duration = 3000) {
  return {
    type: ActionTypes.UI_TOAST_DISPLAY_WARNING,
    payload: {
      message,
      type: 'warning',
      duration,
    },
  } as const
}

export function displayToastInfo(message, duration = 2000) {
  return {
    type: ActionTypes.UI_TOAST_DISPLAY_INFO,
    payload: {
      message,
      type: 'info',
      duration,
    },
  } as const
}

export function removeToast() {
  return {
    type: ActionTypes.UI_TOAST_REMOVE,
  } as const
}

export function startFetch() {
  return {
    type: ActionTypes.UI_FETCHING,
  } as const
}

export function endFetch() {
  return {
    type: ActionTypes.UI_FETCHED,
  } as const
}
