import { fork, call, put, takeEvery } from './helpers/redux-saga'
import * as api from 'api'
import * as ui from 'actions/ui'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'
import { normalize } from 'normalizr'
import schemas from 'schema'

import { t } from 'modules/i18n'

import {
  isExternalLogin,
  getCurrentOrigin,
  getRedirectTo,
} from 'modules/widgets'

import { persistor } from 'store'
import { currentLocale } from 'modules/locale'

import * as actions from 'actions'

function* sendCodeForLogin(
  action: ReturnType<typeof actions.sendCodeForLogin>
) {
  const { code, phoneNumber, history, redirectTo, isModal } = action.payload
  const { response, error } = yield* call(
    api.sendCodeForLogin,
    code,
    phoneNumber
  )
  if (response) {
    yield* put({
      type: ActionTypes.SEND_CODE_FOR_LOGIN_SUCCEEDED,
      payload: {
        phoneCode: code,
        phoneNumber: phoneNumber,
      },
    })

    history.push({
      pathname: '/sign_in/code',
      search: `?redirect_to=${encodeURIComponent(redirectTo)}${
        isExternalLogin()
          ? `&is_external_login=true&locale=${currentLocale()}`
          : ''
      }`,
      state: {
        modal: isModal,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* reSendCodeForLogin(
  action: ReturnType<typeof actions.reSendCodeForLogin>
) {
  const { code, phoneNumber } = action.payload
  const { response, error } = yield* call(
    api.sendCodeForLogin,
    code,
    phoneNumber
  )
  if (response) {
    yield* put(ui.displayToastInfo(t('新しい認証コードを送信しました')))
    yield* put({
      type: ActionTypes.RE_SEND_CODE_FOR_LOGIN_SUCCEEDED,
      payload: {
        phoneCode: code,
        phoneNumber: phoneNumber,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* callCodeForLogin(
  action: ReturnType<typeof actions.callCodeForLogin>
) {
  const { code, phoneNumber } = action.payload
  const { response, error } = yield* call(
    api.callCodeForLogin,
    code,
    phoneNumber
  )
  if (response) {
    yield* put(ui.displayToastInfo(t('認証コードを電話します')))
  } else {
    yield* handleApiError(error)
  }
}

function* login(action: ReturnType<typeof actions.login>) {
  const { phoneCode, phoneNumber, code, redirectTo, history } = action.payload
  const { response, error } = yield* call(
    api.login,
    phoneCode,
    phoneNumber,
    code
  )
  if (response) {
    yield* fork(commonLogin, response, redirectTo, history)
  } else {
    yield* handleApiError(error)
  }
}

function* loginWithPassword(
  action: ReturnType<typeof actions.loginWithPassword>
) {
  const { phoneCode, phoneNumber, email, password, redirectTo, history } =
    action.payload
  const { response, error } = yield* call(
    api.loginWithPassword,
    phoneCode,
    phoneNumber,
    email,
    password
  )
  if (response) {
    yield* fork(commonLogin, response, redirectTo, history)
  } else {
    yield* handleApiError(error)
  }
}

function* commonLogin(response, redirectTo = '/', history) {
  const { entities } = normalize(response.data, schemas.user)
  yield* put({
    type: ActionTypes.SET_ENTITIES,
    payload: entities,
  })

  yield* put(ui.displayToastInfo(t('ログインしました')))

  yield* put({
    type: ActionTypes.SIGN_IN_SUCCEEDED,
    payload: response.data,
  })

  if (isExternalLogin()) {
    const CLOSE_CHECK_THRESHOLD = 2000
    window.opener.postMessage(
      {
        type: 'SIGN_IN',
        payload: {
          token: response.data.token,
          isSignUp: false,
        },
      },
      getCurrentOrigin()
    )

    yield persistor.flush()

    yield* call([window, window.close])

    setTimeout(() => {
      if (!window.closed) {
        // TODO: アプリ内ブラウザの場合の実装をする
        // アプリ内ブラウザで開いた場合など、ポップアップとして開かれていない場合は閉じることに失敗する
        // よって、その場合はここでリダイレクト処理を行う

        const redirectTo = getRedirectTo()
        window.location.href = redirectTo
      }
    }, CLOSE_CHECK_THRESHOLD)
    return
  }

  if (history) {
    history.push(redirectTo)
  }
}

const sessionSagas = [
  takeEvery(ActionTypes.SEND_CODE_FOR_LOGIN, sendCodeForLogin),
  takeEvery(ActionTypes.RE_SEND_CODE_FOR_LOGIN, reSendCodeForLogin),
  takeEvery(ActionTypes.CALL_CODE_FOR_LOGIN, callCodeForLogin),
  takeEvery(ActionTypes.LOGIN, login),
  takeEvery(ActionTypes.LOGIN_WITH_PASSWORD, loginWithPassword),
]

export default sessionSagas
