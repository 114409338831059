import { schema } from 'normalizr'
import callLog from './callLog'
import restaurant from './restaurant'
import restaurantSeat from './restaurantSeat'
import restaurantCourse from './restaurantCourse'

const reservationList = new schema.Entity('reservationLists')

const reservationElement = new schema.Entity('reservationElements', {
  restaurant: restaurant,
  reservation_list: reservationList,
  restaurant_seat: restaurantSeat,
  restaurant_course: restaurantCourse,
  call_logs: [callLog],
})

export default reservationElement
