import React, { useState, useEffect } from 'react'

import { pull } from 'lodash'

// https://github.com/ReactTraining/history/issues/573
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

let pastLocations = []
// https://stackoverflow.com/questions/51825523/react-router-differentiate-goback-and-goforward-in-listen

// https://stackoverflow.com/questions/51825523/react-router-differentiate-goback-and-goforward-in-listen
let previousKey
const locationKeys = []

const updateListeners = []

function updatePastLocations(location, action) {
  const { key } = location

  let isGoBack

  if (key === undefined) {
    isGoBack = true
  } else if (!locationKeys.includes(key)) {
    locationKeys.push(key)
    isGoBack = false
  } else if (locationKeys.indexOf(key) < locationKeys.indexOf(previousKey)) {
    isGoBack = true
  } else {
    isGoBack = false
  }
  previousKey = key

  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location)
      break
    case 'REPLACE':
      // only when using history.replace
      pastLocations[Math.max(pastLocations.length - 1, 0)] = location
      break
    case 'POP': {
      // happens when using the back button, or forward button

      if (isGoBack) {
        pastLocations.pop()
      } else {
        pastLocations.push(location)
      }
      // location according to pastLocations

      const appLocation = pastLocations[pastLocations.length - 1]

      if (appLocation && appLocation.key !== location.key) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations = [location]
      }
      break
    }
    default:
  }

  updateListeners.forEach((listener) => listener())
}

history.listen(updatePastLocations)

export function useIsPreviousLocationWithinApp() {
  const [isWithinApp, setIsWithinApp] = useState(isPreviousLocationWithinApp())

  useEffect(() => {
    function locationUpdated() {
      setIsWithinApp(isPreviousLocationWithinApp())
    }

    updateListeners.push(locationUpdated)
    setIsWithinApp(isPreviousLocationWithinApp())
    return () => {
      pull(updateListeners, locationUpdated)
    }
  }, [])

  return isWithinApp
}

export function isPreviousLocationWithinApp() {
  return pastLocations.length > 1
}

export function goBackOrReplace(location, state?) {
  if (isPreviousLocationWithinApp()) {
    history.goBack()
  } else {
    history.replace(location, state)
  }
}

export function goBackOrPush(location, state?) {
  if (isPreviousLocationWithinApp()) {
    history.goBack()
  } else {
    history.push(location, state)
  }
}

export default history
