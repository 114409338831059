import React from 'react'
import Main from '../layouts/Main'

import { Helmet } from 'react-helmet'

import Head from '../components/Shared/Head'

import { t } from 'modules/i18n'
import makeTitle from 'modules/makeTitle'

import { canonicalOrigin } from 'modules/url'

const title = t('ページが見つかりません')

const url = `${canonicalOrigin()}/404`

function NotFound(props) {
  return (
    <Main {...props} campaign="404" isHeaderAlwaysVisible title={title}>
      <Head
        title={makeTitle(title)}
        url={url}
        imageUrl={null}
        ogType="website"
      />
      <Helmet>
        <meta name="render:status_code" content="404" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div>{t('ページが見つかりませんでした。')}</div>
    </Main>
  )
}

export default NotFound
