import axios from './axios'
import { ResponseWrapper } from './shared'

export const sendCodeForLogin = (code, phoneNumber): ResponseWrapper => {
  return axios
    .post('/sessions/send_code', {
      code: code,
      phone_number: phoneNumber,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const callCodeForLogin = (code, phoneNumber): ResponseWrapper => {
  return axios
    .post('/sessions/call_code', { code: code, phone_number: phoneNumber })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const login = (phoneCode, phoneNumber, code): ResponseWrapper => {
  return axios
    .post('/sessions/login', {
      phone_code: phoneCode,
      phone_number: phoneNumber,
      code: code,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const loginWithPassword = (
  phoneCode,
  phoneNumber,
  email,
  password
): ResponseWrapper => {
  return axios
    .post('/sessions/login_with_password', {
      phone_code: phoneCode,
      phone_number: phoneNumber,
      email: email,
      password: password,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
