export const SYNC = 'SYNC' as const

export const SYNC_USER = 'SYNC_USER' as const
export const SYNC_USER_SUCCEEDED = 'SYNC_USER_SUCCEEDED' as const

export const SIGN_IN = 'SIGN_IN' as const
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED' as const

export const SIGN_UP = 'SIGN_UP' as const
export const SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED' as const

export const SIGN_OUT = 'SIGN_OUT' as const
export const SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED' as const

export const UPDATE_CURRENT_LOCATION = 'UPDATE_CURRENT_LOCATION' as const
export const UPDATE_CURRENT_LOCATION_SUCCEEDED = 'UPDATE_CURRENT_LOCATION_SUCCEEDED' as const

export const SIGN_IN_WITH_TOKEN = 'SIGN_IN_WITH_TOKEN' as const
