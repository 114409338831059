import * as ActionTypes from 'ActionTypes'

export const sendCodeForLogin = (
  code,
  phoneNumber,
  history,
  isModal,
  redirectTo
) => {
  return {
    type: ActionTypes.SEND_CODE_FOR_LOGIN,
    payload: {
      code,
      phoneNumber,
      history,
      isModal,
      redirectTo,
    },
  } as const
}

export const reSendCodeForLogin = (code, phoneNumber) => {
  return {
    type: ActionTypes.RE_SEND_CODE_FOR_LOGIN,
    payload: {
      code,
      phoneNumber,
    },
  } as const
}

export const callCodeForLogin = (code, phoneNumber) => {
  return {
    type: ActionTypes.CALL_CODE_FOR_LOGIN,
    payload: {
      code,
      phoneNumber,
    },
  } as const
}

export const login = (phoneCode, phoneNumber, code, redirectTo, history) => {
  return {
    type: ActionTypes.LOGIN,
    payload: {
      phoneCode,
      phoneNumber,
      code,
      redirectTo,
      history,
    },
  } as const
}

export const loginWithPassword = (
  phoneCode,
  phoneNumber,
  email,
  password,
  redirectTo,
  history
) => {
  return {
    type: ActionTypes.LOGIN_WITH_PASSWORD,
    payload: {
      phoneCode,
      phoneNumber,
      email,
      password,
      redirectTo,
      history,
    },
  } as const
}
