import * as ActionTypes from 'ActionTypes'

export const like = (object) => {
  return {
    type: ActionTypes.LIKE,
    payload: {
      object,
    },
  } as const
}

export const unlike = (object) => {
  return {
    type: ActionTypes.UNLIKE,
    payload: {
      object,
    },
  } as const
}
