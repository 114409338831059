import { t } from 'modules/i18n'
export function getErrorMessage(error) {
  if (error.response) {
    return error.response.data.error || t('エラーが発生しました')
  }
  if (error.request) {
    return t('サーバーへの接続に失敗しました')
  }
  return error.message || t('エラーが発生しました')
}
