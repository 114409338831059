import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export default function useNow() {
  const [now, setNow] = useState(dayjs())

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(dayjs())
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return now
}
