import Cookies, { CookieAttributes } from 'js-cookie'

let options: CookieAttributes = {
  expires: 365 * 10,
}

if (process.env.NODE_ENV === 'production') {
  options = {
    ...options,
    domain: '.autoreserve.com',
    sameSite: 'none',
    secure: true,
  }
}

Cookies.defaults = options

export default Cookies
