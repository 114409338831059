import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const getReview = (auth, reviewId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reviews/${reviewId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createReview = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/reviews', { ...params })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createReviewImages = (
  auth,
  reviewId,
  images,
  onUploadProgress = null
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(
      `/reviews/${reviewId}/create_images`,
      { images: images },
      {
        onUploadProgress: onUploadProgress,
      }
    )
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const updateReview = (
  auth,
  reviewId,
  params,
  removeRestaurantImagesIds
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`/reviews/${reviewId}`, {
      ...params,
      remove_image_ids: removeRestaurantImagesIds,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
