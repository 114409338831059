import * as ActionTypes from 'ActionTypes'
import { isEmpty } from 'lodash'

interface UserState {
  userIdByUserName: Record<string, any>
  cardLoading: boolean
}

const initialState: UserState = {
  userIdByUserName: {},
  cardLoading: false,
}

export default (state = initialState, action): UserState => {
  const payload = action.payload
  const id = payload ? payload.id : null
  let feed = []
  let shopOrders = []
  let tableOrders = []

  switch (action.type) {
    case ActionTypes.GET_USER_SUCCEEDED:
      return {
        ...state,
        userIdByUserName: {
          ...state.userIdByUserName,
          [action.payload.userName]: action.payload.userId,
        },
      }
    case ActionTypes.ADD_CREDIT_CARD:
    case ActionTypes.UPDATE_DEFAULT_CARD:
    case ActionTypes.REMOVE_CARD:
      return {
        ...state,
        cardLoading: true,
      }

    case ActionTypes.GET_PAYMENTS_SUCCEEDED:
    case ActionTypes.CARD_SUCCEEDED:
      return {
        ...state,
        cardLoading: false,
        [id]: {
          ...state[id],
          cards: payload.cards,
        },
      }
    case ActionTypes.CARD_FAILED:
      return {
        ...state,
        cardLoading: false,
      }
    case ActionTypes.GET_USER_FEED_SUCCEEDED:
      if (
        state[id] &&
        state[id].feed &&
        state[id].feedPage && // リフレッシュ時など1ページ目の結果の時はconcatしないよう無視する
        state[id].feedPage !== 1
      ) {
        feed = state[id].feed.concat(payload.feed)
      } else {
        feed = payload.feed
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          feed: feed,
          feedPage: payload.feedPage,
          feedFetched: isEmpty(payload.feed),
        },
      }
    case ActionTypes.GET_SHOP_ORDERS_SUCCEEDED:
      if (
        state[id] &&
        state[id].shopOrders &&
        state[id].shopOrdersPage &&
        state[id].shopOrdersPage !== 1
      ) {
        shopOrders = state[id].shopOrders.concat(payload.shopOrders)
      } else {
        shopOrders = payload.shopOrders
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          shopOrders: shopOrders,
          shopOrdersPage: payload.shopOrdersPage,
          shopOrdersFetched: isEmpty(payload.shopOrders),
        },
      }

    case ActionTypes.GET_TABLE_ORDERS_SUCCEEDED:
      if (
        state[id] &&
        state[id].tableOrders &&
        state[id].tableOrdersPage &&
        state[id].tableOrdersPage !== 1
      ) {
        tableOrders = state[id].tableOrders.concat(payload.tableOrders)
      } else {
        tableOrders = payload.tableOrders
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          tableOrders: tableOrders,
          tableOrdersPage: payload.tableOrdersPage,
          tableOrdersFetched: isEmpty(payload.tableOrders),
        },
      }

    default:
      return state
  }
}
