import { put, takeEvery, select, call, takeLeading } from './helpers/redux-saga'
import * as api from 'api'
import * as ui from 'actions/ui'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { normalize } from 'normalizr'
import schemas from 'schema'
import { retryAPI } from './helpers'
import { get } from 'lodash'

import { t } from 'modules/i18n'

import * as actions from 'actions'

function* getUserId(userName) {
  const user = yield* select((state) => state.user)
  const userId = get(user.userIdByUserName, [userName], null)
  if (userId) {
    return userId
  }
  const { response, error } = yield retryAPI(api.getUserId, userName)
  if (response) {
    const { result, entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_USER_SUCCEEDED,
      payload: {
        userName,
        userId: result,
      },
    })
    return result
  } else {
    yield* handleApiError(error)
  }
}

function* getUser(action: ReturnType<typeof actions.getUser>) {
  const { userName } = action.payload
  const userId = yield* call(getUserId, userName)
  const { response, error } = yield retryAPI(api.getUser, userId)
  if (response) {
    const { result, entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_USER_SUCCEEDED,
      payload: {
        userName,
        userId: result,
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getUserById(action: ReturnType<typeof actions.getUserById>) {
  const { userId } = action.payload
  const { response, error } = yield retryAPI(api.getUser, userId)
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getPayments(action: ReturnType<typeof actions.getPayments>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getPayments, auth)
  if (response) {
    const { result, entities } = normalize(response.data, {
      user: schemas.user,
    })
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_PAYMENTS_SUCCEEDED,
      payload: {
        id: auth.id,
        cards: result.cards,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* addCreditCard(action: ReturnType<typeof actions.addCreditCard>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.addCreditCard,
    auth,
    action.stripeToken,
    action.source
  )
  if (response) {
    const { result, entities } = normalize(response.data, {
      user: schemas.user,
    })
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CARD_SUCCEEDED,
      payload: {
        id: auth.id,
        cards: result.cards,
      },
    })
    // yield call(delay, 1000)
    yield* put(ui.displayToastInfo(t('新しいクレジットカードを登録しました')))
  } else {
    yield* handleApiError(error)
    yield* put({ type: ActionTypes.CARD_FAILED })
  }
}

function* updateDefaultCard(
  action: ReturnType<typeof actions.updateDefaultCard>
) {
  const auth = yield* select((state) => state.auth)
  const { cardId } = action.payload
  const { response, error } = yield* call(api.updateDefaultCard, auth, cardId)
  if (response) {
    const { result, entities } = normalize(response.data, {
      user: schemas.user,
    })
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CARD_SUCCEEDED,
      payload: {
        id: auth.id,
        cards: result.cards,
      },
    })
    yield* put(
      ui.displayToastInfo(t('デフォルトのクレジットカードに設定しました'))
    )
  } else {
    yield* handleApiError(error)
    yield* put({ type: ActionTypes.CARD_FAILED })
  }
}

function* removeCard(action: ReturnType<typeof actions.removeCard>) {
  const auth = yield* select((state) => state.auth)
  const { cardId } = action.payload
  const { response, error } = yield* call(api.removeCard, auth, cardId)
  if (response) {
    const { result, entities } = normalize(response.data, {
      user: schemas.user,
    })
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CARD_SUCCEEDED,
      payload: {
        id: auth.id,
        cards: result.cards,
      },
    })
    yield* put(ui.displayToastInfo(t('クレジットカードを削除しました')))
  } else {
    yield* handleApiError(error)
    yield* put({ type: ActionTypes.CARD_FAILED })
  }
}

export function* updateUser(params) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.updateUser, auth, params)
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    return { error: null }
  } else {
    return yield* handleApiError(error)
  }
}

function* followUser(action: ReturnType<typeof actions.followUser>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.followUser, auth, action.userId)
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    // yield fork(syncUser)
  } else {
    yield* handleApiError(error)
  }
}

function* unfollowUser(action: ReturnType<typeof actions.unfollowUser>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.unfollowUser, auth, action.userId)

  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    // yield fork(syncUser)
  } else {
    yield* handleApiError(error)
  }
}

function* getUserFeed(action: ReturnType<typeof actions.getUserFeed>) {
  const auth = yield* select((state) => state.auth)
  const { userName } = action.payload
  const user = yield* select((state) => state.user)
  const userId = yield* call(getUserId, userName)
  const feedPage: number = get(user, [userId, 'feedPage'], 1)
  const { response, error } = yield retryAPI(
    api.getUserFeed,
    auth,
    userId,
    feedPage
  )
  if (response) {
    const { id } = response.data
    const { result, entities } = normalize(response.data.feed, [schemas.feed])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_USER_FEED_SUCCEEDED,
      payload: {
        id: id,
        feed: result,
        feedPage: feedPage + 1,
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

export function* getUserLists(action: ReturnType<typeof actions.getUserLists>) {
  const auth = yield* select((state) => state.auth)
  const { userName } = action.payload
  const userId = yield* call(getUserId, userName)
  const { response, error } = yield retryAPI(api.getUserLists, auth, userId)
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopOrders(action: ReturnType<typeof actions.getShopOrders>) {
  const auth = yield* select((state) => state.auth)
  const user = yield* select((state) => state.user)
  const shopOrdersPage: number = get(user, [auth.id, 'shopOrdersPage'], 1)
  const { response, error } = yield retryAPI(
    api.getShopOrders,
    auth,
    shopOrdersPage
  )
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.shopOrder])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_SHOP_ORDERS_SUCCEEDED,
      payload: {
        id: auth.id,
        shopOrders: result,
        shopOrdersPage: shopOrdersPage + 1,
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getShopOrder(action: ReturnType<typeof actions.getShopOrder>) {
  const auth = yield* select((state) => state.auth)
  const { id } = action.payload
  const { response, error } = yield retryAPI(api.getShopOrder, auth, id)
  if (response) {
    const { entities } = normalize(response.data, schemas.shopOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* toggleIsCampaignReceivedUser(
  action: ReturnType<typeof actions.toggleIsCampaignReceivedUser>
) {
  const auth = yield* select((state) => state.auth)
  const { value } = action.payload
  const { response, error } = yield retryAPI(
    api.toggleIsCampaignReceivedUser,
    auth,
    value
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* destroyUser(action: ReturnType<typeof actions.destroyUser>) {
  const auth = yield* select((state) => state.auth)
  const { history } = action.payload
  const { response, error } = yield retryAPI(api.destroyUser, auth)
  if (response) {
    yield* put({
      type: ActionTypes.SIGN_OUT_SUCCEEDED,
    })
    yield* put(ui.displayToastInfo(t('アカウントを削除しました')))
    if (history) {
      history.push('/')
    }
  } else {
    yield* handleApiError(error)
  }
}

function* getCrewRequest(action: ReturnType<typeof actions.getCrewRequest>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getCrewRequest, auth)
  if (response) {
    const data = response.data
    const { entities } = normalize(data.user, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_CREW_REQUEST_SUCCEEDED,
      payload: {
        prefectures: data.prefectures,
        transportationTypes: data.transportation_types,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* createCrewRequest(
  action: ReturnType<typeof actions.createCrewRequest>
) {
  const auth = yield* select((state) => state.auth)
  const { params, onFinish } = action.payload
  const { response, error } = yield retryAPI(
    api.createCrewRequest,
    auth,
    params
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    onFinish && onFinish()
    yield* put(ui.displayToastInfo(t('リクエストしました。')))
  } else {
    yield* handleApiError(error)
  }
}

function* createMedium(action: ReturnType<typeof actions.createMedium>) {
  const auth = yield* select((state) => state.auth)
  const { params } = action.payload
  const { response, error } = yield retryAPI(api.createMedium, auth, params)
  if (response) {
    yield* put(ui.displayToastInfo(t('利用申請をしました。')))
  } else {
    yield* handleApiError(error)
  }
}

function* createQrOrderRequests(
  action: ReturnType<typeof actions.createQrOrderRequests>
) {
  const auth = yield* select((state) => state.auth)
  const { params, onFinish } = action.payload
  const { response, error } = yield retryAPI(
    api.createQrOrderRequests,
    auth,
    params
  )
  if (response) {
    yield* put(ui.displayToastInfo(t('キャンペーンにエントリーしました')))
    onFinish()
  } else {
    yield* handleApiError(error)
  }
}

function* sendCodeForPhoneNumberTransfer(
  action: ReturnType<typeof actions.sendCodeForPhoneNumberTransfer>
) {
  const auth = yield* select((state) => state.auth)
  const { params, onFinish } = action.payload
  const { response, error } = yield* call(
    api.sendCodeForPhoneNumberTransfer,
    auth,
    params
  )
  if (response) {
    yield* put(ui.displayToastInfo(t('コードを送信しました')))
    onFinish()
  } else {
    yield* handleApiError(error)
  }
}

function* validateCodeForPhoneNumberTransfer(
  action: ReturnType<typeof actions.validateCodeForPhoneNumberTransfer>
) {
  const auth = yield* select((state) => state.auth)
  const { params, onFinish } = action.payload
  const { response, error } = yield* call(
    api.validateCodeForPhoneNumberTransfer,
    auth,
    params
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put(ui.displayToastInfo(t('電話番号を変更しました')))
    onFinish()
  } else {
    yield* handleApiError(error)
  }
}

const userSagas = [
  takeEvery(ActionTypes.GET_USER_ID, getUserId),
  takeEvery(ActionTypes.GET_USER, getUser),
  takeEvery(ActionTypes.GET_USER_BY_ID, getUserById),
  takeEvery(ActionTypes.FOLLOW_USER, followUser),
  takeEvery(ActionTypes.UNFOLLOW_USER, unfollowUser),
  takeEvery(ActionTypes.GET_PAYMENTS, getPayments),
  takeLeading(ActionTypes.ADD_CREDIT_CARD, addCreditCard),
  takeLeading(ActionTypes.UPDATE_DEFAULT_CARD, updateDefaultCard),
  takeLeading(ActionTypes.REMOVE_CARD, removeCard),
  takeEvery(
    ActionTypes.UPDATE_USER,
    function* ({
      params,
      onFinish,
      onError,
    }: ReturnType<typeof actions.updateUser>) {
      const { error } = yield* updateUser(params)
      if (error) {
        onError?.(error)
        return
      }
      yield* put(ui.displayToastInfo(t('プロフィールをアップデートしました')))
      onFinish?.()
    }
  ),
  takeLeading(ActionTypes.GET_USER_FEED, getUserFeed),
  takeEvery(ActionTypes.GET_USER_LISTS, getUserLists),
  takeLeading(ActionTypes.GET_SHOP_ORDERS, getShopOrders),
  takeEvery(ActionTypes.GET_SHOP_ORDER, getShopOrder),
  takeEvery(
    ActionTypes.TOGGLE_IS_CAMPAIGN_RECEIVED_USER,
    toggleIsCampaignReceivedUser
  ),
  takeEvery(ActionTypes.DESTROY_USER, destroyUser),
  takeEvery(ActionTypes.GET_CREW_REQUEST, getCrewRequest),
  takeEvery(ActionTypes.CREATE_CREW_REQUEST, createCrewRequest),
  takeEvery(ActionTypes.CREATE_MEDIUM, createMedium),
  takeEvery(ActionTypes.CREATE_QR_ORDER_REQUESTS, createQrOrderRequests),

  takeEvery(
    ActionTypes.SEND_CODE_FOR_PHONE_NUMBER_TRANSFER,
    sendCodeForPhoneNumberTransfer
  ),
  takeEvery(
    ActionTypes.VALIDATE_CODE_FOR_PHONE_NUMBER_TRANSFER,
    validateCodeForPhoneNumberTransfer
  ),
]

export default userSagas
