import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'

export function PrivateRoute({
  path,
  component: Component,
  signUp = false,
  ...props
}) {
  const location = useLocation<{ modal?: boolean }>()
  const isAuthenticated = useSelector((state) => !!state.auth.id)

  const redirectTo = location.pathname + location.search + location.hash

  return (
    <Route
      path={path}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: signUp ? '/sign_up' : '/sign_in',
              search: `?redirect_to=${encodeURIComponent(redirectTo)}`,
              state: {
                modal: location.state && location.state.modal,
              },
            }}
          />
        )
      }
    />
  )
}
