import { eventChannel } from 'redux-saga'
import { call, take, put, delay } from './helpers/redux-saga'

import * as actions from 'actions'

import history from 'modules/history'

function createHistoryListenChannel() {
  return eventChannel((emitter) => {
    const unlisten = history.listen((location, action) => {
      emitter({ location, action })
    })

    return unlisten
  })
}

function* watchPageTransitions() {
  const chan = yield* call(createHistoryListenChannel)
  try {
    while (true) {
      yield* take(chan)

      // ページのURLトランジションが完了する前にエラーを消すと、現在のページが再マウントされてまたAPI呼び出しがされてしまうので
      // 1tickぶん待つ
      yield* delay(0)

      yield* put(actions.clearError())
    }
  } finally {
    // fall through
  }
}

const errorSagas = [watchPageTransitions()]

export default errorSagas
