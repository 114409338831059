import React from 'react'

import { View } from 'react-native'

function MenuExpanded({
  backgroundColor = 'white',
  borderRadius = 0,
  children,
}) {
  return (
    <View
      style={[
        GeneralStyles.container,
        // @ts-expect-error
        {
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
        },
      ]}
    >
      <View
        style={{
          alignSelf: 'flex-end',
          width: 0,
          height: 0,
          borderBottomWidth: 16,
          borderBottomColor: backgroundColor,
          borderLeftWidth: 16,
          borderLeftColor: 'transparent',
        }}
      />
      <View
        style={[
          GeneralStyles.container,
          {
            backgroundColor,
            borderRadius,
            borderTopRightRadius: 0,
            overflow: 'hidden',
          },
        ]}
      >
        {children}
      </View>
    </View>
  )
}

export default MenuExpanded
