import React, { useState } from 'react'

import { View, TextInput } from 'react-native'
import { TouchableHighlight } from 'components/Shared/Touchables'
import { t } from 'modules/i18n'

import CommonHeader from './CommonHeader'

import loadable from '@loadable/component'

import MoreButton from './MoreButton'

const SearchSuggestsModal = loadable(() => import('./SearchSuggestsModal'))

const FilterBar = loadable(() => import('components/Home/FilterBar'))

function SPSearchHeader({
  keyword,
  setKeyword,
  search,
  showFilterBar,
  onLayout,
  headerAndBannerRef,
  headerAndBannerHeight,
  filterBarHeight,
  setFilterBarHeight,
}) {
  const [isSearchSuggestsViewVisible, setIsSearchSuggestsViewVisible] =
    useState(false)

  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <View
      style={{
        paddingVertical: 20,
        borderBottomWidth: 0.5,
        borderBottomColor: showFilterBar
          ? 'transparent'
          : Constants.BORDER_COLOR,
      }}
      onLayout={(event) => {
        setHeaderHeight(event.nativeEvent.layout.height)
        onLayout && onLayout(event)
      }}
    >
      <CommonHeader>
        <View
          style={[GeneralStyles.container, GeneralStyles.inlineAlignCenter]}
        >
          <TouchableHighlight
            style={[
              GeneralStyles.container,
              {
                backgroundColor: 'white',
              },
            ]}
            activeOpacity={0.8}
            underlayColor={Constants.LIGHT_GRAY_TRANSPARENT_COLOR}
            onPress={() => {
              setIsSearchSuggestsViewVisible(true)
            }}
          >
            <View
              style={[
                GeneralStyles.container,
                GeneralStyles.inlineAlignCenter,
                {
                  paddingHorizontal: 10,
                  minHeight: 42,
                  borderWidth: 1,
                  borderColor: Constants.LIGHT_GRAY_COLOR,
                  borderRadius: 2,
                },
              ]}
            >
              <FontAwesome5Pro
                name="search"
                size={14}
                color={Constants.GRAY_COLOR}
                light
              />

              <TextInput
                pointerEvents="none"
                value={keyword}
                style={[
                  GeneralStyles.container,
                  {
                    marginLeft: 10,
                    color: Constants.BLACK_COLOR,
                  },
                ]}
                placeholder={t('店名、ジャンル、最寄駅などから探す')}
                placeholderTextColor={Constants.LIGHT_GRAY_COLOR}
              />
            </View>
          </TouchableHighlight>
          <View style={{ marginLeft: 16 }}>
            <MoreButton />
          </View>
        </View>
      </CommonHeader>
      {showFilterBar && (
        <View
          onLayout={(event) => {
            setFilterBarHeight(event.nativeEvent.layout.height)
          }}
          style={{
            top: headerHeight - 5,
            overflow: 'hidden',
            position: 'absolute',
            paddingBottom: 20,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            borderBottomWidth: 0.5,
            borderColor: Constants.BORDER_COLOR,
          }}
        >
          <FilterBar
            headerAndBannerRef={headerAndBannerRef}
            headerAndBannerHeight={headerAndBannerHeight + filterBarHeight - 2}
          />
        </View>
      )}
      <SearchSuggestsModal
        open={isSearchSuggestsViewVisible}
        onClose={() => {
          setIsSearchSuggestsViewVisible(false)
        }}
        keyword={keyword}
        setKeyword={setKeyword}
        search={search}
      />
    </View>
  )
}

export default SPSearchHeader
