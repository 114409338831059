import React, { useEffect } from 'react'

import { useStartTablePolling } from 'modules/table'
import useRedirectTo from 'modules/useRedirectTo'
import { useSelector } from 'react-redux'
import { matchPath, Route, Switch, useHistory, useLocation } from 'react-router'
import { PrivateRoute } from 'components/Shared/Navigation'
import loadable from '@loadable/component'
import { withErrorBoundary } from 'components/Shared/ErrorBoundary'

const TableAuthenticate = loadable(() => import('pages/Table/Authenticate'))
const TableModal = loadable(() => import('components/Table/TableModal'))
const TableStartUnlimitedOrderPlanModal = loadable(
  () => import('components/Table/StartUnlimitedOrderPlanModal')
)
const TableAddCartModal = loadable(
  () => import('components/Table/AddCartModal')
)
const TableOrderHistoryModal = loadable(
  () => import('components/Table/OrderHistoryModal')
)
const TableCartModal = loadable(() => import('components/Table/CartModal'))
const TableCanceledModal = loadable(
  () => import('components/Table/CanceledModal')
)
const TablePayModal = loadable(() => import('components/Table/Pay/PayModal'))

const TablePaySplitBillsModal = loadable(
  () => import('components/Table/Pay/SplitBills/SplitBillsModal')
)

const TablePayShare = loadable(() => import('pages/Table/Pay/Share'))

const TablePaySplitBillsPayModal = loadable(
  () => import('components/Table/Pay/SplitBills/PayModal')
)

const TableRequiredOrderModal = loadable(
  () => import('components/Table/RequiredOrderModal')
)
const TableRegistrationModal = loadable(
  () => import('components/Table/RegistrationModal')
)
const TableSignUpModal = loadable(
  () => import('components/Table/SignUp/SignUpModal')
)
const TableSignUpEmailModal = loadable(
  () => import('components/Table/SignUp/SignUpEmailModal')
)

export const tableModalRoutes = [
  { path: '/table/add_cart/:id', exact: true },
  { path: '/table/cart', exact: true },
  { path: '/table/order_history', exact: true },
  { path: '/table/pay', exact: true },
  { path: '/table/pay/split_bills', exact: true },
  { path: '/table/pay/split_bills/pay', exact: true },
  { path: '/table/canceled', exact: true },
  { path: '/table/start_unlimited_order_plan', exact: true },
  { path: '/table/required_order', exact: true },
  { path: '/table/register', exact: true },
  { path: '/table/sign_up', exact: true },
  { path: '/table/sign_up/email', exact: true },
  {
    path: '/table',
    exact: true,
  },
]

const Pages = {
  TableAuthenticate: withErrorBoundary(TableAuthenticate),
  TablePayShare: withErrorBoundary(TablePayShare),
}

export function TableRoutes() {
  return (
    <Switch>
      <PrivateRoute
        path="/table/pay/share"
        exact
        signUp
        component={Pages.TablePayShare}
      />
      <Route
        path="/table/authenticate"
        exact
        component={Pages.TableAuthenticate}
      />
      {/* /table系ではモーダルの背景をホームではなく白くしたいためマッチしなかった場合に何もrenderしないrouteを配置している */}
      <Route path="/table" />
    </Switch>
  )
}

export function TableModalRoutes() {
  const orderId = useSelector((state) => state.table.orderId)

  const tableOrder = useSelector((state) => state.entity.tableOrders[orderId])

  const history = useHistory()
  const location = useLocation()

  useStartTablePolling()

  const redirectTo = useRedirectTo('/table')
  const authId = useSelector((state) => state.auth.id)
  // const user = useSelector((state) => state.entity.users[state.auth.id])

  useEffect(() => {
    const isAuthenticatePage = matchPath(location.pathname, {
      path: '/table/authenticate',
    })

    const isRequiredOrderPage = matchPath(location.pathname, {
      path: '/table/required_order',
    })

    const isPayPage = matchPath(location.pathname, {
      path: '/table/pay',
    })

    const isCanceledPage = matchPath(location.pathname, {
      path: '/table/canceled',
    })

    const isRegistrationPage = matchPath(location.pathname, {
      path: '/table/register',
    })

    const isSignUpPage = matchPath(location.pathname, {
      path: '/table/sign_up',
    })

    if (isAuthenticatePage) {
      return
    }

    if (
      !orderId &&
      !isAuthenticatePage &&
      !(isRegistrationPage || isSignUpPage)
    ) {
      history.replace('/')
    } else if (!tableOrder) {
      // 読み込み中は何もしない
    } else if (isRegistrationPage || isSignUpPage) {
      // 完了時にそれぞれの登録ページでリダイレクト処理が走るので
      // ここでは何もしない
    } else if (!authId) {
      // 非ログイン時は登録ページに飛ばす
      history.replace(
        `/table/register?redirect_to=${encodeURIComponent(redirectTo)}`
      )
    } else if (tableOrder.status === 'canceled' && !isCanceledPage) {
      history.replace('/table/canceled')
    } else if (tableOrder.status === 'complete' && !isPayPage) {
      history.replace('/table/pay')
    } else if (
      tableOrder.status === 'open' &&
      tableOrder.required_order &&
      !isRequiredOrderPage
    ) {
      history.replace('/table/required_order')
    } else if (
      tableOrder.status === 'open' &&
      !tableOrder.required_order &&
      isRequiredOrderPage
    ) {
      history.replace('/table')
    }
  }, [authId, history, location.pathname, orderId, redirectTo, tableOrder])

  return (
    <Switch>
      <Route
        path="/table/required_order"
        exact
        component={TableRequiredOrderModal}
      />
      <Route path="/table/add_cart/:id" exact component={TableAddCartModal} />

      <PrivateRoute path="/table/cart" exact component={TableCartModal} />
      <Route
        path="/table/order_history"
        exact
        component={TableOrderHistoryModal}
      />
      <PrivateRoute
        path="/table/canceled"
        exact
        component={TableCanceledModal}
      />
      <PrivateRoute
        path="/table/start_unlimited_order_plan"
        exact
        component={TableStartUnlimitedOrderPlanModal}
      />

      <PrivateRoute path="/table/pay" exact component={TablePayModal} />
      <PrivateRoute
        path="/table/pay/split_bills"
        exact
        component={TablePaySplitBillsModal}
      />
      <PrivateRoute
        path="/table/pay/split_bills/pay"
        exact
        signUp
        component={TablePaySplitBillsPayModal}
      />
      <Route path="/table/register" exact component={TableRegistrationModal} />
      <Route path="/table/sign_up" exact component={TableSignUpModal} />
      <Route
        path="/table/sign_up/email"
        exact
        component={TableSignUpEmailModal}
      />
      <Route path={'/table'} exact component={TableModal} />
    </Switch>
  )
}
