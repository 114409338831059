import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from './helpers/redux-saga'
import { eventChannel } from 'redux-saga'

import * as ActionTypes from 'ActionTypes'
import {
  messageChannelConnected,
  messageChannelDisconnected,
  setIsLoadingMoreMessages,
  updateMessageList,
  receiveNewMessage,
  updateReadAt,
  setIsMessageLoading,
  updateMessage,
  sendMessage as sendMessageAction,
  getMoreMessages,
  readMessage as readMessageAction,
  setupMessageChannel as setupMessageChannelAction,
  addMessageList,
  setIsMessageScreenFocused,
  addNewMessages,
  getMessages,
} from 'actions/message'
import { handleApiError } from 'sagas/shared'

import Config from 'Config'

import {
  getMessage,
  getMessageList,
  getMessageListItem,
  readMessage,
  sendMessage,
} from '../models/Message'
import { isNewRestaurantMessageListId } from '../modules/message'

const { createConsumer, logger } = require('@rails/actioncable')
logger.enabled = true

let consumer
const defaultCount = 100

function* subscribeMessageChannel(consumer) {
  return eventChannel<
    ReturnType<
      | typeof messageChannelConnected
      | typeof messageChannelDisconnected
      | typeof receiveNewMessage
      | typeof updateReadAt
    >
  >((emit) => {
    const subscription = consumer.subscriptions.create('MessageChannel', {
      connected() {
        emit(messageChannelConnected())
      },
      disconnected() {
        emit(messageChannelDisconnected())
      },
      received(data) {
        switch (data.type) {
          case 'new_message':
            return emit(receiveNewMessage({ message: data.payload }))
          case 'update_conversation_partner_read_at':
            return emit(updateReadAt(data.payload))
        }
      },
    })

    return () => {
      consumer.subscriptions.remove(subscription)
    }
  })
}

function* readMessageIfActive() {
  const message = yield* select((state) => state.message)
  const selectedMessageList = yield* select((state) =>
    state.message.messageList.find((m) => m.isSelected)
  )
  if (
    selectedMessageList &&
    !isNewRestaurantMessageListId(selectedMessageList.id) &&
    message.isAppActive
  ) {
    yield* put(readMessageAction({ messageListId: selectedMessageList.id }))
  }
}

function* setupMessageChannel(
  action: ReturnType<typeof setupMessageChannelAction>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(getMessageList, { token: auth.token })

  if (error) {
    yield* handleApiError(error)
    return
  }

  yield* put(
    updateMessageList({
      messageList: response.data,
    })
  )

  if (action.payload?.messageListId) {
    yield* put(
      setIsMessageScreenFocused({
        messageListId: action.payload.messageListId,
      })
    )
  }

  if (consumer) {
    if (consumer.connection.isActive()) {
      yield* put(messageChannelConnected())
    } else {
      yield* put(messageChannelDisconnected())
      consumer.ensureActiveConnection()
    }
  } else {
    const url = Config.getWebSocketUrl(auth.token)
    consumer = createConsumer(url)

    yield* fork(function* () {
      const sagaChannel = yield* call(subscribeMessageChannel, consumer)
      while (true) {
        const action = yield* take<any>(sagaChannel)
        yield* put(action)
        if (action.type === ActionTypes.ADD_NEW_MESSAGES) {
          yield* readMessageIfActive()
        }
        yield* delay(500)
      }
    })
  }

  yield* readMessageIfActive()
}

function* callGetMessages(action: ReturnType<typeof getMessages>) {
  const auth = yield* select((state) => state.auth)
  if (!action.payload) {
    return
  }

  const { messageListId } = action.payload
  if (!messageListId) {
    return
  }

  if (messageListId.startsWith('new_')) {
    return
  }

  yield* put(
    setIsMessageLoading({
      messageListId,
      isLoading: true,
    })
  )

  const selectedMessageList = yield* select((state) =>
    state.message.messageList.find((m) => m.id === messageListId)
  )

  if (!selectedMessageList) {
    return
  }

  const { response, error } = yield* call(getMessage, {
    token: auth.token,
    conversation_partner_id: selectedMessageList.conversation_partner_id,
    conversation_partner_type: selectedMessageList.conversation_partner_type,
    count: defaultCount + 1,
  })

  if (error) {
    yield* handleApiError(error)
    return
  }

  yield* put(
    updateMessage({
      messageListId: selectedMessageList.id,
      messages: response.data,
      hasNext: response.data.length > defaultCount,
      replace: true,
    })
  )

  const message = yield* select((state) => state.message)
  if (message.isAppActive) {
    const { error } = yield* call(readMessage, {
      messageListId: selectedMessageList.id,
      token: auth.token,
    })

    if (error) {
      yield* handleApiError(error)
    }
    yield* callGetMessageList(selectedMessageList.id)
  }
}

function* callSendMessage(action: ReturnType<typeof sendMessageAction>) {
  const auth = yield* select((state) => state.auth)
  const { error } = yield* call(sendMessage, {
    token: auth.token,
    ...action.payload,
  })

  if (error) {
    yield* handleApiError(error)
  }
}

function* callReadMessage(action: ReturnType<typeof readMessageAction>) {
  const auth = yield* select((state) => state.auth)
  const messageList = yield* select((state) =>
    state.message.messageList.find((m) => m.id === action.payload.messageListId)
  )
  if (!messageList) {
    return
  }

  yield* call(readMessage, {
    messageListId: messageList.id,
    token: auth.token,
  })
}

function* callGetMoreMessages(action: ReturnType<typeof getMoreMessages>) {
  const auth = yield* select((state) => state.auth)
  const messageList = yield* select((state) =>
    state.message.messageList.find((m) => m.id === action.payload.messageListId)
  )

  if (!messageList) {
    return
  }

  const partnerMessages = yield* select(
    (state) => state.message.messagesByMessageListId[messageList.id]
  )

  if (!partnerMessages) {
    return
  }

  const { messages, hasNext, isLoadingMore } = partnerMessages

  if (!hasNext || isLoadingMore) return

  yield* put(
    setIsLoadingMoreMessages({
      messageListId: messageList.id,
    })
  )

  const { response, error } = yield* call(getMessage, {
    token: auth.token,
    conversation_partner_id: messageList.conversation_partner_id,
    conversation_partner_type: messageList.conversation_partner_type,
    count: defaultCount + 1,
    beforeMessageId: messages[messages.length - 1]?.id,
  })

  if (error) {
    yield* handleApiError(error)
    return
  }

  const newMessages = response.data
  yield* put(
    updateMessage({
      messageListId: messageList.id,
      messages: newMessages.slice(0, defaultCount),
      hasNext: newMessages.length > defaultCount,
      replace: false,
    })
  )
}

function* callUpdateReadAt(action: ReturnType<typeof updateReadAt>) {
  yield* callGetMessageList(action.payload.id)
}

function* callReceiveNewMessage(action: ReturnType<typeof receiveNewMessage>) {
  const { message } = action.payload
  yield* readMessageIfActive()
  yield* callGetMessageList(message.message_list_id)

  yield* put(addNewMessages({ message }))
}

function* callGetMessageList(messageListId: string) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(getMessageListItem, {
    messageListId: messageListId,
    token: auth.token,
  })
  if (error) {
    yield* handleApiError(error)
    return
  }

  yield* put(addMessageList({ messageListItem: response.data }))
}

const messageSagas = [
  takeEvery(ActionTypes.SETUP_MESSAGE_CHANNEL, setupMessageChannel),
  takeEvery(ActionTypes.GET_MESSAGES, callGetMessages),
  takeEvery(ActionTypes.SEND_MESSAGE, callSendMessage),
  takeEvery(ActionTypes.UPDATE_READ_AT, callUpdateReadAt),
  takeEvery(ActionTypes.RECEIVE_NEW_MESSAGE, callReceiveNewMessage),
  takeLatest(ActionTypes.READ_MESSAGE, callReadMessage),
  takeLatest(ActionTypes.GET_MORE_MESSAGES, callGetMoreMessages),
]

export default messageSagas
