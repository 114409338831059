import React, { useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import LinkOpacity from 'components/Shared/LinkOpacity'
import MenuExpanded from './MenuExpanded'
import useClickOutside from 'modules/useClickOutside'
import Menu from 'components/User/Menu'
import RoundIcon from 'components/Shared/RoundIcon'

function UserButton({ user }) {
  const ref = useRef(null)
  const [isVisible, setVisible] = useState(false)

  useClickOutside(ref, () => {
    setVisible(false)
  })

  return (
    <View
      ref={ref}
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <LinkOpacity
        to={`/users/${user.username}`}
        onClickCapture={(event) => {
          event.preventDefault()
          setVisible(!isVisible)
        }}
      >
        <RoundIcon source={{ uri: user.image_url }} size={40} />
      </LinkOpacity>

      {isVisible && (
        <View
          style={{
            width: 320,
            maxHeight: 'calc(100vh - 80px)',
            position: 'absolute',
            top: 38,
            right: 0,
          }}
        >
          <MenuExpanded backgroundColor={'white'} borderRadius={4}>
            <ScrollView>
              <Menu />
            </ScrollView>
          </MenuExpanded>
        </View>
      )}
    </View>
  )
}

export default UserButton
