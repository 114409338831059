import * as ActionTypes from 'ActionTypes'

export const getRestaurantList = (slug, isPageRequest = false) => {
  return {
    type: ActionTypes.GET_RESTAURANT_LIST,
    payload: { slug, isPageRequest },
  } as const
}

export const purchaseRestaurantList = (restaurantListId) => {
  return {
    type: ActionTypes.PURCHASE_RESTAURANT_LIST,
    restaurantListId: restaurantListId,
  } as const
}

export const destroyImportedRestaurantList = (restaurantListId, slug) => {
  return {
    type: ActionTypes.DESTROY_IMPORTED_RESTAURANT_LIST,
    restaurantListId: restaurantListId,
    slug,
  } as const
}
