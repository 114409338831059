import axios from 'axios'
import { ResponseWrapper } from './shared'
// 外部APIなので、内部用のヘッダとかAuthorization tokenを送らないよう注意

export const getIpInfo = (params?): ResponseWrapper => {
  return axios
    .get(`https://ipinfo.io?token=847a4f81693009`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
