import ResizeObserver from 'resize-observer-polyfill'
import 'react-app-polyfill/stable'

import React from 'react'
import { AppRegistry } from 'react-native'

import App from './App'

import './fonts'

// 画像の lazy load
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import _JSXStyle from 'styled-jsx/style'

if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit')
}

if (typeof global !== 'undefined') {
  // WORKAROUND: fix compile errror
  // https://github.com/vercel/styled-jsx/issues/695#issuecomment-805346577
  Object.assign(global, { _JSXStyle })
}

window.ResizeObserver = ResizeObserver

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

AppRegistry.registerComponent('App', () => App)
// rendertronはDOM上にstyleが書き出されていないとrenderされないのでCSSを吐き出しているworkaround
// Rendertronのみで必要なので、UAで分岐している
if (window.navigator.userAgent.includes('HeadlessChrome')) {
  const { getStyleElement } = AppRegistry.getApplication('App', {})

  const style = document.createElement('style')
  style.id = 'react-native-stylesheet-dummy-style-for-rendertron'
  document.head.appendChild(style)

  import('react-dom/server').then((ReactDOMServer) => {
    setInterval(() => {
      if (style) {
        const css = ReactDOMServer.renderToStaticMarkup(getStyleElement())

        style.textContent = css.replace(/^[^>]*>|<\/style>$/g, '')
      }
    }, 1000)
  })
}

AppRegistry.runApplication('App', {
  callback: () => {
    console.log('React rendering has finished')
  },
  hydrate: false,
  initialProps: {},
  rootTag: document.getElementById('root'),
})

// ReactDOM.render(<App />, document.getElementById('root'))
