import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

import Config from 'Config'

export const updateCurrentLocation = (
  auth,
  latitude,
  longitude,
  isAppLocationAccurate
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/users/update_current', {
      latitude: latitude,
      longitude: longitude,
      is_app_location_accurate: isAppLocationAccurate,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getUserId = (userName): ResponseWrapper => {
  return axios
    .get(`/users/${userName}/id_by_username`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getUser = (id): ResponseWrapper => {
  return axios
    .get(`/users/${id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const verifyPhoneNumber = (code, phoneNumber): ResponseWrapper => {
  return axios
    .post('/verify_phone_number', {
      code: code,
      phone_number: phoneNumber,
      for_test: Config.isTest,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const syncUser = (auth): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/users', {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createUser = (params): ResponseWrapper => {
  return axios
    .post('/users', {
      ...params,
      for_test: Config.isTest,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const updateUser = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/users', { ...params })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const resendCode = (tempUserId): ResponseWrapper => {
  return axios
    .post('/resend_code', { temp_user_id: tempUserId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const callCode = (tempUserId): ResponseWrapper => {
  return axios
    .post('/call_code', { temp_user_id: tempUserId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const verifyCode = (tempUserId, code): ResponseWrapper => {
  return axios
    .post('/verify_code', { temp_user_id: tempUserId, code: code })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getPayments = (auth): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/users/payments', {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const addCreditCard = (auth, stripeToken, source): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/users/add_credit_card', { stripe_token: stripeToken, source })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const updateDefaultCard = (auth, cardId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/users/update_default_card', { card_id: cardId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const removeCard = (auth, cardId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .delete('/users/remove_card', { params: { card_id: cardId } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const followUser = (auth, userId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/users/follow', { user_id: userId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const unfollowUser = (auth, userId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/users/unfollow', { user_id: userId })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getUserFeed = (auth, userId, page): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/users/${userId}/new_user_feed`, { params: { page: page } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getUserLists = (auth, userId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/users/${userId}/lists`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getShopOrders = (auth, page): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/shop/orders`, { params: { page: page } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getShopOrder = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/shop/orders/${id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const toggleIsCampaignReceivedUser = (auth, value): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/users/toggle_is_campaign_received', {
      is_campaign_received: value,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const destroyUser = (auth): ResponseWrapper => {
  setHeader(auth)
  return axios
    .delete('/users')
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getCrewRequest = (auth): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/crew_requests')
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createCrewRequest = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/crew_requests', params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createMedium = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/media', {
      medium: params,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const createQrOrderRequests = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/qr_order_requests', params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const sendCodeForPhoneNumberTransfer = (
  auth,
  params
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/user_phone_number_transfers/send_code', params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const validateCodeForPhoneNumberTransfer = (
  auth,
  params
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/user_phone_number_transfers/validate_code', params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
