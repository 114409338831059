import React, { useState } from 'react'

import AppBanner, { APP_BANNER_HEIGHT } from 'components/Shared/AppBanner'

import { View } from 'react-native'

import Header from '../components/Header/Header'
import Tab from '../components/Layouts/Tab'
import LinkOpacity from '../components/Shared/LinkOpacity'

import { t } from 'modules/i18n'
import useResponsive from 'modules/useResponsive'
import loadable from '@loadable/component'

import { Text } from '@hello-ai/ar_shared'

const CalendarBottomBar = loadable(
  () => import('components/Shared/CalendarBottomBar')
)

function getPlatform(userAgent) {
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'ios'
  } else if (/Android/i.test(userAgent)) {
    return 'android'
  } else {
    return 'other'
  }
}

export default (props) => {
  const {
    showTab = true,
    showFilterBar = false,
    showBanner = true,
    headerPosition = 'sticky',
    showCalendarBottomBar = false,
    onHeaderLayout,
    showHeader = true,
    showCovid = false,
    title = null,
    color = Constants.PRIMARY_COLOR,
    backgroundColor = 'white',
    showHeaderBorderBottom = true,
    disableMarginBottom = false,
    goBackTo = '/',
    goBack = 'always',
    style,
  } = props
  const platform = getPlatform(navigator.userAgent)

  const isBannerVisible = ['ios', 'android'].includes(platform)
    ? showBanner
    : false

  const CALENDAR_BAR_HEIGHT = 52

  const [headerAndBannerRef] = useState(React.createRef<any>())

  const [headerAndBannerHeight, setHeaderAndBannerHeight] = useState(0)

  const [filterBarHeight, setFilterBarHeight] = useState(0)

  const { width, md, lg } = useResponsive()
  const isSp = width < lg

  const footerPadding = width < lg ? 72 : 0

  return (
    <View style={[{ flex: 1, flexGrow: 1, height: '100%' }, style]}>
      {showCovid && headerPosition === 'sticky' && (
        <View
          style={{
            position: 'sticky',
            width: '100%',
            zIndex: 10000,
            backgroundColor: '#FEF4F3',
            paddingVertical: 12,
          }}
        >
          <LinkOpacity to={'/covid_19'}>
            <Text
              style={{
                color: '#D72423',
                fontSize: isSp ? 10 : 12,
                fontWeight: '600',
                textAlign: 'center',
                textDecorationLine: 'underline',
              }}
            >
              {t('新型コロナウイルス拡大及び緊急事態宣言における対応のお願い')}
            </Text>
          </LinkOpacity>
        </View>
      )}
      <View
        ref={headerAndBannerRef}
        style={{
          position: headerPosition,
          top: isBannerVisible ? -APP_BANNER_HEIGHT : 0,
          width: '100%',
          backgroundColor: backgroundColor,
          zIndex: 9999,
        }}
        onLayout={(event) => {
          setHeaderAndBannerHeight(event.nativeEvent.layout.height)
        }}
      >
        {showCovid && headerPosition === 'fixed' && (
          <View
            style={{
              width: '100%',
              zIndex: 10000,
              backgroundColor: '#FEF4F3',
              paddingVertical: 12,
            }}
          >
            <LinkOpacity to={'/covid_19'}>
              <Text
                style={{
                  color: '#D72423',
                  fontSize: isSp ? 10 : 12,
                  fontWeight: '600',
                  textAlign: 'center',
                  textDecorationLine: 'underline',
                }}
              >
                {t(
                  '新型コロナウイルス拡大及び緊急事態宣言における対応のお願い'
                )}
              </Text>
            </LinkOpacity>
          </View>
        )}
        {isBannerVisible && (
          <AppBanner
            appURL={props.appURL}
            campaign={props.campaign}
            ct={props.ct}
            platform={platform}
            {...props}
          />
        )}
        {showHeader && (
          <Header
            showFilterBar={showFilterBar}
            onHeaderLayout={onHeaderLayout}
            headerAndBannerRef={headerAndBannerRef}
            headerAndBannerHeight={headerAndBannerHeight}
            filterBarHeight={filterBarHeight}
            setFilterBarHeight={setFilterBarHeight}
            title={title}
            color={color}
            backgroundColor={backgroundColor}
            showHeaderBorderBottom={showHeaderBorderBottom}
            goBackTo={goBackTo}
            goBack={goBack}
          />
        )}
      </View>

      {width < md ? (
        <View
          style={{
            flex: 1,
            flexGrow: 1,
            paddingTop: filterBarHeight && filterBarHeight - 5,
            marginBottom: disableMarginBottom
              ? 0
              : footerPadding +
                (showCalendarBottomBar ? CALENDAR_BAR_HEIGHT : 0),
            paddingBottom: 'env(safe-area-inset-bottom)',
          }}
        >
          {props.children}
          {showCalendarBottomBar && (
            <View
              style={{
                position: 'fixed',
                bottom: 72 - 14,
                marginBottom: 'max(14px, env(safe-area-inset-bottom))',
                width: '100%',
              }}
            >
              <CalendarBottomBar />
            </View>
          )}
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            flexGrow: 1,
            paddingTop: filterBarHeight,
            marginBottom:
              footerPadding + showCalendarBottomBar ? CALENDAR_BAR_HEIGHT : 0,
          }}
        >
          {props.children}
          {showCalendarBottomBar && (
            <View
              style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
              }}
            >
              <CalendarBottomBar />
            </View>
          )}
        </View>
      )}

      {showTab && (
        <View style={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <Tab />
        </View>
      )}
    </View>
  )
}
