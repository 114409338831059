export const GET_SHOP_ORDERS_CART_SHIPPABLE_DATES = 'GET_SHOP_ORDERS_CART_SHIPPABLE_DATES' as const
export const GET_SHOP_ORDERS_CART_SHIPPABLE_DATES_SUCCEEDED = 'GET_SHOP_ORDERS_CART_SHIPPABLE_DATES_SUCCEEDED' as const

export const GET_SHOP_RESTAURANT = 'GET_SHOP_RESTAURANT' as const
export const GET_SHOP_RESTAURANT_PRODUCTS = 'GET_SHOP_RESTAURANT_PRODUCTS' as const
export const GET_SHOP_RESTAURANT_PRODUCTS_SUCCEEDED = 'GET_SHOP_RESTAURANT_PRODUCTS_SUCCEEDED' as const
export const GET_SHOP_PRODUCT = 'GET_SHOP_PRODUCT' as const
export const GET_SHOP_PRODUCT_REVIEWS = 'GET_SHOP_PRODUCT_REVIEWS' as const
export const GET_SHOP_ORDERS_CART = 'GET_SHOP_ORDERS_CART' as const
export const GET_SHOP_ORDERS_CART_SUCCEEDED = 'GET_SHOP_ORDERS_CART_SUCCEEDED' as const
export const UPDATE_SHOP_ORDERS_CART = 'UPDATE_SHOP_ORDERS_CART' as const
export const UPDATE_SHOP_ORDERS_CART_SUCCEEDED = 'UPDATE_SHOP_ORDERS_CART_SUCCEEDED' as const
export const ADD_SHOP_ORDERS_CART_PRODUCT = 'ADD_SHOP_ORDERS_CART_PRODUCT' as const
export const ADD_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED = 'ADD_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED' as const
export const REMOVE_SHOP_ORDERS_CART_PRODUCT = 'REMOVE_SHOP_ORDERS_CART_PRODUCT' as const
export const REMOVE_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED = 'REMOVE_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED' as const
export const UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS = 'UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS' as const
export const UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED = 'UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED' as const
export const UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR = 'UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR' as const

export const OPEN_SHOP_PURCHASE_MODAL = 'OPEN_SHOP_PURCHASE_MODAL' as const
export const OPEN_SHOP_PURCHASE_MODAL_SUCCEEDED = 'OPEN_SHOP_PURCHASE_MODAL_SUCCEEDED' as const
export const OPEN_SHOP_PURCHASE_MODAL_ERROR = 'OPEN_SHOP_PURCHASE_MODAL_ERROR' as const
export const SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL = 'SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL' as const
export const SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL_SUCCEEDED = 'SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL_SUCCEEDED' as const
export const SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL_ERROR = 'SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL_ERROR' as const
export const GO_BACK_SHOP_PURCHASE_MODAL = 'GO_BACK_SHOP_PURCHASE_MODAL' as const
export const SELECT_SHIPPING_SHOP_PURCHASE_MODAL = 'SELECT_SHIPPING_SHOP_PURCHASE_MODAL' as const
export const SELECT_SHIPPING_SHOP_PURCHASE_MODAL_SUCCEEDED = 'SELECT_SHIPPING_SHOP_PURCHASE_MODAL_SUCCEEDED' as const
export const SELECT_SHIPPING_SHOP_PURCHASE_MODAL_ERROR = 'SELECT_SHIPPING_SHOP_PURCHASE_MODAL_ERROR' as const
export const CONFIRM_SHOP_PURCHASE_MODAL = 'CONFIRM_SHOP_PURCHASE_MODAL' as const
export const CONFIRM_SHOP_PURCHASE_MODAL_SUCCEEDED = 'CONFIRM_SHOP_PURCHASE_MODAL_SUCCEEDED' as const
export const CONFIRM_SHOP_PURCHASE_MODAL_ERROR = 'CONFIRM_SHOP_PURCHASE_MODAL_ERROR' as const

export const COMPLETE_SHOP_PURCHASE_MODAL = 'COMPLETE_SHOP_PURCHASE_MODAL' as const
export const COMPLETE_SHOP_PURCHASE_MODAL_SUCCEEDED = 'COMPLETE_SHOP_PURCHASE_MODAL_SUCCEEDED' as const
export const COMPLETE_SHOP_PURCHASE_MODAL_ERROR = 'COMPLETE_SHOP_PURCHASE_MODAL_ERROR' as const
export const COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN = 'COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN' as const
export const COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_SUCCEEDED = 'COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_SUCCEEDED' as const
export const COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR = 'COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR' as const

export const GET_SHOP_POPULAR_PRODUCTS = 'GET_SHOP_POPULAR_PRODUCTS' as const
export const GET_SHOP_POPULAR_PRODUCTS_SUCCEEDED = 'GET_SHOP_POPULAR_PRODUCTS_SUCCEEDED' as const
export const GET_SHOP_LATEST_PRODUCTS = 'GET_SHOP_LATEST_PRODUCTS' as const
export const GET_SHOP_LATEST_PRODUCTS_SUCCEEDED = 'GET_SHOP_LATEST_PRODUCTS_SUCCEEDED' as const
export const GET_SHOP_POPULAR_RESTAURANTS = 'GET_SHOP_POPULAR_RESTAURANTS' as const
export const GET_SHOP_POPULAR_RESTAURANTS_SUCCEEDED = 'GET_SHOP_POPULAR_RESTAURANTS_SUCCEEDED' as const
export const GET_SHOP_LATEST_RESTAURANTS = 'GET_SHOP_LATEST_RESTAURANTS' as const
export const GET_SHOP_LATEST_RESTAURANTS_SUCCEEDED = 'GET_SHOP_LATEST_RESTAURANTS_SUCCEEDED' as const

export const CREATE_SHOP_PRODUCT_ORDER_REVIEW = 'CREATE_SHOP_PRODUCT_ORDER_REVIEW' as const
export const UPDATE_SHOP_PRODUCT_ORDER_REVIEW = 'UPDATE_SHOP_PRODUCT_ORDER_REVIEW' as const

export const CREATE_SHOP_RECEIPT = 'CREATE_SHOP_RECEIPT' as const
