import { schema } from 'normalizr'

import tableProduct from './tableProduct'

import { idAttribute, processStrategy } from 'modules/table'

const tableMenuPage = new schema.Entity(
  'tableMenuPages',
  {
    table_products: [tableProduct],
  },
  {
    idAttribute,
    processStrategy,
  }
)

const tableMenuPages = new schema.Entity(
  'tableMenuPages',
  {
    table_products: [tableProduct],
    takeout_products: [tableProduct],
    table_menu_pages: [tableMenuPage],
  },
  {
    idAttribute,
    processStrategy,
  }
)

export default tableMenuPages
