import React from 'react'

interface CoverProps {
  imageUrl: string
  aspectRatio: number
  accessibilityLabel?: string
  style?: React.ComponentProps<'image'>['style']
  objectFit?: string
  boxBackgroundColor?: string
  borderRadius?: string
}

const Cover = ({
  imageUrl,
  aspectRatio,
  accessibilityLabel,
  style,
  objectFit = 'cover',
  boxBackgroundColor = 'transparent',
  borderRadius = '0px',
}: CoverProps) => {
  return (
    <>
      <style jsx>{`
        .aspect-box {
          width: 100%;
          height: 0;
          padding-top: calc(${1.0 / aspectRatio} * 100%);
          position: relative;
          overflow: hidden;
          background-color: ${boxBackgroundColor};
        }
        .aspect-box-img {
          width: 100%;
          height: 100%;
          border-radius: ${borderRadius};
          object-fit: ${objectFit};
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
      <div className="aspect-box">
        <img
          className="aspect-box-img lazyload"
          alt={accessibilityLabel}
          data-src={imageUrl}
          style={style}
        />
      </div>
    </>
  )
}

export default React.memo(Cover)
