import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const getReservation = (auth, reservationId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservations/${reservationId}`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getReservationElement = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservation_elements/${id}`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const unshareReservation = (
  auth,
  reservationId,
  reservationUserId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/reservations/${reservationId}/unshare`, {
      reservation_user_id: reservationUserId,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getSharedUsers = (auth, reservationId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservations/${reservationId}/shared_users`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRequestUsers = (auth, reservationId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservations/${reservationId}/request_users`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const searchNoSharedUsers = (
  auth,
  reservationId,
  text
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservations/${reservationId}/search_no_shared_users`, {
      params: { text: text },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const changeReservation = (
  auth,
  reservationId,
  partySize,
  reservedAt
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`/reservations/${reservationId}/change`, {
      change_party_size: partySize,
      change_reserved_at: reservedAt,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const cancelReservation = (auth, reservationId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`/reservations/${reservationId}/cancel`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantReservations = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/reservations/restaurant', {
      params: { restaurant_id: restaurantId },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
