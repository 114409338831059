export const GET_TAKEOUT_RESTAURANT = 'GET_TAKEOUT_RESTAURANT' as const
export const GET_TAKEOUT_RESTAURANT_SUCCEEDED = 'GET_TAKEOUT_RESTAURANT_SUCCEEDED' as const

export const GET_TAKEOUT_RESTAURANT_MENUS = 'GET_TAKEOUT_RESTAURANT_MENUS' as const
export const GET_TAKEOUT_RESTAURANT_MENUS_SUCCEEDED = 'GET_TAKEOUT_RESTAURANT_MENUS_SUCCEEDED' as const

export const GET_TAKEOUT_CART = 'GET_TAKEOUT_CART' as const
export const GET_TAKEOUT_CART_SUCCEEDED = 'GET_TAKEOUT_CART_SUCCEEDED' as const

export const CREATE_TAKEOUT_ORDER_ITEM = 'CREATE_TAKEOUT_ORDER_ITEM' as const
export const CREATE_TAKEOUT_ORDER_ITEM_SUCCEEDED = 'CREATE_TAKEOUT_ORDER_ITEM_SUCCEEDED' as const
export const CREATE_TAKEOUT_ORDER_ITEM_FAILED = 'CREATE_TAKEOUT_ORDER_ITEM_FAILED' as const

export const UPDATE_TAKEOUT_ORDER_ITEM = 'UPDATE_TAKEOUT_ORDER_ITEM' as const
export const UPDATE_TAKEOUT_ORDER_ITEM_SUCCEEDED = 'UPDATE_TAKEOUT_ORDER_ITEM_SUCCEEDED' as const
export const UPDATE_TAKEOUT_ORDER_ITEM_FAILED = 'UPDATE_TAKEOUT_ORDER_ITEM_FAILED' as const

export const DESTROY_TAKEOUT_ORDER_ITEM = 'DESTROY_TAKEOUT_ORDER_ITEM' as const
export const DESTROY_TAKEOUT_ORDER_ITEM_SUCCEEDED = 'DESTROY_TAKEOUT_ORDER_ITEM_SUCCEEDED' as const
export const DESTROY_TAKEOUT_ORDER_ITEM_FAILED = 'DESTROY_TAKEOUT_ORDER_ITEM_FAILED' as const
export const REQUEST_TAKEOUT_ORDER = 'REQUEST_TAKEOUT_ORDER' as const
export const REQUEST_TAKEOUT_ORDER_SUCCEEDED = 'REQUEST_TAKEOUT_ORDER_SUCCEEDED' as const
export const REQUEST_TAKEOUT_ORDER_FAILED = 'REQUEST_TAKEOUT_ORDER_FAILED' as const

export const GET_TAKEOUT_ORDER = 'GET_TAKEOUT_ORDER' as const
export const GET_TAKEOUT_ORDER_SUCCEEDED = 'GET_TAKEOUT_ORDER_SUCCEEDED' as const

export const UPDATE_TAKEOUT_ORDER = 'UPDATE_TAKEOUT_ORDER' as const
export const UPDATE_TAKEOUT_ORDER_SUCCEEDED = 'UPDATE_TAKEOUT_ORDER_SUCCEEDED' as const

export const GET_TAKEOUT_USER_ORDERS = 'GET_TAKEOUT_USER_ORDERS' as const
export const GET_TAKEOUT_USER_ORDERS_SUCCEEDED = 'GET_TAKEOUT_USER_ORDERS_SUCCEEDED' as const

export const GET_TAKEOUT_RECEIPT = 'GET_TAKEOUT_RECEIPT' as const

export const CREATE_TAKEOUT_RECEIPT = 'CREATE_TAKEOUT_RECEIPT' as const

export const GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS = 'GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS' as const
export const GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS_SUCCEEDED = 'GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS_SUCCEEDED' as const

export const GET_TAKEOUT_PRODUCT = 'GET_TAKEOUT_PRODUCT'
