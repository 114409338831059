import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'

function Toast() {
  const dispatch = useDispatch()
  const type = useSelector((state) => state.ui.type)
  const message = useSelector((state) => state.ui.message)
  const duration = useSelector((state) => state.ui.duration)

  const [isFadingOut, setIsFadingOut] = useState(false)

  useEffect(() => {
    let timer = null
    if (type && message) {
      setIsFadingOut(false)
      timer = setTimeout(() => {
        setIsFadingOut(true)
      }, duration)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [dispatch, duration, message, type])

  if (!message || !type) {
    return null
  }

  return (
    <div
      className={`flash animated flash-${type} ${
        isFadingOut ? 'fadeOut' : 'fadeIn'
      }`}
      onAnimationEnd={() => {
        if (isFadingOut) {
          setIsFadingOut(false)
          dispatch(actions.removeToast())
        }
      }}
    >
      {message}
      <style jsx>
        {`
      .flash {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999999999 !important;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
}
  .flash-info {
    background-color: ${Constants.PRIMARY_COLOR};
    color: white;
  }
  .flash-error {
    background-color: #dc3545;
    color: white;
  }
  .flash-warning {
    background-color: #ffc10;
    color: white;
  }
}`}
      </style>
    </div>
  )
}

export default Toast
