import axios from './axios'
import { ResponseWrapper } from './shared'

export const signIn = (phone_number, password): ResponseWrapper => {
  return axios
    .post('/sign_in', { phone_number: phone_number, password: password })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const signOut = (): ResponseWrapper => {
  return axios
    .delete('/sign_out', {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
