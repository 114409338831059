import { call, put, select, takeEvery } from './helpers/redux-saga'
import * as api from 'api'
import * as ui from 'actions/ui'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { t } from 'modules/i18n'

import * as actions from 'actions'

const getTempUser = (state) => state.tempUser

function* verifyPhoneNumber(
  action: ReturnType<typeof actions.verifyPhoneNumber>
) {
  //   yield put(ui.startFetch())
  const { response, error } = yield* call(
    api.verifyPhoneNumber,
    action.code,
    action.phoneNumber
  )
  // yield put(ui.endFetch())
  if (response) {
    yield* put({
      type: ActionTypes.VERIFY_PHONE_NUMBER_SUCCEEDED,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* resendCode(action: ReturnType<typeof actions.resendCode>) {
  const tempUser = yield* select(getTempUser)
  const { response, error } = yield* call(api.resendCode, tempUser.id)
  if (response) {
    yield* put(ui.displayToastInfo(t('コードを再度送りました')))
  } else {
    yield* handleApiError(error)
  }
}

function* callCode(action: ReturnType<typeof actions.callCode>) {
  const tempUser = yield* select(getTempUser)
  const { response, error } = yield* call(api.callCode, tempUser.id)
  if (response) {
    yield* put(ui.displayToastInfo(t('コードを電話で伝えます')))
  } else {
    yield* handleApiError(error)
  }
}

function* verifyCode(action: ReturnType<typeof actions.verifyCode>) {
  const tempUser = yield* select(getTempUser)
  // yield put(ui.startFetch())
  const { response, error } = yield* call(
    api.verifyCode,
    tempUser.id,
    action.code
  )
  // yield put(ui.endFetch())
  if (response) {
    yield* put({
      type: ActionTypes.VERIFY_CODE_SUCCEEDED,
      payload: response.data,
    })
    // action.navigation.push('SignUpAccount')
  } else {
    yield* handleApiError(error)
  }
}

const tempUserSagas = [
  takeEvery(ActionTypes.VERIFY_PHONE_NUMBER, verifyPhoneNumber),
  takeEvery(ActionTypes.RESEND_CODE, resendCode),
  takeEvery(ActionTypes.CALL_CODE, callCode),
  takeEvery(ActionTypes.VERIFY_CODE, verifyCode),
]

export default tempUserSagas
