import React, { useRef } from 'react'
import { Image, TextStyle, View } from 'react-native'

import { TouchableOpacity } from 'components/Shared/Touchables'

import LinkOpacity from 'components/Shared/LinkOpacity'

import { useHover } from 'react-native-web-hooks'

import { t } from 'modules/i18n'

import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'actions'
import { useLocation, useHistory } from 'react-router'
import getCurrentPath from 'modules/getCurrentPath'
import RoundIcon from 'components/Shared/RoundIcon'
import Switch from 'components/Shared/Switch'
import LinkText from 'components/Shared/LinkText'
import Config from 'Config'

import { Text } from '@hello-ai/ar_shared'

function ProfileButton() {
  const user = useSelector((state) => state.entity.users[state.auth.id])
  return (
    <LinkOpacity
      style={{
        borderTopWidth: 0,
        padding: 16,
      }}
      to={`/users/${user.username}`}
    >
      <View style={[GeneralStyles.inlineAlignCenter, GeneralStyles.container]}>
        <RoundIcon
          source={{ uri: user.image_url }}
          size={72}
          style={{
            cursor: 'pointer',
          }}
        />
        <View style={{ marginLeft: 16, flex: 1 }}>
          <View style={[GeneralStyles.inlineAlignCenter]}>
            <Text
              style={{
                fontSize: 18,
                fontWeight: '600',
              }}
            >
              {user.name}
            </Text>
            {user.is_foodie && (
              <Image
                style={{ width: 14, height: 14, marginLeft: 4 }}
                source={require('static/foodie.png')}
              />
            )}
          </View>

          <Text
            style={{
              marginTop: 4,
              fontSize: 12,
              color: Constants.LIGHT_BLACK_COLOR,
            }}
          >
            @{user.username}
          </Text>
        </View>
        <FontAwesome5Pro
          name="chevron-right"
          size={12}
          style={{ marginLeft: 16, color: Constants.BLACK_COLOR }}
        />
      </View>
    </LinkOpacity>
  )
}
function MoreLinkText({ to, children, target = null }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)

  const isExternal = /^http/.test(to)

  return (
    <LinkText
      ref={ref}
      to={to}
      target={target || isExternal ? '_blank' : '_self'}
      style={[
        {
          lineHeight: 56,
          paddingHorizontal: 16,
          backgroundColor: 'white',
          textDecorationLine: 'none',
        },
        isHovered && {
          opacity: 0.7,
          backgroundColor: Constants.ULTRA_LIGHT_GRAY_COLOR,
        },
      ]}
    >
      {children}
    </LinkText>
  )
}

function SwithBlock({ text, children }) {
  return (
    <View
      style={{
        paddingHorizontal: 16,
        height: 56,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text>{text}</Text>
      {children}
    </View>
  )
}

function LogoutButton() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const ref = useRef(null)
  const isHovered = useHover(ref)

  return (
    <TouchableOpacity
      ref={ref}
      style={[
        {
          borderTopWidth: 0.5,
          borderTopColor: Constants.BORDER_COLOR,
          height: 56,
          justifyContent: 'center',
          paddingHorizontal: 16,
          backgroundColor: 'white',
        },
        isHovered && { backgroundColor: Constants.ULTRA_LIGHT_GRAY_COLOR },
      ]}
      onPress={() =>
        dispatch(
          actions.signOut({
            redirectTo: getCurrentPath(location),
            history,
          })
        )
      }
    >
      <Text
        style={{
          fontSize: 14,
          color: Constants.DANGER_COLOR,
        }}
      >
        {t('ログアウト')}
      </Text>
    </TouchableOpacity>
  )
}

export default function Menu() {
  const topicStyle: TextStyle = {
    fontSize: 12,
    fontWeight: '600',
    paddingHorizontal: 16,
    marginBottom: 8,
  }
  const dispatch = useDispatch()
  const user = useSelector((state) => state.entity.users[state.auth.id])

  return (
    <View>
      <View
        style={{
          borderBottomWidth: 0.5,
          borderBottomColor: Constants.BORDER_COLOR,
        }}
      >
        <ProfileButton />
      </View>
      <View
        style={{
          marginTop: 28,
        }}
      >
        <Text style={topicStyle}>{t('予約・注文一覧')}</Text>
        <MoreLinkText to="/reservations">{t('予約一覧')}</MoreLinkText>
        <MoreLinkText to="/table_orders">
          {t('レストラン注文一覧')}
        </MoreLinkText>
        <MoreLinkText to="/shop_orders">
          {t('オンラインストア注文一覧')}
        </MoreLinkText>
        <MoreLinkText to="/delivery_orders">
          {t('デリバリー注文一覧')}
        </MoreLinkText>
        <MoreLinkText to="/takeout_orders">
          {t('テイクアウト注文一覧')}
        </MoreLinkText>
      </View>
      <View style={{ marginTop: 28 }}>
        <Text style={topicStyle}>{t('アカウント')}</Text>
        <MoreLinkText to={`/account/edit`}>
          {t('プロフィール編集')}
        </MoreLinkText>
        <MoreLinkText to={`/lists/${user.want_go_list_slug}`}>
          {t('行きたいお店')}
        </MoreLinkText>
        <MoreLinkText to={`/users/${user.username}`}>
          {t('行ったお店')}
        </MoreLinkText>
        <MoreLinkText to={`/users/${user.username}/my_lists`}>
          {t('フォロー中のリスト')}
        </MoreLinkText>
      </View>
      <View style={{ marginTop: 28 }}>
        <Text style={topicStyle}>{t('設定')}</Text>
        <MoreLinkText to={'/account/payments'}>
          {t('お支払い方法')}
        </MoreLinkText>
        {Config.isDevOnly && (
          <MoreLinkText to={'/account/dev_setting'}>
            {t('開発者設定')}
          </MoreLinkText>
        )}
        <SwithBlock text={t('キャンペーン情報を受け取る')}>
          <Switch
            value={user.is_campaign_received}
            trackColor={{
              false: Constants.LIGHT_GRAY_COLOR,
              true: Constants.PRIMARY_COLOR,
            }}
            onValueChange={(value) =>
              dispatch(actions.toggleIsCampaignReceivedUser(value))
            }
          />
        </SwithBlock>
        <MoreLinkText to={'/account/delete'}>
          {t('アカウントを削除')}
        </MoreLinkText>
      </View>
      <View>
        <LogoutButton />
      </View>
    </View>
  )
}
