import { call, takeEvery, takeLeading, select, put } from './helpers/redux-saga'
import * as ActionTypes from 'ActionTypes'

import * as api from 'api'

import * as ui from 'actions/ui'

import { normalize } from 'normalizr'
import schemas from 'schema'

import { handleApiError } from './shared'

import { t } from 'modules/i18n'
import { retryAPI } from './helpers'
import { goBackOrReplace } from 'modules/history'

import * as actions from 'actions'

function* getShopRestaurant(
  action: ReturnType<typeof actions.getShopRestaurant>
) {
  const { restaurantId } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getShopRestaurant,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopRestaurantProducts(
  action: ReturnType<typeof actions.getShopRestaurantProducts>
) {
  const { restaurantId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getShopRestaurantProducts,
    auth,
    restaurantId
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.shopProduct])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_SHOP_RESTAURANT_PRODUCTS_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
        shopProducts: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopProduct(action: ReturnType<typeof actions.getShopProduct>) {
  const { productId } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getShopProduct,
    auth,
    productId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.shopProduct)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopProductReviews(
  action: ReturnType<typeof actions.getShopProductReviews>
) {
  const { productId } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getShopProductReviews,
    auth,
    productId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.shopProduct)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopOrdersCart(
  action: ReturnType<typeof actions.getShopOrdersCart>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getShopOrdersCart, auth)
  if (response) {
    if (response.data) {
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.GET_SHOP_ORDERS_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* put({
        type: ActionTypes.GET_SHOP_ORDERS_CART_SUCCEEDED,
        payload: { cart: null },
      })
    }
  } else {
    yield* handleApiError(error)
  }
}

function* updateShopOrdersCart(
  action: ReturnType<typeof actions.updateShopOrdersCart>
) {
  const { params } = action.payload

  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.updateShopOrdersCart, auth, {
      shop_order: params.shop_order,
    })

    if (response) {
      const { entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SUCCEEDED,
      })
    } else {
      yield* handleApiError(error)
    }
  }
}
function* addShopOrdersCartProduct(
  action: ReturnType<typeof actions.addShopOrdersCartProduct>
) {
  const { shopProductId, quantity } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.addShopOrdersCartProduct, auth, {
    shop_product_id: shopProductId,
    quantity: quantity,
  })

  if (response) {
    const { result, entities } = normalize(response.data, schemas.shopOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.ADD_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED,
      payload: { cart: result },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* removeShopOrdersCartProduct(
  action: ReturnType<typeof actions.removeShopOrdersCartProduct>
) {
  const { shopProductId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.removeShopOrdersCartProduct,
    auth,
    { shop_product_id: shopProductId }
  )
  if (response) {
    const { result, entities } = normalize(response.data, schemas.shopOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.REMOVE_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED,
      payload: { cart: result },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* updateShopOrdersCartShippingAddress(
  action: ReturnType<typeof actions.updateShopOrdersCartShippingAddress>
) {
  const auth = yield* select((state) => state.auth)
  const { params, updateWith } = action.payload
  const { response, error } = yield* call(
    api.updateShopOrdersCartShippingAddress,
    auth,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.shopOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    if (updateWith) {
      const cart = entities.shopOrders[result]

      // Apple Payの場合、住所の更新に成功しても、正しく配送料計算が
      // できなかった場合はtotal_amountがnullになって返ってくる場合があるので
      // その場合はentity自体は更新するが、決済をさせない
      if (
        cart.shipment_cost !== undefined &&
        cart.shipment_cost !== null &&
        cart.total_amount !== undefined &&
        cart.total_amount !== null
      ) {
        yield* call(updateWith, {
          status: 'success',
          total: {
            label: t('商品合計'),
            amount: cart.total_amount,
          },
          shippingOptions: [
            {
              id: 'shipping',
              label: t('配送'),
              amount: cart?.shipment_cost,
            },
          ],
        })
      } else {
        yield* call(updateWith, {
          status: 'invalid_shipping_address',
          shippingOptions: [],
        })
        // Apple PayのSheetの下に隠れるので、toastは出さない
        yield* put({
          type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR,
        })
        return
      }
    }

    yield* put({
      type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED,
      payload: { cart: result },
    })
  } else {
    if (updateWith) {
      yield* call(updateWith, {
        status: 'invalid_shipping_address',
        shippingOptions: [],
      })
      // Apple PayのSheetの下に隠れるので、toastは出さない
      yield* put({
        type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR,
      })
      return
    }
    yield* handleApiError(error)
    yield* put({
      type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR,
    })
  }
}

function* openShopPurchaseModal(
  action: ReturnType<typeof actions.openShopPurchaseModal>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getShopOrdersCart, auth)
  if (response) {
    if (response.data) {
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.GET_SHOP_ORDERS_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* put({
        type: ActionTypes.GET_SHOP_ORDERS_CART_SUCCEEDED,
        payload: { cart: null },
      })
    }
  } else {
    yield* handleApiError(error)
    yield* put({ type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL_ERROR })
    return
  }

  const cart = yield* select(
    (state) => state.entity.shopOrders[state.shop.cart]
  )

  if (!cart || cart.shop_product_orders.length === 0) {
    yield* put(ui.displayToastError(t('カートの中身が空です')))

    yield* put({ type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL_ERROR })
    yield* call(goBackOrReplace, './')
    return
  }

  {
    yield* put({ type: ActionTypes.GET_SHOP_ORDERS_CART_SHIPPABLE_DATES })
    const { response, error } = yield retryAPI(
      api.getShopOrdersCartShippableDates,
      auth
    )

    if (response) {
      yield* put({
        type: ActionTypes.GET_SHOP_ORDERS_CART_SHIPPABLE_DATES_SUCCEEDED,
        payload: {
          shippableDates: response.data,
        },
      })
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL_ERROR })
      return
    }
  }

  let prefectures = []
  {
    const { response, error } = yield retryAPI(
      api.getShopOrdersCartShippingAddressForm,
      auth
    )

    if (response) {
      prefectures = response.data.prefectures
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL_ERROR })
      return
    }
  }

  yield* put({
    type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL_SUCCEEDED,
    payload: {
      prefectures: prefectures,
      notice: response.data.notice,
    },
  })
}

function* selectShippingShopPurchaseModal(
  action: ReturnType<typeof actions.selectShippingShopPurchaseModal>
) {
  const { params } = action.payload

  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.updateShopOrdersCart, auth, {
      shop_order: params.shop_order,
    })

    if (response) {
      const { entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL_SUCCEEDED,
      })
    } else {
      yield* handleApiError(error)
      yield* put({
        type: ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL_ERROR,
      })
    }
  }
}

function* confirmShopPurchaseModal(
  action: ReturnType<typeof actions.confirmShopPurchaseModal>
) {
  const { params } = action.payload

  const auth = yield* select((state) => state.auth)
  {
    if (params.email) {
      const { response, error } = yield* call(api.updateUser, auth, {
        email: params.email,
      })
      if (response) {
        const { entities } = normalize(response.data, schemas.user)
        yield* put({
          type: ActionTypes.SET_ENTITIES,
          payload: entities,
        })
      } else {
        yield* handleApiError(error)
        yield* put({ type: ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_ERROR })
        return
      }
    }

    const user = yield* select((state) => state.entity.users[state.auth.id])
    if (!user.email) {
      yield* put(ui.displayToastError(t('メールアドレスを入力してください')))
      yield* put({ type: ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_ERROR })
      return
    }
  }

  {
    const { response, error } = yield* call(
      api.updateShopOrdersCartShippingAddress,
      auth,
      {
        shipping_address: params.shipping_address,
      }
    )

    if (response) {
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_ERROR })
      return
    }
  }

  yield* put({ type: ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_SUCCEEDED })
}

function* completeShopPurchaseModal(
  action: ReturnType<typeof actions.completeShopPurchaseModal>
) {
  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.completeShopOrdersCart, auth)
    if (response) {
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_ERROR })
    }
  }
}

function* completeShopPurchaseModalWithToken(
  action: ReturnType<typeof actions.completeShopPurchaseModalWithToken>
) {
  const auth = yield* select((state) => state.auth)

  const { params, complete } = action.payload

  {
    const { response, error } = yield* call(api.updateShopOrdersCart, auth, {
      shop_order: params.shop_order,
    })

    if (response) {
      const { entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
    } else {
      yield* call(complete, 'fail')
      yield* handleApiError(error)
      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR,
      })
      return
    }
  }

  {
    if (params.email) {
      const { response, error } = yield* call(api.updateUser, auth, {
        email: params.email,
      })
      if (response) {
        const { entities } = normalize(response.data, schemas.user)
        yield* put({
          type: ActionTypes.SET_ENTITIES,
          payload: entities,
        })
      } else {
        yield* call(complete, 'fail')
        yield* handleApiError(error)
        yield* put({
          type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR,
        })
        return
      }
    }

    const user = yield* select((state) => state.entity.users[state.auth.id])
    if (!user.email) {
      yield* call(complete, 'fail')
      yield* put(ui.displayToastError(t('メールアドレスを入力してください')))
      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR,
      })
      return
    }
  }

  {
    const { response, error } = yield* call(
      api.updateShopOrdersCartShippingAddress,
      auth,
      {
        shipping_address: params.shipping_address,
      }
    )

    if (response) {
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* call(complete, 'fail')
      yield* handleApiError(error)
      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR,
      })
      return
    }
  }

  {
    const { response, error } = yield* call(api.completeShopOrdersCart, auth, {
      token: params.token,
    })
    if (response) {
      yield* call(complete, 'success')
      const { result, entities } = normalize(response.data, schemas.shopOrder)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* call(complete, 'fail')
      yield* handleApiError(error)
      yield* put({
        type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR,
      })
    }
  }
}

function* getShopPopularProducts(
  action: ReturnType<typeof actions.getShopPopularProducts>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getShopPopularProducts, auth)
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.shopProduct])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_SHOP_POPULAR_PRODUCTS_SUCCEEDED,
      payload: {
        products: result,
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getShopLatestProducts(
  action: ReturnType<typeof actions.getShopLatestProducts>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getShopLatestProducts, auth)
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.shopProduct])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_SHOP_LATEST_PRODUCTS_SUCCEEDED,
      payload: {
        products: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopPopularRestaurants(
  action: ReturnType<typeof actions.getShopPopularRestaurants>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getShopPopularRestaurants,
    auth
  )
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.restaurant])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_SHOP_POPULAR_RESTAURANTS_SUCCEEDED,
      payload: {
        restaurants: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getShopLatestRestaurants(
  action: ReturnType<typeof actions.getShopLatestRestaurants>
) {
  const auth = yield* select((state) => state.auth)
  const page = yield* select((state) => state.shop.latestRestaurantsPage)
  const { response, error } = yield retryAPI(
    api.getShopLatestRestaurants,
    auth,
    page
  )
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.restaurant])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_SHOP_LATEST_RESTAURANTS_SUCCEEDED,
      payload: {
        restaurants: result,
        page: page + 1,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* createShopProductOrderReview(
  action: ReturnType<typeof actions.createShopProductOrderReview>
) {
  const auth = yield* select((state) => state.auth)
  const { shopProductOrderId, params, onFinish } = action.payload
  const { response, error } = yield retryAPI(
    api.createShopProductOrderReview,
    auth,
    shopProductOrderId,
    params
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.shopProductOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    onFinish && onFinish()
    yield* put(ui.displayToastInfo(t('レビューを投稿しました。')))
  } else {
    yield* handleApiError(error)
  }
}

function* updateShopProductOrderReview(
  action: ReturnType<typeof actions.updateShopProductOrderReview>
) {
  const auth = yield* select((state) => state.auth)
  const { shopProductOrderReviewId, params, onFinish } = action.payload
  const { response, error } = yield retryAPI(
    api.updateShopProductOrderReview,
    auth,
    shopProductOrderReviewId,
    params
  )
  if (response) {
    const { entities } = normalize(
      response.data,
      schemas.shopProductOrderReview
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    onFinish && onFinish()
    yield* put(ui.displayToastInfo(t('レビューを更新しました。')))
  } else {
    yield* handleApiError(error)
  }
}

function* createShopReceipt(
  action: ReturnType<typeof actions.createShopReceipt>
) {
  const auth = yield* select((state) => state.auth)
  const { shopOrderId, params, onFinish } = action.payload

  const { response, error } = yield* call(
    api.createShopReceipt,
    auth,
    shopOrderId,
    params
  )

  if (response) {
    const { entities } = normalize(response.data, schemas.shopReceipt)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    onFinish()
  } else {
    yield* handleApiError(error)
  }
}

const shopSagas = [
  takeEvery(ActionTypes.GET_SHOP_RESTAURANT, getShopRestaurant),
  takeEvery(
    ActionTypes.GET_SHOP_RESTAURANT_PRODUCTS,
    getShopRestaurantProducts
  ),
  takeEvery(ActionTypes.GET_SHOP_PRODUCT, getShopProduct),
  takeEvery(ActionTypes.GET_SHOP_PRODUCT_REVIEWS, getShopProductReviews),
  takeEvery(ActionTypes.GET_SHOP_ORDERS_CART, getShopOrdersCart),
  takeEvery(ActionTypes.UPDATE_SHOP_ORDERS_CART, updateShopOrdersCart),
  takeEvery(ActionTypes.ADD_SHOP_ORDERS_CART_PRODUCT, addShopOrdersCartProduct),
  takeEvery(
    ActionTypes.REMOVE_SHOP_ORDERS_CART_PRODUCT,
    removeShopOrdersCartProduct
  ),
  takeEvery(
    ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS,
    updateShopOrdersCartShippingAddress
  ),
  takeEvery(ActionTypes.OPEN_SHOP_PURCHASE_MODAL, openShopPurchaseModal),
  takeEvery(
    ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL,
    selectShippingShopPurchaseModal
  ),
  takeEvery(ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL, confirmShopPurchaseModal),
  takeEvery(
    ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL,
    completeShopPurchaseModal
  ),
  takeEvery(
    ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN,
    completeShopPurchaseModalWithToken
  ),
  takeEvery(ActionTypes.GET_SHOP_POPULAR_PRODUCTS, getShopPopularProducts),
  takeEvery(ActionTypes.GET_SHOP_LATEST_PRODUCTS, getShopLatestProducts),
  takeEvery(
    ActionTypes.GET_SHOP_POPULAR_RESTAURANTS,
    getShopPopularRestaurants
  ),
  takeLeading(
    ActionTypes.GET_SHOP_LATEST_RESTAURANTS,
    getShopLatestRestaurants
  ),
  takeEvery(
    ActionTypes.CREATE_SHOP_PRODUCT_ORDER_REVIEW,
    createShopProductOrderReview
  ),
  takeEvery(
    ActionTypes.UPDATE_SHOP_PRODUCT_ORDER_REVIEW,
    updateShopProductOrderReview
  ),
  takeEvery(ActionTypes.CREATE_SHOP_RECEIPT, createShopReceipt),
]

export default shopSagas
