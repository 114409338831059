import { entries } from 'lodash'

/* eslint-disable camelcase */

import FontAwesome5_Pro_Brands_ttf from 'static/fonts/FontAwesome5_Pro_Brands.ttf'
import FontAwesome5_Pro_Light_ttf from 'static/fonts/FontAwesome5_Pro_Light.ttf'
import FontAwesome5_Pro_Regular_ttf from 'static/fonts/FontAwesome5_Pro_Regular.ttf'
import FontAwesome5_Pro_Solid_ttf from 'static/fonts/FontAwesome5_Pro_Solid.ttf'

const fonts = {
  FontAwesome5_Pro_Regular: FontAwesome5_Pro_Regular_ttf,
  FontAwesome5_Pro_Solid: FontAwesome5_Pro_Solid_ttf,
  FontAwesome5_Pro_Light: FontAwesome5_Pro_Light_ttf,
  FontAwesome5_Pro_Brands: FontAwesome5_Pro_Brands_ttf,
  FontAwesome5_Brands: FontAwesome5_Pro_Brands_ttf, // <FontAwesome5Pro brand>としてもfont-family: FontAwesome5_Brands;が使われるため
}

const css = entries(fonts)
  .map(
    ([fontName, url]) =>
      `@font-face { src: url("${url}"); font-family: ${fontName}; }`
  )
  .join('\n')

const style = document.createElement('style')
style.type = 'text/css'
// @ts-expect-error
if (style.styleSheet) style.styleSheet.cssText = css
else style.appendChild(document.createTextNode(css))

document.head.appendChild(style)
