import { call, put, select, takeEvery } from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { normalize } from 'normalizr'
import schemas from 'schema'

import * as actions from 'actions'

function* createAcceptRequest(
  action: ReturnType<typeof actions.createAcceptRequest>
) {
  const auth = yield* select((state) => state.auth)
  const { restaurantId, content } = action.payload
  const { response, error } = yield* call(
    api.createAcceptRequest,
    auth,
    restaurantId,
    content
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.SET_REQUEST_MODAL_VISIBLE,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* updateAcceptRequest(
  action: ReturnType<typeof actions.updateAcceptRequest>
) {
  const auth = yield* select((state) => state.auth)
  const { acceptRequestId, content } = action.payload
  const { response, error } = yield* call(
    api.updateAcceptRequest,
    auth,
    acceptRequestId,
    content
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.SET_REQUEST_MODAL_VISIBLE,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

const acceptRequestSagas = [
  takeEvery(ActionTypes.CREATE_ACCEPT_REQUEST, createAcceptRequest),
  takeEvery(ActionTypes.UPDATE_ACCEPT_REQUEST, updateAcceptRequest),
]

export default acceptRequestSagas
