// timezone data はでかいので, 使うものだけを読み込む
import moment from 'moment-timezone/moment-timezone'
import TimeAgo from 'javascript-time-ago'
import ja from 'javascript-time-ago/locale/ja'
import dayjs from 'dayjs'
import { t } from './i18n'

moment.tz.add(
  'Asia/Tokyo|JST JDT|-90 -a0|010101010|-QJJ0 Rc0 1lc0 14o0 1zc0 Oo0 1zc0 Oo0|38e6'
)

const jaLocale = require('moment/locale/ja')
moment.locale('ja', jaLocale)

TimeAgo.addLocale(ja)
const timeAgo = new TimeAgo('ja')

export function parseReservationTime(
  reservationTimeStr: string
): moment.Moment {
  return moment.parseZone(reservationTimeStr, moment.ISO_8601)
}

export function parseTime(timeStr: string): moment.Moment {
  return moment.parseZone(timeStr, moment.ISO_8601)
}

export function timeAgoStr(
  reservationTimeStr: string,
  style: string | object = 'twitter'
) {
  return timeAgo.format(Number(parseReservationTime(reservationTimeStr)), style)
}

export function now(): moment.Moment {
  return moment.tz(undefined, 'Asia/Tokyo')
}

export function closestFutureReservationTime(
  time = now(),
  interval = 15,
  inclusive = false // timeで指定した時間を未来として含めるかどうか
) {
  const closestFuture = time.clone()
  /* intervalの倍数のうち最も近い先の時間 */
  const minute =
    Math.ceil((closestFuture.minute() + (inclusive ? 0 : 1)) / interval) *
    interval
  closestFuture.minutes(minute)
  closestFuture.second(0).milliseconds(0)

  return closestFuture
}

export function formatReservationTime(reservedAt, showMonthAndDay = true) {
  return dayjs(reservedAt).format(showMonthAndDay ? 'lmll' : 'LT')
}

export function formatTime(timeStr, showMonthAndDay = true) {
  return dayjs(timeStr).format(showMonthAndDay ? 'lmll' : 'LT')
}

export function getDayShort(timeStr: string, now = dayjs()): string | null {
  const time = parseReservationTime(timeStr)

  if (time.isSame(now, 'day')) {
    return t('今日')
  } else if (time.isSame(now.add(1, 'day'), 'day')) {
    return t('明日')
  } else if (time.isSame(now.add(2, 'day'), 'day')) {
    return t('明々後日')
  }
  return null
}

export { moment }

export function wdayName(wday: number) {
  switch (wday) {
    case 0:
      return t('日曜日')
    case 1:
      return t('月曜日')
    case 2:
      return t('火曜日')
    case 3:
      return t('水曜日')
    case 4:
      return t('木曜日')
    case 5:
      return t('金曜日')
    case 6:
      return t('土曜日')
    case 7:
      return t('祝日')
  }
}
