import React from 'react'
import { StyleSheet, Image, View, StyleProp, ViewStyle } from 'react-native'

interface RoundIconProps {
  source: React.ComponentProps<typeof Image>['source']
  size: number
  style?: StyleProp<ViewStyle>
}
function RoundIcon({ source, size, style }: RoundIconProps) {
  const radius = size / 2
  return (
    <View style={StyleSheet.compose({ position: 'relative' }, style)}>
      <Image
        source={source}
        style={{
          width: size,
          height: size,
          borderRadius: radius,
        }}
      />
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: size,
          height: size,
          // @ts-expect-error
          boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
          borderRadius: radius,
        }}
      />
    </View>
  )
}

export default RoundIcon
