import { get, mapValues } from 'lodash'

import * as ActionTypes from 'ActionTypes'

interface EntityState {
  users: Record<number, any>
  reservationUsers: Record<number, any>
  reservationElements: Record<number, any>
  cities: Record<number, any>
  restaurants: Record<number, any>
  restaurantCategories: Record<number, any>
  restaurantCourses: Record<number, any>
  restaurantSeats: Record<number, any>
  restaurantImages: Record<number, any>
  restaurantLists: Record<number, any>
  restaurantMenus: Record<number, any>
  restaurantMenuSections: Record<number, any>
  homeSections: Record<string, any>
  reservationLists: Record<number, any>
  reviews: Record<number, any>
  reservations: Record<number, any>

  shopProducts: Record<number, any>
  shopOrders: Record<number, any>
  shopRestaurants: Record<number, any>
  shopProductOrders: Record<number, any>
  shopProductOrderReviews: Record<number, any>
  shopProductImages: Record<number, any>
  shopReceipts: Record<number, any>

  deliveryRestaurants: Record<number, any>
  deliveryProducts: Record<number, any>
  deliveryOrders: Record<number, any>
  deliveryProductOrders: Record<number, any>
  deliveryProductImages: Record<number, any>
  shippingAddresses: Record<number, any>

  tableMenuNavigations: Record<string, any>
  tableProducts: Record<string, any>
  tableOrders: Record<string, any>
  tableOrderItems: Record<string, any>
  tableOrderItemOptions: Record<string, any>
  tableMenus: Record<string, any>
  tableMenuPages: Record<string, any>
  tableProductOptionBoxes: Record<string, any>
  tableProductOptions: Record<string, any>
  tableUnlimitedOrderPlans: Record<string, any>
  tableOrderItemPlans: Record<string, any>
  tableReceipts: Record<string, any>
  tableSeats: Record<string, any>
  tableBills: Record<string, any>
  tableBillSplits: Record<string, any>
  takeoutOrders: Record<string, any>
  tableProductVotes: Record<string, any>

  takeoutOrderItems: Record<string, any>
  takeoutReceipts: Record<string, any>
  takeoutRestaurants: Record<number, any>
  takeoutRestaurantSettings: Record<string, any>
  takeoutMenus: Record<string, any>
  takeoutMenuPages: Record<string, any>
  takeoutProducts: Record<string, any>
  takeoutProductOptions: Record<string, any>
  takeoutProductOptionBoxes: Record<string, any>

  prefectures: Record<number, any>
  stations: Record<number, any>
  callLogs: Record<string, any>
}

const initialState: EntityState = {
  users: {},
  reservationUsers: {},
  reservationElements: {},
  cities: {},
  restaurants: {},
  restaurantCategories: {},
  restaurantCourses: {},
  restaurantSeats: {},
  restaurantLists: {},
  restaurantImages: {},
  restaurantMenus: {},
  restaurantMenuSections: {},
  homeSections: {},
  reservationLists: {},
  reviews: {},
  reservations: {},

  shopProducts: {},
  shopOrders: {},
  shopRestaurants: {},
  shopProductOrders: {},
  shopProductOrderReviews: {},
  shopProductImages: {},
  shopReceipts: {},

  deliveryRestaurants: {},
  deliveryProducts: {},
  deliveryOrders: {},
  deliveryProductOrders: {},
  deliveryProductImages: {},
  shippingAddresses: {},
  tableMenuNavigations: {},
  tableProducts: {},
  tableOrders: {},
  tableUnlimitedOrderPlans: {},
  tableOrderItems: {},
  tableOrderItemOptions: {},
  tableMenus: {},
  tableMenuPages: {},
  tableProductOptionBoxes: {},
  tableProductOptions: {},
  tableReceipts: {},
  tableSeats: {},
  tableBills: {},
  tableBillSplits: {},
  takeoutOrderItems: {},
  tableOrderItemPlans: {},
  tableProductVotes: {},

  takeoutOrders: {},
  takeoutRestaurants: {},
  takeoutRestaurantSettings: {},
  takeoutReceipts: {},
  takeoutMenus: {},
  takeoutMenuPages: {},
  takeoutProducts: {},
  takeoutProductOptions: {},
  takeoutProductOptionBoxes: {},

  prefectures: {},
  stations: {},
  callLogs: {},
}

export default (state = initialState, action): EntityState => {
  switch (action.type) {
    case ActionTypes.SET_ENTITIES:
      return Object.assign(
        {},
        state,
        mapValues(action.payload, (entities, entityName) => {
          return Object.assign(
            {},
            state[entityName],
            mapValues(entities, (entityValue, entityId) => {
              return {
                ...get(state, [entityName, entityId], {}),
                ...entityValue,
              }
            })
          )
        })
      )
    default:
      return state
  }
}
