import * as ActionTypes from 'ActionTypes'

export const requestOwner = ({ params, history, fbq }) => {
  return {
    type: ActionTypes.REQUEST_OWNER,
    payload: {
      params,
      history,
      fbq,
    },
  } as const
}

export const createDocumentRequest = ({ params, history, fbq }) => {
  return {
    type: ActionTypes.CREATE_DOCUMENT_REQUEST,
    payload: {
      params,
      history,
      fbq,
    },
  } as const
}
