import { Restaurant } from '../models/Restaurant'
const PREFIX_NEW_MESSAGE_ID = 'new_'

export const getNewRestaurantMessageListId = (restaurant: Restaurant) => {
  return `${PREFIX_NEW_MESSAGE_ID}_${restaurant.slug}`
}

export const isNewRestaurantMessageListId = (messageListId: string) => {
  return messageListId.startsWith(PREFIX_NEW_MESSAGE_ID)
}
