import React, { useState } from 'react'
import { View } from 'react-native'

import MultiSlider from '@ptomasroos/react-native-multi-slider'

import numeral from 'numeral'

import useSearchParams from 'modules/useSearchParams'

import useParamsUpdater from 'modules/useParamsUpdater'

import { Text } from '@hello-ai/ar_shared'

// y = x^c の関数が (l, DEFAULT_MAX_PRICE), (l / 2, middle_price) の点を通るように c を計算する
const DEFAULT_MAX_PRICE = 30 * 1000
const MIDDLE_PRICE = 10 * 1000
const c = Math.log(DEFAULT_MAX_PRICE / MIDDLE_PRICE) / Math.log(2)

function valueToPrice(value) {
  const x = value / 100
  return Math.floor(Math.pow(x, c)) * 1000
}

function valueFromPrice(y) {
  return Math.ceil(Math.pow(y / 1000, 1 / c) * 100)
}

function PriceSlider() {
  const params = useSearchParams()

  const minPrice = params.min_price // useSelector(state => state.searchParams.minPrice || 0)

  const maxPrice = params.max_price || DEFAULT_MAX_PRICE // useSelector(state => state.searchParams.maxPrice || DEFAULT_MAX_PRICE)

  const [{ minValue, maxValue }, setValues] = useState({
    minValue: valueFromPrice(minPrice),
    maxValue: valueFromPrice(maxPrice),
  })

  const updateParams = useParamsUpdater()

  const [sliderLength, setSliderLength] = useState(0)

  return (
    <View
      style={{
        flex: 1,
        marginTop: 16,
      }}
      onLayout={(event) => {
        setSliderLength(event.nativeEvent.layout.width)
      }}
    >
      <MultiSlider
        containerStyle={{
          flex: 1,
          marginLeft: 12,
        }}
        values={[minValue, maxValue]}
        sliderLength={sliderLength - 24}
        onValuesChange={(values) => {
          setValues({ minValue: values[0], maxValue: values[1] })
        }}
        onValuesChangeFinish={(values) => {
          setValues({ minValue: values[0], maxValue: values[1] })

          updateParams({
            min_price: valueToPrice(values[0]),
            max_price: valueToPrice(values[1]),
          })

          // dispatch(
          //   actions.setSearchParamsPriceRange({
          //     minPrice: valueToPrice(values[0]),
          //     maxPrice: valueToPrice(values[1])
          //   })
          // )
        }}
        trackStyle={{ height: 8, borderRadius: 12 }}
        unselectedStyle={{
          backgroundColor: Constants.LIGHT_GRAY_COLOR,
        }}
        selectedStyle={{
          backgroundColor: Constants.ACCENT_COLOR,
          opacity: 0.3,
        }}
        markerContainerStyle={{
          position: 'absolute',
          width: 48,
          height: 53,
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        min={0}
        max={valueFromPrice(DEFAULT_MAX_PRICE)}
        step={1}
        isMarkersSeparated
        customMarkerLeft={() => {
          const minPriceText = `¥${numeral(valueToPrice(minValue)).format(
            '0,0'
          )}`
          return (
            <View
              style={{
                width: 24,
                height: 24,
                borderRadius: 12,
                backgroundColor: Constants.ACCENT_COLOR,
              }}
            >
              <Text
                style={{
                  backgroundColor: 'white',
                  position: 'relative',
                  height: 12,
                  textAlign: 'right',
                  left:
                    -100 +
                    24 +
                    (valueToPrice(minValue) < 19000
                      ? // 幅の半分 - sliderの幅の半分
                        0
                      : -((valueToPrice(minValue) - 19000) / 8000) * 30),
                  width: 100,
                  fontWeight: '600',
                  fontSize: 12,
                  lineHeight: 12,
                  bottom: 12 + 8,
                }}
              >
                {minPriceText}
              </Text>
            </View>
          )
        }}
        customMarkerRight={() => {
          const maxPriceText = `¥${numeral(valueToPrice(maxValue)).format(
            '0,0'
          )}${valueToPrice(maxValue) === DEFAULT_MAX_PRICE ? '+' : ''}`

          return (
            <View
              style={{
                width: 24,
                height: 24,
                borderRadius: 12,
                backgroundColor: Constants.ACCENT_COLOR,
              }}
            >
              <Text
                style={{
                  backgroundColor: 'white',
                  position: 'relative',
                  height: 12,
                  textAlign: 'left',
                  left:
                    valueToPrice(maxValue) <= 23000
                      ? 0
                      : (-(valueToPrice(maxValue) - 23000) / 8000) * 30,
                  width: 100,
                  fontWeight: '600',
                  fontSize: 12,
                  lineHeight: 12,
                  bottom: 12 + 8,
                }}
              >
                {maxPriceText}
              </Text>
            </View>
          )
        }}
      />
    </View>
  )
}

export default React.memo(PriceSlider)
