export const SYNC_MESSAGE_STATUS = 'SYNC_MESSAGE_STATUS' as const
export const SETUP_MESSAGE_CHANNEL = 'SETUP_MESSAGE_CHANNEL' as const
export const MESSAGE_CHANNEL_CONNECTED = 'MESSAGE_CHANNEL_CONNECTED' as const
export const MESSAGE_CHANNEL_DISCONNECTED =
  'MESSAGE_CHANNEL_DISCONNECTED' as const
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST' as const
export const ADD_MESSAGE_LIST = 'ADD_MESSAGE_LIST' as const
export const SET_IS_MESSAGE_SCREEN_FOCUSED =
  'SET_IS_MESSAGE_SCREEN_FOCUSED' as const
export const SET_IS_APP_ACTIVE = 'SET_IS_APP_ACTIVE' as const
export const SEND_MESSAGE = 'SEND_MESSAGE' as const
export const READ_MESSAGE = 'READ_MESSAGE' as const
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE' as const
export const GET_MESSAGES = 'GET_MESSAGES' as const
export const GET_MORE_MESSAGES = 'GET_MORE_MESSAGES' as const
export const ADD_NEW_MESSAGES = 'ADD_MESSAGE' as const
export const SET_IS_LOADING_MORE_MESSAGES =
  'SET_IS_LOADING_MORE_MESSAGES' as const
export const SET_IS_LOADING_MESSAGES = 'SET_IS_LOADING_MESSAGES' as const
export const UPDATE_READ_AT = 'UPDATE_READ_AT' as const
export const RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE' as const
export const CLEAR_SELECT_MESSAGE_LIST = 'CLEAR_SELECT_MESSAGE_LIST' as const
export const SELECT_MESSAGE_RESTAURANT = 'SELECT_MESSAGE_RESTAURANT' as const
