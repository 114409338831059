import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'

import { compact } from 'lodash'
import { useLocation, useHistory } from 'react-router'

function normalize(to, location) {
  if (to == null) {
    return { href: undefined, state: undefined }
  }

  if (typeof to === 'string') {
    return { href: to, state: undefined }
  }

  let pathname, search, hash, state

  if (typeof to === 'function') {
    ;({ pathname, search, hash, state } = to(location))
  } else if (to != null) {
    ;({ pathname, search, hash, state } = to)
  }

  return {
    href: compact([pathname, search, hash]).join(''),
    state,
  }
}

function isSameOrigin(url) {
  return (
    new URL(document.baseURI).origin === new URL(url, document.baseURI).origin
  )
}

function useNavigate() {
  const history = useHistory()

  return useCallback(
    (href, state, isStatic) => {
      if (isStatic || !isSameOrigin(href)) {
        window.location.href = href
      } else {
        history.push(href, state)
      }
    },
    [history]
  )
}

function useLinkProps({ to, rel, target, isStatic = false }) {
  const location = useLocation()
  const navigate = useNavigate()

  const { href, state } = normalize(to, location)

  const onClick = useCallback(
    (event) => {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }
      if (target != null && target !== '_self') {
        return
      }

      if (event.defaultPrevented) {
        return
      }

      event.preventDefault()
      navigate(href, state, isStatic)
    },
    [href, isStatic, navigate, state, target]
  )

  const hrefAttrs = useMemo(
    () => ({
      rel,
      target: typeof target === 'string' ? target.replace(/^_/, '') : target,
    }),
    [rel, target]
  )

  return {
    accessibilityRole: 'link',
    href,
    hrefAttrs,
    onClick,
  }
}

interface LinkOwnProps {
  as?: React.ComponentType
  to: string | object
  rel?: string
  target?: string
  isStatic?: boolean
  children?: React.ReactNode
}

type LinkProps<T extends React.ComponentType> = LinkOwnProps &
  React.ComponentProps<T>
function Link(
  {
    as: Component = View,
    to,
    rel,
    target,
    isStatic = false,
    ...props
  }: LinkProps<typeof Component>,
  ref
) {
  const linkProps = useLinkProps({
    to,
    rel,
    target,
    isStatic,
  })

  return <Component ref={ref} {...linkProps} {...props} />
}

export default React.memo(React.forwardRef(Link))
