import * as ActionTypes from 'ActionTypes'

export const getShopRestaurant = (restaurantId) => {
  return {
    type: ActionTypes.GET_SHOP_RESTAURANT,
    payload: {
      restaurantId,
    },
  } as const
}

export const getShopRestaurantProducts = (restaurantId) => {
  return {
    type: ActionTypes.GET_SHOP_RESTAURANT_PRODUCTS,
    payload: {
      restaurantId,
    },
  } as const
}

export const getShopProduct = (productId) => {
  return {
    type: ActionTypes.GET_SHOP_PRODUCT,
    payload: {
      productId,
    },
  } as const
}

export const getShopProductReviews = (productId) => {
  return {
    type: ActionTypes.GET_SHOP_PRODUCT_REVIEWS,
    payload: {
      productId,
    },
  } as const
}

export const getShopOrdersCart = () => {
  return {
    type: ActionTypes.GET_SHOP_ORDERS_CART,
  } as const
}

export const updateShopOrdersCart = (params) => {
  return {
    type: ActionTypes.UPDATE_SHOP_ORDERS_CART,
    payload: { params },
  } as const
}

export const addShopOrdersCartProduct = ({
  shopProductId,
  quantity,
  isCartEmpty = false,
}) => {
  return {
    type: ActionTypes.ADD_SHOP_ORDERS_CART_PRODUCT,
    payload: {
      shopProductId,
      quantity,
      isCartEmpty,
    },
  } as const
}

export const removeShopOrdersCartProduct = ({ shopProductId }) => {
  return {
    type: ActionTypes.REMOVE_SHOP_ORDERS_CART_PRODUCT,
    payload: { shopProductId },
  } as const
}

export const updateShopOrdersCartShippingAddress = (params, updateWith?) => {
  return {
    type: ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS,
    payload: { params, updateWith },
  } as const
}

export const openShopPurchaseModal = () => {
  return {
    type: ActionTypes.OPEN_SHOP_PURCHASE_MODAL,
  } as const
}

export const selectShippingAndPaymentsShopPurchaseModal = () => {
  return {
    type: ActionTypes.SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL,
  } as const
}

export const goBackShopPurchaseModal = (step) => {
  return {
    type: ActionTypes.GO_BACK_SHOP_PURCHASE_MODAL,
    payload: { step },
  } as const
}

export const selectShippingShopPurchaseModal = (params) => {
  return {
    type: ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL,
    payload: { params },
  } as const
}

export const confirmShopPurchaseModal = (params) => {
  return {
    type: ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL,
    payload: { params },
  } as const
}

export const completeShopPurchaseModal = () => {
  return {
    type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL,
  } as const
}

export const completeShopPurchaseModalWithToken = ({ complete, ...params }) => {
  return {
    type: ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN,
    payload: { params, complete },
  } as const
}

export const getShopPopularProducts = () => {
  return {
    type: ActionTypes.GET_SHOP_POPULAR_PRODUCTS,
  } as const
}

export const getShopLatestProducts = () => {
  return {
    type: ActionTypes.GET_SHOP_LATEST_PRODUCTS,
  } as const
}

export const getShopPopularRestaurants = () => {
  return {
    type: ActionTypes.GET_SHOP_POPULAR_RESTAURANTS,
  } as const
}

export const getShopLatestRestaurants = () => {
  return {
    type: ActionTypes.GET_SHOP_LATEST_RESTAURANTS,
  } as const
}

export const createShopProductOrderReview = (
  shopProductOrderId,
  params,
  onFinish
) => {
  return {
    type: ActionTypes.CREATE_SHOP_PRODUCT_ORDER_REVIEW,
    payload: {
      shopProductOrderId,
      params,
      onFinish,
    },
  } as const
}

export const updateShopProductOrderReview = (
  shopProductOrderReviewId,
  params,
  onFinish
) => {
  return {
    type: ActionTypes.UPDATE_SHOP_PRODUCT_ORDER_REVIEW,
    payload: {
      shopProductOrderReviewId,
      params,
      onFinish,
    },
  } as const
}

export const createShopReceipt = (shopOrderId, params, onFinish) => {
  return {
    type: ActionTypes.CREATE_SHOP_RECEIPT,
    payload: {
      shopOrderId,
      params,
      onFinish,
    },
  } as const
}
