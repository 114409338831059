import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getHome = (auth, params): ResponseWrapper => {
  setHeader(auth)

  if (params.keyword?.startsWith('/s/')) {
    const path = params.keyword.replace('/s/', '')
    return wrapResponse(
      axios.get(`/web/search/${path}`, {
        params: { ...params, keyword: null },
      })
    )
  }
  return wrapResponse(
    axios.get('/home', { params, cache: { ignoreCache: false } })
  )
}

export const getHomeSectionContents = (
  auth,
  sectionId,
  params
): ResponseWrapper => {
  setHeader(auth)

  if (params.keyword?.startsWith('/s/')) {
    const path = params.keyword.replace('/s/', '')
    return wrapResponse(
      axios.get(`/web/search/sections/${sectionId}/contents/${path}`, {
        params: { ...params, keyword: null },
        cache: { ignoreCache: false },
      })
    )
  }

  return axios
    .get(`/home/sections/${sectionId}/contents`, { params })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
