import * as ActionTypes from 'ActionTypes'

interface TableState {
  orderId?: string
  tableSeatId?: string

  orders: any[]
  tableOrderItems: any[]

  isRequesting: boolean
  isPaymentProcessing: boolean

  menus?: any[]
  menuIndex?: number

  menuPage?: string

  tableBills?: any[]
  tableBillsFetched: boolean

  isUnverified: boolean
  verifyModalClosedAt: string | null

  emailModalClosedAt: string | null
  nameModalClosedAt: string | null

  menuType: 'default' | 'unlimited'

  tableUnlimitedOrderPlansById: Record<string, string[]>

  tableUnlimitedOrderPlanId?: string

  reviewsByTableOrderId: Record<string, string[]>
  reviewProductsByTableOrderId: Record<string, any>
}

const initialState: TableState = {
  orderId: null,
  tableSeatId: null,

  orders: [],
  tableOrderItems: [],

  isRequesting: false,
  isPaymentProcessing: false,

  menus: null,
  menuIndex: null,

  menuPage: null,

  tableBills: null,
  tableBillsFetched: false,

  isUnverified: false,
  verifyModalClosedAt: null,
  emailModalClosedAt: null,
  nameModalClosedAt: null,

  menuType: 'default',
  tableUnlimitedOrderPlansById: {},
  tableUnlimitedOrderPlanId: null,
  reviewsByTableOrderId: {},
  reviewProductsByTableOrderId: {},
}

export default (state = initialState, action): TableState => {
  switch (action.type) {
    case ActionTypes.OPEN_TABLE_SUCCEEDED:
      return {
        ...state,
        orderId: action.payload.orderId,
      }
    case ActionTypes.GET_TABLE_USER_ORDERS_SUCCEEDED:
      return {
        ...state,
        orders: action.payload.orders,
      }
    case ActionTypes.GET_TABLE_USER_ORDER_REVIEWS_SUCCEEDED:
      return {
        ...state,
        reviewsByTableOrderId: {
          ...state.reviewsByTableOrderId,
          [action.payload.orderId]: action.payload.reviews,
        },
      }
    case ActionTypes.GET_TABLE_SEAT_SUCCEEDED:
      return {
        ...state,
        tableSeatId: action.payload.tableSeatId,
      }
    case ActionTypes.AUTHENTICATE_TABLE_SUCCEEDED:
      return {
        ...state,
        orderId: action.payload.orderId,
        isUnverified: action.payload.isUnverified,
        verifyModalClosedAt: null,
        emailModalClosedAt: null,
        nameModalClosedAt: null,
      }

    case ActionTypes.GET_TABLE_CART_SUCCEEDED:
      return {
        ...state,
        tableOrderItems: action.payload.tableOrderItems,
      }

    case ActionTypes.REQUEST_TABLE_ORDERS:
      return {
        ...state,
        isRequesting: true,
      }
    case ActionTypes.REQUEST_TABLE_ORDERS_SUCCEEDED:
      return {
        ...state,
        isRequesting: false,
      }
    case ActionTypes.REQUEST_TABLE_ORDERS_FAILED:
      return {
        ...state,
        isRequesting: false,
      }

    case ActionTypes.ADD_TABLE_CART_ORDER_ITEMS:
      return {
        ...state,
        isRequesting: true,
      }

    case ActionTypes.ADD_TABLE_CART_ORDER_ITEMS_SUCCEEDED:
      return {
        ...state,
        isRequesting: false,
      }
    case ActionTypes.ADD_TABLE_CART_ORDER_ITEMS_FAILED:
      return {
        ...state,
        isRequesting: false,
      }
    case ActionTypes.SELECT_TABLE_MENU_INDEX:
      return {
        ...state,
        menuIndex: action.payload.menuIndex,
        menuPage: null,
        tableUnlimitedOrderPlanId: null,
        menuType: 'default',
      }
    case ActionTypes.SELECT_TABLE_MENU_PAGE:
      return {
        ...state,
        menuPage: action.payload.menuPage,
      }

    case ActionTypes.GET_TABLE_SEAT_MENUS_SUCCEEDED:
      return {
        ...state,
        menus: action.payload.menus,
        menuIndex: action.payload.menuIndex,
        tableUnlimitedOrderPlanId: action.payload.tableUnlimitedOrderPlanId,
        menuType: action.payload.menuType,
      }

    case ActionTypes.CREATE_TABLE_ORDER_PAYMENT:
      return {
        ...state,
        isPaymentProcessing: true,
      }
    case ActionTypes.CREATE_TABLE_ORDER_PAYMENT_SUCCEEDED:
    case ActionTypes.CREATE_TABLE_ORDER_PAYMENT_FAILED:
      return {
        ...state,
        isPaymentProcessing: false,
      }

    case ActionTypes.CREATE_TABLE_ORDER_BILL_SUCCEEDED:
    case ActionTypes.GET_TABLE_ORDER_BILLS_SUCCEEDED:
      return {
        ...state,
        tableBills: action.payload.tableBills,
        tableBillsFetched: true,
      }

    case ActionTypes.OPEN_TABLE_PAY_SUCCEEDED:
      return {
        ...state,
        orderId: action.payload.orderId,
      }
    case ActionTypes.VERIFY_TABLE_UNVERIFIED_USER_SUCCEEDED:
      return {
        ...state,
        isUnverified: false,
        verifyModalClosedAt: action.payload.verifyModalClosedAt,
      }
    case ActionTypes.CLOSE_TABLE_VERIFY_MODAL:
      return {
        ...state,
        verifyModalClosedAt: action.payload.verifyModalClosedAt,
      }

    case ActionTypes.CLOSE_TABLE_EMAIL_MODAL:
      return {
        ...state,
        emailModalClosedAt: action.payload.emailModalClosedAt,
      }
    case ActionTypes.CLOSE_TABLE_NAME_MODAL:
      return {
        ...state,
        nameModalClosedAt: action.payload.nameModalClosedAt,
      }

    case ActionTypes.SELECT_TABLE_MENU_TYPE:
      return {
        ...state,
        menuType: action.payload.menuType,
        menuPage: null,
      }
    case ActionTypes.SELECT_TABLE_UNLIMITED_ORDER_PLAN:
      return {
        ...state,
        tableUnlimitedOrderPlanId: action.payload.tableUnlimitedOrderPlanId,
      }

    case ActionTypes.GET_TABLE_MENU_UNLIMITED_ORDER_PLANS_SUCCEEDED:
      return {
        ...state,
        tableUnlimitedOrderPlansById: {
          ...state.tableUnlimitedOrderPlansById,
          [action.payload.tableMenuId]: action.payload.tableUnlimitedOrderPlans,
        },
      }
    case ActionTypes.GET_TABLE_ORDER_REVIEW_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        reviewProductsByTableOrderId: {
          ...state.reviewProductsByTableOrderId,
          [action.payload.orderId]: action.payload.reviewProducts,
        },
      }
    default:
      return state
  }
}
