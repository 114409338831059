import { schema } from 'normalizr'
import { idAttribute, processStrategy } from 'modules/table'

const tableUnlimitedOrderPlan = new schema.Entity(
  'tableUnlimitedOrderPlans',
  {},
  {
    processStrategy,
  }
)

export default tableUnlimitedOrderPlan
