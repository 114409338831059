import React from 'react'

import { View } from 'react-native'

import Cover from 'components/Shared/Cover'

import errorImage from 'static/500.png'
import { t } from 'modules/i18n'

import { Text } from '@hello-ai/ar_shared'

export function Error() {
  return (
    <View
      style={[
        GeneralStyles.container,
        {
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 16,
        },
      ]}
    >
      <View style={{ marginTop: -50 }}>
        <View
          style={{
            width: '100%',
            maxWidth: 424,
            alignSelf: 'center',
          }}
        >
          <Cover imageUrl={errorImage} aspectRatio={920 / 380} />
        </View>
        <Text
          style={{
            marginTop: 12,
            fontSize: 24,
            textAlign: 'center',
            fontWeight: '600',
            color: Constants.TEXT_PRIMARY_40,
          }}
        >
          {t('サーバーエラー')}
        </Text>
        <Text
          style={{
            marginTop: 10,
            fontSize: 14,
            lineHeight: 18,
            color: Constants.TEXT_PRIMARY_40,
            textAlign: 'center',
            flex: 1,
          }}
        >
          {t(
            '何らかのエラーが発生しました。時間を置いて再度アクセスしてください。'
          )}
        </Text>
      </View>
    </View>
  )
}
