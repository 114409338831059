import React, { useRef, useState } from 'react'
import { View, ScrollView, TextStyle } from 'react-native'

import { TouchableOpacity } from 'components/Shared/Touchables'

import { useLocation } from 'react-router'

import { t } from 'modules/i18n'

import { useHover } from 'react-native-web-hooks'
import { currentLocale } from 'modules/locale'

import useResponsive from 'modules/useResponsive'

import MenuExpanded from './MenuExpanded'
import Modal from 'components/Shared/BaseModal'
import { css } from 'styled-jsx/css'
import useClickOutside from 'modules/useClickOutside'
import LinkText from 'components/Shared/LinkText'

import { Text } from '@hello-ai/ar_shared'

function Social({ to, fontName }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  const styles = [
    {
      width: 36,
      height: 36,
      borderRadius: 18,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Constants.GRAY_COLOR,
      color: Constants.BLACK_COLOR,
      marginRight: 10,
    },
    isHovered && {
      backgroundColor: Constants.LIGHT_GRAY_COLOR,
      textDecorationLine: 'none',
    },
  ]
  return (
    <Link to={to} target="_blank" ref={ref} style={styles}>
      <FontAwesome5Pro
        name={fontName}
        size={18}
        brand
        style={{ color: 'white' }}
      />
    </Link>
  )
}

function LanguageMenu() {
  const location = useLocation()
  const [visible, setVisible] = useState(false)

  const locales = [
    /* eslint-disable ar-i18n/require-translation-ja */
    ['日本語', 'ja', 'jp'],
    ['简体中文', 'zh-CN', 'cn'],
    ['繁體中文（台灣）', 'zh-TW', 'tw'],
    ['繁體中文（香港）', 'zh-HK', 'hk'],
    ['English', 'en', null],
    /* eslint-enable ar-i18n/require-translation-ja */
  ]
  const locale = currentLocale()

  const [currentLocaleName] = locales.find(([, l]) => l === locale)

  return (
    <View>
      <TouchableOpacity
        style={{
          paddingVertical: 24,
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
        }}
        onPress={() => setVisible(!visible)}
        onPressMinInterval={0}
      >
        <FontAwesome5Pro
          style={{ marginTop: -1, marginRight: 4 }}
          name="globe"
          color={Constants.BLACK_COLOR}
          size={16}
        />
        <Text style={{ fontWeight: '600', flex: 1 }}>{t('言語')}</Text>

        <Text style={{ marginRight: 16 }}>{currentLocaleName}</Text>

        {visible ? (
          <FontAwesome5Pro
            name="angle-up"
            light
            size={12}
            color={Constants.BLACK_COLOR}
          />
        ) : (
          <FontAwesome5Pro
            name="angle-down"
            light
            size={12}
            color={Constants.BLACK_COLOR}
          />
        )}
      </TouchableOpacity>
      <View style={!visible && { display: 'none' }}>
        {locales.map(([label, locale, country]) => {
          let url
          if (country) {
            const host =
              process.env.NODE_ENV === 'production'
                ? `https://${country}.autoreserve.com`
                : `http://${country}.lvh.me:3000`
            url = host + location.pathname
          } else {
            url =
              window.location.origin + location.pathname + `?locale=${locale}`
          }

          return (
            <MoreLinkText key={url} to={url} target="_self">
              {label}
            </MoreLinkText>
          )
        })}
      </View>
    </View>
  )
}

function MoreLinkText({ to, children, target = null, isStatic = false }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)

  const isExternal = /^http/.test(to)

  return (
    <LinkText
      ref={ref}
      to={to}
      target={target || isExternal ? '_blank' : '_self'}
      style={[
        {
          lineHeight: 56,
          paddingHorizontal: 20,
          backgroundColor: 'white',
          textDecorationLine: 'none',
        },
        isHovered && {
          opacity: 0.7,
          backgroundColor: Constants.ULTRA_LIGHT_GRAY_COLOR,
        },
      ]}
      isStatic={isStatic}
    >
      {children}
    </LinkText>
  )
}

function MoreView() {
  const topicStyle: TextStyle = {
    fontWeight: '600',
    paddingHorizontal: 20,
    marginBottom: 10,
  }
  return (
    <View>
      <LanguageMenu />
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: Constants.BORDER_COLOR,
        }}
      />
      <View style={{ marginTop: 24 }}>
        <Text style={topicStyle}>For Restaurants</Text>
        <MoreLinkText to="/for_restaurants">
          {t('レストランの方へ')}
        </MoreLinkText>
        <MoreLinkText to="https://help-for-restaurants.autoreserve.com">
          {t('ヘルプセンター')}
        </MoreLinkText>
      </View>
      {/* <View style={{ marginTop: 24 }}>
        <Text style={topicStyle}>Delivery</Text>
        <MoreLinkText to="/crew">{t('配達員の方へ')}</MoreLinkText>
        <MoreLinkText to="/terms_for_crew">{t('配達員規約')}</MoreLinkText>
      </View> */}
      <View style={{ marginTop: 24 }}>
        <Text style={topicStyle}>For Developers</Text>
        <MoreLinkText to="https://developers.autoreserve.com">
          {t('開発者の方へ')}
        </MoreLinkText>
        <MoreLinkText to="/terms_for_developers">
          {t('API利用規約')}
        </MoreLinkText>
      </View>
      <View style={{ marginTop: 24 }}>
        <Text style={topicStyle}>For Affiliate</Text>
        <MoreLinkText to="/for_affiliate">
          {t('アフィリエイトの方へ')}
        </MoreLinkText>
        <MoreLinkText to="/terms_for_affiliate">
          {t('アフィリエイト利用規約')}
        </MoreLinkText>
      </View>
      <View style={{ marginTop: 24 }}>
        <Text style={topicStyle}>About</Text>
        <MoreLinkText to="https://hello.ai">{t('会社概要')}</MoreLinkText>
        <MoreLinkText to="/terms">{t('利用規約')}</MoreLinkText>
        <MoreLinkText to="/privacy">{t('プライバシーポリシー')}</MoreLinkText>
        <MoreLinkText to="/law">{t('特定商取引法に基づく表記')}</MoreLinkText>
      </View>

      <View
        style={{
          marginTop: 12,
          paddingHorizontal: 20,
          flexDirection: 'row',
        }}
      >
        <Social
          to="https://www.instagram.com/autoreserve/"
          fontName="instagram"
        />
        <Social to="https://twitter.com/auto_reserve/" fontName="twitter" />
        <Social
          to="https://www.facebook.com/autoreserve/"
          fontName="facebook-f"
        />
      </View>
      <Text
        style={{
          paddingHorizontal: 20,
          fontSize: 12,
          marginTop: 16,
          marginBottom: 24,
        }}
      >
        &copy; 2019 Hello, Inc.
      </Text>
    </View>
  )
}

function MoreButton({ size = 22, onPress, active = false }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  return (
    <FontAwesome5Pro
      light
      ref={ref}
      name="ellipsis-h"
      size={size}
      style={[
        {
          cursor: 'pointer',
          color: Constants.LIGHT_BLACK_COLOR,
          // @ts-expect-error
          userSelect: 'none',
        },
        isHovered && {
          color: Constants.BLACK_COLOR,
          textDecorationLine: 'none',
        },
        active && { color: Constants.PRIMARY_COLOR },
      ]}
      onPress={onPress}
    />
  )
}

function PCMoreButton() {
  const [visible, setVisible] = useState(false)

  const ref = useRef(null)

  useClickOutside(ref, () => {
    setVisible(false)
  })

  return (
    <View
      ref={ref}
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <MoreButton
        size={22}
        active={visible}
        onPress={() => setVisible(!visible)}
      />
      <View
        style={[
          {
            width: 320,
            height: 480,
            position: 'absolute',
            top: 30,
            right: 0,
            // @ts-expect-error
            userSelect: 'none',
          },
          !visible && { display: 'none' },
        ]}
      >
        <MenuExpanded backgroundColor="white">
          <ScrollView>
            <MoreView />
          </ScrollView>
        </MenuExpanded>
      </View>
    </View>
  )
}

function SPMoreButton() {
  const [visible, setVisible] = useState(false)

  const { className: overlayClassName, styles: overlayStyles } = css.resolve`
    div {
      padding: 0;
      margin: 0;
      background-color: white;
    }
  `

  const { className: modalClassName, styles: modalStyles } = css.resolve`
    div {
      max-width: none;
      padding: 0;
      box-shadow: none;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `

  return (
    <View
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <MoreButton size={38} onPress={() => setVisible(!visible)} />
      <Modal
        open={visible}
        onClose={() => {
          setVisible(false)
        }}
        classNames={{
          overlay: overlayClassName,
          modal: modalClassName,
        }}
        showCloseIcon={false}
        animationDuration={0}
      >
        {overlayStyles}
        {modalStyles}
        <View style={{ width: '100%' }}>
          <View style={{ width: '100%', marginBottom: 12 }}>
            <TouchableOpacity
              style={{ marginTop: 24, marginLeft: 'auto', marginRight: 20 }}
              onPress={() => setVisible(false)}
            >
              <FontAwesome5Pro
                name="times"
                light
                color={Constants.GRAY_COLOR}
                size={26}
              />
            </TouchableOpacity>
          </View>
          <MoreView />
        </View>
      </Modal>
    </View>
  )
}

function SPOrPCMoreButton() {
  const { width, md } = useResponsive()

  return width < md ? <SPMoreButton /> : <PCMoreButton />
}

export default SPOrPCMoreButton
