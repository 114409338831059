import React, { useMemo } from 'react'
import Breakpoints from './breakpoints'
import useMediaQuery from './useMediaQuery'

function lessThan(bp) {
  return `(max-width: ${bp - 0.02}px)`
}

function isEqual(bp) {
  return `(min-width: ${bp - 0.02}px) and (max-width: ${bp}px)`
}

function useResponsive() {
  const ltXs = useMediaQuery(lessThan(Breakpoints.xs)) // 0
  const isXs = useMediaQuery(isEqual(Breakpoints.xs)) // 1

  const ltSm = useMediaQuery(lessThan(Breakpoints.sm)) // 2
  const isSm = useMediaQuery(isEqual(Breakpoints.sm)) // 3

  const ltMd = useMediaQuery(lessThan(Breakpoints.md)) // 4
  const isMd = useMediaQuery(isEqual(Breakpoints.md)) // 5

  const ltLg = useMediaQuery(lessThan(Breakpoints.lg)) // 6
  const isLg = useMediaQuery(isEqual(Breakpoints.lg)) // 7

  let width

  if (ltXs) {
    width = 0
  } else if (isXs) {
    width = 1
  } else if (ltSm) {
    width = 2
  } else if (isSm) {
    width = 3
  } else if (ltMd) {
    width = 4
  } else if (isMd) {
    width = 5
  } else if (ltLg) {
    width = 6
  } else if (isLg) {
    width = 7
  } else {
    width = 8
  }

  return useMemo(
    () => ({
      width,
      xs: 1,
      sm: 3,
      md: 5,
      lg: 7,
    }),
    [width]
  )
}

export default useResponsive
