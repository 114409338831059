import React, { useRef } from 'react'
import { StyleSheet } from 'react-native'
import Link from './Link'
import { useHover } from 'react-native-web-hooks'
import { Text } from '@hello-ai/ar_shared'
import composeRefs from '@seznam/compose-react-refs'

function LinkText(
  { children, style, color = Constants.BLACK_COLOR, fontSize = 14, ...props },
  forwardedRef
) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  return (
    <Link
      as={Text}
      ref={composeRefs(ref, forwardedRef)}
      style={StyleSheet.compose(
        [
          { cursor: 'pointer', color, fontSize, textDecorationLine: 'none' },
          isHovered && {
            textDecorationLine: 'underline',
          },
        ],
        style
      )}
      {...props}
    >
      {children}
    </Link>
  )
}

export default React.forwardRef(LinkText)
