import { schema } from 'normalizr'

import tableMenuPage from './tableMenuPage'

const tableMenu = new schema.Entity('tableMenus', {
  table_menu_pages: [tableMenuPage],
  takeout_menu_pages: [tableMenuPage],
})

export default tableMenu
