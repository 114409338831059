export const OPEN_REQUEST_RESERVATION_MODAL = 'OPEN_REQUEST_RESERVATION_MODAL' as const

export const GET_REQUEST_RESERVATION_SEATS = 'GET_REQUEST_RESERVATION_SEATS' as const
export const GET_REQUEST_RESERVATION_SEATS_ERROR = 'GET_SEATS_REQUEST_RESERVATION_SEATS_ERROR' as const
export const GET_REQUEST_RESERVATION_SEATS_SUCCEEDED = 'GET_SEATS_REQUEST_RESERVATION_SEATS_SUCCEEDED' as const

export const GET_REQUEST_RESERVATION_COURSES = 'GET_REQUEST_RESERVATION_COURSES' as const
export const GET_REQUEST_RESERVATION_COURSES_SUCCEEDED = 'GET_REQUEST_RESERVATION_COURSES_SUCCEEDED' as const

export const GET_REQUEST_RESERVATION_CANCEL_POLICIES_SUCCEEDED = 'GET_REQUEST_RESERVATION_CANCEL_POLICIES_SUCCEEDED' as const

export const SELECT_SEAT_REQUEST_RESERVATION = 'SELECT_SEAT_REQUEST_RESERVATION' as const
export const SELECT_COURSE_REQUEST_RESERVATION = 'SELECT_COURSE_REQUEST_RESERVATION' as const

export const RESERVE_REQUEST_RESERVATION = 'RESERVE_REQUEST_RESERVATION' as const
export const RESERVE_REQUEST_RESERVATION_ERROR = 'RESERVE_REQUEST_RESERVATION_ERROR' as const
export const RESERVE_REQUEST_RESERVATION_SUCCEEDED = 'RESERVE_REQUEST_RESERVATION_SUCCEEDED' as const

export const GET_REQUEST_RESERVATION_FORM = 'GET_REQUEST_RESERVATION_FORM' as const
export const GET_REQUEST_RESERVATION_FORM_SUCCEEDED = 'GET_REQUEST_RESERVATION_FORM_SUCCEEDED' as const

export const VERIFY_DEVICE_USER = 'VERIFY_DEVICE_USER' as const
export const VERIFY_DEVICE_USER_SUCCEEDED = 'VERIFY_DEVICE_USER_SUCCEEDED' as const
