import axios from 'axios'
// @ts-expect-error
import { setupCache, serializeQuery } from 'axios-cache-adapter'

import Config from 'Config'

import { currentLocale } from 'modules/locale'

import md5 from 'md5'

const cache = setupCache({
  maxAge: 5 * 60 * 1000,
  debug: process.env.NODE_ENV !== 'production',

  key: (req) => {
    const key = md5(JSON.stringify(req.headers))

    return `${key}/${req.url}${serializeQuery(req)}`
  },

  exclude: {
    query: false,
  },
  ignoreCache: true,
})

const instance = axios.create({
  adapter: cache.adapter,
  baseURL: Config.apiUrl,
  headers: {
    ACCEPT: 'application/vnd.autoreserve.com+json; version=1',
    'Content-Type': 'application/json',
    'X-AR-Platform': 'web',
  },
})

instance.interceptors.request.use((config) => {
  if (currentLocale() !== 'ja') {
    config.params = config.params || {}
    config.params.locale = currentLocale()
  }

  return config
})

instance.defaults.headers.common['X-Use-Blank-User'] = '1'

export const { isAxiosError } = axios
export default instance
