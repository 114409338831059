import { get, isEmpty, without } from 'lodash'

import * as ActionTypes from 'ActionTypes'

import { moment, closestFutureReservationTime } from 'modules/time'

const dateTime = closestFutureReservationTime()

interface RestaurantState {
  menusByRestaurantId: Record<number, any>
  restaurants: any[]
  searchSuggests: any[]
  suggestsLoading: boolean
  searchSuggestsSections: any[]
  suggestsSectionsLoading: boolean

  restaurantIdBySlug: Record<string, string>
  reservationTimes: Record<string, any>
  reservationAlternativeDates: Record<string, any>
  reservationAlternativeStatusesByDate: Record<string, any>
  reservationTimesLoading: Record<string, any>
  reservationImpossibleExplanation: Record<string, any>
  isRequestModalVisible: boolean

  partySize: number
  dateTime: any
  date: string
  time: string

  restaurant?: any[]
}

const initialState: RestaurantState = {
  menusByRestaurantId: {},

  restaurants: [],
  searchSuggests: [],
  suggestsLoading: false,
  searchSuggestsSections: [],
  suggestsSectionsLoading: false,

  restaurantIdBySlug: {},
  reservationTimes: {},
  reservationAlternativeDates: {},
  reservationAlternativeStatusesByDate: {},
  reservationTimesLoading: {},
  reservationImpossibleExplanation: {},
  isRequestModalVisible: false,

  partySize: 2,
  dateTime: dateTime,
  date: dateTime.format('YYYY-MM-DD'),
  time: dateTime.format('H:mm'),
}

export default (state = initialState, action): RestaurantState => {
  const payload = action.payload
  const id = payload ? payload.id : null
  switch (action.type) {
    case ActionTypes.GET_RESTAURANT_TABLE_MENUS_SUCCEEDED: {
      return {
        ...state,
        menusByRestaurantId: {
          ...state.menusByRestaurantId,
          [action.payload.restaurantId]: action.payload.menus,
        },
      }
    }
    case ActionTypes.GET_RESTAURANT_VISIT_HISTORIES_SUCCEEDED:
      return {
        ...state,
        restaurants: {
          ...state.restaurants,
          [id]: {
            ...state.restaurants[id],
            visitHistories: payload.visitHistories,
          },
        },
      }
    case ActionTypes.GET_SEARCH_SUGGESTS_STARTED:
      return {
        ...state,
        suggestsLoading: true,
      }
    case ActionTypes.GET_SEARCH_SUGGESTS_SUCCEEDED:
      return {
        ...state,
        searchSuggests: action.payload,
        suggestsLoading: false,
      }
    case ActionTypes.GET_SEARCH_SUGGESTS_SECTIONS_STARTED:
      return {
        ...state,
        suggestsSectionsLoading: true,
      }
    case ActionTypes.GET_SEARCH_SUGGESTS_SECTIONS_SUCCEEDED:
      return {
        ...state,
        searchSuggestsSections: action.payload,
        suggestsSectionsLoading: false,
      }
    case ActionTypes.GET_RESTAURANT_ID_SUCCEEDED:
      return {
        ...state,
        restaurantIdBySlug: {
          ...state.restaurantIdBySlug,
          [action.payload.slug]: action.payload.restaurantId,
        },
      }
    case ActionTypes.GET_RESTAURANT_RESERVATIONS_SUCCEEDED:
      return {
        ...state,
        restaurant: action.payload,
      }

    case ActionTypes.GET_RESERVATION_STATUSES:
      return {
        ...state,
        reservationTimesLoading: {
          ...state.reservationTimesLoading,
          [action.payload.restaurantId]: get(
            state.reservationTimesLoading,
            [action.payload.restaurantId],
            []
          ).concat(`${action.payload.date}_${action.payload.partySize}`),
        },
      }
    case ActionTypes.GET_RESERVATION_STATUSES_SUCCEEDED: {
      return {
        ...state,
        reservationTimesLoading: {
          ...state.reservationTimesLoading,
          [action.payload.restaurantId]: without(
            state.reservationTimesLoading[action.payload.restaurantId],
            `${action.payload.date}_${action.payload.partySize}`
          ),
        },
        reservationTimes: {
          ...state.reservationTimes,
          [action.payload.restaurantId]: {
            ...state.reservationTimes[action.payload.restaurantId],
            [`${action.payload.date}_${action.payload.partySize}`]:
              action.payload.statuses,
          },
        },
        reservationAlternativeDates: {
          ...state.reservationAlternativeDates,
          [action.payload.restaurantId]: {
            ...state.reservationAlternativeDates[action.payload.restaurantId],
            [`${action.payload.date}_${action.payload.partySize}`]:
              action.payload.alternativeDates,
          },
        },
        reservationAlternativeStatusesByDate: {
          ...state.reservationAlternativeStatusesByDate,
          [action.payload.restaurantId]: {
            ...state.reservationAlternativeStatusesByDate[
              action.payload.restaurantId
            ],
            [`${action.payload.date}_${action.payload.partySize}`]:
              action.payload.alternativeStatusesByDate,
          },
        },
        reservationImpossibleExplanation: {
          ...state.reservationImpossibleExplanation,
          [action.payload.restaurantId]: {
            ...state.reservationImpossibleExplanation[
              action.payload.restaurantId
            ],
            [`${action.payload.date}_${action.payload.partySize}`]:
              action.payload.explanation,
          },
        },
      }
    }
    case ActionTypes.GET_RESERVATION_STATUSES_FAILED: {
      return {
        ...state,
        reservationTimesLoading: {
          ...state.reservationTimesLoading,
          [action.payload.restaurantId]: without(
            state.reservationTimesLoading[action.payload.restaurantId],
            `${action.payload.date}_${action.payload.partySize}`
          ),
        },
      }
    }

    case ActionTypes.SET_RESTAURANT_PARTY_SIZE:
      return {
        ...state,
        partySize: action.payload.partySize,
      }
    case ActionTypes.SET_RESTAURANT_DATE:
      // TODO: どっかでH:mmかHH:mmがきててinvlaid dateになってるのかもしれない
      // 特定できていないのでとりあえず両方許容する
      return {
        ...state,
        dateTime: moment.tz(
          `${action.payload.date} ${state.time}`,
          ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD H:mm'],
          'Asia/Tokyo'
        ),
        date: action.payload.date,
      }
    case ActionTypes.SET_RESTAURANT_TIME:
      return {
        ...state,
        dateTime: moment.tz(
          `${state.date} ${action.payload.time}`,
          ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD H:mm'],
          'Asia/Tokyo'
        ),
        time: action.payload.time,
      }
    case ActionTypes.GET_RESTAURANT_REVIEWS_STARTED:
      return {
        ...state,
        [id]: {
          ...state[id],
          visibleReviews: [],
          reviewsLoading: true,
        },
      }
    case ActionTypes.GET_RESTAURANT_REVIEWS_SUCCEEDED:
      return {
        ...state,
        [id]: {
          ...state[id],
          visibleReviews: payload.reviews,
          reviewsLoading: false,
        },
      }
    case ActionTypes.SET_REQUEST_MODAL_VISIBLE:
      return {
        ...state,
        isRequestModalVisible: true,
      }
    case ActionTypes.SET_REQUEST_MODAL_INVISIBLE:
      return {
        ...state,
        isRequestModalVisible: action.payload.isVisible,
      }
    default:
      return state
  }
}
