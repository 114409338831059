import { schema } from 'normalizr'

import restaurant from './restaurant'
import restaurantList from './restaurantList'
import user from './user'

const homeSection = new schema.Entity(
  'homeSections',
  {
    restaurants: [
      {
        restaurant
      }
    ],
    restaurant_lists: [restaurantList],
    users: [user]
  },
  {
    processStrategy: value => {
      if (value && value.restaurants) {
        // WORKAROUND: 予約ステータスをわけて持たないと、昼の時間の予約ステータスをAPIから返している場合と
        // 別の場所で夜のステータスのみを返してる場合とかに表示を分けられずに後からnormalizeしたもので上書きされてしまう
        // なので、一旦、restaurantと分離して持たせている
        return {
          ...value,
          // webではページネーションがあるため、getHomeSectionContentsで必ずとってきている

          ...(value.type === 'restaurants_vertical' && value.has_more
            ? {
                restaurants: [],
                loading: true
              }
            : {
                restaurants: value.restaurants.map(restaurant => {
                  return {
                    restaurant: {
                      ...restaurant,
                      reservation_statuses: undefined
                    },
                    reservationStatuses: restaurant.reservation_statuses
                  }
                })
              })
        }
      }
      return value
    }
  }
)

export default homeSection
