export const GET_DELIVERY_RESTAURANT = 'GET_DELIVERY_RESTAURANT' as const
export const GET_DELIVERY_RESTAURANT_SUCCEEDED = 'GET_DELIVERY_RESTAURANT_SUCCEED' as const
export const GET_DELIVERY_RESTAURANT_PRODUCTS = 'GET_DELIVERY_RESTAURANT_PRODUCTS' as const
export const GET_DELIVERY_RESTAURANT_PRODUCTS_SUCCEEDED = 'GET_DELIVERY_RESTAURANT_PRODUCTS_SUCCEEDED' as const
export const CREATE_DELIVERY_RESTAURANT_PRODUCT = 'CREATE_DELIVERY_RESTAURANT_PRODUCT' as const
export const CREATE_DELIVERY_RESTAURANT_PRODUCT_SUCCEEDED = 'CREATE_DELIVERY_RESTAURANT_PRODUCT_SUCCEED' as const
export const GET_DELIVERY_RESTAURANT_DELIVERY_TIMES = 'GET_DELIVERY_RESTAURANT_DELIVERY_TIMES' as const
export const GET_DELIVERY_RESTAURANT_DELIVERY_TIMES_SUCCEEDED = 'GET_DELIVERY_RESTAURANT_DELIVERY_TIMES_SUCCEEDED' as const
export const GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS = 'GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS' as const
export const GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS_SUCCEEDED = 'GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS_SUCCEEDED' as const

export const GET_DELIVERY_CART = 'GET_DELIVERY_CART' as const
export const GET_DELIVERY_CART_SUCCEEDED = 'GET_DELIVERY_CART_SUCCEEDED' as const
export const UPDATE_DELIVERY_CART = 'UPDATE_DELIVERY_CART' as const
export const UPDATE_DELIVERY_CART_SUCCEEDED = 'UPDATE_DELIVERY_CART_SUCCEED' as const
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS' as const
export const UPDATE_DELIVERY_TIME = 'UPDATE_DELIVERY_TIME' as const
export const ADD_DELIVERY_CART_PRODUCT = 'ADD_DELIVERY_CART_PRODUCT' as const
export const ADD_DELIVERY_CART_PRODUCT_SUCCEEDED = 'ADD_DELIVERY_CART_PRODUCT_SUCCEEDED' as const
export const REMOVE_DELIVERY_CART_PRODUCT = 'REMOVE_DELIVERY_CART_PRODUCT' as const
export const REMOVE_DELIVERY_CART_PRODUCT_SUCCEEDED = 'REMOVE_DELIVERY_CART_PRODUCT_SUCCEEDED' as const
export const REQUEST_DELIVERY_CART = 'REQUEST_DELIVERY_CART' as const
export const REQUEST_DELIVERY_CART_SUCCEEDED = 'REQUEST_DELIVERY_CART_SUCCEEDED' as const

export const OPEN_DELIVERY_PURCHASE_MODAL = 'OPEN_DELIVERY_PURCHASE_MODAL' as const
export const OPEN_DELIVERY_PURCHASE_MODAL_SUCCEEDED = 'OPEN_DELIVERY_PURCHASE_MODAL_SUCCEEDED' as const
export const OPEN_DELIVERY_PURCHASE_MODAL_ERROR = 'OPEN_DELIVERY_PURCHASE_MODAL_ERROR' as const

export const COMPLETE_DELIVERY_PURCHASE_MODAL = 'COMPLETE_DELIVERY_PURCHASE_MODAL' as const
export const COMPLETE_DELIVERY_PURCHASE_MODAL_SUCCEEDED = 'COMPLETE_DELIVERY_PURCHASE_MODAL_SUCCEEDED' as const
export const COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR = 'COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR' as const
