import React from 'react'

import { View, Image } from 'react-native'

import logoSq from 'static/logo_sq.png'
import { t } from 'modules/i18n'
import LinkOpacity from './LinkOpacity'
import Colors from 'constants/Colors'

import { Text } from '@hello-ai/ar_shared'

export const APP_BANNER_HEIGHT = 56

function AppBanner({
  appURL = 'autoreserve://autoreserve/',
  platform,
  campaign = '',
  ct = '',
  ...props
}) {
  const storeURL = `https://app.adjust.com/ek76y1k?campaign=${campaign}&creative=${ct}`

  return (
    <View
      style={{
        width: '100vw',
        height: APP_BANNER_HEIGHT,
        backgroundColor: Colors.bgBlack,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
      }}
    >
      <Image
        source={{
          uri: logoSq,
        }}
        style={{
          width: 44,
          height: 44,
          borderRadius: 15,
        }}
      />
      <View
        style={{
          marginLeft: 8,
          flex: 1,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: '600',
          }}
        >
          AutoReserve
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <LinkOpacity
          href={storeURL}
          style={{
            width: 88,
            height: 32,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
            backgroundColor: Colors.primary,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: 'white',
              fontWeight: '600',
            }}
          >
            {t('インストール')}
          </Text>
        </LinkOpacity>
        {appURL != null && (
          <LinkOpacity
            href={appURL}
            style={{
              marginLeft: 8,
              width: 88,
              height: 32,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 4,
              backgroundColor: 'white',
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: Colors.black,
                fontWeight: '600',
              }}
            >
              {t('アプリで開く')}
            </Text>
          </LinkOpacity>
        )}
      </View>
    </View>
  )
}

export default AppBanner
