import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getRestaurantTableMenus = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/restaurants/${restaurantId}/table_menus`))
}

export const getRestaurantVisitHistories = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${restaurantId}/visit_histories`, {
      params: {},
      cache: { ignoreCache: false },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getSearchSuggests = (auth, keyword): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/search_suggests', {
      params: {
        keyword: keyword,
        latitude: auth.latitude,
        longitude: auth.longitude,
        is_app_location_accurate: auth.isAppLocationAccurate,
      },
      cache: { ignoreCache: false },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getSearchSuggestsSections = (auth, keyword): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/search_suggests/sections', {
      params: {
        keyword: keyword,
        latitude: auth.latitude,
        longitude: auth.longitude,
        is_app_location_accurate: auth.isAppLocationAccurate,
      },
      cache: { ignoreCache: false },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantId = (slug): ResponseWrapper => {
  return axios
    .get(`/restaurants/${slug}/id_by_slug`, { cache: { ignoreCache: false } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${restaurantId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getReservationStatuses = (
  auth,
  restaurantId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${restaurantId}/reservation_statuses`, {
      params: params,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantLists = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${id}/lists`, { cache: { ignoreCache: false } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantRankingUsers = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${restaurantId}/ranking_users`, {
      cache: { ignoreCache: false },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantReviews = (auth, id, page): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${id}/reviews`, {
      params: { page: page },
      cache: { ignoreCache: false },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRestaurantMenus = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurants/${id}/menus`, { cache: { ignoreCache: false } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const suggestRestaurantEdit = (auth, id, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/restaurants/${id}/suggest_edit`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const favoriteRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/restaurants/${restaurantId}/favorite`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const unfavoriteRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/restaurants/${restaurantId}/unfavorite`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
