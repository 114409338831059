import { useHistory, useLocation, useRouteMatch } from 'react-router'

import useLocationParams from 'modules/useLocationParams'
import useParamsUpdater from 'modules/useParamsUpdater'

function useIsTopPage() {
  return useRouteMatch({ path: '/', exact: true })
}

export const DEFAULT_SORT_BY = 'recommended'

export function useSortByParam() {
  const location = useLocation()

  const isTop = useIsTopPage()

  const match = location.pathname.match(/\/sort_by\/([^/]+)/)

  const params = useLocationParams()

  if (isTop) {
    return params.sort_by || DEFAULT_SORT_BY
  }

  const sortBy = match ? match[1] : DEFAULT_SORT_BY

  return sortBy
}

export function useSortByUpdater() {
  const history = useHistory()
  const location = useLocation()

  const isTop = useIsTopPage()

  const updateParams = useParamsUpdater()

  if (isTop) {
    return (newSortBy) => {
      updateParams({ sort_by: newSortBy })
    }
  }

  return (newSortBy) => {
    const newSortByPath =
      newSortBy === DEFAULT_SORT_BY ? '' : `/sort_by/${newSortBy}`
    history.replace({
      pathname: location.pathname.match(/\/sort_by\/([^/]+)/)
        ? location.pathname.replace(
            /\/sort_by\/([^/]+)/,

            newSortByPath
          )
        : (location.pathname.startsWith('/s')
            ? location.pathname
            : '/s/'
          ).replace(/\/?$/, newSortByPath),
      search: location.search,
    })
  }
}
