import { schema } from 'normalizr'

import { idAttribute, processStrategy } from 'modules/table'
import takeoutProduct from './takeoutProduct'

const tableMenuPages = new schema.Entity(
  'takeoutMenuPages',
  {
    takeout_products: [takeoutProduct],
  },
  {
    idAttribute,
    processStrategy,
  }
)

export default tableMenuPages
