import { useRef, useEffect } from 'react'

// Hook
function usePrevious<T>(value: T, initialValue?: T): T {
  const ref = useRef(initialValue)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
