import { schema } from 'normalizr'

import reservation from './reservation'
import review from './review'

const unionReservationAndReview = new schema.Union(
  {
    reservation: reservation,
    review: review
  },
  'type'
)

export default unionReservationAndReview
