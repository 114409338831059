import { takeEvery, select, put, call, fork } from './helpers/redux-saga'
import * as ActionTypes from 'ActionTypes'
import * as api from 'api'
import { normalize } from 'normalizr'
import schemas from 'schema'
import { handleApiError } from './shared'
import { retryAPI } from './helpers'

import { t } from 'modules/i18n'
import * as ui from 'actions/ui'

import { goBackOrReplace } from 'modules/history'

import * as actions from 'actions'

function* isCartEmpty() {
  const cart = yield* select(
    (state) => state.entity.deliveryOrders[state.delivery.cart]
  )

  return !cart || cart.delivery_product_orders.length === 0
}

function* getDeliveryRestaurant(
  action: ReturnType<typeof actions.getDeliveryRestaurant>
) {
  const { restaurantId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getDeliveryRestaurant,
    auth,
    restaurantId
  )

  if (response) {
    const { result, entities } = normalize(
      { id: restaurantId, delivery_restaurant: response.data },
      schemas.restaurant
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_DELIVERY_RESTAURANT_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getDeliveryRestaurantProducts(
  action: ReturnType<typeof actions.getDeliveryRestaurantProducts>
) {
  const { restaurantId, params } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getDeliveryRestaurantProducts,
    auth,
    restaurantId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.deliveryProduct,
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_DELIVERY_RESTAURANT_PRODUCTS_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
        deliveryProducts: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* createDeliveryRestaurantProduct(
  action: ReturnType<typeof actions.createDeliveryRestaurantProduct>
) {
  const { restaurantId, params, onSuccess } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.createDeliveryRestaurantProduct,
    auth,
    restaurantId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.deliveryProduct
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CREATE_DELIVERY_RESTAURANT_PRODUCT_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
      },
    })
    yield* put(ui.displayToastInfo(t('商品を登録しました')))
    if (onSuccess) {
      yield* call(onSuccess)
    }
    yield* fork(getDeliveryRestaurant, {
      type: ActionTypes.GET_DELIVERY_RESTAURANT,
      payload: {
        restaurantId,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getDeliveryRestaurantDeliveryTimes(
  action: ReturnType<typeof actions.getDeliveryRestaurantDeliveryTimes>
) {
  const { restaurantId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getDeliveryRestaurantDeliveryTimes,
    auth,
    restaurantId
  )

  if (response) {
    yield* put({
      type: ActionTypes.GET_DELIVERY_RESTAURANT_DELIVERY_TIMES_SUCCEEDED,
      payload: {
        restaurantId,
        deliveryTimes: response.data,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getDeliveryRestaurantDiscountCampaigns(
  action: ReturnType<typeof actions.getDeliveryRestaurantDiscountCampaigns>
) {
  const { restaurantId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getDeliveryRestaurantDiscountCampaigns,
    auth,
    restaurantId
  )

  if (response) {
    yield* put({
      type: ActionTypes.GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS_SUCCEEDED,
      payload: {
        restaurantId,
        discountCampaigns: response.data,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getDeliveryCart(action: ReturnType<typeof actions.getDeliveryCart>) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getDeliveryCart, auth)
  if (response) {
    if (response.data) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.GET_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* put({
        type: ActionTypes.GET_DELIVERY_CART_SUCCEEDED,
        payload: { cart: null },
      })
    }
  } else {
    yield* handleApiError(error)
  }
}

function* updateDeliveryCart(
  action: ReturnType<typeof actions.updateDeliveryCart>
) {
  const { params } = action.payload

  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.updateDeliveryCart, auth, {
      delivery_order: params.delivery_order,
    })

    if (response) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
    }
  }
}
function* addDeliveryCartProduct(
  action: ReturnType<typeof actions.addDeliveryCartProduct>
) {
  const { params, options } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.addDeliveryCartProduct, auth, {
    delivery_product_order: params.delivery_product_order,
  })

  if (response) {
    const { result, entities } = normalize(response.data, schemas.deliveryOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.ADD_DELIVERY_CART_PRODUCT_SUCCEEDED,
      payload: { cart: result },
    })

    if (options?.showToast) {
      yield* put(ui.displayToastInfo(t('カートに商品を追加しました')))
    }
  } else {
    yield* handleApiError(error)
  }
}

function* removeDeliveryCartProduct(
  action: ReturnType<typeof actions.removeDeliveryCartProduct>
) {
  const { deliveryProductId } = action.payload

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.removeDeliveryCartProduct, auth, {
    delivery_product_order: {
      delivery_product_id: deliveryProductId,
    },
  })
  if (response) {
    const { result, entities } = normalize(response.data, schemas.deliveryOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.REMOVE_DELIVERY_CART_PRODUCT_SUCCEEDED,
      payload: { cart: result },
    })

    if (yield* call(isCartEmpty)) {
      yield* put(ui.displayToastInfo(t('カートを空にしました')))

      yield* put({ type: ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_ERROR })
      yield* call(goBackOrReplace, './')
    }
  } else {
    yield* handleApiError(error)
  }
}

function* requestDeliveryCart(
  action: ReturnType<typeof actions.requestDeliveryCart>
) {
  const cart = yield* select((state) => state.delivery.cart)
  yield* put({
    type: ActionTypes.REQUEST_DELIVERY_CART_SUCCEEDED,
    payload: { cart: cart },
  })

  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.requestDeliveryCart, auth)
  if (response) {
    if (response.data) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.REQUEST_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* put({
        type: ActionTypes.REQUEST_DELIVERY_CART_SUCCEEDED,
        payload: { cart: null },
      })
    }
  } else {
    yield* handleApiError(error)
  }
}

function* updateDeliveryAddress(
  action: ReturnType<typeof actions.updateDeliveryAddress>
) {
  const { params, onHide } = action.payload

  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.updateDeliveryCart, auth, {
      delivery_order: params.delivery_order,
    })

    if (response) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
      yield* put(ui.displayToastInfo(t('お届け先を更新しました')))
      if (onHide) {
        onHide()
      }
    } else {
      yield* handleApiError(error)
    }
  }
}

function* updateDeliveryTime(
  action: ReturnType<typeof actions.updateDeliveryTime>
) {
  const { params } = action.payload

  const auth = yield* select((state) => state.auth)

  {
    const { response, error } = yield* call(api.updateDeliveryCart, auth, {
      delivery_order: params.delivery_order,
    })

    if (response) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
    }
  }
}

function* openDeliveryPurchaseModal(
  action: ReturnType<typeof actions.openDeliveryPurchaseModal>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getDeliveryCart, auth)
  if (response) {
    if (response.data) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.GET_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* put({
        type: ActionTypes.GET_DELIVERY_CART_SUCCEEDED,
        payload: { cart: null },
      })
    }
  } else {
    yield* handleApiError(error)
    yield* put({ type: ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_ERROR })
  }

  if (yield* call(isCartEmpty)) {
    yield* put(ui.displayToastError(t('カートの中身が空です')))

    yield* put({ type: ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_ERROR })
    yield* call(goBackOrReplace, './')
    return
  }

  yield* put({
    type: ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_SUCCEEDED,
  })
}

function* completeDeliveryPurchaseModal(
  action: ReturnType<typeof actions.completeDeliveryPurchaseModal>
) {
  const auth = yield* select((state) => state.auth)

  const { params } = action.payload

  {
    if (params.email) {
      const { response, error } = yield* call(api.updateUser, auth, {
        email: params.email,
      })
      if (response) {
        const { entities } = normalize(response.data, schemas.user)
        yield* put({
          type: ActionTypes.SET_ENTITIES,
          payload: entities,
        })
      } else {
        yield* handleApiError(error)
        yield* put({ type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR })
        return
      }
    }

    const user = yield* select((state) => state.entity.users[state.auth.id])
    if (!user.email) {
      yield* put(ui.displayToastError(t('メールアドレスを入力してください')))
      yield* put({ type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR })
      return
    }
  }

  {
    const { response, error } = yield* call(api.updateDeliveryCart, auth, {
      delivery_order: params.delivery_order,
    })

    if (response) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.UPDATE_DELIVERY_CART_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR })
      return
    }
  }

  {
    const { response, error } = yield* call(api.requestDeliveryCart, auth)
    if (response) {
      const { result, entities } = normalize(
        response.data,
        schemas.deliveryOrder
      )
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })

      yield* put({
        type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_SUCCEEDED,
        payload: { cart: result },
      })
    } else {
      yield* handleApiError(error)
      yield* put({ type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR })
    }
  }
}

const deliverySagas = [
  takeEvery(ActionTypes.GET_DELIVERY_RESTAURANT, getDeliveryRestaurant),
  takeEvery(
    ActionTypes.GET_DELIVERY_RESTAURANT_PRODUCTS,
    getDeliveryRestaurantProducts
  ),
  takeEvery(
    ActionTypes.CREATE_DELIVERY_RESTAURANT_PRODUCT,
    createDeliveryRestaurantProduct
  ),
  takeEvery(
    ActionTypes.GET_DELIVERY_RESTAURANT_DELIVERY_TIMES,
    getDeliveryRestaurantDeliveryTimes
  ),
  takeEvery(
    ActionTypes.GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS,
    getDeliveryRestaurantDiscountCampaigns
  ),
  takeEvery(ActionTypes.GET_DELIVERY_CART, getDeliveryCart),
  takeEvery(ActionTypes.UPDATE_DELIVERY_CART, updateDeliveryCart),
  takeEvery(ActionTypes.UPDATE_DELIVERY_ADDRESS, updateDeliveryAddress),
  takeEvery(ActionTypes.UPDATE_DELIVERY_TIME, updateDeliveryTime),
  takeEvery(ActionTypes.ADD_DELIVERY_CART_PRODUCT, addDeliveryCartProduct),
  takeEvery(
    ActionTypes.REMOVE_DELIVERY_CART_PRODUCT,
    removeDeliveryCartProduct
  ),
  takeEvery(ActionTypes.REQUEST_DELIVERY_CART, requestDeliveryCart),
  takeEvery(
    ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL,
    openDeliveryPurchaseModal
  ),
  takeEvery(
    ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL,
    completeDeliveryPurchaseModal
  ),
]

export default deliverySagas
