import { call, select, takeEvery, put } from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import * as ui from 'actions/ui'
import { handleApiError } from './shared'
import * as actions from 'actions'
import Config from 'Config'

function* requestOwner(action: ReturnType<typeof actions.requestOwner>) {
  const { params, history, fbq } = action.payload

  yield* put(ui.startFetch())

  const { response, error } = yield* call(api.requestOwner, params)

  if (response) {
    const { data: ownerRequest } = response

    if (process.env.NODE_ENV === 'production' && !Config.isStaging) {
      if (params.services.includes('qrorder')) {
        if (fbq !== undefined) {
          fbq(
            'trackCustom',
            'OwnerRequest',
            {
              content_name: 'AutoReserve For Restaurants',
            },
            {
              eventID: ownerRequest.fb_ads_event_id,
              event: 'OwnerRequest',
            }
          )
        }
      }
    }

    history.push({
      pathname: '/for_restaurants/contact_done',
      state: ownerRequest,
    })
  } else {
    yield* handleApiError(error)
  }

  yield* put(ui.endFetch())
}

function* createDocumentRequest(
  action: ReturnType<typeof actions.createDocumentRequest>
) {
  const { params, history, fbq } = action.payload

  yield* put(ui.startFetch())

  const { response, error } = yield* call(api.createDocumentRequest, params)

  if (response) {
    const { data: documentRequest } = response

    if (process.env.NODE_ENV === 'production' && !Config.isStaging) {
      if (fbq !== undefined) {
        fbq(
          'trackCustom',
          'OwnerRequest',
          {
            content_name: 'AutoReserve For Restaurants',
          },
          {
            eventID: documentRequest.fb_ads_event_id,
            event: 'OwnerRequest',
          }
        )

        fbq(
          'trackCustom',
          'DocumentRequest',
          {
            content_name: 'AutoReserve For Restaurants',
          },
          {
            eventID: documentRequest.fb_ads_event_id,
            event: 'DocumentRequest',
          }
        )
      }
    }

    history.push({
      pathname: '/for_restaurants/document_request_done',
    })
  } else {
    yield* handleApiError(error)
  }

  yield* put(ui.endFetch())
}

const ownerSagas = [
  takeEvery(ActionTypes.REQUEST_OWNER, requestOwner),
  takeEvery(ActionTypes.CREATE_DOCUMENT_REQUEST, createDocumentRequest),
]

export default ownerSagas
