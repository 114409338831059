import React, { useLayoutEffect } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'

import { Helmet } from 'react-helmet'

import ReactDOM from 'react-dom'

import './Bootstrap.scss'

import { matchPath, Router } from 'react-router'

import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom'

import * as Constants from 'Constants'

import 'modules/nprogress'

import { compact } from 'lodash'
import * as Sentry from '@sentry/browser'

import Config from 'Config'

import 'static/animate.css'

import loadable from '@loadable/component'

// import Notifications from './pages/notifications'

import NotFound from './pages/NotFound'
import Error from './pages/Error'

import { withErrorBoundary } from 'components/Shared/ErrorBoundary'

import Toast from 'components/Shared/Toast'

import * as actions from 'actions'
import waitUntil from 'modules/waitUntil'

import history from 'modules/history'
import { currentLocale } from 'modules/locale'

import { isExternalLogin } from 'modules/widgets'

import { RootState } from 'reducers'
import Loading from 'components/Shared/Loading'
import { View } from 'react-native'
import { LogBox, LogBoxNotification } from 'react-native-web-log-box'
import { PrivateRoute } from 'components/Shared/Navigation'
import {
  tableModalRoutes,
  TableModalRoutes,
  TableRoutes,
} from 'navigation/Table'

import { ForRRoutes } from 'navigation/ForR'

import { AppProvider } from 'components/Shared/AppProvider'

import {
  Colors,
  ResponsiveProvider,
  ConfigProvider as ArSharedConfigProvider,
  Config as ArSharedConfig,
} from '@hello-ai/ar_shared'

const Suspended = loadable(() => import('pages/Suspended'))

const DeliveryHome = loadable(() => import('./pages/DeliveryHome'))
const DeliveryOrders = loadable(() => import('./pages/Delivery/Orders'))
const DeliveryOrder = loadable(() => import('./pages/Delivery/Orders/Show'))
const DeliveryOrderReceipt = loadable(
  () => import('./pages/Delivery/Orders/Receipt')
)

const Restaurants = loadable(() => import('./pages/Restaurants'))
const RestaurantsNew = loadable(() => import('./pages/Restaurants/New'))
const RestaurantShop = loadable(() => import('./pages/Restaurants/Shop'))
const RestaurantShopProduct = loadable(
  () => import('./pages/Restaurants/ShopProduct')
)
const RestaurantShopLaw = loadable(() => import('./pages/Restaurants/ShopLaw'))
const RestaurantDelivery = loadable(
  () => import('./pages/Restaurants/Delivery')
)
const RestaurantDeliveryAddProductModal = loadable(
  () => import('components/Delivery/AddProductModal')
)

const RestaurantTakeout = loadable(() => import('./pages/Restaurants/Takeout'))

const RestaurantTakeoutCartModal = loadable(
  () => import('components/Takeout/CartModal')
)

const RestaurantTakeoutAddCartModal = loadable(
  () => import('components/Takeout/AddCartModal')
)

const AcceptReservationRequest = loadable(
  () => import('./pages/Restaurants/AcceptReservationRequest')
)
const ChangeInformationRequestModal = loadable(
  () => import('components/Restaurant/ChangeInformationRequestModal')
)
const WriteReviewModal = loadable(
  () => import('components/Restaurant/WriteReviewModal')
)
const RestaurantVisitHistoriesModal = loadable(
  () => import('components/Restaurant/RestaurantVisitHistoriesModal')
)

const RestaurantDeliveryPurchaseModal = loadable(
  () => import('components/Delivery/PurchaseModal/index')
)
const RestaurantShopCartModal = loadable(
  () => import('components/Shop/CartModal')
)
const RestaurantShopPurchaseModal = loadable(
  () => import('components/Shop/PurchaseModal/index')
)

const UsersShow = loadable(() => import('./pages/Users/Show'))
const UserMyLists = loadable(() => import('./pages/Users/MyLists'))

// Account
const Account = loadable(() => import('./pages/Account'))
const AccountEdit = loadable(() => import('./pages/Account/Edit'))
const AccountPhoneNumberEdit = loadable(
  () => import('./pages/Account/PhoneNumberEdit')
)
const AccountPhoneNumberValidate = loadable(
  () => import('./pages/Account/PhoneNumberValidate')
)
const AccountPasswordEdit = loadable(
  () => import('./pages/Account/PasswordEdit')
)
const AccountPayments = loadable(() => import('./pages/Account/Payments'))
const AccountPaymentsModal = loadable(
  () => import('components/Account/PaymentsModal')
)
const AccountDelete = loadable(() => import('./pages/Account/Delete'))
const AccountDevSetting = loadable(() => import('./pages/Account/DevSetting'))

const Home = loadable(() => import('./pages/Home/index'))
const Search = loadable(() => import('./pages/Home/Search'))
const Timeline = loadable(() => import('./pages/Timeline'))
const Messages = loadable(() => import('./pages/Messages'))
const Reservations = loadable(() => import('./pages/Reservations'))
const ReservationsShow = loadable(() => import('./pages/Reservations/Show'))
const ReservationRequestsShow = loadable(
  () => import('./pages/Reservations/RequestsShow')
)
const ReservationElementsShow = loadable(
  () => import('./pages/Reservations/ElementsShow')
)

const Lists = loadable(() => import('./pages/Lists'))

const SignIn = loadable(() => import('./pages/SignIn/index'))
const SignInModal = loadable(() => import('components/SignIn/SignInModal'))
const LoginCode = loadable(() => import('pages/SignIn/LoginCode'))
const LoginCodeModal = loadable(
  () => import('components/SignIn/LoginCodeModal')
)
const LoginWithPassword = loadable(
  () => import('pages/SignIn/LoginWithPassword')
)
const LoginWithPasswordModal = loadable(
  () => import('components/SignIn/LoginWithPasswordModal')
)
const SignUp = loadable(() => import('./pages/SignUp'))
const SignUpModal = loadable(() => import('components/SignUp/SignUpModal'))
const RequestReservationModal = loadable(
  () => import('components/RequestReservation/RequestReservationModal')
)

const WidgetsReserve = loadable(() => import('./pages/Widgets/Reserve'))
const WidgetsSignUp = loadable(() => import('./pages/Widgets/SignUp'))

const ShopOrders = loadable(() => import('./pages/ShopOrders'))
const ShopOrdersShow = loadable(() => import('./pages/ShopOrders/Show'))
const ShopOrdersWriteReviewModal = loadable(
  () => import('components/Shop/WriteReviewModal')
)
const ShopOrderReceipt = loadable(() => import('./pages/ShopOrders/Receipt'))

const Crew = loadable(() => import('./pages/Crew/index'))
const CrewRequestModal = loadable(() => import('./pages/Crew/RequestModal'))

const OnlineStore = loadable(() => import('./pages/OnlineStore'))

const TableOrders = loadable(() => import('./pages/TableOrders'))
const TableOrder = loadable(() => import('./pages/TableOrders/Show'))
const TableOrderReceipt = loadable(() => import('./pages/TableOrders/Receipt'))

const TableOrderWriteReviewModal = loadable(
  () => import('components/Table/Review/WriteReviewModal')
)

const TakeoutOrders = loadable(() => import('./pages/TakeoutOrders'))
const TakeoutOrdersShow = loadable(() => import('./pages/TakeoutOrders/Show'))
const TakeoutOrdersReceipt = loadable(
  () => import('./pages/TakeoutOrders/Receipt')
)

const QrOrderCampaign = loadable(() => import('./pages/QrOrderCampaign'))
const ForAffiliate = loadable(() => import('./pages/ForAffiliate'))
const Covid = loadable(() => import('./pages/Covid'))
const Company = loadable(() => import('./pages/Company'))
const Privacy = loadable(() => import('./pages/Privacy'))
const Law = loadable(() => import('./pages/Law'))
const TermsForCrew = loadable(() => import('./pages/TermsForCrew'))
const TermsForDevelopers = loadable(() => import('./pages/TermsForDevelopers'))
const TermsForAffiliate = loadable(() => import('./pages/TermsForAffiliate'))
const Terms = loadable(() => import('./pages/Terms'))

// withErrorBoundaryをすると新しいコンポネントが返ってくるので、inline化して使うと
// 毎回コンポネントが生成されて再レンダリングされてしまうので、使う前に予め定義しておく
const Pages = {
  Suspended: withErrorBoundary(Suspended),

  DeliveryHome: withErrorBoundary(DeliveryHome),
  DeliveryOrders: withErrorBoundary(DeliveryOrders),
  DeliveryOrder: withErrorBoundary(DeliveryOrder),
  DeliveryOrderReceipt: withErrorBoundary(DeliveryOrderReceipt),

  Restaurants: withErrorBoundary(Restaurants),
  RestaurantsNew: withErrorBoundary(RestaurantsNew),
  RestaurantShop: withErrorBoundary(RestaurantShop),
  RestaurantShopProduct: withErrorBoundary(RestaurantShopProduct),
  RestaurantShopLaw: withErrorBoundary(RestaurantShopLaw),
  RestaurantDelivery: withErrorBoundary(RestaurantDelivery),
  RestaurantTakeout: withErrorBoundary(RestaurantTakeout),
  AcceptReservationRequest: withErrorBoundary(AcceptReservationRequest),
  UsersShow: withErrorBoundary(UsersShow),
  UserMyLists: withErrorBoundary(UserMyLists),

  Account: withErrorBoundary(Account),
  AccountEdit: withErrorBoundary(AccountEdit),
  AccountPhoneNumberEdit: withErrorBoundary(AccountPhoneNumberEdit),
  AccountPhoneNumberValidate: withErrorBoundary(AccountPhoneNumberValidate),
  AccountPasswordEdit: withErrorBoundary(AccountPasswordEdit),
  AccountPayments: withErrorBoundary(AccountPayments),
  AccountDelete: withErrorBoundary(AccountDelete),
  AccountDevSetting: withErrorBoundary(AccountDevSetting),
  Lists: withErrorBoundary(Lists),

  SignIn: withErrorBoundary(SignIn),
  LoginCode: withErrorBoundary(LoginCode),
  LoginWithPassword: withErrorBoundary(LoginWithPassword),
  SignUp: withErrorBoundary(SignUp),

  Crew: withErrorBoundary(Crew),
  CrewRequestModal: withErrorBoundary(CrewRequestModal),

  OnlineStore: withErrorBoundary(OnlineStore),

  // Notifications: withErrorBoundary(Notifications),
  Home: withErrorBoundary(Home),
  Search: withErrorBoundary(Search),
  NotFound: withErrorBoundary(NotFound),
  Error: withErrorBoundary(Error),
  Timeline: withErrorBoundary(Timeline),
  Messages: withErrorBoundary(Messages),
  ReservationsShow: withErrorBoundary(ReservationsShow),
  Reservations: withErrorBoundary(Reservations),
  ReservationRequestsShow: withErrorBoundary(ReservationRequestsShow),
  ReservationElementsShow: withErrorBoundary(ReservationElementsShow),
  WidgetsReserve: withErrorBoundary(WidgetsReserve),
  WidgetsSignUp: withErrorBoundary(WidgetsSignUp),

  ShopOrders: withErrorBoundary(ShopOrders),
  ShopOrdersShow: withErrorBoundary(ShopOrdersShow),
  ShopOrderReceipt: withErrorBoundary(ShopOrderReceipt),

  TableOrders: withErrorBoundary(TableOrders),
  TableOrder: withErrorBoundary(TableOrder),
  TableOrderReceipt: withErrorBoundary(TableOrderReceipt),

  TakeoutOrders: withErrorBoundary(TakeoutOrders),
  TakeoutOrdersShow: withErrorBoundary(TakeoutOrdersShow),
  TakeoutOrdersReceipt: withErrorBoundary(TakeoutOrdersReceipt),

  QrOrderCampaign: withErrorBoundary(QrOrderCampaign),
  ForAffiliate: withErrorBoundary(ForAffiliate),
  Covid: withErrorBoundary(Covid),
  Company: withErrorBoundary(Company),
  Privacy: withErrorBoundary(Privacy),
  Law: withErrorBoundary(Law),
  Terms: withErrorBoundary(Terms),
  TermsForDevelopers: withErrorBoundary(TermsForDevelopers),
  TermsForAffiliate: withErrorBoundary(TermsForAffiliate),
  TermsForCrew: withErrorBoundary(TermsForCrew),
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1d6fdd0ab100414f8a2afb2ad24ead34@sentry.io/1495765',
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    ignoreErrors: [
      'ChunkLoadError',
      'ResizeObserver',
      'failed to asynchronously load component',
    ],
  })
}

if (process.env.NODE_ENV !== 'production') {
  LogBox.ignoreLogs([
    "Can't perform a React state update on an unmounted component",
    'Warning: Received `false` for a non-boolean attribute `collapsable`',
    'Touch identifier',
    'enterkeyhint',
    'React does not recognize the `secondaryFill`',
    'React does not recognize the `secondaryOpacity`',
    '@typescript-eslint/no-unused-vars',
    'You may test your Stripe.js integration over HTTP',
    '[HMR]',
    /Syntax Error|Reference Error|TypeScript error/, // lintやtscでキャッチされるエラーは開発中に表示すると邪魔なので、無視
    'The above error occured in',
    'Animated: `useNativeDriver` is not supported', // from external lib
  ])
  LogBox.install()
}

function App() {
  return (
    <>
      <AppProvider>
        <ResponsiveProvider>
          <Container />
        </ResponsiveProvider>
      </AppProvider>
      <View
        pointerEvents="box-none"
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <LogBoxNotification />
      </View>
    </>
  )
}

function Head() {
  const gTagId = Config.isStaging ? 'GTM-P952GLM' : 'GTM-KQ3DBJB'

  return (
    <>
      <Helmet htmlAttributes={{ lang: currentLocale() }}>
        {process.env.NODE_ENV === 'production' && (
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', ${JSON.stringify(
              Config.isStaging ? 'UA-150090909-2' : 'UA-150090909-1'
            )});
            `}
          </script>
        )}
        {process.env.NODE_ENV === 'production' && (
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer',${JSON.stringify(gTagId)});
  `}
          </script>
        )}
        {process.env.NODE_ENV === 'production' && (
          <noscript>
            {`<iframe src="https://www.googletagmanager.com/ns.html?id=${gTagId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
          </noscript>
        )}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '338368776662553');
            fbq('track', 'PageView');
        `}
        </script>
        <noscript>
          {`
            <img
              height="1"
              width="1"
              style="display: none"
              src="https://www.facebook.com/tr?id=338368776662553&ev=PageView&noscript=1"
              alt=""
            />
          `}
        </noscript>

        <script>
          {process.env.NODE_ENV === 'production' && !Config.isStaging
            ? `
                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
                analytics.load("eVs2P8TYHWDaG5hv6YJhOzqiSB6g9u7o");
                }}();
                `
            : `
                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
                analytics.load("2WT9T3ZDbd4a4h2xq4YIrCRVIULFjRmo");
                }}();
                `}
        </script>
      </Helmet>
      <style jsx global>
        {`
          #nprogress .bar {
            background: ${Constants.PRIMARY_COLOR};
          }
          #nprogress .peg {
            box-shadow: 0 0 10px ${Constants.PRIMARY_COLOR}, 0 0 5px #29d;
          }

          html {
            height: 100%;
          }

          body {
            min-height: 100%;
            font-size: 14px;
            line-height: 1;
            letter-spacing: 0.2;
            color: #444444;
            font-family: 'Hiragino Sans';
            display: flex;
            flex-direction: column;
          }

          #root {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
          }

          input,
          textarea,
          *:focus:not([data-focusvisible-polyfill='true']) {
            outline: none;
          }

          *:focus[data-focusvisible-polyfill='true'] {
            outline-color: ${Constants.PRIMARY_COLOR};
          }

          .blackLink {
            color: ${Constants.BLACK_COLOR};
          }
          .blackLink:hover {
            color: ${Constants.BLACK_COLOR};
          }
        `}
      </style>
    </>
    /*
      注意: ここに安易にグローバルなCSSを追加しないこと。
      共有したいスタイルがでたら、グローバルなスタイルを定義するのではなく、コンポネントを作るか、ページ単位でCSSを定義する
    */
  )
}

function Container() {
  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.id)

  const user = useSelector((state) => state.entity.users[userId])
  const isReady = Boolean(!userId || user)
  const fetching = useSelector((state) => state.ui.fetching)

  useLayoutEffect(() => {
    if (userId) {
      dispatch(actions.syncUser())
    }
  }, [dispatch, userId])

  // userId更新後なるべく早くuserIdをセットしたいのでuseEffectではなくuseLayoutEffectを使う
  useLayoutEffect(() => {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: String(userId),
      })
    })
  }, [userId])

  if (!isReady) return null

  const isSuspended = user != null && user.status === 'suspended'

  return (
    <>
      <Head />

      <Router history={history}>
        {isSuspended ? (
          <Route component={Pages.Suspended} />
        ) : (
          <Route component={ConnectedModalSwitch} />
        )}
      </Router>
      {ReactDOM.createPortal(<Toast />, document.body)}
      {fetching &&
        ReactDOM.createPortal(
          <View
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255,  255, 255,  0.8)',
              zIndex: 10000,
            }}
          >
            <Loading />
          </View>,
          document.body
        )}
    </>
  )
}

type ModalSwitchProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{}, {}, { modal: boolean }>
class ModalSwitch extends React.Component<ModalSwitchProps> {
  // 直接読み込んでも常にmodalとして表示するnested route
  static ALWAYS_MODAL_ROUTES = compact([
    {
      path: '/restaurants/:slug/change_information_request',
    },
    { path: '/restaurants/:slug/write_review', exact: true },
    { path: '/restaurants/:slug/visit_histories', exact: true },
    { path: '/restaurants/:slug/online_store/purchase', exact: true },
    { path: '/restaurants/:slug/online_store/cart', exact: true },
    { path: '/restaurants/:slug/delivery/purchase', exact: true },
    { path: '/restaurants/:slug/delivery/cart', exact: true },
    { path: '/restaurants/:slug/add_delivery_product', exact: true },
    { path: '/restaurants/:slug/takeout/cart', exact: true },
    { path: '/restaurants/:slug/takeout/add_cart/:id', exact: true },
    {
      path: '/shop_orders/:shop_order_id/product_orders/:id/write_review',
      exact: true,
    },
    { path: '/sign_up', exact: true },
    !isExternalLogin() && { path: '/sign_in', exact: true },
    !isExternalLogin() && { path: '/sign_in/code', exact: true },
    !isExternalLogin() && { path: '/sign_in/password', exact: true },
    { path: '/reserve' },
    ...tableModalRoutes,
    {
      path: '/table_orders/:id/write_review',
      exact: true,
    },
    { path: '/crew/request', exact: true },
  ])

  previousLocation = this.props.location

  componentDidMount() {
    const { location, history } = this.props

    // リロード後にもlocation stateがpersistすると、モーダルのURLの状態でリロードした時にブラウザバックで前後に戻るとおかしくなるので
    // リセットする
    if (history.action !== 'REPLACE') {
      // <Redirect>によってmountが発生した場合はreplaceするとリダイレクト元に戻されてしまうので無視
      history.replace(location.pathname + location.search + location.hash)
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { location } = this.props

    const locationChanged = location !== nextProps.location

    if (!locationChanged) {
      return
    }

    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }

    if (
      nextProps.history.action !== 'POP' &&
      !ModalSwitch.ALWAYS_MODAL_ROUTES.some((route) =>
        matchPath(nextProps.location.pathname, route)
      ) &&
      (!nextProps.location.state || !nextProps.location.state.modal)
    ) {
      // モーダルではなく、新規ページに進んだ場合のみ上にスクロールする

      window.scrollTo(0, 0)
    }
  }

  render() {
    const { location } = this.props
    const isModal =
      ModalSwitch.ALWAYS_MODAL_ROUTES.some((route) =>
        matchPath(location.pathname, route)
      ) ||
      (location.state && location.state.modal)

    return (
      <>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route exact path="/delivery" component={Pages.DeliveryHome} />
          <Route
            exact
            path="/delivery_orders"
            component={Pages.DeliveryOrders}
          />
          <Route
            exact
            path="/delivery_orders/:id"
            component={Pages.DeliveryOrder}
          />
          <Route
            exact
            path="/delivery_orders/:id/receipt"
            component={Pages.DeliveryOrderReceipt}
          />
          <PrivateRoute exact path="/timeline" component={Pages.Timeline} />
          <Redirect from="/assistant" to="/messages" />
          <PrivateRoute exact path="/messages" component={Pages.Messages} />
          <PrivateRoute exact path="/messages/:id" component={Pages.Messages} />
          <PrivateRoute
            path="/reservations"
            exact
            component={Pages.Reservations}
          />
          <PrivateRoute
            path="/reservations/requests/:id"
            exact
            component={Pages.ReservationRequestsShow}
          />
          <PrivateRoute
            path="/reservation_elements/:id"
            exact
            component={Pages.ReservationElementsShow}
          />
          <PrivateRoute
            path="/reservations/:id"
            exact
            component={Pages.ReservationsShow}
          />
          <Route
            path="/restaurants/:slug/accept_reservation_request"
            exact
            component={Pages.AcceptReservationRequest}
          />
          <Route
            exact
            path="/restaurants/:slug/online_store/products/:id"
            component={Pages.RestaurantShopProduct}
          />
          <Route
            exact
            path="/restaurants/:slug/online_store/law"
            component={Pages.RestaurantShopLaw}
          />
          <Route
            exact
            path="/restaurants/:slug/online_store"
            component={Pages.RestaurantShop}
          />
          <Route
            exact
            path="/restaurants/:slug/delivery"
            component={Pages.RestaurantDelivery}
          />
          <Route
            exact
            path="/restaurants/:slug/takeout"
            component={Pages.RestaurantTakeout}
          />
          <Route
            exact
            path="/restaurants/new"
            component={Pages.RestaurantsNew}
          />
          <Route
            path="/restaurants/:slug"
            exact
            component={Pages.Restaurants}
          />
          <PrivateRoute
            exact
            path="/takeout_orders/:id/receipt"
            component={Pages.TakeoutOrdersReceipt}
          />
          <PrivateRoute
            exact
            path="/takeout_orders/:id"
            component={Pages.TakeoutOrdersShow}
          />
          <PrivateRoute
            exact
            path="/takeout_orders"
            component={Pages.TakeoutOrders}
          />
          <PrivateRoute exact path="/account" component={Pages.Account} />
          <PrivateRoute
            path="/account/edit"
            exact
            component={Pages.AccountEdit}
          />
          <PrivateRoute
            path="/account/phone_number/edit"
            exact
            component={Pages.AccountPhoneNumberEdit}
          />
          <PrivateRoute
            path="/account/phone_number/validate"
            exact
            component={Pages.AccountPhoneNumberValidate}
          />
          <PrivateRoute
            path="/account/password/edit"
            exact
            component={Pages.AccountPasswordEdit}
          />
          <PrivateRoute
            path="/account/payments"
            exact
            component={Pages.AccountPayments}
          />
          <PrivateRoute
            path="/account/delete"
            exact
            component={Pages.AccountDelete}
          />
          {Config.isDevOnly && (
            <Route
              path="/account/dev_setting"
              exact
              component={Pages.AccountDevSetting}
            />
          )}
          <Route path="/users/:userName" exact component={Pages.UsersShow} />
          <Route
            path="/users/:userName/my_lists"
            exact
            component={Pages.UserMyLists}
          />
          <Route exact path="/lists/:slug" component={Pages.Lists} />
          <Route exact path="/online_store" component={Pages.OnlineStore} />
          <Route path="/crew" component={Pages.Crew} />
          {isExternalLogin() && (
            <Route path="/sign_in" exact component={Pages.SignIn} />
          )}
          {isExternalLogin() && (
            <Route path="/sign_in/code" exact component={Pages.LoginCode} />
          )}
          {isExternalLogin() && (
            <Route
              path="/sign_in/password"
              exact
              component={Pages.LoginWithPassword}
            />
          )}
          {/* {false && (
            <PrivateRoute
              path="/notifications"
              component={Pages.Notifications}
            />
          )} */}
          <Route exact path="/" component={Pages.Home} />
          <Route path="/s/" component={Pages.Search} />
          <Route exact path="/404" component={Pages.NotFound} />)
          <Route exact path="/500" component={Pages.Error} />
          <Route
            exact
            path="/widgets/reserve"
            component={Pages.WidgetsReserve}
          />
          <Route
            exact
            path="/widgets/sign_up"
            component={Pages.WidgetsSignUp}
          />
          <PrivateRoute
            exact
            path="/shop_orders/:id/receipt"
            component={Pages.ShopOrderReceipt}
          />
          <PrivateRoute
            exact
            path="/shop_orders/:id"
            component={Pages.ShopOrdersShow}
          />
          <PrivateRoute
            exact
            path="/shop_orders"
            component={Pages.ShopOrders}
          />
          <Route
            exact
            path="/qr_order_campaign"
            component={Pages.QrOrderCampaign}
          />
          <Route exact path="/for_affiliate" component={Pages.ForAffiliate} />
          <Route exact path="/covid_19" component={Pages.Covid} />
          <Route exact path="/company" component={Pages.Company} />
          <Route exact path="/terms" component={Pages.Terms} />
          <Route exact path="/privacy" component={Pages.Privacy} />
          <Route exact path="/law" component={Pages.Law} />
          <Route
            path="/terms_for_developers"
            exact
            component={Pages.TermsForDevelopers}
          />
          <Route
            path="/terms_for_affiliate"
            exact
            component={Pages.TermsForAffiliate}
          />
          <Route path="/terms_for_crew" exact component={Pages.TermsForCrew} />
          <Route path="/for_restaurants" component={ForRRoutes} />
          <PrivateRoute
            exact
            path="/table_orders/:id/receipt"
            component={Pages.TableOrderReceipt}
          />
          <PrivateRoute
            exact
            path="/table_orders/:id"
            component={Pages.TableOrder}
          />
          <PrivateRoute
            exact
            path="/table_orders"
            component={Pages.TableOrders}
          />
          <Route path="/table" component={TableRoutes} />
          {isModal ? ( // これは上で一致しなかったもの全てにマッチするので、これより後にルートをおいてもNotFoundになるので注意
            <Route path="/" component={Pages.Home} />
          ) : (
            <Route component={Pages.NotFound} />
          )}
        </Switch>

        {isModal && (
          <>
            <Route exact path="/sign_in" component={SignInModal} />
            <Route exact path="/sign_in/code" component={LoginCodeModal} />
            <Route
              path="/sign_in/password"
              exact
              component={LoginWithPasswordModal}
            />
            <Route exact path="/sign_up" component={SignUpModal} />
            <Route path="/reserve" component={RequestReservationModal} />
            <PrivateRoute
              path="/restaurants/:slug/change_information_request"
              exact
              component={ChangeInformationRequestModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/delivery/purchase"
              exact
              component={RestaurantDeliveryPurchaseModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/add_delivery_product"
              exact
              component={RestaurantDeliveryAddProductModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/online_store/cart"
              exact
              component={RestaurantShopCartModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/online_store/purchase"
              exact
              component={RestaurantShopPurchaseModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/takeout/cart"
              exact
              component={RestaurantTakeoutCartModal}
            />
            <Route
              path="/restaurants/:slug/takeout/add_cart/:id"
              exact
              component={RestaurantTakeoutAddCartModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/write_review"
              exact
              component={WriteReviewModal}
            />
            <PrivateRoute
              path="/restaurants/:slug/visit_histories"
              exact
              component={RestaurantVisitHistoriesModal}
            />
            <PrivateRoute
              path="/account/payments"
              component={AccountPaymentsModal}
            />
            <PrivateRoute
              path="/shop_orders/:shop_order_id/product_orders/:id/write_review"
              exact
              component={ShopOrdersWriteReviewModal}
            />
            <PrivateRoute
              exact
              path="/table_orders/:id/write_review"
              component={TableOrderWriteReviewModal}
            />
            <Route path="/table" component={TableModalRoutes} />
            <PrivateRoute
              path="/crew/request"
              exact
              component={CrewRequestModal}
            />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return { auth: state.auth }
}

const ConnectedModalSwitch = connect(mapStateToProps)(ModalSwitch)

export default App
