import React from 'react'

import useResponsive from 'modules/useResponsive'
import { View } from 'react-native'

interface CommonHeaderProps {
  children?: React.ReactNode
  onLayout?: React.ComponentProps<typeof View>['onLayout']
}
function CommonHeader({ children, onLayout }: CommonHeaderProps) {
  const { width, md } = useResponsive()
  return (
    <View
      accessibilityRole="navigation"
      onLayout={onLayout}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: width < md ? 16 : 20,
        zIndex: 100,
        backgroundColor: 'white',
      }}
    >
      {children}
    </View>
  )
}

export default CommonHeader
