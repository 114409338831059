import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

import Config from 'Config'

export const getRequestReservationSeats = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/request_reservations/seats`, { params }))
}

export const getRequestReservationCourses = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/request_reservations/courses`, { params }))
}

export const getRequestReservationCancelPolicies = (
  auth,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/request_reservations/cancel_policies`, { params })
  )
}

export const reserveRequestReservation = (auth, params): ResponseWrapper => {
  setHeader(auth)
  params = { ...params, dry_run: Config.dryReserve, request_source: 'web' }

  return wrapResponse(axios.post(`/request_reservations/reserve`, params))
}

export const getRequestReservationForm = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/request_reservations/form`, { params }))
}
