import * as ActionTypes from 'ActionTypes'
import { GiftedChat } from 'react-native-gifted-chat'
import { MessageListItem, Messages } from '../models/Message'
import { Draft, produce } from 'immer'

interface MessageState {
  messageEnabled: boolean
  messageList: MessageListItem[]
  messagesByMessageListId: Record<string, Messages>
  isAppActive: boolean
  isLoading: boolean
  connected: boolean
  isMessageListLoading: boolean
}

const initialState: MessageState = {
  messageEnabled: false,
  messageList: [],
  messagesByMessageListId: {},
  isAppActive: true,
  isLoading: true,
  connected: false,
  isMessageListLoading: true,
}

const initMessages = (): Messages => {
  return {
    messages: [],
    hasNext: false,
    isLoading: false,
    isLoadingMore: false,
  }
}

const initPartnerMessages = (draft: Draft<MessageState>, messageListId) => {
  if (!draft.messagesByMessageListId[messageListId]) {
    draft.messagesByMessageListId[messageListId] = initMessages()
  }
}

export default (state = initialState, action): MessageState => {
  switch (action.type) {
    case ActionTypes.SET_IS_APP_ACTIVE:
      return produce(state, (draft) => {
        draft.isAppActive = action.payload.active
      })
    case ActionTypes.SET_IS_LOADING_MESSAGES: {
      const { isLoading, messageListId } = action.payload
      return produce(state, (draft) => {
        initPartnerMessages(draft, messageListId)
        draft.messagesByMessageListId[messageListId].isLoading = isLoading
      })
    }

    case ActionTypes.SET_IS_LOADING_MORE_MESSAGES: {
      const { messageListId, messages } = action.payload
      if (!messages) {
        return state
      }
      return produce(state, (draft) => {
        initPartnerMessages(draft, messageListId)
        draft.messagesByMessageListId[messageListId].messages =
          GiftedChat.append(
            draft.messagesByMessageListId[messageListId].messages,
            [action.payload.message]
          )
      })
    }
    case ActionTypes.SET_IS_MESSAGE_SCREEN_FOCUSED: {
      const { messageListId } = action.payload

      return produce(state, (draft) => {
        draft.messageList.forEach((_, i) => {
          draft.messageList[i].isSelected = false
        })
        const index = draft.messageList.findIndex((m) => {
          return m.id === messageListId
        })

        if (index < 0) {
          return draft
        }

        draft.messageList[index].isSelected = true
      })
    }
    case ActionTypes.MESSAGE_CHANNEL_CONNECTED:
      return produce(state, (draft) => {
        draft.connected = true
      })
    case ActionTypes.MESSAGE_CHANNEL_DISCONNECTED:
      return produce(state, (draft) => {
        draft.connected = false
      })
    case ActionTypes.UPDATE_MESSAGE_LIST:
      return produce(state, (draft) => {
        const messageListFromFront = draft.messageList.filter((m) => {
          const index = action.payload.messageList.findIndex((am) => {
            return (
              m.conversation_partner_id === am.conversation_partner_id &&
              m.conversation_partner_type === am.conversation_partner_type
            )
          })
          return index < 0
        })

        draft.messageList = action.payload.messageList.map((m) => {
          const oldMessageList = draft.messageList.find(
            (om) =>
              m.conversation_partner_id === om.conversation_partner_id &&
              m.conversation_partner_type === om.conversation_partner_type
          )
          return {
            ...m,
            isSelected: oldMessageList?.isSelected ?? false,
          }
        })
        draft.messageList = messageListFromFront.concat(draft.messageList)
        draft.isMessageListLoading = false
      })

    case ActionTypes.ADD_MESSAGE_LIST: {
      const { messageListItem } = action.payload
      return produce(state, (draft) => {
        // 新規ユーザーとのメッセージのやり取りの場合、idがtype_idにしているので、partnerの方でmatchさせている。
        const index = draft.messageList.findIndex((m) => {
          return (
            m.conversation_partner_id ===
              messageListItem.conversation_partner_id &&
            m.conversation_partner_type ===
              messageListItem.conversation_partner_type
          )
        })

        if (index < 0) {
          draft.messageList = [
            action.payload.messageListItem,
            ...state.messageList,
          ]
        } else {
          draft.messageList[index] = {
            ...messageListItem,
            isSelected: draft.messageList[index].isSelected,
          }
        }
      })
    }

    case ActionTypes.ADD_NEW_MESSAGES: {
      const { message_list_id, ...rest } = action.payload.message
      return produce(state, (draft) => {
        initPartnerMessages(draft, message_list_id)
        draft.messagesByMessageListId[message_list_id].messages =
          GiftedChat.append(
            draft.messagesByMessageListId[message_list_id].messages,
            [rest]
          )
      })
    }
    case ActionTypes.UPDATE_MESSAGE: {
      const { messageListId, replace, messages, hasNext } = action.payload
      if (replace) {
        return produce(state, (draft) => {
          initPartnerMessages(draft, messageListId)
          draft.messagesByMessageListId[messageListId].messages = messages
          draft.messagesByMessageListId[messageListId].hasNext = hasNext
          draft.messagesByMessageListId[messageListId].isLoading = false
          draft.messagesByMessageListId[messageListId].isLoadingMore = false
        })
      }

      return produce(state, (draft) => {
        initPartnerMessages(draft, messageListId)
        draft.messagesByMessageListId[messageListId].messages =
          GiftedChat.prepend<any>(
            draft.messagesByMessageListId[messageListId].messages,
            messages
          )
        draft.messagesByMessageListId[messageListId].hasNext = hasNext
        draft.messagesByMessageListId[messageListId].isLoading = false
        draft.messagesByMessageListId[messageListId].isLoadingMore = false
      })
    }

    case ActionTypes.UPDATE_READ_AT: {
      const { id, conversation_partner_read_at } = action.payload
      return produce(state, (draft) => {
        draft.messageList.forEach((m, i) => {
          if (m.id === id) {
            draft.messageList[i].conversation_partner_read_at =
              conversation_partner_read_at
          }
        })
      })
    }

    case ActionTypes.CLEAR_SELECT_MESSAGE_LIST: {
      return produce(state, (draft) => {
        draft.messageList.forEach((_, i) => {
          draft.messageList[i].isSelected = false
        })
      })
    }

    case ActionTypes.SELECT_MESSAGE_RESTAURANT: {
      const { restaurant, messageListId } = action.payload
      return produce(state, (draft) => {
        const index = draft.messageList.findIndex(
          (m) =>
            m.conversation_partner_id === restaurant.id &&
            m.conversation_partner_type === 'Restaurant'
        )

        draft.messageList.forEach((_, i) => {
          draft.messageList[i].isSelected = false
        })

        if (index < 0) {
          draft.messageList = [
            {
              id: messageListId,
              conversation_partner_id: restaurant.id,
              conversation_partner_type: 'Restaurant',
              conversation_partner_profile_image: restaurant.cover_image?.md,
              conversation_partner: {
                id: restaurant.id,
                is_foodie: false,
                image_url: restaurant.image_url,
                name: restaurant.name,
                username: restaurant.name,
              },
              latest_message_body: '',
              latest_message_created_at: '',
              unread_message_count: 0,
              isSelected: true,
            },
            ...draft.messageList,
          ]
        } else {
          draft.messageList[index].isSelected = true
        }
      })
    }

    default:
      return state
  }
}
