import React from 'react'
import { Provider } from 'react-redux'
import { SWRConfig, swrDefaultConfig } from 'modules/swr'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'store'
import {
  ConfigProvider as ArSharedConfigProvider,
  Config as ArSharedConfig,
  AlertProvider,
  setAlertRef,
} from '@hello-ai/ar_shared'
import Modal from 'react-native-modal'
import { StyleProp, View, ViewStyle } from 'react-native'
import { fontFamily } from 'modules/text'

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: Constants.PRIMARY_COLOR,
    accent: Constants.ACCENT_COLOR,
    text: Constants.BLACK_COLOR,
  },
  palette: {
    primary: { main: Constants.PRIMARY_COLOR },
    secondary: { main: Constants.ACCENT_COLOR },
  },
}

interface ModalProps {
  isVisible: boolean
  onBackButtonPress?: () => void
  onBackdropPress?: () => void
  style: StyleProp<ViewStyle>
  children?: React.ReactNode
}

function Modal_({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <>{children}</>
    </Modal>
  )
}

const config: ArSharedConfig = {
  Text: {
    fontFamily: fontFamily(),
    fontSize: 14,
    fontWeight: '400',
  },
  TextInput: {
    fontSize: 14,
  },
  ModalComponent: Modal_,
}

export function AppProvider({ children }: { children?: React.ReactNode }) {
  return (
    <ArSharedConfigProvider value={config}>
      <SWRConfig value={swrDefaultConfig}>
        <Provider store={store}>
          <PaperProvider
            theme={theme}
            settings={{
              icon: (props) => <FontAwesome5Pro {...props} />,
            }}
          >
            <PersistGate loading={null} persistor={persistor}>
              {children}
              <View
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                pointerEvents="box-none"
              >
                <AlertProvider ref={(ref) => setAlertRef(ref)} />
              </View>
            </PersistGate>
          </PaperProvider>
        </Provider>
      </SWRConfig>
    </ArSharedConfigProvider>
  )
}
