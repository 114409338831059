import axios, { isAxiosError } from 'api/axios'
import { setHeader } from 'api/shared'
import { useRef } from 'react'
import useSWRRaw, { SWRConfiguration, Key, useSWRInfinite, cache } from 'swr'
import {
  Configuration,
  Fetcher,
  KeyLoader,
  Revalidator,
  RevalidatorOptions,
  SWRInfiniteConfiguration,
} from 'swr/dist/types'

import { isArray, isObject } from 'lodash'
import { getErrorMessage } from 'modules/error'
import { store } from 'store'

import * as actions from 'actions'
import * as ActionTypes from 'ActionTypes'
import { useToken } from 'models/Auth'

export const swrDefaultConfig: SWRConfiguration = {
  onError,
  onErrorRetry,
  focusThrottleInterval: 0,
}

// fetcherを必須にしデフォルトをunknownにしたいためwrapしている
export default function useSWR<Data, Error = unknown>(
  key: Key,
  fetcher: Fetcher<Data>,
  config?: SWRConfiguration
) {
  return useSWRRaw<Data, Error>(key, fetcher, config)
}
export * from 'swr'

export function swrKey<T extends Record<string, unknown>>(
  token: string | null,
  url: string,
  params?: T
): [string | null, string, string] {
  function replacer(key: string, value: any) {
    if (isObject(value) && !isArray(value)) {
      // objectの場合、ソートしたキー順にassignしキー順序をstableにする
      // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
      return Object.keys(value)
        .sort()
        .reduce((sorted: any, key: string) => {
          sorted[key] = (value as unknown as any)[key]
          return sorted
        }, {})
    }
    return value
  }

  return [token, url, JSON.stringify(params ?? {}, replacer)]
}
export function createSWRHook<Params, Data>(
  getKey: (params: Params) => string | [string, Partial<Params>]
) {
  return (params: Params, config?: SWRConfiguration<Data>) => {
    const token = useToken()
    const key = getKey(params)
    const normalizedKey: [string, Partial<Params>] =
      typeof key === 'string' ? [key, {}] : key

    return useSWR<Data>(
      params === undefined ? undefined : swrKey(token, ...normalizedKey),
      (token, url, params) => fetcher(token, url, JSON.parse(params)),
      config
    )
  }
}

export function onError(error: unknown) {
  if (isAxiosError(error) && error.response?.status === 401) {
    store.dispatch({ type: ActionTypes.SIGN_OUT_SUCCEEDED })
  }

  store.dispatch(actions.displayToastError(getErrorMessage(error)))
}

// Based on:
// https://github.com/vercel/swr/blob/5a05547df9aa700d634510f629d00c9f4cbdb9c7/src/config.ts#L12
function onErrorRetry(
  error: unknown,
  __: string,
  config: Readonly<Required<Configuration>>,
  revalidate: Revalidator,
  opts: Required<RevalidatorOptions>
): void {
  if (isAxiosError(error)) {
    const status = error.response?.status
    // 400系はリトライしない
    if (status !== undefined && status < 500) return
  }

  if (document.visibilityState === 'hidden') {
    return
  }

  if (
    typeof config.errorRetryCount === 'number' &&
    opts.retryCount > config.errorRetryCount
  ) {
    return
  }

  // exponential backoff
  const count = Math.min(opts.retryCount, 8)
  const timeout =
    ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval
  setTimeout(() => {
    revalidate(opts)
  }, timeout)
}

export function fetcherCache<T extends any[]>(...args: T) {
  const [serializedKey] = cache.serializeKey(args.length <= 1 ? args[0] : args)

  return cache.get(serializedKey)
}

export async function fetcherPost<T, P extends {} = {}>(
  token: string | null,
  url: string,
  params?: P
) {
  setHeader({ token: token })
  const response = await axios.post<T>(url, params)
  return response.data
}

export async function fetcher<T, P extends {} = {}>(
  token: string | null,
  url: string,
  params?: P
) {
  setHeader({ token: token })
  const response = await axios.get<T>(url, { params })
  return response.data
}

function useStickyResult<Data>(value: Data) {
  const sticky = useRef<Data | undefined>()
  if (value !== undefined) sticky.current = value
  return sticky.current
}

// https://github.com/vercel/swr/issues/192#issuecomment-568944696
export function useStickySWR<Data, Error = unknown>(
  key: Key,
  fetcher: Fetcher<Data>,
  config?: SWRConfiguration<Data, Error>
) {
  const { data, isValidating, error, ...rest } = useSWR<Data, Error>(
    key,
    fetcher,
    config
  )

  const stickyData = useStickyResult(data)

  return {
    ...rest,
    isValidating,
    error,
    data: stickyData,
  }
}

type SWRInfiniteParametrs<Data, Error> =
  | readonly [KeyLoader<Data>]
  | readonly [KeyLoader<Data>, Fetcher<Data>]
  | readonly [
      KeyLoader<Data>,
      SWRInfiniteConfiguration<Data, Error> | undefined
    ]
  | readonly [
      KeyLoader<Data>,
      Fetcher<Data>,
      SWRInfiniteConfiguration<Data, Error> | undefined
    ]

export function useStickySWRInfinite<Data, Error = unknown>(
  ...args: SWRInfiniteParametrs<Data, Error>
) {
  const { data, ...rest } = useSWRInfinite<Data, Error>(...args)

  const stickyData = useStickyResult(data)

  return {
    ...rest,
    data: stickyData,
  }
}
