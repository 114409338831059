import { useState, useEffect, useMemo } from 'react'

export default function useMediaQuery(query) {
  const mql = useMemo(() => window.matchMedia(query), [query])
  const [matches, setMatch] = useState(mql.matches)

  useEffect(() => {
    const handler = (e) => setMatch(e.matches)

    mql.addListener(handler)

    return () => {
      mql.removeListener(handler)
    }
  }, [mql])

  return matches
}
