import { schema } from 'normalizr'
import { idAttribute, processStrategy } from 'modules/table'

const takeoutProductOptionBox = new schema.Entity(
  'takeoutProductOptionBoxes',
  {},
  {
    idAttribute,
    processStrategy,
  }
)

export default takeoutProductOptionBox
