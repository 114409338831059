import {
  StyleSheet,
  Platform,
  StyleProp,
  ViewStyle,
  FlexStyle,
} from 'react-native'
import * as Constants from 'Constants'

const GeneralStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inline: {
    flexDirection: 'row',
  },
  inlineAllCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inlineCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  inlineAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inlineWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  alignCenter: {
    alignItems: 'center',
  },
  flexCenter: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexSpaceBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pXl: {
    padding: 30,
  },
  p: {
    padding: 20,
  },
  pG: {
    padding: 16,
  },
  pMd: {
    padding: 15,
  },
  pSm: {
    padding: 10,
  },
  pH: {
    paddingHorizontal: 20,
  },
  pVSm: {
    paddingVertical: 10,
  },
  pB0: {
    paddingBottom: 0,
  },
  mT: {
    marginTop: 20,
  },
  mTSm: {
    marginTop: 10,
  },
  mTXs: {
    marginTop: 5,
  },
  mBXl: {
    marginBottom: 40,
  },
  mBLg: {
    marginBottom: 30,
  },
  mB: {
    marginBottom: 20,
  },
  mBMd: {
    marginBottom: 15,
  },
  mBSm: {
    marginBottom: 10,
  },
  mBXs: {
    marginBottom: 5,
  },
  mL: {
    marginLeft: 20,
  },
  mLSm: {
    marginLeft: 10,
  },
  mLXs: {
    marginLeft: 5,
  },
  mR: {
    marginRight: 20,
  },
  mRd: {
    marginRight: 15,
  },
  mRSm: {
    marginRight: 10,
  },
  mRXs: {
    marginRight: 5,
  },
  mH: {
    marginHorizontal: 20,
  },
  mHSm: {
    marginHorizontal: 10,
  },
  mV: {
    marginVertical: 20,
  },
  mVSm: {
    marginVertical: 10,
  },
  textCenter: {
    textAlign: 'center',
  },
  textWhite: {
    color: 'white',
  },
  textDarkGray: {
    color: Constants.DARK_GRAY_COLOR,
  },
  textUltraDarkGray: {
    color: Constants.ULTRA_DARK_GRAY_COLOR,
  },
  textPrimary: {
    color: Constants.PRIMARY_COLOR,
  },
  textBody: {
    fontSize: 14,
  },
  fontJa: {
    fontFamily: Platform.select({ ios: 'HiraginoSans-W3' }),
  },
  fontBold: {
    fontWeight: '600',
  },
  f: {
    fontSize: 20,
    lineHeight: 28,
  },
  fS: {
    fontSize: 16,
    lineHeight: 22,
  },
  fX: {
    fontSize: 13,
  },
  bgWhite: {
    backgroundColor: 'white',
  },
  bgGray: {
    backgroundColor: Constants.ULTRA_LIGHT_GRAY_COLOR,
  },
  bgPrimary: {
    backgroundColor: Constants.PRIMARY_COLOR,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderColor: Constants.LIGHT_GRAY_COLOR,
  },
  formGroup: {
    marginBottom: 20,
  },
  formGroupInline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formLabel: {
    marginBottom: 10,
  },
  formLabelInline: {},
  input: {
    width: '100%',
    padding: 10,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: Constants.LIGHT_GRAY_COLOR,
    backgroundColor: 'white',
  },
  formCover: {
    backgroundColor: Constants.LIGHT_GRAY_COLOR,
  },
  formCoverIcon: {
    position: 'absolute',
    bottom: 10,
    right: 12,
  },
  helpText: {
    color: Constants.GRAY_COLOR,
    marginTop: 8,
    fontSize: 12,
  },
  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cover: {
    flex: 1,
    width: '100%',
    height: 'auto',
  },
  coverInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  coverContents: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
  },
  coverTitle: {
    color: 'white',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 2,
    textAlign: 'center',
  },
  coverText: {
    color: 'white',
    letterSpacing: 1,
    textAlign: 'center',
    marginTop: 12,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  darkOverlay: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  section: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: Constants.ULTRA_LIGHT_GRAY_COLOR,
  },
  sectionText: {
    fontSize: 12,
    fontWeight: '600',
    color: Constants.DARK_GRAY_COLOR,
  },
  bandContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  reservationList: {
    backgroundColor: Constants.PRIMARY_COLOR,
    height: 48,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reservationListText: {
    color: 'white',
  },
  reservationListElementsLength: {
    paddingHorizontal: 6,
    paddingVertical: 1,
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 3,
  },
  appBg: {
    backgroundColor: Constants.ULTRA_ULTRA_LIGHT_GRAY_COLOR,
  },
  formInput: {
    backgroundColor: '#f5f5f5',
    height: 56,
    paddingHorizontal: 12,
  },
  boxShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 3,
    shadowOpacity: 0.3,
    elevation: 3,
  },
})

export default GeneralStyles
