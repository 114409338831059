// https://github.com/captbaritone/raven-for-redux/issues/93
// https://gist.github.com/olavoasantos/9ac791098758ee7dedf0c0424ec8b398

import { omit } from 'lodash'

const filter = () => true
const getUndefined = () => {}
const getType = (action) => action.type
const identity = (x) => x

const createSentryMiddleware = (Sentry, options: any = {}) => {
  const {
    actionTransformer = identity,
    breadcrumbCategory = 'redux-action',
    breadcrumbDataFromAction = getUndefined,
    breadcrumbMessageFromAction = getType,
    filterBreadcrumbActions = filter,
    getTags,
    getUserContext,
    stateTransformer = identity,
  } = options

  let lastAction
  let lastEventId
  let capturedEvents = {}
  return (store) => {
    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event) => {
        const state = store.getState()

        // If the user getter is defined, we
        // should add it to the current
        // context.
        if (getUserContext) {
          scope.setUser(getUserContext(state))
        }

        // If the tags getter is defined, we
        // should add it to the current
        // context.
        if (getTags) {
          const tags = getTags(state)
          Object.keys(tags).forEach((key) => {
            scope.setTag(key, tags[key])
          })
        }

        // Get the captured event data and remove it from
        // the captured events to avoid storing
        // excessive data.
        const capturedEvent = capturedEvents[event.event_id] || {
          extra: {
            state: stateTransformer(state),
            lastAction: actionTransformer(lastAction),
          },
          breadcrumbs: event.breadcrumbs,
        }
        capturedEvents = omit(capturedEvents, event.event_id)

        // Return our modified event which now contains the
        // extra data, user, tag and only the breadcrumbs
        // added util the error was thrown.
        return {
          ...event,
          breadcrumbs: capturedEvent.breadcrumbs,
          extra: {
            ...event.extra,
            ...capturedEvent.extra,
          },
        }
      })
    })

    return (next) => (action) => {
      if (filterBreadcrumbActions(action)) {
        // If there is a new error emmited, we should
        // take a snapshot of the current state,
        // action and breadcrumbs.
        if (Sentry.lastEventId() !== lastEventId) {
          const state = store.getState()
          lastEventId = Sentry.lastEventId()
          capturedEvents[lastEventId] = {
            extra: {
              state: stateTransformer(state),
              lastAction: actionTransformer(lastAction),
            },
            breadcrumbs:
              Sentry.getCurrentHub().getStack()[0].scope._breadcrumbs,
          }
        }

        // Add new breadcrumb
        Sentry.addBreadcrumb({
          category: breadcrumbCategory,
          data: breadcrumbDataFromAction(action),
          message: breadcrumbMessageFromAction(action),
        })
      }

      lastAction = action
      return next(action)
    }
  }
}

export default createSentryMiddleware
