import { moment } from 'modules/time'

import {
  takeLatest,
  takeEvery,
  takeLeading,
  put,
  select,
  call,
  delay,
} from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import { normalize } from 'normalizr'
import schemas from 'schema'
import { get } from 'lodash'
import { retryAPI, takeLatestPerKey, takeLeadingPerKey } from './helpers'
import { handleApiError } from './shared'
import * as ui from 'actions/ui'
import { t } from 'modules/i18n'

import * as actions from 'actions'

function* getRestaurantTableMenus(
  action: ReturnType<typeof actions.getRestaurantTableMenus>
) {
  const auth = yield* select((state) => state.auth)
  const { restaurantId } = action.payload

  const { response, error } = yield retryAPI(
    api.getRestaurantTableMenus,
    auth,
    restaurantId
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableMenu])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_RESTAURANT_TABLE_MENUS_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
        menus: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getRestaurantVisitHistories(
  action: ReturnType<typeof actions.getRestaurantVisitHistories>
) {
  const auth = yield* select((state) => state.auth)
  const restaurantId = action.restaurantId
  const { response, error } = yield* call(
    api.getRestaurantVisitHistories,
    auth,
    restaurantId
  )
  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.unionReservationAndReview,
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_RESTAURANT_VISIT_HISTORIES_SUCCEEDED,
      payload: {
        id: restaurantId,
        visitHistories: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getSearchSuggests(
  action: ReturnType<typeof actions.getSearchSuggests>
) {
  yield* put({ type: ActionTypes.GET_SEARCH_SUGGESTS_STARTED })
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getSearchSuggests,
    auth,
    action.keyword
  )

  if (response) {
    yield* put({
      type: ActionTypes.GET_SEARCH_SUGGESTS_SUCCEEDED,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getSearchSuggestsSections(
  action: ReturnType<typeof actions.getSearchSuggests>
) {
  yield* put({ type: ActionTypes.GET_SEARCH_SUGGESTS_SECTIONS_STARTED })
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getSearchSuggestsSections,
    auth,
    action.keyword
  )

  if (response) {
    yield* put({
      type: ActionTypes.GET_SEARCH_SUGGESTS_SECTIONS_SUCCEEDED,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getRestaurantId(action: ReturnType<typeof actions.getRestaurantId>) {
  const { slug } = action.payload

  const restaurant = yield* select((state) => state.restaurant)
  const restaurantId = get(restaurant.restaurantIdBySlug, [slug], null)
  if (restaurantId) {
    return restaurantId
  }
  const { response, error } = yield retryAPI(api.getRestaurantId, slug)
  if (response) {
    const { result, entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_RESTAURANT_ID_SUCCEEDED,
      payload: {
        slug,
        restaurantId: result,
      },
    })
    return result
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

export function* getRestaurant(
  action: ReturnType<typeof actions.getRestaurant>
) {
  const { slug } = action.payload
  const auth = yield* select((state) => state.auth)
  const restaurantId = yield* call(getRestaurantId, {
    type: ActionTypes.GET_RESTAURANT_ID,
    payload: { slug },
  })
  const { response, error } = yield retryAPI(
    api.getRestaurant,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getRestaurantLists(
  action: ReturnType<typeof actions.getRestaurantLists>
) {
  const { slug } = action.payload
  const auth = yield* select((state) => state.auth)
  const restaurantId = yield* call(getRestaurantId, {
    type: ActionTypes.GET_RESTAURANT_ID,
    payload: { slug },
  })
  const { response, error } = yield retryAPI(
    api.getRestaurantLists,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function* getRestaurantRankingUsers(
  action: ReturnType<typeof actions.getRestaurantRankingUsers>
) {
  const { slug } = action.payload
  const auth = yield* select((state) => state.auth)
  const restaurantId = yield* call(getRestaurantId, {
    type: ActionTypes.GET_RESTAURANT_ID,
    payload: { slug },
  })
  const { response, error } = yield retryAPI(
    api.getRestaurantRankingUsers,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getRestaurantReviews(
  action: ReturnType<typeof actions.getRestaurantReviews>
) {
  const { slug, page } = action.payload
  const auth = yield* select((state) => state.auth)
  const restaurantId = yield* call(getRestaurantId, {
    type: ActionTypes.GET_RESTAURANT_ID,
    payload: { slug },
  })
  yield* put({
    type: ActionTypes.GET_RESTAURANT_REVIEWS_STARTED,
    payload: {
      id: restaurantId,
    },
  })
  const { response, error } = yield retryAPI(
    api.getRestaurantReviews,
    auth,
    restaurantId,
    page
  )
  if (response) {
    const { result, entities } = normalize(response.data.reviews, [
      schemas.review,
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    const payload = {
      id: response.data.id,
      reviews: result,
      reviewsPage: page,
    }
    yield* put({
      type: ActionTypes.GET_RESTAURANT_REVIEWS_SUCCEEDED,
      payload: payload,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getRestaurantMenus(
  action: ReturnType<typeof actions.getRestaurantMenus>
) {
  const { id } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getRestaurantMenus, auth, id)
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

export function* getReservationStatuses(
  action: ReturnType<typeof actions.getReservationStatuses>
) {
  const auth = yield* select((state) => state.auth)
  const restaurant = yield* select((state) => state.restaurant)

  const { restaurantId, date, partySize } = action.payload

  const reservedAt = moment.tz(date, 'YYYY-MM-DD', 'Asia/Tokyo')

  const { response, error } = yield retryAPI(
    api.getReservationStatuses,
    auth,
    restaurantId,
    {
      party_size: partySize,
      reserved_at: reservedAt.format(),
    }
  )

  if (error) {
    yield* handleApiError(error)
    return
  }

  if (response.status !== 202) {
    yield* call(getReservationStatusesSucceeded, action, response)
    return
  }

  // reservation status 取得を待つ
  // 合計で 5s
  for (let i = 0; i < 4; i++) {
    yield* delay(500 * (i + 1))

    const { response, error } = yield* call(
      api.getReservationStatuses,
      auth,
      restaurantId,
      {
        party_size: restaurant.partySize,
        reserved_at: reservedAt.format(),
      }
    )

    if (error) {
      yield* handleApiError(error)
      break
    }

    if (response.status === 200) {
      yield* call(getReservationStatusesSucceeded, action, response)
      return
    }
  }

  yield* put({
    type: ActionTypes.GET_RESERVATION_STATUSES_FAILED,
    payload: {
      restaurantId: restaurantId,
      partySize: restaurant.partySize,
      date: date,
    },
  })
}

function* getReservationStatusesSucceeded(
  action: ReturnType<typeof actions.getReservationStatuses>,
  response
) {
  const { restaurantId, date, partySize } = action.payload

  yield* put({
    type: ActionTypes.GET_RESERVATION_STATUSES_SUCCEEDED,
    payload: {
      restaurantId: restaurantId,
      partySize: partySize,
      date: date,
      statuses: response.data.statuses,
      alternativeDates: response.data.alternative_reservable_dates,
      alternativeStatusesByDate: response.data.statuses_by_reservable_dates,
      explanation: response.data.explanation,
    },
  })
}

function* suggestRestaurantEdit(
  action: ReturnType<typeof actions.suggestRestaurantEdit>
) {
  const auth = yield* select((state) => state.auth)
  const { restaurant, params, history } = action.payload
  const { response, error } = yield retryAPI(
    api.suggestRestaurantEdit,
    auth,
    restaurant.id,
    params
  )
  if (response) {
    history.push(`/restaurants/${restaurant.slug}`)
    yield* put(ui.displayToastInfo(t('情報修正を提案しました')))
  } else {
    yield* handleApiError(error)
  }
}

function* favoriteRestaurant(
  action: ReturnType<typeof actions.favoriteRestaurant>
) {
  const auth = yield* select((state) => state.auth)
  const { restaurantId } = action.payload
  const { response, error } = yield retryAPI(
    api.favoriteRestaurant,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* unfavoriteRestaurant(
  action: ReturnType<typeof actions.unfavoriteRestaurant>
) {
  const auth = yield* select((state) => state.auth)
  const { restaurantId } = action.payload
  const { response, error } = yield retryAPI(
    api.unfavoriteRestaurant,
    auth,
    restaurantId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

const restaurantSagas = [
  takeEvery(ActionTypes.GET_RESTAURANT_TABLE_MENUS, getRestaurantTableMenus),

  takeLeadingPerKey(
    ActionTypes.GET_RESTAURANT_ID,
    getRestaurantId,
    (action) => action.payload.slug
  ),
  takeLatest(ActionTypes.GET_SEARCH_SUGGESTS, getSearchSuggests),
  takeLatest(ActionTypes.GET_SEARCH_SUGGESTS, getSearchSuggestsSections),
  takeEvery(ActionTypes.GET_RESTAURANT, getRestaurant),
  takeEvery(ActionTypes.GET_RESTAURANT_LISTS, getRestaurantLists),
  takeEvery(
    ActionTypes.GET_RESTAURANT_RANKING_USERS,
    getRestaurantRankingUsers
  ),
  takeEvery(ActionTypes.GET_RESTAURANT_REVIEWS, getRestaurantReviews),
  takeEvery(ActionTypes.GET_RESTAURANT_MENUS, getRestaurantMenus),
  takeEvery(ActionTypes.GET_RESERVATION_STATUSES, getReservationStatuses),
  takeEvery(
    ActionTypes.GET_RESTAURANT_VISIT_HISTORIES,
    getRestaurantVisitHistories
  ),
  takeEvery(ActionTypes.SUGGEST_RESTAURANT_EDIT, suggestRestaurantEdit),
  takeEvery(ActionTypes.FAVORITE_RESTAURANT, favoriteRestaurant),
  takeEvery(ActionTypes.UNFAVORITE_RESTAURANT, unfavoriteRestaurant),
]

export default restaurantSagas
