import * as ActionTypes from 'ActionTypes'

interface ReviewState {
  isUploadingImages: boolean
  uploadingImagesProgress: number
  uploadingImagesProgressCurrent: number
  uploadingImagesProgressTotal: number
}

const initialState: ReviewState = {
  isUploadingImages: false,
  uploadingImagesProgress: 0,
  uploadingImagesProgressCurrent: 1,
  uploadingImagesProgressTotal: 1,
}

export default (state = initialState, action): ReviewState => {
  switch (action.type) {
    case ActionTypes.CREATE_REVIEW_IMAGES_STARTED:
      return {
        ...state,
        isUploadingImages: true,
        uploadingImagesProgress: 0,
        uploadingImagesProgressCurrent: action.payload.current,
        uploadingImagesProgressTotal: action.payload.total,
      }

    case ActionTypes.CREATE_REVIEW_IMAGES_PROGRESS:
      return {
        ...state,
        uploadingImagesProgress: action.payload.progress,
      }
    case ActionTypes.CREATE_REVIEW_IMAGES_PROGRESS_IMAGE_UPLOADED:
      return {
        ...state,
        uploadingImagesProgress: 0,
        uploadingImagesProgressCurrent: action.payload.current + 1,
      }
    case ActionTypes.CREATE_REVIEW_IMAGES_SUCCEEDED:
      return {
        ...state,
        isUploadingImages: false,
        uploadingImagesProgress: 0,
      }

    case ActionTypes.UPDATE_REVIEW_SUCCEEDED:
      return {
        ...state,
      }

    default:
      return state
  }
}
