import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const getNotifications = (auth, page): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get('/notifications', { params: { page: page } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const readNotifications = (auth): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch('/notifications/read', {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
