import loadable from '@loadable/component'
import { withErrorBoundary } from 'components/Shared/ErrorBoundary'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ForRMagazineRoutes } from './Magazine'
import { mapValues } from 'lodash'

const Pages = mapValues(
  {
    ForR: loadable(() => import('../../pages/ForR')),
    ForRQrOrder: loadable(() => import('../../pages/ForR/QrOrder')),
    ForRTerms: loadable(() => import('../../pages/ForR/Terms')),
    ForRDocumentRequest: loadable(
      () => import('../../pages/ForR/DocumentRequest')
    ),
    ForRDocumentRequestDone: loadable(
      () => import('../../pages/ForR/DocumentRequestDone')
    ),
    ForRContact: loadable(() => import('../../pages/ForR/Contact')),
    ForRContactDone: loadable(() => import('../../pages/ForR/ContactDone')),
    ForRInterviewsSalone2007: loadable(
      () => import('../../pages/ForR/Interviews/Salone2007')
    ),
    ForRInterviewsKanojonocurry: loadable(
      () => import('../../pages/ForR/Interviews/Kanojonocurry')
    ),
    ForRInterviewsUsagi: loadable(
      () => import('../../pages/ForR/Interviews/Usagi')
    ),
    ForRInterviewsTanbam: loadable(
      () => import('../../pages/ForR/Interviews/Tanbam')
    ),
    ForRInterviewsYoshibee: loadable(
      () => import('../../pages/ForR/Interviews/Yoshibee')
    ),
    NotFound: loadable(() => import('pages/NotFound')),
  },
  (Page) => withErrorBoundary(Page)
)

export function ForRRoutes() {
  return (
    <Switch>
      <Route path="/for_restaurants" exact component={Pages.ForR} />
      <Route
        path="/for_restaurants/qr_order"
        exact
        component={Pages.ForRQrOrder}
      />
      <Route path="/for_restaurants/terms" exact component={Pages.ForRTerms} />
      <Route
        path="/for_restaurants/document_request"
        exact
        component={Pages.ForRDocumentRequest}
      />
      <Route
        path="/for_restaurants/document_request_done"
        exact
        component={Pages.ForRDocumentRequestDone}
      />
      <Route
        path="/for_restaurants/contact"
        exact
        component={Pages.ForRContact}
      />
      <Route
        path="/for_restaurants/contact_done"
        exact
        component={Pages.ForRContactDone}
      />
      <Route
        path="/for_restaurants/interviews/salone2007"
        exact
        component={Pages.ForRInterviewsSalone2007}
      />
      <Route
        path="/for_restaurants/interviews/kanojonocurry"
        exact
        component={Pages.ForRInterviewsKanojonocurry}
      />
      <Route
        path="/for_restaurants/interviews/usagi"
        exact
        component={Pages.ForRInterviewsUsagi}
      />
      <Route
        path="/for_restaurants/interviews/tanbam"
        exact
        component={Pages.ForRInterviewsTanbam}
      />
      <Route
        path="/for_restaurants/interviews/yoshibee"
        exact
        component={Pages.ForRInterviewsYoshibee}
      />
      <Route path="/for_restaurants/magazine" component={ForRMagazineRoutes} />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  )
}
