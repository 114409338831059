import * as ActionTypes from 'ActionTypes'

export const getRestaurantReservations = (restaurantId) => {
  return {
    type: ActionTypes.GET_RESTAURANT_RESERVATIONS,
    restaurantId: restaurantId,
  } as const
}

export const resetReservationListProgressStatus = () => {
  return {
    type: ActionTypes.RESET_RESERVATION_LIST_PROGRESS_STATUS,
  } as const
}

export const getReservationList = (reservationId) => {
  return {
    type: ActionTypes.GET_RESERVATION_LIST,
    reservationId: reservationId,
  } as const
}

export const getReservationElement = (id) => {
  return {
    type: ActionTypes.GET_RESERVATION_ELEMENT,
    id: id,
  } as const
}

export const getReservation = (reservationId) => {
  return {
    type: ActionTypes.GET_RESERVATION,
    reservationId: reservationId,
  }
}

export const cancelReservation = (reservationId) => {
  return {
    type: ActionTypes.CANCEL_RESERVATION,
    reservationId: reservationId,
  }
}

export const editReservationList = (
  reservationId,
  deletedReservationElementIds,
  reorderedReservationElementIds
) => {
  return {
    type: ActionTypes.EDIT_RESERVATION_LIST,
    payload: {
      reservationId,
      deletedReservationElementIds,
      reorderedReservationElementIds,
    },
  }
}
