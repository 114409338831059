import { isEmpty } from 'lodash'

import { call, select, takeLatest, put } from './helpers/redux-saga'
import * as ActionTypes from 'ActionTypes'
import { handleApiError, getHome, updateCurrentLocation } from './shared'
import * as api from 'api'
import { retryAPI, takeLeadingPerKey } from './helpers'

import { normalize } from 'normalizr'
import schemas from 'schema'

import * as actions from 'actions'

function* watchHome(action: ReturnType<typeof actions.getHome>) {
  // ホームを一から取り直す系のアクションが走っている間は
  // ページネーションのアクションを取っても仕方ないので、ホームを取り直すsagaはcallで呼ぶ
  if (action.type === ActionTypes.GET_HOME) {
    yield* call(getHome, action)
  }

  // ホームを取得し終わったら、ページネーション系のアクションを監視する
  // 同時に二回呼ばれるなどした場合、最初に発行したアクションを継続したいためtakeLeadingPerKeyを使う
  // watchHomeが新たに呼ばれた時はこのGET_HOME_SECTION_NEXT_CONTENTSは自動でキャンセルされる
  yield takeLeadingPerKey(
    ActionTypes.GET_HOME_SECTION_CONTENTS,
    getHomeSectionContents,
    (action) => action.payload.sectionId
  )
}

function* getHomeSectionContents(
  action: ReturnType<typeof actions.getHomeSectionContents>
) {
  const { sectionId, params } = action.payload

  const auth = yield* select((state) => state.auth)

  yield* put({
    type: ActionTypes.SET_ENTITIES,
    payload: {
      homeSections: {
        [sectionId]: {
          restaurants: [],
          loading: true,
        },
      },
    },
  })

  const { response, error } = yield retryAPI(
    api.getHomeSectionContents,
    auth,
    sectionId,
    {
      ...params,
    }
  )

  if (response) {
    // TODO: 横スクロールのページネーションも含めて複数のページネーションに対応できるような構造にしてるが
    // 今はverticalかつrestaurantsの場合しか対応してない

    const { result, entities } = normalize(
      response.data.map((restaurant) => {
        return {
          restaurant: { ...restaurant, reservation_statuses: undefined },
          reservationStatuses: restaurant.reservation_statuses,
        }
      }),
      [{ restaurant: schemas.restaurant }]
    )

    const entity = yield* select((state) => state.entity)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: {
        ...entities,
        homeSections: {
          ...entity.homeSections,
          [sectionId]: {
            ...entity.homeSections[sectionId],
            restaurants: result,
            loading: false,
          },
        },
      },
    })
    yield* put({
      type: ActionTypes.GET_HOME_SECTION_CONTENTS_SUCCEEDED,
      payload: {
        sectionId,
        page: params.page,
        isAllFetched: isEmpty(result),
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

const homeSagas = [
  takeLatest([ActionTypes.GET_HOME], watchHome),
  takeLatest(ActionTypes.UPDATE_CURRENT_LOCATION, updateCurrentLocation),
]

export default homeSagas
