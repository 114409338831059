import React from 'react'

import {
  goBackOrReplace,
  useIsPreviousLocationWithinApp,
} from 'modules/history'
import SimpleHeader from './SimpleHeader'
import { LayoutChangeEvent } from 'react-native'

interface SPHeaderProps {
  onLayout?: (event: LayoutChangeEvent) => void
  title?: string | null
  color?: string | null
  backgroundColor?: string | null
  back?: boolean
  showHeaderBorderBottom?: boolean
  goBackTo?: string
  goBack?: string
}

function SPHeader({
  onLayout,
  title = null,
  color = Constants.PRIMARY_COLOR,
  backgroundColor = 'white',
  back = true,
  showHeaderBorderBottom = true,
  goBackTo = '/',
  goBack = 'always', // always, available, never
}: // alwaysは、常に表示、availableはアプリ内で前に戻れる場合のみ表示、neverは戻るを表示しない
SPHeaderProps) {
  const isPreviousLocationWithinApp = useIsPreviousLocationWithinApp()
  const isBackVisible =
    goBack === 'always' ||
    (goBack === 'available' &&
      isPreviousLocationWithinApp &&
      // おそらく実装がおかしい
      // @ts-expect-error
      goBack !== 'never')

  const onBackPress = () => {
    goBackOrReplace(goBackTo)
  }

  return (
    <SimpleHeader
      title={title}
      color={color}
      backgroundColor={backgroundColor}
      onLayout={onLayout}
      isBackVisible={isBackVisible}
      backType={back ? 'back' : 'close'}
      onBackPress={onBackPress}
      showHeaderBorderBottom={showHeaderBorderBottom}
    />
  )
}

export default SPHeader
