export const GET_RESTAURANT_TABLE_MENUS = 'GET_RESTAURANT_TABLE_MENUS' as const
export const GET_RESTAURANT_TABLE_MENUS_SUCCEEDED = 'GET_RESTAURANT_TABLE_MENUS_SUCCEEDED' as const

export const GET_RESTAURANT_VISIT_HISTORIES = 'GET_RESTAURANT_VISIT_HISTORIES' as const
export const GET_RESTAURANT_VISIT_HISTORIES_SUCCEEDED = 'GET_RESTAURANT_VISIT_HISTORIES_SUCCEEDED' as const

export const GET_SEARCH_SUGGESTS = 'GET_SEARCH_SUGGESTS' as const
export const GET_SEARCH_SUGGESTS_STARTED = 'GET_SEARCH_SUGGESTS_STARTED' as const
export const GET_SEARCH_SUGGESTS_SUCCEEDED = 'GET_SEARCH_SUGGESTS_SUCCEEDED' as const
export const GET_SEARCH_SUGGESTS_SECTIONS_STARTED = 'GET_SEARCH_SUGGESTS_SECTIONS_STARTED' as const
export const GET_SEARCH_SUGGESTS_SECTIONS_SUCCEEDED = 'GET_SEARCH_SUGGESTS_SECTIONS_SUCCEEDED' as const

export const GET_RESTAURANT_ID = 'GET_RESTAURANT_ID' as const
export const GET_RESTAURANT_ID_SUCCEEDED = 'GET_RESTAURANT_ID_SUCCEEDED' as const
export const GET_RESTAURANT = 'GET_RESTAURANT' as const
export const GET_RESTAURANT_SUCCEEDED = 'GET_RESTAURANT_SUCCEEDED' as const
export const GET_RESTAURANT_LISTS = 'GET_RESTAURANT_LISTS' as const
export const GET_RESTAURANT_RANKING_USERS = 'GET_RESTAURANT_RANKING_USERS' as const
export const GET_RESTAURANT_MENUS = 'GET_RESTAURANT_MENUS' as const

export const GET_RESERVATION_STATUSES = 'GET_RESERVATION_STATUSES' as const
export const GET_RESERVATION_STATUSES_SUCCEEDED = 'GET_RESERVATION_STATUSES_SUCCEEDED' as const
export const GET_RESERVATION_STATUSES_FAILED = 'GET_RESERVATION_STATUSES_FAILED' as const

export const SET_RESTAURANT_DATE = 'SET_RESTAURANT_DATE' as const
export const SET_RESTAURANT_TIME = 'SET_RESTAURANT_TIME' as const
export const SET_RESTAURANT_PARTY_SIZE = 'SET_RESTAURANT_PARTY_SIZE' as const

export const GET_RESTAURANT_REVIEWS = 'GET_RESTAURANT_REVIEWS' as const
export const GET_RESTAURANT_REVIEWS_STARTED = 'GET_RESTAURANT_REVIEWS_STARTED' as const
export const GET_RESTAURANT_REVIEWS_SUCCEEDED = 'GET_RESTAURANT_REVIEWS_SUCCEEDED' as const

export const SUGGEST_RESTAURANT_EDIT = 'SUGGEST_RESTAURANT_EDIT' as const

export const FAVORITE_RESTAURANT = 'FAVORITE_RESTAURANT' as const
export const UNFAVORITE_RESTAURANT = 'UNFAVORITE_RESTAURANT' as const

export const SET_REQUEST_MODAL_VISIBLE = 'SET_REQUEST_MODAL_VISIBLE'
export const SET_REQUEST_MODAL_INVISIBLE = 'SET_REQUEST_MODAL_INVISIBLE'
