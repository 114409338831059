import * as ActionTypes from 'ActionTypes'
import ReviewImageUploading from 'components/Restaurant/ReviewImageUploading'

export const getReview = (reviewId) => {
  return {
    type: ActionTypes.GET_REVIEW,
    payload: {
      reviewId,
    },
  } as const
}

export const createReview = (params, images, onFinish, from = null) => {
  return {
    type: ActionTypes.CREATE_REVIEW,
    payload: {
      params,
      images,
      onFinish,
      from,
    },
  } as const
}

export const updateReview = (
  reviewId,
  params,
  images,
  removeRestaurantImagesIds,
  onFinish,
  from = null
) => {
  return {
    type: ActionTypes.UPDATE_REVIEW,
    payload: {
      reviewId,
      params,
      images,
      removeRestaurantImagesIds,
      onFinish,
      from,
    },
  } as const
}

export const createOrUpdateReview = (
  reviewId,
  params,
  images,
  removeRestaurantImagesIds,
  onFinish,
  from = null
) => {
  return {
    type: ActionTypes.CREATE_OR_UPDATE_REVIEW,
    payload: {
      reviewId,
      params,
      images,
      removeRestaurantImagesIds,
      onFinish,
      from,
    },
  } as const
}
