import * as ActionTypes from 'ActionTypes'

import { isEmpty } from 'lodash'

interface DeliveryHomeState {
  restaurants: number[]
  loading: boolean
  isFetched: boolean
  page: number
  keyword: string
  latitude?: number
  longitude?: number
  delivery_time?: number
  isFirstSearch: boolean
}

const initialState: DeliveryHomeState = {
  restaurants: [],
  loading: true,
  isFetched: false,
  page: 1,
  keyword: '',
  latitude: null,
  longitude: null,
  delivery_time: null,
  isFirstSearch: true,
}
export default (state = initialState, action): DeliveryHomeState => {
  switch (action.type) {
    case ActionTypes.GET_DELIVERY_RESTAURANTS: {
      const {
        keyword,
        latitude,
        longitude,
        delivery_time,
      } = action.payload.params
      return {
        ...state,
        restaurants: [],
        loading: true,
        isFetched: false,
        page: 1,
        keyword,
        latitude,
        longitude,
        delivery_time,
        isFirstSearch: false,
      }
    }
    case ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS_SUCCEEDED:
      return {
        ...state,
        restaurants: state.restaurants.concat(action.payload.restaurants),
        isFetched: isEmpty(action.payload.restaurants),
        page: action.payload.page,
        loading: false,
      }

    default:
      return state
  }
}
