import React from 'react'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'
import ErrorPage from 'pages/Error'

interface ErrorBoundaryProps {
  hasError: boolean
  children: React.ReactNode
  FallbackComponet: React.ComponentType
}
export const ErrorBoundary = connect(
  (state) => state.error,
  null
)(
  class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {
      hasError: false,
    }

    // eslint-disable-next-line
    static getDerivedStateFromError(error) {
      return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
      console.error(error, errorInfo)
      Sentry.captureException(error, errorInfo)
    }

    render() {
      const FallbackComponent = this.props.FallbackComponet
      if (this.props.hasError) {
        return <FallbackComponent />
      }

      if (this.state.hasError) {
        return <FallbackComponent />
      }
      return this.props.children
    }
  }
)

export function withErrorBoundary(Component, FallbackComponent = ErrorPage) {
  return function WithErrorBoundary(props) {
    return (
      <ErrorBoundary FallbackComponet={FallbackComponent}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
