import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const getReservationList = (
  auth,
  reservationListId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/reservation_lists/${reservationListId}`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const cancelReservationList = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`/reservation_lists/${id}/cancel`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const editReservationList = (auth, id, params): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`reservation_lists/${id}/edit`, params)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
