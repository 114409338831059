import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getDeliveryRestaurants = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get('/delivery/restaurants/', {
      params,
      cache: { ignoreCache: false },
    })
  )
}
