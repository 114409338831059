import React, { useRef } from 'react'

import Modal from 'react-responsive-modal'

function BaseModal({ children, ...props }) {
  const ref = useRef(null)

  return (
    <Modal
      focusTrapOptions={{
        fallbackFocus: ref.current,
      }}
      {...(props as any)}
    >
      <div ref={ref} tabIndex={0} style={{ outline: 'none' }}>
        {children}
      </div>
    </Modal>
  )
}

export default BaseModal
