import * as ActionTypes from 'ActionTypes'

export function getHome(params) {
  return {
    type: ActionTypes.GET_HOME,
    payload: { params },
  } as const
}

export const getHomeSectionContents = (sectionId, params) => {
  return {
    type: ActionTypes.GET_HOME_SECTION_CONTENTS,
    payload: { sectionId, params },
  } as const
}

export const updateCurrentLocation = (requestAccurateLocation, forceUpdate) => {
  return {
    type: ActionTypes.UPDATE_CURRENT_LOCATION,
    payload: { requestAccurateLocation, forceUpdate },
  } as const
}
