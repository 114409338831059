import React from 'react'
import {
  View,
  ActivityIndicator,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native'

import * as Constants from '../../Constants'

import GeneralStyles from '../..//styles/GeneralStyles'

interface LoadingProps {
  style?: StyleProp<any>
}

const Loading = React.memo(function Loading({ style }: LoadingProps) {
  return (
    <View style={StyleSheet.compose(GeneralStyles.flexCenter, style)}>
      <ActivityIndicator size="small" color={Constants.PRIMARY_COLOR} />
    </View>
  )
})

export default Loading
