import * as ActionTypes from 'ActionTypes'

export const getTakeoutRestaurant = (restaurantId: number) => {
  return {
    type: ActionTypes.GET_TAKEOUT_RESTAURANT,
    payload: {
      restaurantId,
    },
  }
}
export const getTakeoutRestaurantMenus = (restaurantId, params) => {
  return {
    type: ActionTypes.GET_TAKEOUT_RESTAURANT_MENUS,
    payload: { restaurantId, params },
  } as const
}
export const getTakeoutProduct = (tableProductId, params?) => {
  return {
    type: ActionTypes.GET_TAKEOUT_PRODUCT,
    payload: { tableProductId, params },
  } as const
}

export const getTakeoutCart = (restaurantId) => {
  return {
    type: ActionTypes.GET_TAKEOUT_CART,
    payload: { restaurantId },
  } as const
}

export const createTakeoutOrderItem = (
  restaurantId,
  orderId,
  params,
  onFinish = null
) => {
  return {
    type: ActionTypes.CREATE_TAKEOUT_ORDER_ITEM,
    payload: { restaurantId, orderId, params, onFinish },
  } as const
}

export const updateTakeoutOrderItem = (
  restaurantId,
  orderItemId,
  params,
  onFinish = null
) => {
  return {
    type: ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM,
    payload: {
      restaurantId,
      orderItemId,
      params,
      onFinish,
    },
  } as const
}

export const destroyTakeoutOrderItem = (
  restaurantId,
  orderId,
  params?,
  onFinish: () => void = null
) => {
  return {
    type: ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM,
    payload: {
      restaurantId,
      orderId,
      params,
      onFinish,
    },
  } as const
}

export const requestTakeoutOrder = (
  restaurantId,
  orderId,
  params,
  onFinish = null
) => {
  return {
    type: ActionTypes.REQUEST_TAKEOUT_ORDER,
    payload: { restaurantId, orderId, params, onFinish },
  } as const
}

export const getTakeoutOrder = (orderId, params?) => {
  return {
    type: ActionTypes.GET_TAKEOUT_ORDER,
    payload: {
      orderId,
      params,
    },
  } as const
}

export const updateTakeoutOrder = (orderId, params?, onFinish?) => {
  return {
    type: ActionTypes.UPDATE_TAKEOUT_ORDER,
    payload: {
      orderId,
      params,
      onFinish,
    },
  } as const
}

export const updateTakeoutOrderSucceeded = (orderId, params?) => {
  return {
    type: ActionTypes.UPDATE_TAKEOUT_ORDER_SUCCEEDED,
    payload: {
      orderId,
      params,
    },
  } as const
}

export const getTakeoutUserOrders = (params?) => {
  return {
    type: ActionTypes.GET_TAKEOUT_USER_ORDERS,
    payload: { params },
  } as const
}

export const getTakeoutReceipt = (orderId, params?, onFinish?) => {
  return {
    type: ActionTypes.GET_TAKEOUT_RECEIPT,
    payload: {
      orderId,
      params,
      onFinish,
    },
  } as const
}

export const createTakeoutReceipt = (orderId, params?, onFinish?) => {
  return {
    type: ActionTypes.CREATE_TAKEOUT_RECEIPT,
    payload: {
      orderId,
      params,
      onFinish,
    },
  } as const
}

export const getTakeoutOrderReceiveTimeOptions = (
  orderId,
  params?,
  onFinish?
) => {
  return {
    type: ActionTypes.GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS,
    payload: {
      orderId,
      params,
      onFinish,
    },
  } as const
}
export const getTakeoutOrderReceiveTimeOptionsSucceeded = ({
  receiveTimeOptions,
}) => {
  return {
    type: ActionTypes.GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS_SUCCEEDED,
    payload: { receiveTimeOptions },
  } as const
}
