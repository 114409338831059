import axios from './axios'

import { ResponseWrapper, setHeader } from './shared'

export const getTop = (): ResponseWrapper => {
  return axios
    .get(`/web/top`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const getRequestReservation = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/web/request_reservation`, {
      params: {
        id: id,
      },
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
