import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import useResponsive from 'modules/useResponsive'
import { View } from 'react-native'
import { useHover } from 'react-native-web-hooks'
import Link from 'components/Shared/Link'

import { useRouteMatch } from 'react-router-dom'

import { t } from 'modules/i18n'

import { Text } from '@hello-ai/ar_shared'

interface TabItemProps {
  to: string
  iconName
  renderIcon?: (boolean) => React.ReactNode
  label: string
  exact?: boolean
  unreadCount?: number
}

function TabItem({
  to,
  iconName,
  renderIcon,
  label,
  exact = false,
  unreadCount = 0,
}: TabItemProps) {
  let match = useRouteMatch({
    path: to,
    exact,
  })

  const searchMatch = useRouteMatch({
    path: '/s/',
  })

  if (to === '/') {
    match = match || searchMatch
  }

  const ref = useRef(null)
  const isHovered = useHover(ref)

  return (
    <View
      accessibilityRole="listitem"
      style={{
        flex: 1,
        height: '100%',
      }}
    >
      <Link
        to={to}
        ref={ref}
        style={[
          {
            color: Constants.DARK_GRAY_COLOR,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: 15,
            // insetがない時の最低のpadding-bottomを14pxにして
            // insetがある時、14pxぶん余分にpaddingがつかないよう最低14pxを指定してmaxをとる
            paddingBottom: 'max(15px, env(safe-area-inset-bottom))',
            textDecorationLine: 'none',
          },
          isHovered && { textDecorationLine: 'none' },
        ]}
      >
        <View
          style={{
            height: 80 - 15 * 2,
            // 全体でinset-bottomがない時に高さが72になるようにしたいので、上下のpaddingぶんを引いた高さを持たせる
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <View style={{ position: 'relative' }}>
            {renderIcon ? (
              renderIcon(match)
            ) : (
              <FontAwesome5Pro
                name={iconName}
                size={22}
                color={
                  match ? Constants.PRIMARY_COLOR : Constants.TEXT_PRIMARY_36
                }
                solid
              />
            )}

            {unreadCount > 0 && (
              <View
                style={{
                  position: 'absolute',
                  top: -12,
                  right: -16,
                  paddingHorizontal: 3,
                  paddingVertical: 2,
                  borderRadius: 2,
                  backgroundColor: Constants.PRIMARY_COLOR,
                }}
              >
                <Text
                  style={{ fontSize: 8, color: 'white', fontWeight: '600' }}
                >
                  {unreadCount}
                </Text>
              </View>
            )}
          </View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '600',
              marginTop: 8,
              color: match
                ? Constants.PRIMARY_COLOR
                : Constants.TEXT_PRIMARY_36,
            }}
          >
            {label}
          </Text>
        </View>
      </Link>
    </View>
  )
}

function NoLoggedInTabItems() {
  return (
    <>
      <TabItem to="/" exact iconName="home-alt" label={t('ホーム')} />
      <TabItem to="/sign_up" iconName="user-plus" label={t('登録')} />
      <TabItem to="/sign_in" iconName="sign-in-alt" label={t('ログイン')} />
    </>
  )
}

function LoggedInTabItems() {
  const unreadMessagesCount = useSelector((state) => {
    if (state.message.messageList.length === 0) {
      return 0
    }
    return state.message.messageList
      .map((m) => m.unread_message_count)
      .reduce((previous, next) => previous + next)
  })
  return (
    <>
      <TabItem to="/" exact iconName="home-alt" label={t('ホーム')} />
      <TabItem
        to="/messages"
        iconName="envelope"
        label={t('メッセージ')}
        unreadCount={unreadMessagesCount}
      />
      <TabItem to="/timeline" iconName="clock" label={t('タイムライン')} />
      <TabItem to="/account" iconName="user-circle" label={t('アカウント')} />
    </>
  )
}

export default function Tab() {
  const isLoggedIn = useSelector((state) => !!state.auth.id)
  const { width, md } = useResponsive()

  if (width >= md) {
    return null
  }

  return (
    <View
      accessibilityRole="navigation"
      style={{
        backgroundColor: 'white',
        borderTopWidth: 1,
        borderTopColor: Constants.LIGHT_GRAY_COLOR,
      }}
    >
      <View
        accessibilityRole="list"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          margin: 0,
          padding: 0,
        }}
      >
        {isLoggedIn ? <LoggedInTabItems /> : <NoLoggedInTabItems />}
      </View>
    </View>
  )
}
