import {
  put,
  take,
  delay,
  call,
  select,
  takeEvery,
  takeLeading,
  fork,
  cancel,
} from './helpers/redux-saga'

import * as api from 'api'
import * as ActionTypes from 'ActionTypes'

import { handleApiError } from './shared'
import { syncUser } from 'sagas/auth'
import { normalize } from 'normalizr'
import schemas from 'schema'
import { retryAPI } from './helpers'
import { t } from 'modules/i18n'

import * as actions from 'actions'
import * as ui from 'actions/ui'

function* getRestaurantReservations(
  action: ReturnType<typeof actions.getRestaurantReservations>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getRestaurantReservations,
    auth,
    action.restaurantId
  )
  if (response) {
    yield* put({
      type: ActionTypes.GET_RESTAURANT_RESERVATIONS_SUCCEEDED,
      payload: response.data,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getReservationList(action) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getReservationList,
    auth,
    action.reservationId
  )
  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.reservationList
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getReservationElement(action) {
  const auth = yield select((state) => state.auth)
  const { response, error } = yield* call(
    api.getReservationElement,
    auth,
    action.id
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.reservationElement)
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

export function* getReservation(action) {
  const auth = yield select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getReservation,
    auth,
    action.reservationId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.reservation)
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* cancelReservation(action) {
  const auth = yield select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.cancelReservationList,
    auth,
    action.reservationId
  )
  if (response) {
    const { result, entities } = normalize(response.data, schemas.reservation)
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield put({
      type: ActionTypes.CANCEL_RESERVATION_SUCCEEDED,
      payload: result,
    })
    // キャンセル成功時に予約リクエスト数を更新するためにsyncする
    yield* syncUser({ type: ActionTypes.SYNC_USER })
    // キャンセルは非同期のためこの場では予約系の同期とかはしない。pollingとかrefreshで更新されるのを待つ
    yield put(ui.displayToastInfo(t('予約キャンセルを実行しています')))
  } else {
    yield* handleApiError(error)
  }
}

function* editReservationList(action) {
  const auth = yield select((state) => state.auth)
  const { response, error } = yield* call(
    api.editReservationList,
    auth,
    action.payload.reservationId,
    {
      deleted_reservation_element_ids:
        action.payload.deletedReservationElementIds,
      reordered_reservation_element_ids:
        action.payload.reorderedReservationElementIds,
    }
  )
  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.reservationList
    )
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield put({
      type: ActionTypes.EDIT_RESERVATION_LIST_SUCCEEDED,
      payload: result,
    })
    yield put(ui.displayToastInfo(t('リクエストの変更を確定しました')))
  } else {
    yield* handleApiError(error)
  }
}

const reservationSagas = [
  takeEvery(ActionTypes.GET_RESTAURANT_RESERVATIONS, getRestaurantReservations),
  takeEvery(ActionTypes.GET_RESERVATION_LIST, getReservationList),
  takeEvery(ActionTypes.GET_RESERVATION_ELEMENT, getReservationElement),
  takeEvery(ActionTypes.GET_RESERVATION, getReservation),
  takeLeading(ActionTypes.CANCEL_RESERVATION, cancelReservation),
  takeEvery(ActionTypes.EDIT_RESERVATION_LIST, editReservationList),
]
export default reservationSagas
