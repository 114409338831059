import { compact, without } from 'lodash'
import React, { useReducer, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'

import { ScrollView, useWindowDimensions, View } from 'react-native'

import { TouchableOpacity } from 'components/Shared/Touchables'

import PriceSlider from './PriceSlider'
import DistanceSlider from './DistanceSlider'

import useParamsUpdater from 'modules/useParamsUpdater'

import useSearchParams from 'modules/useSearchParams'

import { useSortByParam, useSortByUpdater } from 'modules/sortByUtils'

import useResponsive from 'modules/useResponsive'
import { t } from 'modules/i18n'

import { Text } from '@hello-ai/ar_shared'

function FilterButton(props) {
  return (
    <TouchableOpacity onPressMinInterval={0} {...props}>
      <View
        style={{
          width: 32,
          height: 32,
          borderRadius: 16,
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.2)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesome5Pro name="sliders-h" regular size={12} />
      </View>
    </TouchableOpacity>
  )
}

function RoundButton(props) {
  const { text, active = false, open = false, caret = false, ...rest } = props
  return (
    <TouchableOpacity onPressMinInterval={0} {...rest}>
      <View
        style={{
          height: 32,
          borderRadius: 16,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor:
            active || open ? Constants.ACCENT_COLOR : 'rgba(0, 0, 0, 0.2)',
          backgroundColor: active ? Constants.ACCENT_COLOR : 'white',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          paddingHorizontal: 16,
        }}
      >
        <View style={GeneralStyles.inlineAlignCenter}>
          <Text
            style={{
              fontSize: 14,
              color: active ? 'white' : Constants.BLACK_COLOR,
            }}
          >
            {text}
          </Text>
          {caret && (
            <FontAwesome5Pro
              solid
              name={open ? 'caret-up' : 'caret-down'}
              size={10}
              style={{
                color: active ? 'white' : Constants.BLACK_COLOR,
                marginLeft: 8,
              }}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}

function getSortByNameForLabel(sortBy) {
  if (sortBy === 'recommended') {
    return t('並び順')
  }
  if (sortBy === 'ranking') {
    return t('人気順')
  }
  if (sortBy === 'distance') {
    return t('近い順')
  }
  return null
}

function FilterBar(props, ref) {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()

  const { headerAndBannerRef, headerAndBannerHeight } = props

  const { width, md } = useResponsive()

  const { hidePanel = false } = props

  const [selectedPanel, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'TOGGLE_PANEL':
        return state === action.payload.selectedPanel
          ? null
          : action.payload.selectedPanel
      case 'CLOSE_PANEL':
        return null
      default:
        console.error(`Error: no action type ${action.type} exists.`)
        return state
    }
  }, null)

  const sortBy = useSortByParam()

  const DEFAULT_MAX_PRICE = 30 * 1000
  const minPrice = params.min_price
  const maxPrice = params.max_price
  const maxDistance = params.max_distance
  const seat = params.seat
  const isSmoking = params.is_no_smoking
  const isNoSmoking = params.is_no_smoking
  const sources = params.sources
  const openOnly = params.open_only
  const michelinAwards = params.michelin_awards
  const facility = {
    parkingStatus: params.parking_status,
    barrierFreeStatus: params.barrier_free_status,
    wifiStatus: params.wifi_status,
    powerStatus: params.power_status,
    washletStatus: params.washlet_status,
  }
  const isFacilitySelected = (() => {
    const values = Object.values(facility)
    const selectedValues = values.filter((value) => value === 'available')
    return selectedValues.length > 0 && selectedValues.length < values.length
  })()

  useImperativeHandle(
    ref,
    () => {
      return {
        collapse() {
          dispatch({
            type: 'CLOSE_PANEL',
          })
        },
      }
    },
    []
  )

  return (
    <View
      style={{
        backgroundColor: 'white',
      }}
    >
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={{ backgroundColor: 'white' }}
        contentContainerStyle={{
          paddingHorizontal: 16,
        }}
      >
        {
          false && <FilterButton /> // FilterModalを実装したら表示する
        }
        <View
          style={{
            marginLeft: 0,
          }}
        >
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'sortBy',
                },
              })
            }}
            open={selectedPanel === 'sortBy'}
            text={getSortByNameForLabel(sortBy)}
            active={selectedPanel !== 'sortBy' && sortBy !== 'recommended'}
          />
        </View>

        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'price',
                },
              })
            }}
            open={selectedPanel === 'price'}
            text={t('価格帯')}
            active={
              selectedPanel !== 'price' &&
              ((minPrice !== null && minPrice > 0) ||
                (maxPrice !== null && maxPrice < DEFAULT_MAX_PRICE))
            }
          />
        </View>

        <View
          style={{
            marginLeft: 10,
          }}
        >
          <RoundButton
            onPress={() => {
              updateParams({
                open_only: !openOnly,
              })
              // rootDispatch(actions.setSearchParamsOpenOnly(!openOnly))
            }}
            text={t('現在営業中')}
            active={openOnly}
          />
        </View>

        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'distance',
                },
              })
            }}
            open={selectedPanel === 'distance'}
            text={t('距離')}
            active={selectedPanel !== 'price' && maxDistance !== 1.6}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'seat',
                },
              })
            }}
            open={selectedPanel === 'seat'}
            text={t('席種')}
            active={
              // 何も選択していない時と全て選択しているときはアクティブではない
              selectedPanel !== 'seat' && seat.length >= 1 && seat.length <= 2
            }
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'smoking',
                },
              })
            }}
            open={selectedPanel === 'smoking'}
            text={
              (!isSmoking && !isNoSmoking) || (isSmoking && isNoSmoking)
                ? t('禁煙・喫煙')
                : isSmoking
                ? t('喫煙')
                : t('禁煙')
            }
            active={
              selectedPanel !== 'smoking' &&
              ((isSmoking && !isNoSmoking) || (!isSmoking && isNoSmoking))
            }
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'sources',
                },
              })
            }}
            open={selectedPanel === 'sources'}
            text={
              sources.length === 0 || sources.length === 2
                ? t('予約方法')
                : sources.includes('web')
                ? t('即予約')
                : t('リクエスト予約')
            }
            active={
              selectedPanel !== 'sources' &&
              sources.length >= 1 &&
              sources.length < 2
            }
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'michelinAwards',
                },
              })
            }}
            open={selectedPanel === 'michelinAwards'}
            text={t('受賞歴')}
            active={
              // 何も選択していない時と全て選択しているときはアクティブではない
              selectedPanel !== 'michelinAwards' &&
              michelinAwards.length >= 1 &&
              michelinAwards.length <= 3
            }
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <RoundButton
            caret
            onPress={() => {
              dispatch({
                type: 'TOGGLE_PANEL',
                payload: {
                  selectedPanel: 'facility',
                },
              })
            }}
            open={selectedPanel === 'facility'}
            text={t('設備')}
            active={selectedPanel !== 'facility' && isFacilitySelected}
          />
        </View>
      </ScrollView>
      {!hidePanel &&
        selectedPanel &&
        headerAndBannerRef.current &&
        headerAndBannerRef.current && // ボタンを押してページが切り替わった時に、refの参照先のDOMが消える瞬間があるのでチェックする
        ReactDOM.createPortal(
          <View
            style={[
              GeneralStyles.inlineAlignCenter,
              {
                // PCの場合はロゴの分右に寄せる
                paddingLeft: 16 + (width < md ? 0 : 64 + 8),
                paddingRight: 16,
                paddingTop: 24,
                paddingBottom: 30,
                position: 'absolute',
                left: 0,
                right: 0,
                top: headerAndBannerHeight - 0.5 - 20, // 元々のヘッダの下部のパディングとボーダーの幅分を引く
                backgroundColor: 'white',
                borderBottomWidth: 0.5,
                borderBottomColor: Constants.BORDER_COLOR,
                // @ts-expect-error
                overflowX: 'hidden',
              },
            ]}
          >
            {selectedPanel === 'sortBy' && <SortBy />}
            {selectedPanel === 'price' && <PriceSlider />}
            {selectedPanel === 'distance' && <DistanceSlider />}
            {selectedPanel === 'seat' && <Seat />}
            {selectedPanel === 'smoking' && <Smoking />}
            {selectedPanel === 'sources' && <Sources />}
            {selectedPanel === 'michelinAwards' && <MichelinAwards />}
            {selectedPanel === 'facility' && <Facility />}
          </View>,
          headerAndBannerRef.current
        )}
    </View>
  )
}

function Seat() {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()

  const seat = params.seat

  return (
    <View style={[GeneralStyles.inlineAlignCenter]}>
      {[
        { name: t('カウンター'), value: 'counter' },
        { name: t('テーブル'), value: 'table' },
        { name: t('個室'), value: 'private_room' },
      ].map(({ name, value }, index) => {
        const active = seat.includes(value)
        return (
          <TouchableOpacity
            key={String(value)}
            onPressMinInterval={0}
            style={{
              flex: 1,
              marginLeft: index === 0 ? 0 : 10,
            }}
            onPress={() => {
              updateParams({
                seat: compact(
                  active ? without(seat, value) : seat.concat(value)
                ),
              })
              // dispatch(
              //   actions.setSearchParamsSeatPreference(
              //     active ? without(seat, value) : seat.concat(value)
              //   )
              // )
            }}
          >
            <View
              style={{
                height: 32,

                borderRadius: 16,
                borderStyle: 'solid',
                borderWidth: 1.5,
                borderColor: active
                  ? Constants.ACCENT_COLOR
                  : Constants.ULTRA_LIGHT_GRAY_COLOR,
                backgroundColor: active
                  ? Constants.ACCENT_COLOR
                  : Constants.ULTRA_LIGHT_GRAY_COLOR,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                paddingHorizontal: 24,
              }}
            >
              <View style={GeneralStyles.inlineAlignCenter}>
                <Text
                  style={{
                    fontSize: 14,
                    color: active ? 'white' : Constants.DARK_GRAY_COLOR,
                  }}
                >
                  {name}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

function ButtonWithCheck(props) {
  const { selected, text, ...rest } = props
  return (
    <TouchableOpacity
      {...rest}
      style={[
        GeneralStyles.container,
        GeneralStyles.inlineAllCenter,
        {
          minWidth: 120,
        },
      ]}
    >
      <Text
        style={{
          marginLeft: 10, // checkマークの(marginLeft + width) / 2ぶん
          fontSize: 14,

          fontWeight: selected ? '600' : 'normal',
        }}
      >
        {text}
      </Text>
      <FontAwesome5Pro
        name="check"
        regular
        size={12}
        color={Constants.ACCENT_COLOR}
        style={{
          marginLeft: 8,
          width: 12,
          opacity: selected ? 1 : 0,
        }}
      />
    </TouchableOpacity>
  )
}

function SortBy() {
  const sortBy = useSortByParam()
  const updateSortBy = useSortByUpdater()

  return (
    <View style={[GeneralStyles.inlineAlignCenter]}>
      <ButtonWithCheck
        onPress={() => {
          updateSortBy('recommended')
        }}
        selected={sortBy === 'recommended'}
        text={t('おすすめ')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateSortBy('ranking')
        }}
        selected={sortBy === 'ranking'}
        text={t('人気順')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateSortBy('distance')
        }}
        selected={sortBy === 'distance'}
        text={t('近い順')}
      />
    </View>
  )
}

function Smoking() {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()

  const isSmoking = params.is_smoking
  const isNoSmoking = params.is_no_smoking

  return (
    <View style={[GeneralStyles.inlineAlignCenter]}>
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            is_smoking: false,
            is_no_smoking: false,
          })
        }}
        selected={
          // 全部選択してる時と全部選択してない時が同じ
          (!isSmoking && !isNoSmoking) || (isSmoking && isNoSmoking)
        }
        text={t('すべて')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            is_smoking: false,
            is_no_smoking: true,
          })
        }}
        selected={isNoSmoking && !isSmoking}
        text={t('禁煙')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            is_smoking: true,
            is_no_smoking: false,
          })
        }}
        selected={isSmoking && !isNoSmoking}
        text={t('喫煙')}
      />
    </View>
  )
}

function Sources() {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()
  const sources = params.sources

  const allSelected = sources.length === 0 || sources.length === 2

  return (
    <View style={[GeneralStyles.inlineAlignCenter]}>
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            sources: [],
          })
        }}
        selected={allSelected}
        text={t('すべて')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            sources: ['web'],
          })
        }}
        selected={!allSelected && sources.includes('web')}
        text={t('即予約')}
      />
      <ButtonWithCheck
        onPress={() => {
          updateParams({
            sources: ['phone'],
          })
        }}
        selected={!allSelected && sources.includes('phone')}
        text={t('リクエスト予約')}
      />
    </View>
  )
}

function MichelinAwards() {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()
  const { width, sm } = useResponsive()
  const windowWidth = useWindowDimensions().width
  const itemMaxWidth = width < sm ? windowWidth / 2 - 20 : 168
  const michelinAwards = params.michelin_awards

  return (
    <View>
      {[
        {
          name: t('ミシュラン'),
          list: [
            { name: t('三つ星'), value: 'three_stars' },
            { name: t('二つ星'), value: 'two_stars' },
            { name: t('一つ星'), value: 'one_star' },
            { name: t('ビブグルマン'), value: 'bibgourmand' },
          ],
        },
      ].map(({ name, list }) => {
        return (
          <View key={name}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: '700',
              }}
            >
              {name}
            </Text>
            <View
              style={[
                GeneralStyles.inlineAlignCenter,
                {
                  flexWrap: 'wrap',
                  width: windowWidth - 24,
                  marginLeft: -8,
                },
              ]}
            >
              {list.map(({ name, value }) => {
                const active = michelinAwards.includes(value)
                return (
                  <TouchableOpacity
                    key={String(value)}
                    onPressMinInterval={0}
                    style={{
                      flexGrow: 1,
                      flexBasis: itemMaxWidth,
                      marginTop: 16,
                      marginLeft: 8,
                      maxWidth: itemMaxWidth,
                    }}
                    onPress={() => {
                      updateParams({
                        michelin_awards: compact(
                          active
                            ? without(michelinAwards, value)
                            : michelinAwards.concat(value)
                        ),
                      })
                    }}
                  >
                    <View
                      style={{
                        height: 32,
                        borderRadius: 16,
                        borderStyle: 'solid',
                        borderWidth: 1.5,
                        borderColor: active
                          ? Constants.ACCENT_COLOR
                          : Constants.ULTRA_LIGHT_GRAY_COLOR,
                        backgroundColor: active
                          ? Constants.ACCENT_COLOR
                          : Constants.ULTRA_LIGHT_GRAY_COLOR,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                        paddingHorizontal: 24,
                      }}
                    >
                      <View style={GeneralStyles.inlineWrap}>
                        <Text
                          style={{
                            fontSize: 14,
                            color: active ? 'white' : Constants.DARK_GRAY_COLOR,
                          }}
                        >
                          {name}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                )
              })}
            </View>
          </View>
        )
      })}
    </View>
  )
}

function Facility() {
  const params = useSearchParams()
  const updateParams = useParamsUpdater()
  const { width, sm } = useResponsive()
  const windowWidth = useWindowDimensions().width
  const itemMaxWidth = width < sm ? windowWidth / 2 - 20 : 168
  const facility = {
    parking_status: params.parking_status,
    barrier_free_status: params.barrier_free_status,
    wifi_status: params.wifi_status,
    power_status: params.power_status,
    washlet_status: params.washlet_status,
  }

  return (
    <View
      style={[
        GeneralStyles.inlineAlignCenter,
        {
          flexWrap: 'wrap',
          width: windowWidth - 24,
          marginTop: -16,
          marginLeft: -8,
        },
      ]}
    >
      {[
        { name: t('駐車場あり'), value: 'parking_status' },
        { name: t('バリアフリーあり'), value: 'barrier_free_status' },
        { name: t('Wi-Fiあり'), value: 'wifi_status' },
        { name: t('電源あり'), value: 'power_status' },
        { name: t('ウォシュレットあり'), value: 'washlet_status' },
      ].map(({ name, value }) => {
        const active = facility[value] === 'available'
        return (
          <TouchableOpacity
            key={String(value)}
            onPressMinInterval={0}
            style={{
              flexGrow: 1,
              flexBasis: itemMaxWidth,
              marginTop: 16,
              marginLeft: 8,
              maxWidth: itemMaxWidth,
            }}
            onPress={() => {
              updateParams({
                [value]: active ? '' : 'available',
              })
            }}
          >
            <View
              style={{
                height: 32,
                borderRadius: 16,
                borderStyle: 'solid',
                borderWidth: 1.5,
                borderColor: active
                  ? Constants.ACCENT_COLOR
                  : Constants.ULTRA_LIGHT_GRAY_COLOR,
                backgroundColor: active
                  ? Constants.ACCENT_COLOR
                  : Constants.ULTRA_LIGHT_GRAY_COLOR,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                paddingHorizontal: 24,
              }}
            >
              <View style={GeneralStyles.inlineWrap}>
                <Text
                  style={{
                    fontSize: 14,
                    color: active ? 'white' : Constants.DARK_GRAY_COLOR,
                  }}
                >
                  {name}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

export default React.forwardRef(FilterBar)
