import * as ActionTypes from 'ActionTypes'

interface UIState {
  fetching: boolean
  message?: string
  type?: 'info' | 'error' | 'warning'
  duration?: number
}

const initialState: UIState = {
  fetching: false,
  message: null,
  type: null, // 'info', 'error', 'warning'
  duration: null,
}

export default (state = initialState, action): UIState => {
  switch (action.type) {
    case ActionTypes.UI_FETCHING:
      return {
        ...state,
        fetching: true,
      }
    case ActionTypes.UI_FETCHED:
      return {
        ...state,
        fetching: false,
      }
    case ActionTypes.UI_TOAST_DISPLAY_INFO:
    case ActionTypes.UI_TOAST_DISPLAY_WARNING:
    case ActionTypes.UI_TOAST_DISPLAY_ERROR:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type,
        duration: action.payload.duration,
      }
    case ActionTypes.UI_TOAST_REMOVE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
