export const isEmbedded = () => window.self !== window.top

export const isWidgetsReserve = () => {
  return window.location.pathname === '/widgets/reserve'
}

export const getMediumSlugAndFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  return {
    medium_slug: params.get('medium_slug') || null,
    from_url: params.get('from_url'),
  }
}

export const getRedirectTo = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('redirect_to')
}

export const isExternalLogin = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('is_external_login') === 'true'
}

export const isExternalSignUp = () => {
  return window.location.pathname === '/widgets/sign_up'
}

export const getCurrentOrigin = () =>
  `${window.location.protocol}//${window.location.host}`
