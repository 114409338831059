import * as ActionTypes from 'ActionTypes'

export const getDeliveryRestaurant = (restaurantId) => {
  return {
    type: ActionTypes.GET_DELIVERY_RESTAURANT,
    payload: { restaurantId },
  } as const
}

export const getDeliveryRestaurantProducts = (restaurantId, params?) => {
  return {
    type: ActionTypes.GET_DELIVERY_RESTAURANT_PRODUCTS,
    payload: {
      restaurantId,
      params,
    },
  } as const
}

export const createDeliveryRestaurantProduct = (
  restaurantId,
  params,
  onSuccess
) => {
  return {
    type: ActionTypes.CREATE_DELIVERY_RESTAURANT_PRODUCT,
    payload: {
      restaurantId,
      params,
      onSuccess,
    },
  } as const
}

export const getDeliveryRestaurantDeliveryTimes = (restaurantId) => {
  return {
    type: ActionTypes.GET_DELIVERY_RESTAURANT_DELIVERY_TIMES,
    payload: { restaurantId },
  } as const
}

export const getDeliveryRestaurantDiscountCampaigns = (restaurantId) => {
  return {
    type: ActionTypes.GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS,
    payload: { restaurantId },
  } as const
}

export const getDeliveryCart = () => {
  return {
    type: ActionTypes.GET_DELIVERY_CART,
  } as const
}

export const updateDeliveryCart = (params) => {
  return {
    type: ActionTypes.UPDATE_DELIVERY_CART,
    payload: { params },
  } as const
}
export const updateDeliveryAddress = (params, onHide) => {
  return {
    type: ActionTypes.UPDATE_DELIVERY_ADDRESS,
    payload: { params, onHide },
  } as const
}
export const updateDeliveryTime = (params) => {
  return {
    type: ActionTypes.UPDATE_DELIVERY_TIME,
    payload: { params },
  } as const
}

export const addDeliveryCartProduct = (params, options?) => {
  return {
    type: ActionTypes.ADD_DELIVERY_CART_PRODUCT,
    payload: {
      params,
      options,
    },
  } as const
}

export const removeDeliveryCartProduct = ({ deliveryProductId }) => {
  return {
    type: ActionTypes.REMOVE_DELIVERY_CART_PRODUCT,
    payload: { deliveryProductId },
  } as const
}

export const requestDeliveryCart = () => {
  return {
    type: ActionTypes.REQUEST_DELIVERY_CART,
  } as const
}

export const openDeliveryPurchaseModal = () => {
  return {
    type: ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL,
  } as const
}

export const completeDeliveryPurchaseModal = (params) => {
  return {
    type: ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL,
    payload: { params },
  } as const
}
