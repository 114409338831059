import * as ActionTypes from 'ActionTypes'

export const getNotifications = () => {
  return {
    type: ActionTypes.GET_NOTIFICATIONS,
  } as const
}

export const getNextNotifications = () => {
  return {
    type: ActionTypes.GET_NEXT_NOTIFICATIONS,
  } as const
}
