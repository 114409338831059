import { isEmpty } from 'lodash'
import React from 'react'

import { Helmet } from 'react-helmet'

interface ArHeadProps {
  title?: string
  url?: string
  imageUrl?: string
  description?: string
  ogType?: string
  noindex?: boolean
  nofollow?: boolean
}

const ArHead = ({
  title,
  url,
  imageUrl,
  description,
  ogType = 'article',
  noindex = false,
  nofollow = false,
}: ArHeadProps) => {
  return (
    <Helmet>
      {!isEmpty(title) && <title>{title}</title>}
      {!isEmpty(url) && <link rel="canonical" href={url} />}
      {!isEmpty(title) && <meta property="og:title" content={title} />}
      {!isEmpty(title) && <meta name="twitter:title" content={title} />}
      {!isEmpty(description) && (
        <meta name="description" content={description} />
      )}
      {!isEmpty(description) && (
        <meta property="og:description" content={description} />
      )}
      {!isEmpty(description) && (
        <meta property="twitter:description" content={description} />
      )}
      {!isEmpty(url) && <meta property="og:url" content={url} />}
      {!isEmpty(url) && (
        <meta key="twitter-url" name="twitter:url" content={url} />
      )}
      {!isEmpty(imageUrl) && <meta property="og:image" content={imageUrl} />}
      {!isEmpty(imageUrl) && <meta name="twitter:image" content={imageUrl} />}
      <meta property="fb:app_id" content="356394264916708" />
      <meta property="og:type" content={ogType} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@auto_reserve" />
      {noindex && <meta name="robots" content="noindex" />}
      {nofollow && <meta name="robots" content="nofollow" />}
    </Helmet>
  )
}

export default ArHead
