import { put, call, takeEvery } from './helpers/redux-saga'

import { normalize } from 'normalizr'
import schemas from 'schema'

import * as api from 'api'
import * as ActionTypes from 'ActionTypes'

import { handleApiError } from './shared'

import * as ui from 'actions/ui'

import * as actions from 'actions'

function* getTop(action: ReturnType<typeof actions.getTop>) {
  yield* put(ui.startFetch())

  const { response, error } = yield* call(api.getTop)

  if (response) {
    const { result, entities } = normalize(response.data, {
      popular_lists: [schemas.restaurantList],
      recent_lists: [schemas.restaurantList],
      restaurants: [schemas.restaurant],
      popular_users: [schemas.user],
      pickup_users: [schemas.user],
    })

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TOP_SUCCEEDED,
      payload: result,
    })
  } else {
    yield* handleApiError(error)
  }

  yield* put(ui.endFetch())
}

const topSagas = [takeEvery(ActionTypes.GET_TOP, getTop)]

export default topSagas
