import { isEmpty } from 'lodash'

import * as ActionTypes from 'ActionTypes'

interface NotificationState {
  list: any[]
  isNotificationFetched: boolean
  isAllFetched: boolean
  page: number
  badge: number
}

const initialState: NotificationState = {
  list: [],
  isNotificationFetched: false,
  isAllFetched: false,
  page: 1,
  badge: 0,
}

export default (state = initialState, action): NotificationState => {
  switch (action.type) {
    case ActionTypes.GET_NOTIFICATIONS_SUCCEEDED:
      return {
        ...state,
        list: state.list.concat(action.payload),
        isNotificationFetched: true,
        isAllFetched: isEmpty(action.payload),
        page: state.page + 1,
      }
    case ActionTypes.SYNC_NOTIFICATIONS_BADGE:
      return {
        ...state,
        badge: action.payload,
      }
    default:
      return state
  }
}
