const LOCALES = ['ja', 'zh-CN', 'zh-TW', 'zh-HK']
const COUNTRY_TO_LOCALE = {
  jp: 'ja',
  cn: 'zh-CN',
  tw: 'zh-TW',
  hk: 'zh-HK',
}

export function currentLocale() {
  window.locale = window.locale || detectLocale() || 'ja'
  return window.locale
}

export function detectLocale() {
  const url = new URL(window.location.href)
  const locale = new URLSearchParams(url.search).get('locale')
  if (locale) {
    return locale
  }

  const subdomain = url.hostname.split('.')[0]
  const subdomainLocale = COUNTRY_TO_LOCALE[subdomain]
  if (subdomainLocale) {
    return subdomainLocale
  }

  const languages = window.navigator.languages || [
    window.navigator.language ||
      window.navigator.userLanguage ||
      window.navigator.browserLanguage,
  ]

  if (languages.some((language) => language.startsWith('ja'))) {
    return 'ja'
  }

  const language = languages.find((language) =>
    LOCALES.some((locale) => language.startsWith(locale))
  )
  if (language) {
    return LOCALES.find((locale) => language.startsWith(locale))
  }

  return null
}

export function detectCountry() {
  const [, country] = currentLocale().split('-')
  return country || 'JP'
}

export function isZh() {
  return currentLocale().startsWith('zh')
}
