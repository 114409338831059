import axios from './axios'
import { wrapResponse } from './shared'

export const get = (path) => {
  return (data) => {
    return wrapResponse(axios.get(path, data))
  }
}

export const post = (path) => {
  return (data) => {
    return wrapResponse(axios.post(path, data))
  }
}
