import {
  takeEvery,
  takeLatest,
  takeLeading,
  select,
  put,
  call,
} from './helpers/redux-saga'
import * as ActionTypes from 'ActionTypes'
import * as api from 'api'
import { normalize } from 'normalizr'
import schemas from 'schema'
import { handleApiError } from './shared'
import { retryAPI } from './helpers'

import * as actions from 'actions'

function* watchDeliveryRestaurants(
  action: ReturnType<typeof actions.getDeliveryRestaurants>
) {
  if (action.type === ActionTypes.GET_DELIVERY_RESTAURANTS) {
    yield* call(getDeliveryRestaurants, action)
  }
  yield* takeLeading(ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS, function* () {
    // ページネーションは同一のパラメータに対して行わないと、重複した結果が追加されたりおかしくなるので
    // getDeliveryRestaurantのアクションのパラメータを再利用する
    yield* call(getDeliveryNextRestaurants, {
      type: ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS,
      payload: { params: action.payload.params },
    })
  })
}

function* getDeliveryRestaurants(
  action: ReturnType<typeof actions.getDeliveryRestaurants>
) {
  yield* call(getDeliveryNextRestaurants, {
    type: ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS,
    payload: { params: action.payload.params },
  })
  yield* put({ type: ActionTypes.GET_DELIVERY_RESTAURANTS_SUCCEEDED })
}

function* getDeliveryNextRestaurants(
  action: ReturnType<typeof actions.getDeliveryNextRestaurants>
) {
  const page: number = yield* select((state) => state.deliveryHome.page)

  const { params } = action.payload
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getDeliveryRestaurants, auth, {
    ...params,
    page,
  })
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.restaurant])

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS_SUCCEEDED,
      payload: {
        restaurants: result,
        page: page + 1,
      },
    })
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

const deliveryHomeSagas = [
  takeLatest([ActionTypes.GET_DELIVERY_RESTAURANTS], watchDeliveryRestaurants),
]

export default deliveryHomeSagas
