import { useSelector } from 'react-redux'
import useSWR, { fetcher, swrKey } from 'modules/swr'
import { setHeader, wrapResponse } from 'api/shared'
import axios from 'api/axios'

export type ConversationPartnerType = 'User' | 'Restaurant'

export interface ConversationPartner {
  id: number
  image_url: string
  is_foodie: boolean
  name: string
  username: string
}

export interface MessageListItem {
  id?: string
  conversation_partner_id: number
  conversation_partner_type: ConversationPartnerType
  conversation_partner_profile_image: string
  conversation_partner: ConversationPartner
  latest_message_body: string
  latest_message_created_at: string
  unread_message_count: number
  conversation_partner_read_at?: string
  isSelected?: boolean
}

export interface Messages {
  messages: MessageItem[]
  hasNext: boolean
  isLoadingMore: boolean
  isLoading: boolean
}

interface Messenger {
  id: number
  name: string
  type: ConversationPartnerType
}

export interface MessageItem {
  id: number
  body: string
  created_at: string
  sender: Messenger
  receiver: Messenger
  actions: any[]
  image?: any
}

export const getInboxMessage = ({ token }: { token: string }) => {
  setHeader({ token })
  return wrapResponse(axios.get(`/message/index`))
}

export const getMessage = ({
  token,
  conversation_partner_id,
  conversation_partner_type,
  beforeMessageId,
  count,
}: {
  token: string
  conversation_partner_id: number
  conversation_partner_type: ConversationPartnerType
  beforeMessageId?: number
  count?: number
}) => {
  setHeader({ token })
  return wrapResponse(
    axios.get(`/messages`, {
      params: {
        conversation_partner_id,
        conversation_partner_type,
        count,
        before_message_id: beforeMessageId,
      },
    })
  )
}

export const sendMessage = ({
  token,
  conversation_partner_id,
  conversation_partner_type,
  body,
  image,
}: {
  token: string
  conversation_partner_id: number
  conversation_partner_type: ConversationPartnerType
  body?: string
  image?: string
}) => {
  setHeader({ token })
  return wrapResponse(
    axios.post(`/messages`, {
      conversation_partner_id,
      conversation_partner_type,
      body,
      image,
    })
  )
}

export const readMessage = ({
  messageListId,
  token,
}: {
  messageListId: string
  token: string
}) => {
  setHeader({ token })
  return wrapResponse(axios.put(`/message_lists/${messageListId}/read`))
}

export function getMessageList({ token }: { token: string }) {
  setHeader({ token })
  return wrapResponse(axios.get(`/message_lists`))
}

export function useMessageLists() {
  const token = useSelector((state) => state.auth.token)
  const { data: messageLists, error } = useSWR<MessageListItem[]>(
    swrKey(token, `/message_lists`),
    fetcher
  )

  return {
    messageLists,
    error,
    isLoading: !messageLists && !error,
  }
}

export function getMessageListItem({
  messageListId,
  token,
}: {
  messageListId: string
  token: string
}) {
  setHeader({ token })
  return wrapResponse(axios.get(`message_lists/${messageListId}`))
}
