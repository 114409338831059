import * as ActionTypes from 'ActionTypes'

export const createAcceptRequest = (restaurantId, content) => {
  return {
    type: ActionTypes.CREATE_ACCEPT_REQUEST,
    payload: {
      restaurantId,
      content,
    },
  } as const
}

export const updateAcceptRequest = (acceptRequestId, content) => {
  return {
    type: ActionTypes.UPDATE_ACCEPT_REQUEST,
    payload: {
      acceptRequestId,
      content,
    },
  } as const
}

export const setRequestModalInvisible = (isVisible) => {
  return {
    type: ActionTypes.SET_REQUEST_MODAL_INVISIBLE,
    payload: { isVisible },
  }
}
