// Color
import setOpacity from 'modules/setOpacity'
export const PRIMARY_COLOR = '#AB935A' // rgba(171,147,90,1.0)
export const PRIMARY_TRANSPARENT_COLOR = setOpacity(0.8, PRIMARY_COLOR)
export const PRIMARY_DARKEN_BG_COLOR = setOpacity(0.1, PRIMARY_COLOR)
export const PRIMARY_BG_COLOR = setOpacity(0.06, PRIMARY_COLOR)
export const PRIMARY_DISABLED_COLOR = setOpacity(0.4, PRIMARY_COLOR)
export const LIGHT_PRIMARY_COLOR = '#b9a575'
export const ACCENT_COLOR = '#009688' // rgba(76,156,101,100)
export const ACCENT_TRANSPARENT_COLOR = setOpacity(0.8, ACCENT_COLOR)
export const DARK_ACCENT_COLOR = '#05755c'
export const ACCENT_BG_COLOR = setOpacity(0.06, ACCENT_COLOR)
export const DANGER_COLOR = '#E84733'
export const ULTRA_DARK_GRAY_COLOR = '#4A4A4A'
export const DARK_GRAY_COLOR = '#737373'
export const GRAY_COLOR = '#9B9B9B'
export const GRAY_TRANSPARENT_COLOR = setOpacity(0.8, GRAY_COLOR)
export const ULTRA_ULTRA_LIGHT_GRAY_COLOR = '#F7F7F7'
export const BLACK_COLOR = '#444444'
export const WHITE_COLOR = '#ffffff'
export const LIGHT_GRAY_TRANSPARENT_COLOR = setOpacity(0.8, 'white')
export const LIGHT_GRAY_COLOR = '#E1E1E1'
export const ULTRA_LIGHT_GRAY_COLOR = '#F2F2F2'
export const BLACK_DISABLED_COLOR = setOpacity(0.4, BLACK_COLOR)
export const LIGHT_BLACK_COLOR = setOpacity(0.6, BLACK_COLOR)

export const LIGHT_DANGER_COLOR = setOpacity(0.04, DANGER_COLOR)
export const BORDER_COLOR = 'rgba(0,0,0,0.16)'
export const DARK_OVERLAY = 'rgb(0,0,0)'
export const DARK_OVERLAY_20 = setOpacity(0.2, DARK_OVERLAY)
export const DARK_OVERLAY_40 = setOpacity(0.4, DARK_OVERLAY)
export const DARK_OVERLAY_80 = setOpacity(0.8, DARK_OVERLAY)
export const TEXT_PRIMARY_6 = setOpacity(0.06, BLACK_COLOR)
export const TEXT_PRIMARY_60 = setOpacity(0.6, BLACK_COLOR)
export const TEXT_PRIMARY_36 = setOpacity(0.36, BLACK_COLOR)
export const TEXT_PRIMARY_40 = setOpacity(0.4, BLACK_COLOR)
export const TEXT_PRIMARY_50 = setOpacity(0.5, BLACK_COLOR)
export const TEXT_SECONDARY = setOpacity(0.6, BLACK_COLOR)
export const CAUTION = '#e84733'
export const CAUTION_6 = setOpacity(0.06, CAUTION)
export const CAUTION_40 = 'rgba(232, 71, 51, 0.4)'
export const SUCCESS = 'rgb(0,150,136)'
export const SUCCESS_6 = setOpacity(0.06, SUCCESS)
export const FIELD_COLOR = '#f5f5f5'

export const STRIPE_PUBLISHABLE_API_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_6YOBOoVijl16s61nWqCYgbKJ'
    : 'pk_test_lxEdu6jai0Q8xEbs7D17tK1d'

export const GOOGLE_API_KEY = 'AIzaSyCoxamFki13NIbNYGbPasKnjbGMkbeOMCE'
