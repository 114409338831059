import React from 'react'
import { Switch } from 'react-native'
import * as Constants from 'Constants'

function CustomSwitch(props) {
  return (
    <Switch
      {...props}
      trackColor={Constants.LIGHT_GRAY_COLOR}
      activeTrackColor={Constants.ACCENT_TRANSPARENT_COLOR}
      thumbColor={'white'}
      activeThumbColor={Constants.ACCENT_COLOR}
    />
  )
}

export default CustomSwitch
