export const GET_REVIEW = 'GET_REVIEW' as const
export const GET_REVIEW_SUCCEEDED = 'GET_REVIEW_SUCCEEDED' as const
export const GET_REVIEW_FAILED = 'GET_REVIEW_FAILED' as const

export const CREATE_REVIEW = 'CREATE_REVIEW' as const
export const CREATE_REVIEW_SUCCEEDED = 'CREATE_REVIEW_SUCCEEDED' as const
export const CREATE_REVIEW_IMAGES_STARTED = 'CREATE_REVIEW_IMAGES_STARTED' as const
export const CREATE_REVIEW_IMAGES_PROGRESS_IMAGE_UPLOADED = 'CREATE_REVIEW_IMAGES_PROGRESS_IMAGE_UPLOADED' as const
export const CREATE_REVIEW_IMAGES_SUCCEEDED = 'CREATE_REVIEW_IMAGES_SUCCEEDED' as const
export const CREATE_REVIEW_IMAGES_PROGRESS = 'CREATE_REVIEW_IMAGES_PROGRESS' as const
export const UPDATE_REVIEW = 'UPDATE_REVIEW' as const
export const UPDATE_REVIEW_SUCCEEDED = 'UPDATE_REVIEW_SUCCEEDED' as const
export const CREATE_OR_UPDATE_REVIEW = 'CREATE_OR_UPDATE_REVIEW' as const
export const CREATE_OR_UPDATE_REVIEW_SUCCEEDED = 'CREATE_OR_UPDATE_REVIEW_SUCCEEDED' as const
