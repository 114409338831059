import { fromPairs } from 'lodash'

import { useHistory, useLocation } from 'react-router'

function useParamsUpdater() {
  const history = useHistory()
  const location = useLocation()

  return (paramsToUpdate: Record<string, any>) => {
    // {key: 'aa', key2: 'bb'}
    const params = fromPairs([
      ...new URLSearchParams(location.search).entries(),
    ])

    for (const [key, value] of Object.entries(paramsToUpdate)) {
      if (value === undefined || value === null) {
        delete params[key]
      } else {
        params[key] = value
      }
    }

    history.replace({
      pathname: location.pathname,
      search: Object.entries(params).reduce(
        (str, [key, val]: [string, string], index) => {
          const sign = index === 0 ? '?' : '&'
          return str + `${sign}${key}=${val}`
        },
        ''
      ),
    })
  }
}

export default useParamsUpdater
