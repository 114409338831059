import { detectCountry } from 'modules/locale'

export function canonicalOrigin() {
  const country = detectCountry().toLowerCase()

  const host =
    process.env.NODE_ENV !== 'development' ? 'autoreserve.com' : 'lvh.me:3000'
  const scheme = process.env.NODE_ENV !== 'development' ? 'https://' : 'http://'

  if (['cn', 'tw', 'hk'].includes(country)) {
    return `${scheme}${country}.${host}`
  } else {
    return `${scheme}${host}`
  }
}
