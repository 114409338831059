import axios from './axios'
import { ResponseWrapper, wrapResponse } from './shared'

export const requestOwner = (params): ResponseWrapper => {
  return wrapResponse(axios.post(`/owner_requests`, params))
}

export const createDocumentRequest = (params): ResponseWrapper => {
  return wrapResponse(axios.post(`/owner/document_requests`, params))
}
