import * as ActionTypes from 'ActionTypes'

export function destroyTempUser() {
  return {
    type: ActionTypes.DESTROY_TEMP_USER,
  } as const
}

export function verifyPhoneNumber(code, phoneNumber) {
  return {
    type: ActionTypes.VERIFY_PHONE_NUMBER,
    code,
    phoneNumber,
  } as const
}

export function resendCode() {
  return {
    type: ActionTypes.RESEND_CODE,
  } as const
}

export function callCode() {
  return {
    type: ActionTypes.CALL_CODE,
  } as const
}

export function verifyCode(code) {
  return {
    type: ActionTypes.VERIFY_CODE,
    code: code,
  } as const
}
