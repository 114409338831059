/* eslint-disable no-restricted-imports */
import React, { useEffect, useCallback, useRef } from 'react'
import {
  TouchableWithoutFeedback,
  TouchableHighlight,
  TouchableNativeFeedback,
  TouchableOpacity,
} from 'react-native'

import useThrottleEventCallback from 'modules/useThrottleEventCallback'

/*
  やってることの説明:

  1. onPressにデフォルトでON_PRESS_MIN_INTERVALぶんのthrottleを入れている

  理由: 短時間の連続したタップを無視した方が体験上良い場合が多いので。
  例:APIを呼び出して何かを表示する、モーダルを開く、フォローをする操作、など

  ただし、ボタンのトグルなど、即座にUIに反映できて副作用がない場合など
  一部の操作はthrottleして欲しくない場合もあるので、そういった時は
  <TouchableOpacity
    onPress={() => {
      console.log('onPressMinIntervalを0にするとthrottleされない')
    }}
    onPressMinInterval={0}
  />
  のようにonPressMinIntervalを指定する


  2. デフォルトでhitSlopを上下左右に10ずつ入れている
  ほとんどの場合ではこれで問題ないが、
  ボタンの配置間隔が狭い場合などはhitSlopを含めてかぶると片方が押せなくなる場合があるのでその場合は手動で指定して上書きすると良い

  例: paddingがない横並びのボタン
  <View style={GeneralStyles.inline}>
    <TouchableOpacity
      style={{width: 32, height: 32}}
      hitSlop={{ top: 10, left: 10, right: 0, bottom: 10 }}
    />
    <TouchableOpacity
      style={{width: 32, height: 32}}
      hitSlop={{ top: 10, left: 0, right: 10, bottom: 10 }}
    />
  </View>

*/

const ON_PRESS_MIN_INTERVAL = 600

const defaultHitSlop = Object.freeze({
  top: 10,
  left: 10,
  right: 10,
  bottom: 10,
})

function createTouchable(TouchableComponent) {
  return React.forwardRef(function Touchable(props: any, ref) {
    const {
      onPress,
      onPressMinInterval = ON_PRESS_MIN_INTERVAL,
      hitSlop = defaultHitSlop,
      ...restProps
    } = props

    const throttledOnPress = useThrottleEventCallback((...args) => {
      onPress && onPress(...args)
    }, onPressMinInterval)

    return (
      <TouchableComponent
        ref={ref}
        hitSlop={hitSlop}
        onPress={throttledOnPress}
        {...restProps}
      />
    )
  })
}

const MyTouchableOpacity = React.memo(createTouchable(TouchableOpacity))

const MyTouchableNativeFeedback = React.memo(
  createTouchable(TouchableNativeFeedback)
)
const MyTouchableHighlight = React.memo(createTouchable(TouchableHighlight))
const MyTouchableWithoutFeedback = React.memo(
  createTouchable(TouchableWithoutFeedback)
)

export {
  MyTouchableOpacity as TouchableOpacity,
  MyTouchableNativeFeedback as TouchableNativeFeedback,
  MyTouchableHighlight as TouchableHighlight,
  MyTouchableWithoutFeedback as TouchableWithoutFeedback,
}
