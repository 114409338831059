import * as ActionTypes from 'ActionTypes'

export const setDeliveryHomeKeyword = (keyword) => {
  return {
    type: ActionTypes.SET_DELIVERY_HOME_KEYWORD,
    payload: { keyword },
  } as const
}

export const getDeliveryRestaurants = (params?) => {
  return {
    type: ActionTypes.GET_DELIVERY_RESTAURANTS,
    payload: { params },
  } as const
}

export const getDeliveryNextRestaurants = (params?) => {
  return {
    type: ActionTypes.GET_DELIVERY_NEXT_RESTAURANTS,
    payload: { params },
  } as const
}
