import * as ActionTypes from 'ActionTypes'

interface Top {
  popularListsIds: any[]
  recentListsIds: any[]
  restaurantsIds: any[]
  popularUsersIds: any[]
  pickupUsersIds: any[]
}

type TopState = Top | null

const initialState: TopState = null

export default (state = initialState, action): TopState => {
  switch (action.type) {
    case ActionTypes.GET_TOP_SUCCEEDED:
      return action.payload
    default:
      return state
  }
}
