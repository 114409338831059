import { compact, startsWith } from 'lodash'
import useLocationParams from 'modules/useLocationParams'

import { useSortByParam } from 'modules/sortByUtils'

import { useLocation } from 'react-router'

import { useSelector } from 'react-redux'

import {
  closestFutureReservationTime,
  parseReservationTime,
  now,
} from 'modules/time'

/*

useSearchParamsは

{

  latitude: xxx,
  longitude: yyy,

  keyword: '',

  page: 1,

  reserved_at: xxx,
  party_size: 2,

  is_date_time_enabled: true,
}

のように、APIに投げる検索パラメータをURLから取得したりデフォルト値をセットしたりしたオブジェクトを返すので

is_date_time_enabledがfalseの時に
reserved_at, party_sizeを除去したり、実際に使う前にgetActualSearchParamsを通す必要がある



*/
export function getActualSearchParams(params) {
  const { is_date_time_enabled, reserved_at, party_size, ...rest } = params

  const result = {
    ...(is_date_time_enabled && { reserved_at, party_size }),
    ...rest,
  }

  return result
}

function useSearchParams() {
  const location = useLocation()

  const params = useLocationParams()

  const latitude = useSelector((state) => state.auth.latitude)
  const longitude = useSelector((state) => state.auth.longitude)
  const isAppLocationAccurate = useSelector(
    (state) => state.auth.isAppLocationAccurate
  )

  const sortBy = useSortByParam()

  let keyword = null

  if (startsWith(location.pathname, '/s/')) {
    if (params.keyword) {
      keyword = params.keyword
    } else {
      keyword = location.pathname
    }
  }

  const facility = {
    parking_status: params.parking_status,
    barrier_free_status: params.barrier_free_status,
    wifi_status: params.wifi_status,
    power_status: params.power_status,
    washlet_status: params.washlet_status,
  }

  return {
    keyword,
    page: parseInt(params.page || '1', 10),
    max_distance: Number(params.max_distance || '1.6'),
    min_price: parseInt(params.min_price, 10) || 0,
    max_price: parseInt(params.max_price, 10) || null,
    seat: compact((params.seat || '').split(',') || []),
    sources: compact((params.sources || '').split(',') || []),
    is_smoking: params.is_smoking === 'true',
    is_no_smoking: params.is_no_smoking === 'true',
    michelin_awards: compact((params.michelin_awards || '').split(',') || []),
    sort_by: sortBy,
    open_only: params.open_only === 'true',

    //  TODO: 正しく実装
    reserved_at: params.reserved_at
      ? // TODO: なぜかURLから取得すると+がスペースになってしまってinvalid dateエラーになる
        parseReservationTime(params.reserved_at.replace(' ', '+'))
      : closestFutureReservationTime(now(), 30),

    party_size: parseInt(params.party_size || '2', 10),

    latitude,
    longitude,
    is_app_location_accurate: isAppLocationAccurate,
    is_date_time_enabled: Boolean(params.reserved_at && params.party_size),
    ...facility,
  }
}

export default useSearchParams
