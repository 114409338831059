import * as ActionTypes from 'ActionTypes'

export const getRestaurantTableMenus = (restaurantId) => {
  return {
    type: ActionTypes.GET_RESTAURANT_TABLE_MENUS,
    payload: { restaurantId },
  } as const
}

export function getRestaurantId(slug) {
  return {
    type: ActionTypes.GET_RESTAURANT_ID,
    payload: { slug },
  } as const
}

export const getRestaurantVisitHistories = (restaurantId) => {
  return {
    type: ActionTypes.GET_RESTAURANT_VISIT_HISTORIES,
    restaurantId,
  } as const
}

export function getSearchSuggests(keyword) {
  return {
    type: ActionTypes.GET_SEARCH_SUGGESTS,
    keyword: keyword,
  } as const
}

export const getRestaurant = (slug) => {
  return {
    type: ActionTypes.GET_RESTAURANT,
    payload: {
      slug,
    },
  } as const
}

export const getRestaurantLists = (slug) => {
  return {
    type: ActionTypes.GET_RESTAURANT_LISTS,
    payload: {
      slug,
    },
  } as const
}

export const getRestaurantRankingUsers = (slug) => {
  return {
    type: ActionTypes.GET_RESTAURANT_RANKING_USERS,
    payload: {
      slug,
    },
  } as const
}

export const getRestaurantReviews = (slug, page) => {
  return {
    type: ActionTypes.GET_RESTAURANT_REVIEWS,
    payload: {
      slug,
      page,
    },
  } as const
}

export const getRestaurantMenus = (id, page?) => {
  return {
    type: ActionTypes.GET_RESTAURANT_MENUS,
    payload: {
      id,
      page,
    },
  } as const
}

// dateはrestaurant.dateとかで使っているYYYY-MM-DDの文字列
export const getReservationStatuses = (restaurantId, date, partySize) => {
  return {
    type: ActionTypes.GET_RESERVATION_STATUSES,
    payload: {
      restaurantId: restaurantId,
      date: date,
      partySize: partySize,
    },
  } as const
}

export const setRestaurantPartySize = (partySize) => {
  return {
    type: ActionTypes.SET_RESTAURANT_PARTY_SIZE,
    payload: {
      partySize: partySize,
    },
  } as const
}

export const setRestaurantDate = (date) => {
  return {
    type: ActionTypes.SET_RESTAURANT_DATE,
    payload: {
      date,
    },
  } as const
}

export const setRestaurantTime = (time) => {
  return {
    type: ActionTypes.SET_RESTAURANT_TIME,
    payload: {
      time,
    },
  } as const
}

export const suggestRestaurantEdit = (restaurant, params, history) => {
  return {
    type: ActionTypes.SUGGEST_RESTAURANT_EDIT,
    payload: {
      restaurant,
      params,
      history,
    },
  } as const
}

export const favoriteRestaurant = (restaurantId) => {
  return {
    type: ActionTypes.FAVORITE_RESTAURANT,
    payload: {
      restaurantId,
    },
  } as const
}

export const unfavoriteRestaurant = (restaurantId) => {
  return {
    type: ActionTypes.UNFAVORITE_RESTAURANT,
    payload: {
      restaurantId,
    },
  } as const
}
