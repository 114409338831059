import { applyMiddleware, createStore, compose, Dispatch, Store } from 'redux'
import createReduxCookiesMiddleware from 'redux-cookies-middleware'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import reducers, { RootState } from 'reducers'
import rootSaga from 'sagas'

import createSentryMiddleware from 'modules/createSentryMiddleware'
import Reactotron from 'reactotron-react-js'
import sagaPlugin from 'reactotron-redux-saga'
import { reactotronRedux } from 'reactotron-redux'
import Config from 'Config'
import Cookies from 'modules/cookie'

import * as Sentry from '@sentry/browser'
import { RootAction } from 'actions/RootAction'

declare module 'react-redux' {
  interface DefaultRootState extends RootState {}
  export function useDispatch<TDispatch = Dispatch<RootAction>>(): TDispatch
  export function useStore<S = DefaultRootState>(): Store<S, RootAction>
}

function onError(e) {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.captureException(e)
  }
  console.error(e)
}

function configureStore(initState) {
  let store, sagaMiddleware, reactotron

  const sagaOptions = { onError }
  if (process.env.NODE_ENV !== 'development') {
    sagaMiddleware = createSagaMiddleware(sagaOptions)
  } else {
    reactotron = Reactotron.configure()
      .use(sagaPlugin({}))
      .use(reactotronRedux())
      .connect()
    const sagaMonitor = reactotron.createSagaMonitor()
    sagaMiddleware = createSagaMiddleware({ ...sagaOptions, sagaMonitor })
  }

  const sentryMiddleware = createSentryMiddleware(Sentry)

  const paths = {
    'auth.token': {
      name: '_auto_reserve_token',
    },
  }

  const customOptions = {
    defaultDeleteCheck: (value) => !value,
    setCookie: (name, value, expiry = 365 * 10, secure) => {
      Cookies.set(name, String(JSON.parse(value)), {
        expires: expiry,
      })
    },
  }

  const reduxCookiesMiddleware = createReduxCookiesMiddleware(
    paths,
    customOptions
  )

  if (process.env.NODE_ENV !== 'development') {
    store = createStore(
      reducers,
      initState,
      applyMiddleware(reduxCookiesMiddleware, sentryMiddleware, sagaMiddleware)
    )
  } else {
    store = createStore(
      reducers,
      initState,
      compose(
        applyMiddleware(
          reduxCookiesMiddleware,
          sentryMiddleware,
          sagaMiddleware
        ),
        Reactotron.createEnhancer()
      )
    )
  }

  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return { persistor, store }
}

let preloadedState

if (Config.isTest) {
  // ログイン状態でのテストのためauth系のreducerの初期値をCypress側をセットしている
  preloadedState = window.preloadedState
}

const { persistor, store } = configureStore(preloadedState)

if (Config.isTest) {
  // テスト用にstoreも露出させる
  window.store = store
}

export { persistor, store }
