import loadable from '@loadable/component'
import { withErrorBoundary } from 'components/Shared/ErrorBoundary'
import { mapValues } from 'lodash'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

const Pages = mapValues(
  {
    ForRMagazine: loadable(() => import('../../pages/ForR/Magazine')),
    ForRMagazineCategory: loadable(
      () => import('../../pages/ForR/Magazine/Category')
    ),
    ForRMagazinePost: loadable(() => import('../../pages/ForR/Magazine/Post')),
  },
  (Page) => withErrorBoundary(Page)
)
export function ForRMagazineRoutes() {
  return (
    <Switch>
      <Route
        path="/for_restaurants/magazine"
        exact
        component={Pages.ForRMagazine}
      />
      <Route
        path="/for_restaurants/magazine/:category_slug"
        exact
        component={Pages.ForRMagazineCategory}
      />
      <Route
        path="/for_restaurants/magazine/:category_slug/:post_slug"
        exact
        component={Pages.ForRMagazinePost}
      />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  )
}
