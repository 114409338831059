import i18n, { TFunction } from 'i18next'
import { currentLocale } from 'modules/locale'
import { initReactI18next } from 'react-i18next'

function preprocess(data: Array<[string, string]>) {
  const result = {}
  Object.entries(data).forEach(([k, v]) => {
    if (v.length > 0) {
      result[k] = v
    }
  })
  return result
}

function loadLocale(locale) {
  return import(`./i18n/translations/${locale.toLowerCase()}.json`)
    .then((module) => {
      i18n.addResourceBundle(
        locale,
        'translation',
        preprocess(module.default),
        true,
        true
      )
    })
    .catch((err) => console.error(err))
}

const locale = currentLocale()
i18n.use(initReactI18next).init({
  debug: false,
  lng: locale,
  resources: {},
  fallbackLng: {
    zh: ['zh-CN', 'zh-TW', 'zh-HK', 'ja'],
    'zh-TW': ['zh-CN', 'ja'],
  },
  interpolation: {
    escapeValue: false,
  },
})

if (locale) {
  if (locale === 'zh') {
    loadLocale('zh-CN')
  } else if (locale !== 'ja') {
    loadLocale(locale)
  }
}

export const t: (...args: any[]) => string = i18n.t.bind(i18n)
