import { schema } from 'normalizr'
import { idAttribute, processStrategy } from 'modules/table'

const tableProductOption = new schema.Entity(
  'tableProductOptions',
  {},
  {
    idAttribute,
    processStrategy,
  }
)

export default tableProductOption
