import { call, put, select, takeEvery, delay } from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { retryAPI } from './helpers'

import { normalize } from 'normalizr'
import schemas from 'schema'

// import firebase from '@react-native-firebase/app'
// import analytics from '@segment/analytics-react-native'

import * as ui from 'actions/ui'

import { syncUser } from './auth'

import { t } from 'modules/i18n'

import * as actions from 'actions'

function* getRestaurant(restaurantId) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getRestaurant,
    auth,
    restaurantId
  )

  if (response) {
    const { entities } = normalize(response.data, schemas.restaurant)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else if (error) {
    yield* handleApiError(error)
  }
}

function* openRequestReservationModal(
  action: ReturnType<typeof actions.openRequestReservationModal>
) {
  const { reservationStatus } = action

  const params = {
    restaurant_id: reservationStatus.restaurant_id,
    reserved_at: reservationStatus.reserved_at,
    party_size: reservationStatus.party_size,
  }

  yield* call(syncUser, { type: ActionTypes.SYNC_USER })

  yield* call(getRestaurant, reservationStatus.restaurant_id)

  yield* fetchForm({ params })

  const error = yield* fetchSeats({ params })
  if (error) return
  yield* fetchCourses({ params })
  yield* fetchCancelPolicies({ params })
}

function* getRequestReservationForm(
  action: ReturnType<typeof actions.getRequestReservationForm>
) {
  const { reservationStatus } = action

  const params = {
    restaurant_id: reservationStatus.restaurant_id,
    reserved_at: reservationStatus.reserved_at,
    party_size: reservationStatus.party_size,
  }
  yield* fetchForm({ params })
}

function* fetchForm({ params }) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getRequestReservationForm,
    auth,
    params
  )

  if (error) {
    yield* handleApiError(error)
    yield* finishRequestReservationAsError()
    return
  }

  yield* put({
    type: ActionTypes.GET_REQUEST_RESERVATION_FORM_SUCCEEDED,
    payload: response.data,
  })
}

function* fetchSeats(action: { params: any }) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getRequestReservationSeats,
    auth,
    action.params
  )

  if (error) {
    if (error.response?.status === 422) {
      yield* put({
        type: ActionTypes.GET_REQUEST_RESERVATION_SEATS_ERROR,
        payload: error.response.data,
      })
    } else {
      yield* handleApiError(error)
    }

    return true
  }

  yield* put({
    type: ActionTypes.GET_REQUEST_RESERVATION_SEATS_SUCCEEDED,
    payload: response.data,
  })

  return false
}

function* fetchCourses(action: { params: any }) {
  const auth = yield* select((state) => state.auth)
  const restaurantSeat = yield* select(
    (state) => state.requestReservation.restaurantSeat
  )
  const params = {
    restaurant_seat_id: restaurantSeat.id,
    source: restaurantSeat.source,
    ...action.params,
  }

  const { response, error } = yield retryAPI(
    api.getRequestReservationCourses,
    auth,
    params
  )

  if (error) {
    yield* handleApiError(error)
    return
  }

  yield* put({
    type: ActionTypes.GET_REQUEST_RESERVATION_COURSES_SUCCEEDED,
    payload: response.data,
  })
}

function* fetchCancelPolicies(action: { params: any }) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getRequestReservationCancelPolicies,
    auth,
    action.params
  )

  if (error) {
    yield* handleApiError(error)
    return
  }

  yield* put({
    type: ActionTypes.GET_REQUEST_RESERVATION_CANCEL_POLICIES_SUCCEEDED,
    payload: response.data,
  })
}

function* finishRequestReservationAsProgress(reservationElement, history) {
  yield* put({
    type: ActionTypes.RESERVE_REQUEST_RESERVATION_SUCCEEDED,
    payload: {
      type: 'reservation_element',
      reservation_element: reservationElement,
    },
  })

  yield* put(ui.displayToastInfo(t('予約リクエストを受け付けました')))

  yield* call(
    [history, history.push],
    `/reservations/requests/${reservationElement.reservation_list.id}`
  )
}

function* finishRequestReservationAsError() {
  yield* put({
    type: ActionTypes.RESERVE_REQUEST_RESERVATION_ERROR,
    payload: {},
  })
}

function* finishRequestReservationAsReserved(reservationElement, history) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getReservation,
    auth,
    reservationElement.reservation_id
  )

  if (error) {
    yield* handleApiError(error)
    return
  }

  const reservation = response.data
  yield* put({
    type: ActionTypes.RESERVE_REQUEST_RESERVATION_SUCCEEDED,
    payload: {
      type: 'reservation',
      reservation: reservation,
    },
  })

  yield* call([history, history.push], `/reservations/${reservation.id}`)

  yield* put(ui.displayToastInfo(t('予約が完了しました')))
}

function* logRequestReservationCompleted(reservationElement) {
  if (window.gtag) {
    window.gtag('event', 'reservation_request')
  }
}

function* reserveRequestReservation(
  action: ReturnType<typeof actions.reserveRequestReservation>
) {
  const { history } = action
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.reserveRequestReservation,
    auth,
    action.params
  )

  if (error) {
    yield* finishRequestReservationAsError()
    yield* handleApiError(error)
    return
  }

  const data = response.data
  let reservationElement = data.reservation_element

  yield* logRequestReservationCompleted(reservationElement)

  if (!data.wait) {
    yield* finishRequestReservationAsProgress(reservationElement, history)
    return
  }

  // wait reservation_element to be finished
  const reservationElementId = reservationElement.id
  for (let i = 0; i < 10; i++) {
    yield* delay(1000)

    const { response, error } = yield* call(
      api.getReservationElement,
      auth,
      reservationElementId
    )

    if (error) continue

    reservationElement = response.data
    if (['reserved', 'not_reserved'].includes(reservationElement.status)) {
      break
    }
  }

  // timeout した or 予約不可だった
  if (!reservationElement.reservation_id) {
    yield* finishRequestReservationAsProgress(reservationElement, history)
    return
  }

  yield* finishRequestReservationAsReserved(reservationElement, history)
}

function* verifyDeviceUser(
  action: ReturnType<typeof actions.verifyDeviceUser>
) {
  const { response, error } = yield* call(
    api.post('/device_users/verify'),
    action.params
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.user)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.VERIFY_DEVICE_USER_SUCCEEDED,
      payload: response.data,
    })

    const from = action.from || ''

    if (from === 'RequestReservation') {
      yield* put(ui.displayToastInfo(t('電話番号認証が完了しました')))
    }
  } else {
    yield* handleApiError(error)
  }
}

const requestReservationSagas = [
  takeEvery(
    ActionTypes.OPEN_REQUEST_RESERVATION_MODAL,
    openRequestReservationModal
  ),
  takeEvery(
    ActionTypes.GET_REQUEST_RESERVATION_FORM,
    getRequestReservationForm
  ),
  takeEvery(
    ActionTypes.SELECT_SEAT_REQUEST_RESERVATION,
    function* ({
      params,
    }: ReturnType<typeof actions.selectSeatRequestReservation>) {
      yield* fetchCourses({ params })
    }
  ),
  takeEvery(ActionTypes.RESERVE_REQUEST_RESERVATION, reserveRequestReservation),
  takeEvery(ActionTypes.VERIFY_DEVICE_USER, verifyDeviceUser),
]
export default requestReservationSagas
