import defaultAxios from 'axios'
import { wrapResponse } from './shared'
import { currentLocale } from 'modules/locale'
import Config from 'Config'
import { WaiterCallType } from '../models/TableOrder'

export const axios = defaultAxios.create({
  baseURL: Config.tableApiUrl,
  headers: {
    ACCEPT: 'application/vnd.autoreserve.com+json; version=1',
    'Content-Type': 'application/json',
    'X-AR-Platform': 'web',
  },
})

axios.interceptors.request.use((config) => {
  if (currentLocale() !== 'ja') {
    config.params = config.params || {}
    config.params.locale = currentLocale()
  }

  return config
})

export function setHeader({ token }) {
  axios.defaults.headers.common.Authorization = `Token token=${token}`

  if (token) {
    delete axios.defaults.headers.common['X-Use-Blank-User']
  } else {
    axios.defaults.headers.common['X-Use-Blank-User'] = '1'
  }
}

export const getTableUserOrders = (auth, params) => {
  setHeader(auth)
  return wrapResponse(axios.get('/user/orders', { params }))
}

export const getTableUserOrderReviews = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/user/orders/${orderId}/reviews`))
}

export const createTableWaiterCall = (
  auth,
  orderId,
  callType: WaiterCallType
) => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/orders/${orderId}/waiter_calls`, {
      call_type: callType,
    })
  )
}

export const getTableOrderMenus = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}/menus`))
}

export const getTableOrderDefaultMenu = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}/menu`))
}

export const getTableOrderMenu = (auth, orderId, tableMenuId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}/menus/${tableMenuId}`))
}

export const getTableMenu = (auth, tableMenuId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/menus/${tableMenuId}`))
}

export const createTableSeatOrder = (auth, token) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/seats/${token}/orders`))
}

export const getTableOrders = (auth, status, tableOrdersPage) => {
  setHeader(auth)
  return wrapResponse(
    axios.get('/user/orders', {
      params: { status: status, page: tableOrdersPage },
    })
  )
}

export const getTableOrder = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}`))
}

export const updateTableOrder = (auth, orderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.patch(`/orders/${orderId}`, params))
}

export const getTableOrderReviewProducts = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}/review_products`))
}

export const createTableOrderPayment = (auth, orderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/orders/${orderId}/payments`, params))
}

export const createTableOrderItem = (auth, orderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/orders/${orderId}/order_items`, params))
}

export const createTableOrderItemDraft = (auth, orderId, params) => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/orders/${orderId}/order_items/drafts`, params)
  )
}

export const getTableOrderItems = (auth, orderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${orderId}/order_items`, { params }))
}

export const updateTableOrderItem = (auth, tableOrderItemId, params) => {
  setHeader(auth)
  return wrapResponse(axios.patch(`/order_items/${tableOrderItemId}`, params))
}

export const removeTableOrderItem = (auth, tableOrderItemId) => {
  setHeader(auth)
  return wrapResponse(axios.delete(`/order_items/${tableOrderItemId}`))
}

export const submitTableOrderItemsDraft = (auth, orderId) => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/orders/${orderId}/order_items/drafts/submit`)
  )
}

export const getTableProduct = (auth, tableProductId, params) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/products/${tableProductId}`, { params }))
}

export const getTableReceipt = (auth, tableOrderId) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${tableOrderId}/receipt`))
}

export const createTableReceipt = (auth, tableOrderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/orders/${tableOrderId}/receipt`, params))
}

export const createTableOrderBill = (auth, tableOrderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/orders/${tableOrderId}/bills`, params))
}

export const removeTableOrderBill = (auth, billId) => {
  setHeader(auth)
  return wrapResponse(axios.delete(`/bills/${billId}`))
}

export const getTableOrderBills = (auth, tableOrderId, params) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/orders/${tableOrderId}/bills`, { params }))
}

export const getTableBill = (auth, tableBillId, params) => {
  setHeader(auth)
  return wrapResponse(axios.get(`/bills/${tableBillId}`, { params }))
}

export const updateTableBill = (auth, tableBillId, params) => {
  setHeader(auth)
  return wrapResponse(axios.patch(`/bills/${tableBillId}`, { params }))
}

export const getTableMenuUnlimitedOrderPlan = (
  auth,
  tableUnlimitedOrderPlanId
) => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/unlimited_order_plans/${tableUnlimitedOrderPlanId}`)
  )
}

export const getTableMenuUnlimitedOrderPlans = (auth, menuId, params) => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/menus/${menuId}/unlimited_order_plans`, { params })
  )
}

export const createTableOrderOrderItemsUnlimitedOrderPlan = (
  auth,
  orderId,
  params
) => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/orders/${orderId}/order_items/unlimited_order_plans`, params)
  )
}

export const createTableProductVote = (auth, params) => {
  setHeader(auth)
  return wrapResponse(axios.post(`/product_votes`, params))
}
