import { call, fork, delay, cancel, take } from './helpers/redux-saga'
import * as ActionTypes from 'ActionTypes'

import NProgress from 'modules/nprogress'

function* fetchWatcher() {
  const MINIMUM_THRESHOLD = 300
  let task = null
  while (true) {
    const action = yield* take([
      ActionTypes.UI_FETCHING,
      ActionTypes.UI_FETCHED,
    ])
    if (action.type === ActionTypes.UI_FETCHING) {
      task = yield* fork(function* () {
        yield* delay(MINIMUM_THRESHOLD)
        yield* call([NProgress, NProgress.start])
      })
    } else if (action.type === ActionTypes.UI_FETCHED) {
      if (task) {
        yield* cancel(task)
        task = null
        yield* call([NProgress, NProgress.done])
      }
    }
  }
}

const uiSagas = [fetchWatcher()]

export default uiSagas
