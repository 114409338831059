import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getShopRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/shop/restaurants/${restaurantId}`))
}

export const getShopRestaurantProducts = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/shop/restaurants/${restaurantId}/products`))
}

export const getShopProduct = (auth, productId): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/shop/products/${productId}`))
}

export const getShopProductReviews = (auth, productId): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/shop/products/${productId}/reviews`))
}

export const getShopOrdersCart = (auth): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/shop/orders/cart`))
}

export const getShopOrdersCartShippableDates = (auth): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/shop/orders/cart/shippable_dates`))
}

export const updateShopOrdersCart = (auth, params): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.patch(`/shop/orders/cart`, params))
}

export const addShopOrdersCartProduct = (auth, params): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.post(`/shop/orders/cart/add_product`, params))
}
export const removeShopOrdersCartProduct = (auth, params): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.post(`/shop/orders/cart/remove_product`, params))
}

export const updateShopOrdersCartShippingAddress = (
  auth,
  params
): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.post(`/shop/orders/cart/shipping_address`, params))
}

export const getShopOrdersCartShippingAddressForm = (auth): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/shop/orders/cart/shipping_address/form`))
}

export const completeShopOrdersCart = (auth, params?): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.post(`/shop/orders/cart/complete`, params))
}

export const getShopPopularProducts = (auth): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get('/shop/products/popular'))
}

export const getShopLatestProducts = (auth): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get('/shop/products/latest'))
}

export const getShopPopularRestaurants = (auth): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get('/shop/restaurants/popular'))
}

export const getShopLatestRestaurants = (auth, page): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get('/shop/restaurants/latest', { params: { page: page } })
  )
}

export const createShopProductOrderReview = (
  auth,
  shopProductOrderId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.post('/shop/product_order_reviews', {
      shop_product_order_id: shopProductOrderId,
      ...params,
    })
  )
}

export const updateShopProductOrderReview = (
  auth,
  shopProductOrderReviewId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.patch(
      `/shop/product_order_reviews/${shopProductOrderReviewId}`,
      params
    )
  )
}

export const createShopReceipt = (auth, shopOrderId, params) => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/shop/orders/${shopOrderId}/create_receipt`, params)
  )
}
