import * as ActionTypes from 'ActionTypes'
import { now, closestFutureReservationTime } from 'modules/time'
import moment from 'moment'

interface HomeState {
  initialPage: number

  data: string[]

  isFetched: boolean
  isRefreshing: boolean
  dateTime: moment.Moment
  date: string
  time: string
  partySize: number
  locationKeyword?: string
  interpretedKeyword: string
  latitude?: number
  longitude?: number

  sortBy: string
  minPrice?: number
  maxPrice?: number
  maxDistance: number
  seat: any[]
  isSmoking: boolean
  isNoSmoking: boolean
  sources: any[]

  keyword: string

  isAllFetchedBySectionId: Record<string, any>
  pageBySectionId: Record<string, any>
}

const dateTime = closestFutureReservationTime(now(), 30)

const initialState: HomeState = {
  initialPage: 1,

  data: [],

  isFetched: false,
  isRefreshing: false,
  dateTime: dateTime,
  date: dateTime.format('YYYY-MM-DD'),
  time: dateTime.format('HH:mm'),
  partySize: 2,
  locationKeyword: null,
  interpretedKeyword: '',
  latitude: null,
  longitude: null,

  sortBy: 'recommended',
  minPrice: null,
  maxPrice: null,
  maxDistance: 1.6,
  seat: [],
  isSmoking: false,
  isNoSmoking: false,
  sources: [],

  keyword: '',

  isAllFetchedBySectionId: {},
  pageBySectionId: {},
}

export default (state = initialState, action): HomeState => {
  const payload = action.payload
  switch (action.type) {
    case ActionTypes.GET_HOME_STARTED:
      return {
        ...state,

        initialPage: action.payload.params.page,

        isFetched: false,
        isAllFetchedBySectionId: {},
        pageBySectionId: {},
        interpretedKeyword: '',
      }

    case ActionTypes.GET_HOME_SUCCEEDED:
      return {
        ...state,
        data: payload.data,
        locationKeyword: payload.location_keyword,
        interpretedKeyword: payload.keyword,
        latitude: payload.latitude,
        longitude: payload.longitude,
        isFetched: true,
      }

    case ActionTypes.GET_HOME_SECTION_CONTENTS_SUCCEEDED:
      return {
        ...state,
        isAllFetchedBySectionId: {
          ...state.isAllFetchedBySectionId,
          [action.payload.sectionId]: action.payload.isAllFetched,
        },
        pageBySectionId: {
          ...state.pageBySectionId,
          [action.payload.sectionId]: action.payload.page,
        },
      }
    default:
      return state
  }
}
