import * as ActionTypes from 'ActionTypes'

interface TempUserState {
  id?: string
  phoneNumber?: string
  nationalPhoneNumber?: string
  countryCode?: string
  dialCode?: string
  verified: boolean
}

const initialState: TempUserState = {
  id: null,
  phoneNumber: null,
  nationalPhoneNumber: null,
  countryCode: null,
  dialCode: null,
  verified: false,
}

export default (state = initialState, action): TempUserState => {
  switch (action.type) {
    case ActionTypes.DESTROY_TEMP_USER:
      return {
        ...state,
        id: null,
        phoneNumber: null,
        verified: false,
      }
    case ActionTypes.VERIFY_PHONE_NUMBER_SUCCEEDED:
    case ActionTypes.VERIFY_CODE_SUCCEEDED:
      return {
        ...state,
        id: action.payload.id,
        phoneNumber: action.payload.phone_number,
        nationalPhoneNumber: action.payload.national_phone_number,
        countryCode: action.payload.country,
        dialCode: action.payload.country_code,
        verified: action.payload.verified,
      }
    default:
      return state
  }
}
