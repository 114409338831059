import { call, delay, cancel, fork, take } from './redux-saga'

export function* retryAPI(apiCall, ...args) {
  const RETRY_COUNT = 3
  const RETRY_INTERVAL = 1000

  let result
  for (let i = 0; i < RETRY_COUNT; i++) {
    result = yield* call(apiCall, ...args)

    if (result.response || result.error.response) {
      return result
    }
    yield* delay(RETRY_INTERVAL)
  }

  return result
}

// https://github.com/redux-saga/redux-saga/pull/1744

export function takeLatestPerKey(
  patternOrChannel,
  worker,
  keySelector: (AnyAction) => string | number,
  ...args
) {
  return fork(function* () {
    const tasks = {}

    while (true) {
      const action = yield* take(patternOrChannel)
      const key = yield* call(keySelector, action)

      if (tasks[key]) {
        yield* cancel(tasks[key])
      }

      tasks[key] = yield* fork(worker, ...args, action)
    }
  })
}

export function takeLeadingPerKey(
  patternOrChannel,
  worker,
  keySelector: (AnyAction) => string | number,
  ...args
) {
  return fork(function* () {
    const tasks = {}

    while (true) {
      const action = yield* take(patternOrChannel)
      const key = yield* call(keySelector, action)

      if (!(tasks[key] && tasks[key].isRunning())) {
        tasks[key] = yield* fork(worker, ...args, action)
      }
    }
  })
}
