const Colors = {
  primary: '#ab935a',
  primary90: 'rgba(171,147,90,0.9)',
  primaryBg: 'rgba(171,147,90,0.06)',
  accent: '#009688',
  accentBg: 'rgba(0,150,136,0.06)',
  black: '#444',
  black20: 'rgba(68,68,68,0.2)',
  black60: 'rgba(68,68,68,0.6)',
  black80: 'rgba(68,68,68,0.8)',
  success: '#009688',
  caution: '#e84733',
  cautionBg: 'rgba(232,71,51,0.06)',
  caution90: 'rgba(232,71,51,0.9)',
  caution40: 'rgba(232,71,51,0.4)',
  secondaryBlack: 'rgba(68,68,68,0.6)',
  disabledBlack: 'rgba(68,68,68,0.4)',
  bgBlack: 'rgba(68,68,68,0.06)',
  bgLightBlack: 'rgba(68,68,68,0.01)',
  border: 'rgba(0,0,0,0.16)',
  darkOverlay: '#000',
  darkOverlay20: 'rgba(0,0,0,0.2)',
  textDisabled: 'rgba(68,68,68,0.2)',
  field: 'rgb(245,245,245)',
  fieldDisabled: 'rgba(245,245,245,.2)',
}

export default Colors
