import React, { useRef } from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import Link from './Link'
import { useHover } from 'react-native-web-hooks'

interface LinkOpacityProps extends React.ComponentProps<typeof Link> {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}
function LinkOpacity({ children, style, ...props }: LinkOpacityProps) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  return (
    <Link
      ref={ref}
      style={StyleSheet.compose(
        [
          { cursor: 'pointer', textDecorationLine: 'none' },
          isHovered && {
            opacity: 0.7,
            textDecorationLine: 'none',
          },
        ] as any,
        style
      )}
      {...props}
    >
      {children}
    </Link>
  )
}

export default LinkOpacity
