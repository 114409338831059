import React from 'react'
import Main from '../layouts/Main'

import { Helmet } from 'react-helmet'

import Head from '../components/Shared/Head'
import makeTitle from 'modules/makeTitle'
import { t } from 'modules/i18n'

import { canonicalOrigin } from 'modules/url'
import { View, Image } from 'react-native'

import logo from 'static/logo.png'
import useResponsive from 'modules/useResponsive'

import { Error as ErrorComponent } from 'components/Shared/Error'

function Header() {
  const { width, md } = useResponsive()
  return (
    <View
      accessibilityRole="navigation"
      style={{
        paddingVertical: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: width < md ? 16 : 20,
        zIndex: 100,
        backgroundColor: 'white',
        borderBottomWidth: 0.5,
        borderBottomColor: Constants.BORDER_COLOR,
      }}
    >
      <Link to="/" style={{ height: 44, justifyContent: 'center' }}>
        <Image source={{ uri: logo }} style={{ width: 33.52, height: 32 }} />
      </Link>
    </View>
  )
}

const title = t('エラーが発生しました')
const url = `${canonicalOrigin()}/500`

function ErrorPage(props) {
  return (
    <View style={[GeneralStyles.container]}>
      <Head
        title={makeTitle(title)}
        url={url}
        imageUrl={null}
        ogType="website"
      />
      <Helmet>
        <meta name="render:status_code" content="500" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <View style={[GeneralStyles.container]}>
        <Header />
        <ErrorComponent />
      </View>
    </View>
  )
}

export default ErrorPage
