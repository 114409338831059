/* eslint-disable import/export */
/* eslint-disable no-restricted-imports */
import { RootState } from 'reducers'
import { select as rawSelect } from 'typed-redux-saga'
export * from 'typed-redux-saga'

// https://github.com/redux-saga/redux-saga/issues/970#issuecomment-299561039
export function* select<T>(fn: (state: RootState) => T) {
  return yield* rawSelect(fn)
}
