import * as ActionTypes from 'ActionTypes'

interface SessionState {
  phoneCode?: string
  phoneNumber?: string
}

const initialState: SessionState = {
  phoneCode: null,
  phoneNumber: null,
}

export default (state = initialState, action): SessionState => {
  switch (action.type) {
    case ActionTypes.SEND_CODE_FOR_LOGIN_SUCCEEDED:
      return {
        ...state,
        phoneCode: action.payload.phoneCode,
        phoneNumber: action.payload.phoneNumber,
      }
    default:
      return state
  }
}
