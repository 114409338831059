import { call, fork, put, select, takeLeading } from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'
import { normalize } from 'normalizr'
import schemas from 'schema'

import { retryAPI } from './helpers'

import * as actions from 'actions'

function* getNotifications(
  action: ReturnType<typeof actions.getNotifications>
) {
  const auth = yield* select((state) => state.auth)
  const notification = yield* select((state) => state.notification)

  const { response, error } = yield retryAPI(
    api.getNotifications,
    auth,
    notification.page
  )
  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.notification,
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_NOTIFICATIONS_SUCCEEDED,
      payload: result,
    })

    yield* fork(readNotifications)
  } else {
    yield* handleApiError(error)
  }
}

function* readNotifications() {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.readNotifications, auth)
  if (response) {
    yield* put({
      type: ActionTypes.SYNC_NOTIFICATIONS_BADGE,
      payload: response.data.unread_notification_count,
    })
  } else {
    yield* handleApiError(error)
  }
}

const notificationSagas = [
  takeLeading(ActionTypes.GET_NOTIFICATIONS, getNotifications),
  takeLeading(ActionTypes.GET_NEXT_NOTIFICATIONS, getNotifications),
]
export default notificationSagas
