import { persistCombineReducers, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import auth from './auth'
import crew from './crew'
import delivery from './delivery'
import deliveryHome from './deliveryHome'
import entity from './entity'
import top from './top'
import restaurant from './restaurant'
import user from './user'
import restaurantList from './restaurantList'
import ui from './ui'
import tempUser from './tempUser'
import notification from './notification'
import requestReservation from './requestReservation'
import reservation from './reservation'
import session from './session'
import home from './home'
import review from './review'
import message from './message'
import shop from './shop'
import error from './error'
import table from './table'
import takeout from './takeout'

const config = {
  key: 'root',
  storage,
  whitelist: [],
}

const authConfig = {
  key: 'auth',
  storage,
  whitelist: ['id', 'token', 'isAppLocationAccurate'],
}

const tempUserConfig = {
  key: 'tempUser',
  storage,
}

const tableConfig = {
  key: 'table',
  storage,
  whitelist: [
    'orderId',
    'isUnverified',
    'verifyModalClosedAt',
    'emailModalClosedAt',
    'nameModalClosedAt',
  ],
}

export type RootState = ReturnType<typeof rootReducer>

const rootReducer = persistCombineReducers(config, {
  auth: persistReducer(authConfig, auth),
  crew,
  delivery,
  entity,
  top,
  restaurant,
  user,
  restaurantList,
  ui,
  tempUser: persistReducer(tempUserConfig, tempUser),
  notification,
  requestReservation,
  reservation,
  session,
  home,
  review,
  message,
  shop,
  deliveryHome,
  error,
  table: persistReducer(tableConfig, table),
  takeout,
})

export default rootReducer
