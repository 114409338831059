import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import zhCn from 'dayjs/locale/zh-cn'
import zhTw from 'dayjs/locale/zh-tw'
import zhHk from 'dayjs/locale/zh-hk'
import en from 'dayjs/locale/en'
import { currentLocale } from 'modules/locale'

// dayjs/plugin/localizedFormat
const LocalizedFormat = (o, c, d) => {
  const proto = c.prototype
  const oldFormat = proto.format
  const englishFormats = {
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
  }
  d.en.formats = englishFormats
  const t = (format) =>
    format.replace(
      /(\[[^\]]+])|(MMMM|MM|DD|dddd)/g,
      (_, a, b) => a || b.slice(1)
    )
  proto.format = function (formatStr) {
    const { formats = {} } = this.$locale()
    const result = formatStr?.replace(
      /(\[[^\]]+])|(LTS?|lmll?|l{1,4}|L{1,4})/g,
      (_, a, b) => {
        const B = b && b.toUpperCase()
        return a || formats[b] || englishFormats[b] || t(formats[B])
      }
    )
    return oldFormat.call(this, result)
  }
}

/* eslint-disable */
ja.formats = {
  ...ja.formats,
  LLL: 'YYYY年M月D日 HH時mm分',
  LLLL: 'YYYY年M月D日(ddd) HH時mm分',
  lml: 'M月D日 HH:mm',
  lmll: 'M月D日(ddd) HH:mm',
} as any
zhCn.formats = {
  ...zhCn.formats,
  lml: 'M月D日 HH時mm分',
  lmll: 'M月D日dddd HH:mm',
} as any
zhTw.formats = {
  ...zhTw.formats,
  lml: 'M月D日 HH時mm分',
  lmll: 'M月D日dddd HH:mm',
} as any
zhHk.formats = {
  ...zhHk.formats,
  lml: 'M月D日 HH時mm分',
  lmll: 'M月D日dddd HH:mm',
} as any
en.formats = {
  ...en.formats,
  LTS: 'h:mm:ss A',
  LT: 'h:mm A',
  L: 'MM/DD/YYYY',
  LL: 'MMMM D, YYYY',
  LLL: 'MMMM D, YYYY h:mm A',
  LLLL: 'dddd, MMMM D, YYYY h:mm A',
  lml: 'MMMM D h:mm A',
  lmll: 'dddd, MMMM D h:mm A',
} as any
/* eslint-enable */

dayjs.locale('ja', ja)
dayjs.locale('zh', zhCn)
dayjs.locale('zh-CN', zhCn)
dayjs.locale('zh-TW', zhTw)
dayjs.locale('zh-HK', zhHk)
dayjs.locale('en', en)

dayjs.extend(LocalizedFormat)
dayjs.locale(currentLocale())

export default dayjs
