export const OPEN_TABLE = 'OPEN_TABLE' as const
export const OPEN_TABLE_SUCCEEDED = 'OPEN_TABLE_SUCCEEDED' as const
export const GET_TABLE_USER_ORDERS = 'GET_TABLE_USER_ORDERS' as const
export const GET_TABLE_USER_ORDERS_SUCCEEDED =
  'GET_TABLE_USER_ORDERS_SUCCEEDED' as const

export const GET_TABLE_USER_ORDER_REVIEWS =
  'GET_TABLE_USER_ORDER_REVIEWS' as const
export const GET_TABLE_USER_ORDER_REVIEWS_SUCCEEDED =
  'GET_TABLE_USER_ORDER_REVIEWS_SUCCEEDED' as const
export const GET_TABLE_SEAT = 'GET_TABLE_SEAT' as const
export const GET_TABLE_SEAT_SUCCEEDED = 'GET_TABLE_SEAT_SUCCEEDED' as const

export const START_TABLE_POLLING = 'START_TABLE_POLLING' as const
export const END_TABLE_POLLING = 'END_TABLE_POLLING' as const
export const AUTHENTICATE_TABLE = 'AUTHENTICATE_TABLE' as const
export const AUTHENTICATE_TABLE_SUCCEEDED =
  'AUTHENTICATE_TABLE_SUCCEEDED' as const

export const START_TABLE_CART_POLLING = 'START_TABLE_CART_POLLING' as const
export const REQUEST_TABLE_CART_REFRESHING =
  'REQUEST_TABLE_CART_REFRESHING' as const
export const END_TABLE_CART_POLLING = 'END_TABLE_CART_POLLING' as const

export const GET_TABLE_CART = 'GET_TABLE_CART' as const
export const GET_TABLE_CART_SUCCEEDED = 'GET_TABLE_CART_SUCCEEDED' as const

export const UPDATE_TABLE_ORDER_ITEM = 'UPDATE_TABLE_ORDER_ITEM' as const
export const UPDATE_TABLE_ORDER_ITEM_SUCCEEDED =
  'UPDATE_UPDATE_TABLE_ORDER_ITEM_SUCCEEDED' as const

export const REMOVE_TABLE_ORDER_ITEM = 'REMOVE_TABLE_ORDER_ITEM' as const
export const REMOVE_TABLE_ORDER_ITEM_SUCCEEDED =
  'REMOVE_TABLE_ORDER_ITEM_SUCCEEDED' as const

export const REQUEST_TABLE_ORDERS = 'REQUEST_TABLE_ORDERS' as const
export const REQUEST_TABLE_ORDERS_SUCCEEDED =
  ' REQUEST_TABLE_ORDERS_SUCCEEDED' as const
export const REQUEST_TABLE_ORDERS_FAILED =
  'REQUEST_TABLE_ORDERS_FAILED' as const

export const SELECT_TABLE_MENU_INDEX = 'SELECT_TABLE_MENU_INDEX' as const
export const SELECT_TABLE_MENU_PAGE = 'SELECT_TABLE_MENU_PAGE' as const

export const ADD_TABLE_CART_ORDER_ITEMS = 'ADD_TABLE_CART_ORDER_ITEMS' as const
export const ADD_TABLE_CART_ORDER_ITEMS_SUCCEEDED =
  'ADD_TABLE_CART_ORDER_ITEMS_SUCCEEDED' as const
export const ADD_TABLE_CART_ORDER_ITEMS_FAILED =
  'ADD_TABLE_CART_ORDER_ITEMS_FAILED' as const

export const CREATE_TABLE_WAITER_CALL = 'CREATE_TABLE_WAITER_CALL' as const
export const CREATE_TABLE_WAITER_CALL_SUCCEEDED =
  'CREATE_TABLE_WAITER_CALL_SUCCEEDED' as const

export const GET_TABLE_SEAT_MENUS = 'GET_TABLE_SEAT_MENUS' as const
export const GET_TABLE_SEAT_MENUS_SUCCEEDED =
  'GET_TABLE_SEAT_MENUS_SUCCEEDED' as const

export const CREATE_TABLE_SEAT_ORDER = 'CREATE_TABLE_SEAT_ORDER' as const
export const CREATE_TABLE_SEAT_ORDER_SUCCEEDED =
  'CREATE_TABLE_SEAT_ORDER_SUCCEEDED' as const

export const GET_TABLE_ORDERS = 'GET_TABLE_ORDERS' as const
export const GET_TABLE_ORDERS_SUCCEEDED = 'GET_TABLE_ORDERS_SUCCEEDED' as const
export const GET_TABLE_ORDER = 'GET_TABLE_ORDER' as const
export const GET_TABLE_ORDER_SUCCEEDED = 'GET_TABLE_ORDER_SUCCEEDED' as const

export const GET_TABLE_ORDER_REVIEW_PRODUCTS =
  'GET_TABLE_ORDER_REVIEW_PRODUCTS' as const
export const GET_TABLE_ORDER_REVIEW_PRODUCTS_SUCCEEDED =
  'GET_TABLE_ORDER_REVIEW_PRODUCTS_SUCCEEDED' as const
export const SUBMIT_TABLE_PRODUCT_VOTES = 'SUBMIT_TABLE_PRODUCT_VOTES' as const
export const SUBMIT_TABLE_PRODUCT_VOTES_SUCCEEDED =
  'SUBMIT_TABLE_PRODUCT_VOTES_SUCCEEDED' as const

export const CREATE_TABLE_ORDER_PAYMENT = 'CREATE_TABLE_ORDER_PAYMENT' as const
export const CREATE_TABLE_ORDER_PAYMENT_SUCCEEDED =
  'CREATE_TABLE_ORDER_PAYMENT_SUCCEEDED' as const
export const CREATE_TABLE_ORDER_PAYMENT_FAILED =
  'CREATE_TABLE_ORDER_PAYMENT_FAILED' as const
export const GET_TABLE_PRODUCT = 'GET_TABLE_PRODUCT' as const
export const GET_TABLE_PRODUCT_SUCCEEDED =
  'GET_TABLE_PRODUCT_SUCCEEDED' as const

export const GET_TABLE_RECEIPT = 'GET_TABLE_RECEIPT' as const
export const CREATE_TABLE_RECEIPT = 'CREATE_TABLE_RECEIPT' as const

export const CREATE_TABLE_ORDER_BILL = 'CREATE_TABLE_ORDER_BILL' as const
export const DELETE_TABLE_ORDER_BULL = 'DELETE_TABLE_ORDER_BULL' as const
export const CREATE_TABLE_ORDER_BILL_SUCCEEDED =
  'CREATE_TABLE_ORDER_BILL_SUCCEEDED' as const
export const DELETE_TABLE_ORDER_BILL_SUCCEEDED =
  'DELETE_TABLE_ORDER_BILL_SUCCEEDED' as const

export const GET_TABLE_ORDER_BILLS = 'GET_TABLE_ORDER_BILLS' as const
export const GET_TABLE_ORDER_BILLS_SUCCEEDED =
  'GET_TABLE_ORDER_BILLS_SUCCEEDED' as const

export const GET_TABLE_BILL = 'GET_TABLE_BILL' as const
export const GET_TABLE_BILL_SUCCEEDED = 'GET_TABLE_BILL_SUCCEEDED' as const

export const UPDATE_TABLE_BILL = 'UPDATE_TABLE_BILL' as const
export const UPDATE_TABLE_BILL_SUCCEEDED =
  'UPDATE_TABLE_BILL_SUCCEEDED' as const

export const OPEN_TABLE_PAY = 'OPEN_TABLE_PAY' as const
export const OPEN_TABLE_PAY_SUCCEEDED = 'OPEN_TABLE_PAY_SUCCEEDED' as const
export const UPDATE_TABLE_ORDER = 'UPDATE_TABLE_ORDER' as const
export const CLOSE_TABLE_VERIFY_MODAL = 'CLOSE_TABLE_VERIFY_MODAL' as const
export const CLOSE_TABLE_EMAIL_MODAL = 'CLOSE_TABLE_EMAIL_MODAL' as const
export const CLOSE_TABLE_NAME_MODAL = 'CLOSE_TABLE_NAME_MODAL' as const

export const GET_TABLE_UNLIMITED_ORDER_PLAN =
  'GET_TABLE_UNLIMITED_ORDER_PLAN' as const
export const GET_TABLE_MENU_UNLIMITED_ORDER_PLANS =
  'GET_TABLE_MENU_UNLIMITED_ORDER_PLANS' as const
export const GET_TABLE_MENU_UNLIMITED_ORDER_PLANS_SUCCEEDED =
  'GET_TABLE_MENU_UNLIMITED_ORDER_PLANS_SUCCEEDED' as const
export const SELECT_TABLE_MENU_TYPE = 'SELECT_TABLE_MENU_TYPE' as const
export const SELECT_TABLE_UNLIMITED_ORDER_PLAN =
  'SELECT_TABLE_UNLIMITED_ORDER_PLAN' as const

export const CREATE_TABLE_ORDER_ORDER_ITEMS_UNLIMITED_ORDER_PLAN =
  'CREATE_TABLE_ORDER_ORDER_ITEMS_UNLIMITED_ORDER_PLAN' as const

export const GET_TABLE_ORDER_MENU = 'GET_TABLE_ORDER_MENU' as const

export const GET_TABLE_MENU = 'GET_TABLE_MENU' as const
export const CREATE_TABLE_PRODUCT_VOTE = 'CREATE_TABLE_PRODUCT_VOTE'
export const CREATE_TABLE_PRODUCT_VOTE_SUCCEEDED =
  'CREATE_TABLE_PRODUCT_VOTE_SUCCEEDED'

export const VERIFY_TABLE_UNVERIFIED_USER = 'VERIFY_TABLE_UNVERIFIED_USER'
export const VERIFY_TABLE_UNVERIFIED_USER_SUCCEEDED =
  'VERIFY_TABLE_UNVERIFIED_USER_SUCCEEDED'
