import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const like = (auth, id, type): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/likes`, { id: id, type: type })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const unlike = (auth, likeId): ResponseWrapper => {
  setHeader(auth)
  return axios
    .delete(`/likes/${likeId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
