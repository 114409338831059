import { currentLocale } from './locale'

export function fontFamily() {
  const locale = currentLocale()
  if (locale === 'zh-CN') {
    // eslint-disable-next-line ar-i18n/require-translation-ja
    return "'Helvetica Neue', 'PingFang-SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Heiti SC', 'sans-serif'"
  }

  if (locale === 'zh-TW' || locale === 'zh-HK') {
    return "'Helvetica Neue', sans-serif"
  }

  return "'Hiragino Sans', sans-serif"
}
