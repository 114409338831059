import * as ActionTypes from 'ActionTypes'
import { isEmpty } from 'lodash'

interface ReservationState {
  isEditReservationSucceeded: boolean
}

const initialState: ReservationState = {
  isEditReservationSucceeded: false,
}

export default (state = initialState, action): ReservationState => {
  switch (action.type) {
    case ActionTypes.RESET_RESERVATION_LIST_PROGRESS_STATUS:
      return {
        ...state,
        isEditReservationSucceeded: false,
      }
    case ActionTypes.EDIT_RESERVATION_LIST_SUCCEEDED:
      return {
        ...state,
        isEditReservationSucceeded: true,
      }
    default:
      return state
  }
}
