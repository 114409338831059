import * as ActionTypes from 'ActionTypes'

export const getUserId = (userName) => {
  return {
    type: ActionTypes.GET_USER_ID,
    payload: {
      userName,
    },
  } as const
}

export const getUserById = (userId) => {
  return {
    type: ActionTypes.GET_USER_BY_ID,
    payload: {
      userId,
    },
  } as const
}

export const getUser = (userName) => {
  return {
    type: ActionTypes.GET_USER,
    payload: {
      userName,
    },
  } as const
}

export const getPayments = () => {
  return {
    type: ActionTypes.GET_PAYMENTS,
  } as const
}

export const addCreditCard = (stripeToken, source = null) => {
  return {
    type: ActionTypes.ADD_CREDIT_CARD,
    stripeToken: stripeToken,
    source,
  } as const
}

export const updateDefaultCard = (cardId) => {
  console.log(cardId)
  return {
    type: ActionTypes.UPDATE_DEFAULT_CARD,
    payload: {
      cardId,
    },
  } as const
}

export const removeCard = (cardId) => {
  return {
    type: ActionTypes.REMOVE_CARD,
    payload: {
      cardId,
    },
  } as const
}

export const updateUser = (params, onFinish = null, onError = null) => {
  return {
    type: ActionTypes.UPDATE_USER,
    params: params,
    onFinish,
    onError,
  } as const
}

export const followUser = (userId) => {
  return {
    type: ActionTypes.FOLLOW_USER,
    userId: userId,
  } as const
}

export const unfollowUser = (userId) => {
  return {
    type: ActionTypes.UNFOLLOW_USER,
    userId: userId,
  } as const
}

export const getUserFeed = (userName) => {
  return {
    type: ActionTypes.GET_USER_FEED,
    payload: {
      userName,
    },
  } as const
}

export const getUserLists = (userName) => {
  return {
    type: ActionTypes.GET_USER_LISTS,
    payload: {
      userName,
    },
  } as const
}

export const getShopOrders = () => {
  return {
    type: ActionTypes.GET_SHOP_ORDERS,
  } as const
}

export const getShopOrder = (id) => {
  return {
    type: ActionTypes.GET_SHOP_ORDER,
    payload: {
      id,
    },
  } as const
}

export const toggleIsCampaignReceivedUser = (value) => {
  return {
    type: ActionTypes.TOGGLE_IS_CAMPAIGN_RECEIVED_USER,
    payload: {
      value,
    },
  } as const
}

export const destroyUser = (history) => {
  return {
    type: ActionTypes.DESTROY_USER,
    payload: {
      history,
    },
  } as const
}

export const getCrewRequest = () => {
  return {
    type: ActionTypes.GET_CREW_REQUEST,
  } as const
}

export const createCrewRequest = (params, onFinish) => {
  return {
    type: ActionTypes.CREATE_CREW_REQUEST,
    payload: {
      params,
      onFinish,
    },
  } as const
}

export const createMedium = (params) => {
  return {
    type: ActionTypes.CREATE_MEDIUM,
    payload: {
      params,
    },
  } as const
}

export const createQrOrderRequests = (params, onFinish) => {
  return {
    type: ActionTypes.CREATE_QR_ORDER_REQUESTS,
    payload: {
      params,
      onFinish,
    },
  } as const
}

export const sendCodeForPhoneNumberTransfer = (params, onFinish) => {
  return {
    type: ActionTypes.SEND_CODE_FOR_PHONE_NUMBER_TRANSFER,
    payload: {
      params,
      onFinish,
    },
  } as const
}

export const validateCodeForPhoneNumberTransfer = (params, onFinish) => {
  return {
    type: ActionTypes.VALIDATE_CODE_FOR_PHONE_NUMBER_TRANSFER,
    payload: {
      params,
      onFinish,
    },
  } as const
}
