import axios from './axios'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getDeliveryRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/delivery/restaurants/${restaurantId}`))
}

export const getDeliveryRestaurantProducts = (
  auth,
  restaurantId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/delivery/restaurants/${restaurantId}/products`, { params })
  )
}

export const getDeliveryRestaurantDiscountCampaigns = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/delivery/restaurants/${restaurantId}/discount_campaigns`)
  )
}

export const createDeliveryRestaurantProduct = (
  auth,
  restaurantId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/delivery/restaurants/${restaurantId}/products`, params)
  )
}

export const getDeliveryRestaurantDeliveryTimes = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/delivery/restaurants/${restaurantId}/delivery_times`)
  )
}

export const getDeliveryCart = (auth): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get('/delivery/cart'))
}

export const updateDeliveryCart = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.put('/delivery/cart', params))
}

export const addDeliveryCartProduct = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.post('/delivery/cart/product_orders', params))
}

export const removeDeliveryCartProduct = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.delete('/delivery/cart/product_orders', { data: params })
  )
}

export const requestDeliveryCart = (auth): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.put('/delivery/cart/request'))
}
