export const GET_RESTAURANT_RESERVATIONS =
  'GET_RESTAURANT_RESERVATIONS' as const
export const GET_RESTAURANT_RESERVATIONS_SUCCEEDED =
  'GET_RESTAURANT_RESERVATIONS_SUCCEEDED' as const
export const RESET_RESERVATION_LIST_PROGRESS_STATUS =
  'RESET_RESERVATION_LIST_PROGRESS_STATUS' as const
export const GET_RESERVATION_LIST = 'GET_RESERVATION_LIST' as const
export const GET_RESERVATION_ELEMENT = 'GET_RESERVATION_ELEMENT' as const

export const GET_RESERVATION = 'GET_RESERVATION' as const
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION' as const
export const CANCEL_RESERVATION_SUCCEEDED =
  'CANCEL_RESERVATION_SUCCEEDED' as const
export const EDIT_RESERVATION_LIST = 'EDIT_RESERVATION_LIST'
export const EDIT_RESERVATION_LIST_SUCCEEDED = 'EDIT_RESERVATION_LIST_SUCCEEDED'
