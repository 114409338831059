import { schema } from 'normalizr'
import restaurantList from './restaurantList'
import user from './user'

const importedRestaurantList = new schema.Entity('importedRestaurantLists', {
  restaurant_list: restaurantList,
  user: user
})

export default importedRestaurantList
