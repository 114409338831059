import React, { useRef, useState } from 'react'
import { useHover } from 'react-native-web-hooks'
import { View } from 'react-native'

import loadable from '@loadable/component'

import { useSelector } from 'react-redux'

import MenuExpanded from './MenuExpanded'

import useClickOutside from 'modules/useClickOutside'

import { Text } from '@hello-ai/ar_shared'

const ModalMessage = loadable(() => import('components/Message/ModalMessage'))

function Button({ size = 22, unreadCount = 0, onPress, active = false }) {
  const ref = useRef(null)
  const isHovered = useHover(ref)
  return (
    <View ref={ref} style={{ position: 'relative' }}>
      <FontAwesome5Pro
        light
        name="envelope"
        size={size}
        style={[
          {
            cursor: 'pointer',
            color: Constants.LIGHT_BLACK_COLOR,
            // @ts-expect-error
            userSelect: 'none',
          },
          isHovered && {
            color: Constants.BLACK_COLOR,
            textDecorationLine: 'none',
          },
          active && { color: Constants.PRIMARY_COLOR },
        ]}
        solid={active}
        onPress={onPress}
      />
      {unreadCount > 0 && (
        <View
          style={{
            position: 'absolute',
            top: -4,
            right: -7,
            paddingHorizontal: 2,
            paddingVertical: 1,
            borderRadius: 2,
            backgroundColor: Constants.PRIMARY_COLOR,
          }}
        >
          <Text style={{ fontSize: 8, color: 'white' }}>{unreadCount}</Text>
        </View>
      )}
    </View>
  )
}

function MessageButton() {
  const ref = useRef(null)
  const [isVisible, setVisible] = useState(false)

  const unreadMessageCount = useSelector((state) => {
    if (state.message.messageList.length === 0) {
      return 0
    }
    return state.message.messageList
      .map((m) => m.unread_message_count)
      .reduce((previous, next) => previous + next)
  })

  useClickOutside(ref, () => {
    setVisible(false)
  })

  return (
    <View
      ref={ref}
      style={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Button
        active={isVisible}
        unreadCount={unreadMessageCount}
        onPress={() => {
          setVisible(!isVisible)
        }}
      />
      {isVisible && (
        <View
          style={{
            width: 520,
            height: 721,
            maxHeight: 'calc(100vh - 80px)',
            position: 'absolute',
            top: 30,
            right: 0,
          }}
        >
          <MenuExpanded backgroundColor="white" borderRadius={4}>
            <ModalMessage alwaysShowSend />
          </MenuExpanded>
        </View>
      )}
    </View>
  )
}

export default MessageButton
