import axios from './axios'
import { axios as tableAxios, setHeader as tableSetHeader } from './table'
import { ResponseWrapper, setHeader, wrapResponse } from './shared'

export const getTakeoutRestaurant = (auth, restaurantId): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/takeout/restaurants/${restaurantId}`))
}

export const getTakeoutRestaurantMenus = (
  auth,
  restaurantId,
  params
): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(
    axios.get(`/takeout/restaurants/${restaurantId}/menus`, { params })
  )
}

export const getTakeoutProduct = (auth, productId, params): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(axios.get(`/takeout/products/${productId}`, { params }))
}

export const getTakeoutRestaurantReceiveTimes = (
  auth,
  restaurantId
): ResponseWrapper => {
  setHeader(auth)

  return wrapResponse(
    axios.get(`/takeout/restaurants/${restaurantId}/receive_times`)
  )
}

export const createTakeoutRestaurantOrder = (
  auth,
  restaurantId,
  params?
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/takeout/restaurants/${restaurantId}/orders`, params)
  )
}

export const getTakeoutOrder = (auth, orderId, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get(`/takeout/orders/${orderId}`, { params }))
}

export const updateTakeoutOrder = (auth, orderId, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.patch(`/takeout/orders/${orderId}`, params))
}

export const createTakeoutOrderItem = (
  auth,
  orderId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.post(`/takeout/orders/${orderId}/order_items`, params)
  )
}

export const updateTakeoutOrderItem = (
  auth,
  orderItemId,
  params
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.patch(`/takeout/order_items/${orderItemId}`, params)
  )
}

export const destroyTakeoutOrderItem = (
  auth,
  orderItemId,
  params?
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.delete(`/takeout/order_items/${orderItemId}`, params)
  )
}

export const requestTakeoutOrder = (auth, orderId, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.post(`/takeout/orders/${orderId}/request`, params))
}

export const getTakeoutOrderReceiveTimeOptions = (
  auth,
  orderId: string,
  params?
): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(
    axios.get(`/takeout/orders/${orderId}/receive_time_options`, { params })
  )
}

export const getTakeoutUserOrders = (auth, params): ResponseWrapper => {
  setHeader(auth)
  return wrapResponse(axios.get('/takeout/user/orders'))
}

export const getTakeoutReceipt = (auth, tableOrderId): ResponseWrapper => {
  tableSetHeader(auth)
  return wrapResponse(tableAxios.get(`/takeout/orders/${tableOrderId}/receipt`))
}

export const createTakeoutReceipt = (
  auth,
  orderId,
  params
): ResponseWrapper => {
  tableSetHeader(auth)
  return wrapResponse(
    tableAxios.post(`/takeout/orders/${orderId}/receipt`, params)
  )
}
