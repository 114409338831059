import * as ActionTypes from 'ActionTypes'

interface RestaurantListState {
  restaurantListIdBySlug: Record<string, any>
}

const initialState: RestaurantListState = {
  restaurantListIdBySlug: {},
}

export default (state = initialState, action): RestaurantListState => {
  switch (action.type) {
    case ActionTypes.GET_RESTAURANT_LIST_SUCCEEDED:
      return {
        ...state,
        restaurantListIdBySlug: {
          ...state.restaurantListIdBySlug,
          [action.payload.slug]: action.payload.restaurantListId,
        },
      }
    default:
      return state
  }
}
