import {
  put,
  select,
  call,
  takeEvery,
  takeLeading,
  take,
  race,
  fork,
  delay,
} from './helpers/redux-saga'
import { get } from 'lodash'
import * as ui from 'actions/ui'

import { normalize } from 'normalizr'

import { retryAPI } from './helpers'
import * as api from 'api'
import schemas from 'schema'

import * as ActionTypes from 'ActionTypes'

import * as actions from 'actions'
import { t } from 'modules/i18n'

import { handleApiError } from './shared'
import * as Sentry from '@sentry/browser'

import { refreshInterval } from 'modules/table'
import dayjs from 'dayjs'
import { createTableUnverifiedUser } from 'models/TableUnverifiedUser'
import { signInWithToken } from './auth'
import * as tableUnverifiedUser from 'models/TableUnverifiedUser'
function* openTable(action: ReturnType<typeof actions.openTable>) {
  const { token, orderId, history } = action.payload
  yield* put({
    type: ActionTypes.OPEN_TABLE_SUCCEEDED,
    payload: {
      token,
      orderId,
    },
  })
  history.push('/table')
}

function* getTableUserOrders() {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(api.getTableUserOrders, auth, {
    status: 'open',
  })

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableOrder])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TABLE_USER_ORDERS_SUCCEEDED,
      payload: {
        orders: result,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getTableUserOrderReviews(orderId) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getTableUserOrderReviews,
    auth,
    orderId
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.review])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TABLE_USER_ORDER_REVIEWS_SUCCEEDED,
      payload: {
        orderId,
        reviews: result,
      },
    })
    return { result }
  } else {
    return yield* handleApiError(error)
  }
}

function* getTableSeat(action: ReturnType<typeof actions.getTableSeat>) {
  const { orderId } = action.payload

  yield* call(getTableOrder, orderId)
  const order = yield* select((state) => state.entity.tableOrders[orderId])

  yield* put({
    type: ActionTypes.GET_TABLE_SEAT_SUCCEEDED,
    payload: {
      tableSeatId: order.table_seat,
    },
  })
}

function* startTablePolling(
  action: ReturnType<typeof actions.startTablePolling>
) {
  yield* put(actions.startTableCartPolling())
  const task = yield* fork(function* () {
    while (true) {
      const orderId = yield* select((state) => state.table.orderId)

      yield* call(getTableOrder, orderId)
      yield* delay(refreshInterval)
    }
  })

  yield* take([ActionTypes.END_TABLE_POLLING])
  yield* call([task, task.cancel])
  yield* put(actions.endTableCartPolling())
}

function* startTableCartPolling() {
  const task = yield* fork(function* () {
    while (true) {
      yield* call(getTableCart)

      yield* race([
        take(ActionTypes.REQUEST_TABLE_CART_REFRESHING),
        delay(refreshInterval),
      ])
    }
  })

  yield* take([ActionTypes.END_TABLE_CART_POLLING])

  yield* call([task, task.cancel])
}

function* getTableCart() {
  const auth = yield* select((state) => state.auth)
  const orderId = yield* select((state) => state.table.orderId)
  const { response, error } = yield retryAPI(
    api.getTableOrderItems,
    auth,
    orderId,
    {
      status: 'draft',
    }
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.tableOrderItem,
    ])

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TABLE_CART_SUCCEEDED,
      payload: {
        tableOrderItems: result,
      },
    })
  } else {
    // pollingしているのでここでは何も表示しない
    console.log(error)
  }
}

function* authenticateTable(
  action: ReturnType<typeof actions.authenticateTable>
) {
  const auth = yield* select((state) => state.auth)
  const authId = auth.id
  const { token, type, history } = action.payload

  const { response, error } = yield* call(api.createTableSeatOrder, auth, token)

  if (response) {
    const { result, entities } = normalize(response.data, schemas.tableOrder)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    if (type === 'onetime_token') {
      if (authId == null) {
        const { tableUnverifiedUser, error } = yield* call(
          createTableUnverifiedUser,
          auth.token,
          {
            order_onetime_token: token,
          }
        )
        if (error != null) {
          return
        }
        {
          const { error } = yield* signInWithToken(tableUnverifiedUser.token)
          if (error) return
        }
      }
    }

    yield* put({
      type: ActionTypes.AUTHENTICATE_TABLE_SUCCEEDED,
      payload: {
        token,
        orderId: result,
        isUnverified: type === 'onetime_token',
      },
    })
    history.replace('/table')
  } else {
    yield* handleApiError(error)
  }
}

function* updateTableOrderItem(
  action: ReturnType<typeof actions.updateTableOrderItem>
) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderItemId, params } = action.payload

  const { response, error } = yield* call(
    api.updateTableOrderItem,
    auth,
    tableOrderItemId,
    params
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.tableOrderItem)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* removeTableOrderItem(
  action: ReturnType<typeof actions.removeTableOrderItem>
) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderItemId } = action.payload

  const { response, error } = yield* call(
    api.removeTableOrderItem,
    auth,
    tableOrderItemId
  )

  if (response) {
    yield* put(actions.requestTableCartRefreshing())
  } else {
    yield* handleApiError(error)
  }
}

function* addTableCartOrderItems(
  action: ReturnType<typeof actions.addTableCartOrderItems>
) {
  const auth = yield* select((state) => state.auth)
  const { orderId, orderItems, submit, history } = action.payload

  const { response, error } = yield* call(
    submit ? api.createTableOrderItem : api.createTableOrderItemDraft,
    auth,
    orderId,
    orderItems
  )

  if (response) {
    const { entities } = normalize(response.data, [schemas.tableOrderItem])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.ADD_TABLE_CART_ORDER_ITEMS_SUCCEEDED,
    })
  } else {
    yield* put({ type: ActionTypes.ADD_TABLE_CART_ORDER_ITEMS_FAILED })
    yield* handleApiError(error)
    return
  }

  yield* put(actions.requestTableCartRefreshing())
  if (submit) {
    // 必須オーダーの場合は、required_orderの状態を見て自動で遷移するのでここではreplaceしない
    yield* put(ui.displayToastInfo(t('注文しました')))
  } else {
    yield* put(ui.displayToastInfo(t('カートに追加しました')))
  }

  if (history) {
    history.replace('/table')
  }
}

function* requestTableOrders(
  action: ReturnType<typeof actions.requestTableOrders>
) {
  const auth = yield* select((state) => state.auth)
  const { orderId, history } = action.payload

  const { response, error } = yield* call(
    api.submitTableOrderItemsDraft,
    auth,
    orderId
  )

  if (response) {
    const { entities } = normalize(response.data, [schemas.tableOrderItem])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    const orderId = yield* select((state) => state.table.orderId)
    yield* call(getTableOrder, orderId)

    yield* put(actions.requestTableCartRefreshing())

    yield* put({
      type: ActionTypes.REQUEST_TABLE_ORDERS_SUCCEEDED,
    })

    yield* put(actions.displayToastInfo(t('注文しました')))

    history.replace('/table')
    yield* fork(getTableUserOrders)
  } else {
    yield* put({ type: ActionTypes.REQUEST_TABLE_ORDERS_FAILED })
    yield* handleApiError(error)
    Sentry.captureException(error)
  }
}

function* createTableWaiterCall(
  action: ReturnType<typeof actions.createTableWaiterCall>
) {
  const auth = yield* select((state) => state.auth)
  const { orderId, callType } = action.payload

  const { response, error } = yield* call(
    api.createTableWaiterCall,
    auth,
    orderId,
    callType
  )

  if (response) {
    yield* put({
      type: ActionTypes.CREATE_TABLE_WAITER_CALL_SUCCEEDED,
    })
    yield* put(ui.displayToastInfo(t('店員を呼び出しています')))
  } else {
    yield* handleApiError(error)
  }
}

function* getTableOrderDefaultMenu(orderId: string) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getTableOrderDefaultMenu,
    auth,
    orderId
  )

  // 現在提供中のメニューはありませんのエラーが出つづけると困るので
  // エラーメッセージは出さない

  if (response) {
    if (!response.data) {
      // yield put(ui.displayToastError(t('選択可能なメニューがありません')))
      return null
    }
    const { result, entities } = normalize(response.data, schemas.tableMenu)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    return result
  } else {
    // yield* handleApiError(error)
    return null
  }
}

function* getLatestUnlimitedOrderPlan() {
  const orderId = yield* select((state) => state.table.orderId)
  const { error } = yield* getTableOrder(orderId)
  if (error) return null

  const order = yield* select((state) => state.entity.tableOrders[orderId])
  const plans = order.table_order_item_plans

  if (!plans || plans.length === 0) return null

  const sortedPlans = plans
    .concat()
    .sort((a, b) => (dayjs(a.expire_at).isBefore(dayjs(b.expire_at)) ? 1 : -1))

  return sortedPlans[0].plan
}

function* getTableOrderMenus(
  action: ReturnType<typeof actions.getTableOrderMenus>
) {
  const auth = yield* select((state) => state.auth)
  const { orderId } = action.payload

  const { response, error } = yield retryAPI(
    api.getTableOrderMenus,
    auth,
    orderId
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableMenu])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    const menuIndex = yield* select((state) => state.table.menuIndex)

    if (menuIndex == null) {
      const latestPlan = yield* getLatestUnlimitedOrderPlan()

      if (latestPlan && latestPlan.current_table_menu) {
        // 時間内の放題プランメニューがある場合のみ放題メニューをデフォルトで開く
        const tableMenuId = latestPlan.current_table_menu.id

        const index = tableMenuId ? result.indexOf(tableMenuId) : 0

        yield* put({
          type: ActionTypes.GET_TABLE_SEAT_MENUS_SUCCEEDED,
          payload: {
            menus: result,
            menuIndex: index,
            menuType: 'unlimited',
            tableUnlimitedOrderPlanId: latestPlan.id,
          },
        })
        return
      }

      const tableMenuId = yield* call(getTableOrderDefaultMenu, orderId)

      const index = tableMenuId ? result.indexOf(tableMenuId) : 0

      yield* put({
        type: ActionTypes.GET_TABLE_SEAT_MENUS_SUCCEEDED,
        payload: {
          menus: result,
          menuIndex: index,
          menuType: 'default',
          tableUnlimitedOrderPlanId: null,
        },
      })
    }
  } else {
    yield* handleApiError(error)
  }
}

function* getTableOrders(action: ReturnType<typeof actions.getTableOrders>) {
  const auth = yield* select((state) => state.auth)
  const user = yield* select((state) => state.user)

  const { status } = action.payload
  const tableOrdersPage: number = get(user, [auth.id, 'tableOrdersPage'], 1)

  const { response, error } = yield retryAPI(
    api.getTableOrders,
    auth,
    status,
    tableOrdersPage
  )
  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableOrder])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_TABLE_ORDERS_SUCCEEDED,
      payload: {
        id: auth.id,
        tableOrders: result,
        tableOrdersPage: tableOrdersPage + 1,
      },
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getTableOrder(orderId) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(api.getTableOrder, auth, orderId)

  if (response) {
    const { result, entities } = normalize(response.data, schemas.tableOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* getTableOrderReviewProducts(orderId) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTableOrderReviewProducts,
    auth,
    orderId
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      {
        table_product: schemas.tableProduct,
        table_product_vote: schemas.tableProductVote,
      },
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_TABLE_ORDER_REVIEW_PRODUCTS_SUCCEEDED,
      payload: {
        orderId,
        reviewProducts: result,
      },
    })
    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* createTableProductVote(params) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.createTableProductVote,
    auth,
    params
  )
  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableProductVote
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CREATE_TABLE_PRODUCT_VOTE_SUCCEEDED,
    })
    return { result, error: null }
  } else {
    return yield* handleApiError(error)
  }
}

function* submitTableProductVotes(tableOrderId, votes) {
  for (const [tableProductId, vote] of Object.entries(votes)) {
    const { error } = yield* call(createTableProductVote, {
      table_order_id: tableOrderId,
      table_product_id: tableProductId,
      vote_type: vote,
    })

    if (error) return error
  }
  return {}
}

function* createTableOrderPayment(
  action: ReturnType<typeof actions.createTableOrderPayment>
) {
  const auth = yield* select((state) => state.auth)
  const { orderId, params, complete, onFinish } = action.payload

  const { response, error } = yield* call(
    api.createTableOrderPayment,
    auth,
    orderId,
    params
  )

  if (response) {
    if (complete) {
      yield* call(complete, 'success')
    }
    const { entities } = normalize(response.data, schemas.tableOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CREATE_TABLE_ORDER_PAYMENT_SUCCEEDED,
    })

    if (onFinish) {
      onFinish()
    }

    yield* put(ui.displayToastInfo(t('支払いが完了しました')))

    yield* fork(getTableUserOrders)
  } else {
    if (complete) {
      yield* call(complete, 'fail')
    }
    yield* put({
      type: ActionTypes.CREATE_TABLE_ORDER_PAYMENT_FAILED,
    })
    yield* handleApiError(error)
  }
}

function* getTableProduct(action: ReturnType<typeof actions.getTableProduct>) {
  const auth = yield* select((state) => state.auth)
  const { tableProductId, params } = action.payload

  const { response, error } = yield retryAPI(
    api.getTableProduct,
    auth,
    tableProductId,
    params
  )

  if (response) {
    const { entities } = normalize(
      {
        ...(params?.table_unlimited_order_plan_id && {
          table_unlimited_order_plan_id: params.table_unlimited_order_plan_id,
        }),
        ...(params?.table_order_id && {
          table_order_id: params.table_order_id,
        }),
        ...response.data,
      },
      schemas.tableProduct
    )

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* getTableReceipt(action: ReturnType<typeof actions.getTableReceipt>) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderId } = action.payload

  const { response, error } = yield retryAPI(
    api.getTableReceipt,
    auth,
    tableOrderId
  )

  if (response) {
    const { entities } = normalize(response.data, schemas.tableReceipt)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* createTableReceipt(
  action: ReturnType<typeof actions.createTableReceipt>
) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderId, params, onFinish } = action.payload

  const { response, error } = yield* call(
    api.createTableReceipt,
    auth,
    tableOrderId,
    params
  )

  if (response) {
    const { entities } = normalize(response.data, schemas.tableReceipt)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    onFinish()
  } else {
    yield* handleApiError(error)
  }
}

function* updateTableOrder(tableOrderId, params, onFinish) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.updateTableOrder,
    auth,
    tableOrderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.tableOrder)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    return { result, error: null }
  } else {
    return yield* handleApiError(error)
  }
}

function* createTableOrderBill(
  action: ReturnType<typeof actions.createTableOrderBill>
) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderId, params, onFinish } = action.payload

  const { response, error } = yield* call(
    api.createTableOrderBill,
    auth,
    tableOrderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableBill])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.CREATE_TABLE_ORDER_BILL_SUCCEEDED,
      payload: {
        tableBills: result,
      },
    })

    if (onFinish) {
      onFinish()
    }
  } else {
    yield* handleApiError(error)
  }
}

function* deleteTableOrderBill(
  action: ReturnType<typeof actions.removeTableOrderBill>
) {
  const auth = yield* select((state) => state.auth)
  const { billId } = action.payload
  const { error } = yield* call(api.removeTableOrderBill, auth, billId)

  if (error) {
    yield* handleApiError(error)
  } else {
    yield* put({
      type: ActionTypes.CREATE_TABLE_ORDER_BILL_SUCCEEDED,
      payload: {
        tableBillId: billId,
      },
    })
  }
}

function* getTableOrderBills(
  action: ReturnType<typeof actions.getTableOrderBills>
) {
  const auth = yield* select((state) => state.auth)
  const { tableOrderId, params, onFinish } = action.payload

  const { response, error } = yield* call(
    api.getTableOrderBills,
    auth,
    tableOrderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.tableBill])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TABLE_ORDER_BILLS_SUCCEEDED,
      payload: {
        tableBills: result,
      },
    })

    if (onFinish) {
      onFinish()
    }
  } else {
    yield* handleApiError(error)
  }
}

function* openTablePay(action: ReturnType<typeof actions.openTablePay>) {
  const { orderId, history } = action.payload
  yield* put({
    type: ActionTypes.OPEN_TABLE_SUCCEEDED,
    payload: {
      orderId,
    },
  })
  history.push('/table/pay')
}

function* getTableUnlimitedOrderPlan(tableUnlimitedOrderPlanId) {
  const auth = yield select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTableMenuUnlimitedOrderPlan,
    auth,
    tableUnlimitedOrderPlanId
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableUnlimitedOrderPlan
    )
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* getTableMenuUnlimitedOrderPlans(tableMenuId, params = {}) {
  const auth = yield select((state) => state.auth)
  const { response, error } = yield retryAPI(
    api.getTableMenuUnlimitedOrderPlans,
    auth,
    tableMenuId,
    params
  )
  if (response) {
    const { result, entities } = normalize(
      response.data.map((plan) => ({
        ...plan,
        table_unlimited_order_plan_id: plan.id,
      })),
      [schemas.tableUnlimitedOrderPlan]
    )

    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield put({
      type: ActionTypes.GET_TABLE_MENU_UNLIMITED_ORDER_PLANS_SUCCEEDED,
      payload: {
        tableMenuId,
        tableUnlimitedOrderPlans: result,
      },
    })
    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* createTableOrderOrderItemsUnlimitedOrderPlan(tableOrderId, params) {
  const auth = yield select((state) => state.auth)

  const { response, error } = yield* call(
    api.createTableOrderOrderItemsUnlimitedOrderPlan,
    auth,
    tableOrderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableOrderItem
    )
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* getTableMenu(tableMenuId) {
  const auth = yield select((state) => state.auth)

  const { response, error } = yield* call(api.getTableMenu, auth, tableMenuId)

  if (response) {
    const { result, entities } = normalize(response.data, schemas.tableMenu)
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* getTableOrderMenu(orderId, tableMenuId) {
  const auth = yield select((state) => state.auth)

  const { response, error } = yield* call(
    api.getTableOrderMenu,
    auth,
    orderId,
    tableMenuId
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.tableMenu)
    yield put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    return { result }
  } else {
    yield* handleApiError(error)
    return { error }
  }
}

function* verifyTableUnverifiedUser(params) {
  const auth = yield* select((state) => state.auth)
  const { user, error } = yield* call(
    tableUnverifiedUser.verifyTableUnverifiedUser,
    auth.token,
    params
  )
  if (error != null) return

  yield* put({
    type: ActionTypes.VERIFY_TABLE_UNVERIFIED_USER_SUCCEEDED,
    payload: {
      verifyModalClosedAt: dayjs().format(),
    },
  })

  const { entities } = normalize(user, schemas.user)
  yield* put({
    type: ActionTypes.SET_ENTITIES,
    payload: entities,
  })

  yield* put(actions.destroyTempUser())

  yield* put(ui.displayToastInfo(t('アカウント登録しました')))
}

const tableSagas = [
  takeEvery(ActionTypes.OPEN_TABLE, openTable),
  takeEvery(ActionTypes.GET_TABLE_USER_ORDERS, getTableUserOrders),
  takeEvery(
    ActionTypes.GET_TABLE_USER_ORDER_REVIEWS,
    function* ({
      payload: { orderId },
    }: ReturnType<typeof actions.getTableUserOrderReviews>) {
      yield* getTableUserOrderReviews(orderId)
    }
  ),
  takeEvery(ActionTypes.GET_TABLE_SEAT, getTableSeat),
  takeLeading(ActionTypes.START_TABLE_POLLING, startTablePolling),
  takeEvery(ActionTypes.AUTHENTICATE_TABLE, authenticateTable),
  takeLeading(ActionTypes.START_TABLE_CART_POLLING, startTableCartPolling),

  takeLeading(ActionTypes.ADD_TABLE_CART_ORDER_ITEMS, addTableCartOrderItems),

  takeEvery(ActionTypes.UPDATE_TABLE_ORDER_ITEM, updateTableOrderItem),
  takeEvery(ActionTypes.REMOVE_TABLE_ORDER_ITEM, removeTableOrderItem),

  takeEvery(ActionTypes.REQUEST_TABLE_ORDERS, requestTableOrders),

  takeEvery(ActionTypes.CREATE_TABLE_WAITER_CALL, createTableWaiterCall),
  takeEvery(ActionTypes.GET_TABLE_SEAT_MENUS, getTableOrderMenus),
  takeEvery(ActionTypes.GET_TABLE_ORDERS, getTableOrders),
  takeEvery(
    ActionTypes.GET_TABLE_ORDER,
    function* ({
      payload: { orderId },
    }: ReturnType<typeof actions.getTableOrder>) {
      yield* getTableOrder(orderId)
    }
  ),
  takeEvery(
    ActionTypes.GET_TABLE_ORDER_REVIEW_PRODUCTS,
    function* ({
      payload: { orderId },
    }: ReturnType<typeof actions.getTableOrderReviewProducts>) {
      yield* getTableOrderReviewProducts(orderId)
    }
  ),
  takeEvery(
    ActionTypes.SUBMIT_TABLE_PRODUCT_VOTES,
    function* ({
      payload: { tableOrderId, votes, onFinish },
    }: ReturnType<typeof actions.submitTableProductVotes>) {
      const { error } = yield* submitTableProductVotes(tableOrderId, votes)
      if (error) return
      onFinish?.()
    }
  ),
  takeEvery(ActionTypes.CREATE_TABLE_ORDER_PAYMENT, createTableOrderPayment),
  takeEvery(ActionTypes.GET_TABLE_PRODUCT, getTableProduct),

  takeEvery(ActionTypes.GET_TABLE_RECEIPT, getTableReceipt),
  takeEvery(ActionTypes.CREATE_TABLE_RECEIPT, createTableReceipt),
  takeEvery(ActionTypes.CREATE_TABLE_ORDER_BILL, createTableOrderBill),
  takeEvery(ActionTypes.DELETE_TABLE_ORDER_BULL, deleteTableOrderBill),
  takeEvery(ActionTypes.GET_TABLE_ORDER_BILLS, getTableOrderBills),
  takeEvery(ActionTypes.OPEN_TABLE_PAY, openTablePay),
  takeEvery(
    ActionTypes.UPDATE_TABLE_ORDER,
    function* ({
      payload: { tableOrderId, params, onFinish },
    }: ReturnType<typeof actions.updateTableOrder>) {
      const { error } = yield* updateTableOrder(tableOrderId, params, onFinish)
      if (error) {
        return
      }
      let message = t('オーダーを更新しました')
      if (params?.party_size != null) {
        message = t('人数を入力しました')
      } else if (params?.male_size != null || params?.female_size) {
        message = t('男女の人数を入力しました')
      }

      yield* put(ui.displayToastInfo(message))
    }
  ),
  takeEvery(
    ActionTypes.GET_TABLE_UNLIMITED_ORDER_PLAN,
    function* ({
      payload: { tableUnlimitedOrderPlanId },
    }: ReturnType<typeof actions.getTableUnlimitedOrderPlan>) {
      yield* getTableUnlimitedOrderPlan(tableUnlimitedOrderPlanId)
    }
  ),
  takeEvery(
    ActionTypes.GET_TABLE_MENU_UNLIMITED_ORDER_PLANS,
    function* ({
      payload: { tableMenuId, params, onFinish },
    }: ReturnType<typeof actions.getTableMenuUnlimitedOrderPlans>) {
      const { error } = yield* getTableMenuUnlimitedOrderPlans(
        tableMenuId,
        params
      )
      if (error) return

      if (onFinish) {
        onFinish()
      }
    }
  ),

  takeEvery(
    ActionTypes.CREATE_TABLE_ORDER_ORDER_ITEMS_UNLIMITED_ORDER_PLAN,
    function* ({
      payload: { tableOrderId, params, onFinish },
    }: ReturnType<
      typeof actions.createTableOrderOrderItemsUnlimitedOrderPlan
    >) {
      yield* put(actions.startFetch())
      const { error } = yield* createTableOrderOrderItemsUnlimitedOrderPlan(
        tableOrderId,
        params
      )
      if (error) {
        yield* put(actions.endFetch())
        return
      }

      const orderId = yield* select((state) => state.table.orderId)
      yield* call(getTableOrder, orderId)
      yield* put(actions.endFetch())

      yield* put(ui.displayToastInfo(t('放題プランを開始しました')))

      if (onFinish) {
        onFinish()
      }
    }
  ),
  takeEvery(
    ActionTypes.GET_TABLE_ORDER_MENU,
    function* ({
      payload: { orderId, tableMenuId },
    }: ReturnType<typeof actions.getTableOrderMenu>) {
      yield* getTableOrderMenu(orderId, tableMenuId)
    }
  ),
  takeEvery(
    ActionTypes.GET_TABLE_MENU,
    function* ({
      payload: { tableMenuId },
    }: ReturnType<typeof actions.getTableMenu>) {
      yield* getTableMenu(tableMenuId)
    }
  ),
  takeEvery(
    ActionTypes.CREATE_TABLE_PRODUCT_VOTE,
    function* ({
      payload: { params },
    }: ReturnType<typeof actions.createTableProductVote>) {
      yield* createTableProductVote(params)
    }
  ),
  takeEvery(
    ActionTypes.VERIFY_TABLE_UNVERIFIED_USER,
    function* ({
      payload: { params },
    }: ReturnType<typeof actions.verifyTableUnverifiedUser>) {
      yield* verifyTableUnverifiedUser(params)
    }
  ),
]

export default tableSagas
