import * as ActionTypes from 'ActionTypes'
import { isEmpty } from 'lodash'

interface ShopState {
  shopProductsById: Record<string, string[]>
  isShippableDatesLoading?: boolean
  shopProductsLoading: Record<string, boolean>
  cartFetched: boolean
  shippingAddressLoading: boolean
  cart: string | null
  prefectures: []
  notice: string | null

  currentStep: 'start' | 'shipping' | 'form' | 'confirm' | 'complete'
  shippableDates: string[] | null

  openShopPurchaseModalLoading: boolean
  selectShippingShopPurchaseModalLoading: boolean
  confirmShopPurchaseModalLoading: boolean
  completeShopPurchaseModalLoading: boolean

  latestProducts: string[]
  popularProducts: string[]
  latestRestaurants: string[]
  latestRestaurantsPage: number
  latestRestaurantsFetched: boolean
  popularRestaurants: string[]
}

const initialState: ShopState = {
  shopProductsById: {},
  shopProductsLoading: {},
  cartFetched: false,
  shippingAddressLoading: false,
  cart: null,
  prefectures: [],
  notice: null,

  currentStep: 'start',
  shippableDates: null,

  openShopPurchaseModalLoading: false,
  selectShippingShopPurchaseModalLoading: false,
  confirmShopPurchaseModalLoading: false,
  completeShopPurchaseModalLoading: false,

  latestProducts: [],
  popularProducts: [],
  latestRestaurants: [],
  latestRestaurantsPage: 1,
  latestRestaurantsFetched: false,
  popularRestaurants: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SHOP_ORDERS_CART_SHIPPABLE_DATES_SUCCEEDED:
      return {
        ...state,
        isShippableDatesLoading: false,
        shippableDates: action.payload.shippableDates,
      }
    case ActionTypes.GET_SHOP_RESTAURANT_PRODUCTS:
      return {
        ...state,
        shopProductsLoading: {
          ...state.shopProductsLoading,
          [action.payload.restaurantId]: true,
        },
      }
    case ActionTypes.GET_SHOP_RESTAURANT_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        shopProductsById: {
          ...state.shopProductsById,
          [action.payload.restaurantId]: action.payload.shopProducts,
        },
        shopProductsLoading: {
          ...state.shopProductsLoading,
          [action.payload.restaurantId]: false,
        },
      }

    case ActionTypes.GET_SHOP_ORDERS_CART:
      return {
        ...state,
        // カートが存在する場合のみ、すでにロード済であればローディングを表示しない
        cartFetched: (state.cartFetched && Boolean(state.cart)) || false,
      }
    case ActionTypes.ADD_SHOP_ORDERS_CART_PRODUCT:
      return {
        ...state,
        cartFetched:
          // カートが空の状態から、商品が追加された場合はローディングを表示するが
          // それ以外の場合は、すでにロード済みだった場合ローディングを表示しない（個数の変更、二個目の商品の追加など）
          (state.cartFetched && !action.payload.isCartEmpty) || false,
      }

    case ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddressLoading: true,
      }
    case ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_SUCCEEDED:
      return {
        ...state,
        cartFetched: true,
        cart: action.payload.cart,
        shippingAddressLoading: false,
      }
    case ActionTypes.UPDATE_SHOP_ORDERS_CART_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        shippingAddressLoading: false,
      }
    case ActionTypes.GET_SHOP_ORDERS_CART_SUCCEEDED:
    case ActionTypes.ADD_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED:
    case ActionTypes.REMOVE_SHOP_ORDERS_CART_PRODUCT_SUCCEEDED:
      return {
        ...state,
        cartFetched: true,
        cart: action.payload.cart,
      }

    case ActionTypes.OPEN_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        openShopPurchaseModalLoading: true,
        currentStep: 'start',
      }
    case ActionTypes.GO_BACK_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        currentStep: action.payload.step,
      }
    case ActionTypes.OPEN_SHOP_PURCHASE_MODAL_SUCCEEDED:
      return {
        ...state,
        openShopPurchaseModalLoading: false,
        currentStep: 'shipping',
        prefectures: action.payload.prefectures,
        notice: action.payload.notice,
      }
    case ActionTypes.OPEN_SHOP_PURCHASE_MODAL_ERROR:
      return {
        ...state,
        openShopPurchaseModalLoading: false,
      }
    case ActionTypes.SELECT_SHIPPING_AND_PAYMENTS_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        currentStep: 'form',
      }
    case ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        selectShippingShopPurchaseModalLoading: true,
      }
    case ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL_SUCCEEDED:
      return {
        ...state,
        currentStep: 'form',
        selectShippingShopPurchaseModalLoading: false,
      }
    case ActionTypes.SELECT_SHIPPING_SHOP_PURCHASE_MODAL_ERROR:
      return {
        ...state,
        selectShippingShopPurchaseModalLoading: false,
      }
    case ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        confirmShopPurchaseModalLoading: true,
      }
    case ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_SUCCEEDED:
      return {
        ...state,
        confirmShopPurchaseModalLoading: false,
        currentStep: 'confirm',
      }
    case ActionTypes.CONFIRM_SHOP_PURCHASE_MODAL_ERROR:
      return {
        ...state,
        confirmShopPurchaseModalLoading: false,
      }
    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN:
    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL:
      return {
        ...state,
        completeShopPurchaseModalLoading: true,
      }
    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_SUCCEEDED:
    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_SUCCEEDED:
      return {
        ...state,
        cart: action.payload.cart,
        cartFetched: true,
        currentStep: 'complete',
        completeShopPurchaseModalLoading: false,
      }

    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_ERROR:
    case ActionTypes.COMPLETE_SHOP_PURCHASE_MODAL_WITH_TOKEN_ERROR:
      return {
        ...state,
        completeShopPurchaseModalLoading: false,
      }

    case ActionTypes.GET_SHOP_POPULAR_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        popularProducts: action.payload.products,
      }
    case ActionTypes.GET_SHOP_LATEST_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        latestProducts: action.payload.products,
      }
    case ActionTypes.GET_SHOP_POPULAR_RESTAURANTS_SUCCEEDED:
      return {
        ...state,
        popularRestaurants: action.payload.restaurants,
      }
    case ActionTypes.GET_SHOP_LATEST_RESTAURANTS_SUCCEEDED:
      return {
        ...state,
        latestRestaurants: state.latestRestaurants.concat(
          action.payload.restaurants
        ),
        latestRestaurantsPage: action.payload.page,
        latestRestaurantsFetched: isEmpty(action.payload.restaurants),
      }
    default:
      return state
  }
}
