export const GET_USER_ID = 'GET_USER_ID' as const
export const GET_USER = 'GET_USER' as const
export const GET_USER_BY_ID = 'GET_USER_BY_ID' as const
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED' as const
export const GET_PAYMENTS = 'GET_PAYMENTS' as const
export const GET_PAYMENTS_SUCCEEDED = 'GET_PAYMENTS_SUCCEEDED' as const
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD' as const
export const REMOVE_CARD = 'REMOVE_CARD' as const
export const UPDATE_DEFAULT_CARD = 'UPDATE_DEFAULT_CARD' as const
export const CARD_SUCCEEDED = 'CARD_SUCCEEDED' as const
export const CARD_FAILED = 'CARD_FAILED' as const
export const UPDATE_USER = 'UPDATE_USER' as const
export const FOLLOW_USER = 'FOLLOW_USER' as const
export const UNFOLLOW_USER = 'UNFOLLOW_USER' as const
export const GET_USER_FEED = 'GET_USER_FEED' as const
export const GET_USER_FEED_SUCCEEDED = 'GET_USER_FEED_SUCCEEDED' as const
export const GET_USER_LISTS = 'GET_USER_LISTS' as const
export const GET_SHOP_ORDERS = 'GET_SHOP_ORDERS' as const
export const GET_SHOP_ORDERS_SUCCEEDED = 'GET_SHOP_ORDERS_SUCCEEDED' as const
export const GET_SHOP_ORDER = 'GET_SHOP_ORDER' as const
export const TOGGLE_IS_CAMPAIGN_RECEIVED_USER = 'TOGGLE_IS_CAMPAIGN_RECEIVED_USER' as const
export const DESTROY_USER = 'DESTROY_USER' as const

export const GET_CREW_REQUEST = 'GET_CREW_REQUEST' as const
export const GET_CREW_REQUEST_SUCCEEDED = 'GET_CREW_REQUEST_SUCCEEDED' as const
export const CREATE_CREW_REQUEST = 'CREATE_CREW_REQUEST' as const

export const CREATE_MEDIUM = 'CREATE_MEDIUM' as const

export const CREATE_QR_ORDER_REQUESTS = 'CREATE_QR_ORDER_REQUESTS' as const

export const SEND_CODE_FOR_PHONE_NUMBER_TRANSFER = 'SEND_CODE_FOR_PHONE_NUMBER_TRANSFER' as const
export const VALIDATE_CODE_FOR_PHONE_NUMBER_TRANSFER = 'VALIDATE_CODE_FOR_PHONE_NUMBER_TRANSFER' as const
