import { schema } from 'normalizr'

import { idAttribute, processStrategy } from 'modules/table'

const takeoutProduct = new schema.Entity(
  'takeoutProducts',
  {},
  {
    idAttribute,
    processStrategy,
  }
)

export default takeoutProduct
