import * as ActionTypes from 'ActionTypes'

export const setError = ({ hasError, error }) => {
  return {
    type: ActionTypes.SET_ERROR,
    payload: {
      hasError,
      error,
    },
  } as const
}

export const clearError = () => {
  return {
    type: ActionTypes.CLEAR_ERROR,
  } as const
}
