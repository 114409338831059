import { put, select, call } from './helpers/redux-saga'
import * as ui from 'actions/ui'

import { normalize } from 'normalizr'

import { retryAPI } from './helpers'
import * as api from 'api'
import schemas from 'schema'

import * as ActionTypes from 'ActionTypes'

import * as actions from 'actions'
import { getErrorMessage } from 'modules/error'
export function* handleApiError(
  error,
  { abortOnError = false }: { abortOnError: boolean } = { abortOnError: false }
): Generator<any, { error: any }, any> {
  const message = getErrorMessage(error)
  if (!abortOnError) {
    yield* put(ui.displayToastError(message))
  }

  if (error.response) {
    if (error.response.status === 401) {
      yield* put({ type: ActionTypes.SIGN_OUT_SUCCEEDED })
      setTimeout(() => {
        // window.location.href = '/sign_in'
      }, 1000)
      return { error }
    }
  }

  if (abortOnError) {
    if (error.response) {
      if (error.response.status === 404 || error.response.status === 403) {
        window.location.href = '/404'
      } else {
        yield* put(actions.setError({ hasError: true, error: error }))
        // window.location.href = '/500'
      }
    } else {
      yield* put(actions.setError({ hasError: true, error: error }))

      //   window.location.href = '/500'
    }
    return { error }
  }

  if (error.response) {
    console.log(error.response.data)
    if (error.response.status === 404) {
      // window.location.href = '/404'
    }
  }
  return { error }
}

export function* getHome(action: ReturnType<typeof actions.getHome>) {
  yield* put({ type: ActionTypes.GET_HOME_STARTED, payload: action.payload })

  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(api.getHome, auth, {
    ...action.payload.params,
  })

  if (response) {
    const { result, entities } = normalize(response.data, {
      data: [schemas.homeSection],
    })

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_HOME_SUCCEEDED,
      payload: result,
    })

    // yield* trackHomeSearched(searchParams)
  } else {
    yield* handleApiError(error, { abortOnError: true })
  }
}

function getCurrentPosition(options) {
  return new Promise<{ coords: GeolocationCoordinates }>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

let lastCoords = null

function* syncCurrentLocationIfNeeded(
  latitude,
  longitude,
  isAppLocationAccurate
) {
  const auth = yield* select((state) => state.auth)
  if (auth.id && latitude && longitude) {
    const { error } = yield* call(
      api.updateCurrentLocation,
      auth,
      latitude,
      longitude,
      isAppLocationAccurate
    )
    if (error) {
      yield* handleApiError(error)
    }
  }
}

export function* updateCurrentLocation(
  action: ReturnType<typeof actions.updateCurrentLocation>
) {
  const { requestAccurateLocation = false, forceUpdate = false } =
    action.payload

  if (requestAccurateLocation) {
    if (lastCoords && !forceUpdate) {
      return
    }
    try {
      const { coords } = yield* call(getCurrentPosition, {
        maximumAge: 0,
        timeout: 1000 * 60,
      })
      lastCoords = coords

      const { latitude, longitude } = lastCoords

      yield* call(syncCurrentLocationIfNeeded, latitude, longitude, true)

      yield* put({
        type: ActionTypes.UPDATE_CURRENT_LOCATION_SUCCEEDED,
        payload: {
          latitude: latitude,
          longitude: longitude,
          isAppLocationAccurate: true,
        },
      })
      return
    } catch (err) {
      console.error(err)
      // このまま続行する
    }
  }

  if (locationCache && !forceUpdate) {
    return
  }

  const { latitude, longitude } = yield* call(getCurrentLocationByIpInfo)

  yield* call(syncCurrentLocationIfNeeded, latitude, longitude, false)

  yield* put({
    type: ActionTypes.UPDATE_CURRENT_LOCATION_SUCCEEDED,
    payload: { latitude, longitude, isAppLocationAccurate: false },
  })
}

let locationCache = null

export function* getCurrentLocationByIpInfo() {
  const { response, error } = yield* call(api.getIpInfo)

  if (response && response.data) {
    if (response.data.loc && response.data.country === 'JP') {
      const [latStr, longStr] = response.data.loc.split(/,/)
      const latitude = Number(latStr)
      const longitude = Number(longStr)
      locationCache = { latitude, longitude }
      return locationCache
    }
  } else {
    console.error(error)
    // fall through
  }

  // エラーになったり国外からのアクセスでは渋谷を返す
  locationCache = { latitude: 35.658, longitude: 139.7016 }
  return locationCache
}
