import { eventChannel, END } from 'redux-saga'
import {
  take,
  fork,
  call,
  put,
  select,
  takeEvery,
  takeLeading,
} from './helpers/redux-saga'
import * as api from 'api'
import * as ui from 'actions/ui'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { normalize } from 'normalizr'
import schemas from 'schema'
import { retryAPI } from './helpers'

import { isEmpty, throttle } from 'lodash'
import { t } from 'modules/i18n'

import * as actions from 'actions'

function* getCommonDataAfterSucceeded(from, reviewId) {
  const entity = yield* select((state) => state.entity)
  const review = entity.reviews[reviewId]
  // TODO: レストランページのgetRestaurantReviewsとかを更新する必要がある
}

function* getReview(action: ReturnType<typeof actions.getReview>) {
  const auth = yield* select((state) => state.auth)

  const { reviewId } = action.payload

  const { response, error } = yield retryAPI(api.getReview, auth, reviewId)

  if (response) {
    const { result, entities } = normalize(response.data, schemas.review)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_REVIEW_SUCCEEDED,
      payload: result,
    })
  } else {
    yield* put({
      type: ActionTypes.GET_REVIEW_FAILED,
      payload: reviewId,
    })
    yield* handleApiError(error)
  }
}

export function* createReview(params, images, onFinish, from) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(api.createReview, auth, params)
  if (response) {
    const { result, entities } = normalize(response.data, schemas.review)
    const restaurantId = entities.reviews[result].restaurant

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.CREATE_REVIEW_SUCCEEDED,
      payload: restaurantId,
    })
    yield createReviewImages(result, images)

    const review = entities.reviews[result]

    yield* fork(getCommonDataAfterSucceeded, from, result)

    onFinish && onFinish()

    if (from !== 'table_pay') {
      yield* put(ui.displayToastInfo(t('レビューを投稿しました。')))
    }

    yield* call(logReviewCreated, {
      restaurantId,
      reviewId: result,
      content: review.content,
      rating: review.rating,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* logReviewCreated({ restaurantId, reviewId, content, rating }) {
  if (window.gtag) {
    window.gtag('event', 'review')
  }
}

export function* updateReview(
  reviewId,
  params,
  images,
  removeRestaurantImagesIds,
  onFinish,
  from
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.updateReview,
    auth,
    reviewId,
    params,
    removeRestaurantImagesIds
  )
  if (response) {
    const { result, entities } = normalize(response.data, schemas.review)
    const restaurantId = entities.reviews[result].restaurant

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.UPDATE_REVIEW_SUCCEEDED,
      payload: restaurantId,
    })
    yield createReviewImages(result, images)
    if (onFinish) {
      onFinish()
    }
    if (from !== 'table_pay') {
      yield* put(ui.displayToastInfo(t('レビューを更新しました。')))
    }
  } else {
    yield* handleApiError(error)
  }
}

function createReviewImagesChannel(auth, reviewId, images) {
  return eventChannel<{ progress?; response?; error? }>((emitter) => {
    const throttledEmitter = throttle(emitter, 300)
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        throttledEmitter({
          progress: progressEvent.loaded / progressEvent.total,
        })
      }
    }
    api
      .createReviewImages(auth, reviewId, images, onUploadProgress)
      .then(({ response, error }) => {
        if (response) {
          emitter({ response })
          emitter(END)
        } else {
          emitter({ error })
          emitter(END)
        }
      })
      .catch(handleApiError)
    // TODO

    return () => {}
  })
}

export function* createReviewImages(reviewId, images) {
  if (isEmpty(images)) {
    return
  }

  const auth = yield* select((state) => state.auth)

  yield* put({
    type: ActionTypes.CREATE_REVIEW_IMAGES_STARTED,
    payload: { reviewId, current: 1, total: images.length },
  })

  for (let current = 1; current <= images.length; current++) {
    const channel = yield* call(
      createReviewImagesChannel,
      auth,
      reviewId,
      // 元々は複数画像をまとめてアップロードしていたが、タイムアウト対策で
      // 一枚ずつアップするように変更したので一枚の画像が含まれた配列をとりあえず渡している
      images.slice(current - 1, current)
    )

    while (true) {
      const { progress = 0, response, error } = yield* take(channel)
      if (error) {
        yield* handleApiError(error)
        return
      }
      if (response) {
        const { result, entities } = normalize(response.data, schemas.review)
        const restaurantId = entities.reviews[result].restaurant

        yield* put({
          type: ActionTypes.SET_ENTITIES,
          payload: entities,
        })

        if (current < images.length) {
          // 最後の画像アップロード以外では アップロード中(1 / 3)的なUIを更新するためにこのアクションを投げる
          yield* put({
            type: ActionTypes.CREATE_REVIEW_IMAGES_PROGRESS_IMAGE_UPLOADED,
            payload: { reviewId, restaurantId, current },
          })
          break
        } else {
          // 最後の画像の場合はbreak直後に外側のforも終了して、CREATE_REVIEW_IMAGES_SUCCEEDEDが呼ばれる
          break
        }
      }

      yield* put({
        type: ActionTypes.CREATE_REVIEW_IMAGES_PROGRESS,
        payload: { reviewId, progress, current },
      })
    }
  }
  yield* put({
    type: ActionTypes.CREATE_REVIEW_IMAGES_SUCCEEDED,
    payload: { reviewId },
  })
}

const reviewSagas = [
  takeEvery(ActionTypes.GET_REVIEW, getReview),
  takeLeading(
    ActionTypes.CREATE_REVIEW,
    function* ({
      payload: { params, images, onFinish, from },
    }: ReturnType<typeof actions.createReview>) {
      yield* createReview(params, images, onFinish, from)
    }
  ),
  takeEvery(
    ActionTypes.UPDATE_REVIEW,
    function* ({
      payload: {
        reviewId,
        params,
        images,
        removeRestaurantImagesIds,
        onFinish,
        from,
      },
    }: ReturnType<typeof actions.updateReview>) {
      yield* updateReview(
        reviewId,
        params,
        images,
        removeRestaurantImagesIds,
        onFinish,
        from
      )
    }
  ),
  takeEvery(
    ActionTypes.CREATE_OR_UPDATE_REVIEW,
    function* ({
      payload: {
        reviewId,
        params,
        images,
        removeRestaurantImagesIds,
        onFinish,
        from,
      },
    }: ReturnType<typeof actions.createOrUpdateReview>) {
      if (reviewId) {
        yield* updateReview(
          reviewId,
          params,
          images,
          removeRestaurantImagesIds,
          onFinish,
          from
        )
      } else {
        yield* createReview(params, images, onFinish, from)
      }
    }
  ),
]

export default reviewSagas
