import React, { useRef, useCallback } from 'react'
import { TouchableOpacity as RNTouchableOpacity } from '../node_modules/react-native-web'
import composeRefs from '@seznam/compose-react-refs'
/*  WORKAROUND:
本来ここでexport *すると、babel-plugin-react-nativeでのbundleの最適化が効かなくなるため
よくないのだが依存してるパッケージ量が多いため、fork/patch地獄にならずに使うため苦肉の策として
config-overrides.jsでreact-native, react-native-webでcompatさせている
*/

export * from '../node_modules/react-native-web'

// 様々な依存ライブラリから使われているdeprecatedなprop, 不要になったら消す

export const ViewPropTypes =
  process.env.NODE_ENV === 'production'
    ? {}
    : new Proxy(
        {},
        {
          get(target, name) {
            return () => {}
          },
        }
      )

// react-native-vector-iconsがアクセスしているがRNWには存在しない
export const TabBarIOS = {}
