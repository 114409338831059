import React, { useRef, useState } from 'react'

import { useHistory } from 'react-router'
import { goBackOrReplace } from 'modules/history'

import useResponsive from 'modules/useResponsive'

import useLocationParams from 'modules/useLocationParams'

import { omit } from 'lodash'

import { useSortByParam } from 'modules/sortByUtils'
import { useRouteMatch } from 'react-router-dom'

import SPSearchHeader from './SPSearchHeader'
import SPHeader from './SPHeader'
import PCHeader from './PCHeader'
import SimpleHeader from './SimpleHeader'

function useHomeOrSearchMatch() {
  const isHome = useRouteMatch({ path: '/', exact: true })
  const isSearch = useRouteMatch({ path: '/s/' })
  return isHome || isSearch
}

function Header({
  showFilterBar = false,
  onHeaderLayout,
  headerAndBannerRef,
  headerAndBannerHeight,
  filterBarHeight,
  setFilterBarHeight,
  title,
  color = Constants.PRIMARY_COLOR,
  backgroundColor = 'white',
  showHeaderBorderBottom = true,
  goBackTo,
  goBack,
}) {
  const history = useHistory()
  const params = useLocationParams()
  const [keyword, setKeyword] = useState(params.keyword || '')

  const inputRef = useRef(null)

  const sortBy = useSortByParam()

  const onBackPress = () => {
    goBackOrReplace(goBackTo)
  }

  const search = (keyword, reservedAt = null, partySize = null) => {
    setKeyword(keyword)
    const search = Object.entries({
      ...omit(params, [
        'keyword',
        'page',
        'sort_by',
        'reserved_at',
        'party_size',
      ]),
      ...(keyword && { keyword }),

      ...(reservedAt && {
        reserved_at: reservedAt && reservedAt.format(),
      }),
      ...(partySize && {
        party_size: partySize,
      }),
    }).reduce((str, [key, val]: [string, string], index) => {
      const sign = index === 0 ? '?' : '&'
      return str + `${sign}${key}=${val}`
    }, '')

    if (keyword) {
      // TODO: keyword検索時に状態を維持するのを正しく実装
      history.push({
        pathname: '/s/' + (sortBy === 'recommended' ? '' : `sort_by/${sortBy}`),
        search: search,
      })
    } else {
      history.push({
        pathname: '/',
        search: search,
      })
    }
  }

  const isHomeOrSearch = useHomeOrSearchMatch()
  const { width, md } = useResponsive()

  if (width < md) {
    if (isHomeOrSearch) {
      return (
        <SPSearchHeader
          keyword={keyword}
          setKeyword={setKeyword}
          search={search}
          showFilterBar={showFilterBar}
          onLayout={onHeaderLayout}
          headerAndBannerRef={headerAndBannerRef}
          headerAndBannerHeight={headerAndBannerHeight}
          filterBarHeight={filterBarHeight}
          setFilterBarHeight={setFilterBarHeight}
        />
      )
    }

    return (
      <SPHeader
        onLayout={onHeaderLayout}
        title={title}
        color={color}
        backgroundColor={backgroundColor}
        showHeaderBorderBottom={showHeaderBorderBottom}
        goBackTo={goBackTo}
        goBack={goBack}
      />
    )
  }

  return (
    <PCHeader
      inputRef={inputRef}
      keyword={keyword}
      setKeyword={setKeyword}
      search={search}
      showFilterBar={showFilterBar}
      onLayout={onHeaderLayout}
      headerAndBannerRef={headerAndBannerRef}
      headerAndBannerHeight={headerAndBannerHeight}
      filterBarHeight={filterBarHeight}
      setFilterBarHeight={setFilterBarHeight}
      backgroundColor={backgroundColor}
      showHeaderBorderBottom={showHeaderBorderBottom}
    />
  )
}

export default React.memo(Header)
