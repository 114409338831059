import { call, put, select, takeEvery } from './helpers/redux-saga'
import * as api from 'api'
import * as ActionTypes from 'ActionTypes'
import { handleApiError } from './shared'

import { normalize } from 'normalizr'
import schemas from 'schema'

import * as actions from 'actions'

function* like(action: ReturnType<typeof actions.like>) {
  const auth = yield* select((state) => state.auth)
  const { id, type } = action.payload.object
  const { response, error } = yield* call(api.like, auth, id, type)
  if (response) {
    const { entities } = normalize(response.data, schemas.feed)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

function* unlike(action: ReturnType<typeof actions.unlike>) {
  const auth = yield* select((state) => state.auth)
  const likeId = action.payload.object.like_id
  const { response, error } = yield* call(api.unlike, auth, likeId)
  if (response) {
    const { entities } = normalize(response.data, schemas.feed)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

const likeSagas = [
  takeEvery(ActionTypes.LIKE, like),
  takeEvery(ActionTypes.UNLIKE, unlike),
]

export default likeSagas
