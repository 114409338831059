import {
  put,
  select,
  call,
  takeEvery,
  takeLeading,
  take,
  race,
  fork,
  delay,
} from './helpers/redux-saga'
import { get } from 'lodash'
import * as ui from 'actions/ui'

import { normalize } from 'normalizr'

import { retryAPI } from './helpers'
import * as api from 'api'
import schemas from 'schema'

import * as ActionTypes from 'ActionTypes'

import * as actions from 'actions'
import { t } from 'modules/i18n'

import { handleApiError } from './shared'
import * as Sentry from '@sentry/browser'
import { updateUser } from './user'

function* getTakeoutRestaurant(restaurantId) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTakeoutRestaurant,
    auth,
    restaurantId
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.takeoutRestaurant
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_TAKEOUT_RESTAURANT_SUCCEEDED,
    })
    return { result }
  } else {
    return yield* handleApiError(error)
  }
}

function* getTakeoutRestaurantMenus(restaurantId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTakeoutRestaurantMenus,
    auth,
    restaurantId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [schemas.takeoutMenu])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.GET_TAKEOUT_RESTAURANT_MENUS_SUCCEEDED,
      payload: {
        restaurantId: restaurantId,
        menus: result,
      },
    })
    return { result, error: null }
  } else {
    return yield* handleApiError(error)
  }
}

function* getTakeoutCart(restaurantId) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.createTakeoutRestaurantOrder,
    auth,
    restaurantId
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.takeoutOrder)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_TAKEOUT_CART_SUCCEEDED,
      payload: {
        restaurantId,
        orderId: result,
      },
    })
    return { result, error: null }
  } else {
    return yield* handleApiError(error)
  }
}

function* createTakeoutOrderItem(orderId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.createTakeoutOrderItem,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableOrderItem
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.CREATE_TAKEOUT_ORDER_ITEM_SUCCEEDED,
    })
    return { result, error: null }
  } else {
    yield* put({ type: ActionTypes.CREATE_TAKEOUT_ORDER_ITEM_FAILED })
    return yield* handleApiError(error)
  }
}

function* updateTakeoutOrderItem(orderItemId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.updateTakeoutOrderItem,
    auth,
    orderItemId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableOrderItem
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM_SUCCEEDED,
    })
    return { result, error: null }
  } else {
    yield* put({ type: ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM_FAILED })
    return yield* handleApiError(error)
  }
}

function* destroyTakeoutOrderItem(orderId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.destroyTakeoutOrderItem,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.tableOrderItem
    )
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM_SUCCEEDED,
    })
    return { result, error: null }
  } else {
    yield* put({ type: ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM_FAILED })
    return yield* handleApiError(error)
  }
}

function* updateTakeoutOrder(orderId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.updateTakeoutOrder,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.takeoutOrder)
    yield* put(actions.setEntities(entities))
    return { result, error: null }
  }

  return yield* handleApiError(error)
}

function* requestTakeoutOrder(orderId, { complete, email, ...params }) {
  const auth = yield* select((state) => state.auth)

  if (email) {
    const { error } = yield* updateUser({ email })
    if (error) {
      yield* put({ type: ActionTypes.REQUEST_TAKEOUT_ORDER_FAILED })
      return yield* handleApiError(error)
    }
  }

  const { response, error } = yield* call(
    api.requestTakeoutOrder,
    auth,
    orderId,
    params
  )

  if (response) {
    if (complete) {
      yield* call(complete, 'success')
    }
    const { result, entities } = normalize(response.data, [
      schemas.takeoutOrder,
    ])
    yield* put(actions.setEntities(entities))
    yield* put({ type: ActionTypes.REQUEST_TAKEOUT_ORDER_SUCCEEDED })
    return { result, error: null }
  }
  if (complete) {
    yield* call(complete, 'fail')
  }
  yield* put({ type: ActionTypes.REQUEST_TAKEOUT_ORDER_FAILED })

  return yield* handleApiError(error)
}

function* getTakeoutOrder(orderId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* retryAPI(
    api.getTakeoutOrder,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, schemas.takeoutOrder)
    yield* put(actions.setEntities(entities))
    yield* put({ type: ActionTypes.GET_TAKEOUT_ORDER_SUCCEEDED })
    return { result, error: null }
  }
  return yield* handleApiError(error)
}

function* getTakeoutUserOrders(params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTakeoutUserOrders,
    auth,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.takeoutOrder,
    ])
    yield* put(actions.setEntities(entities))
    yield* put({
      type: ActionTypes.GET_TAKEOUT_USER_ORDERS_SUCCEEDED,
      payload: {
        orders: result,
      },
    })
    return { result, error: null }
  }

  return yield* handleApiError(error)
}

function* getTakeoutReceipt(orderId, params, onFinish) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTakeoutReceipt,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.takeoutReceipt
    )
    yield* put(actions.setEntities(entities))
    onFinish && onFinish()
    return { result, error: null }
  }

  return yield* handleApiError(error)
}

function* createTakeoutReceipt(orderId, params, onFinish) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* call(
    api.createTakeoutReceipt,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.takeoutOrder,
    ])
    yield* put(actions.setEntities(entities))
    onFinish && onFinish()
    return { result, error: null }
  }

  return yield* handleApiError(error)
}

function* getTakeoutOrderReceiveTimeOptions(orderId: string, params?) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield* retryAPI(
    api.getTakeoutOrderReceiveTimeOptions,
    auth,
    orderId,
    params
  )

  if (response) {
    const { result, entities } = normalize(response.data, [
      schemas.takeoutOrder,
    ])
    yield* put(actions.setEntities(entities))

    yield* put(
      actions.getTakeoutOrderReceiveTimeOptionsSucceeded({
        receiveTimeOptions: result,
      })
    )

    return { result, error: null }
  }

  return yield* handleApiError(error)
}

function* getTakeoutProduct(tableProductId, params) {
  const auth = yield* select((state) => state.auth)

  const { response, error } = yield retryAPI(
    api.getTakeoutProduct,
    auth,
    tableProductId,
    params
  )

  if (response) {
    const { entities } = normalize(response.data, schemas.takeoutProduct)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
  } else {
    yield* handleApiError(error)
  }
}

const takeoutSagas = [
  takeEvery(
    ActionTypes.GET_TAKEOUT_RESTAURANT,
    function* ({
      payload: { restaurantId },
    }: ReturnType<typeof actions.getTakeoutRestaurant>) {
      yield* getTakeoutRestaurant(restaurantId)
    }
  ),
  takeEvery(
    ActionTypes.GET_TAKEOUT_RESTAURANT_MENUS,
    function* ({
      payload: { restaurantId, params },
    }: ReturnType<typeof actions.getTakeoutRestaurantMenus>) {
      yield* getTakeoutRestaurantMenus(restaurantId, params)
    }
  ),

  takeEvery(
    ActionTypes.GET_TAKEOUT_PRODUCT,
    function* ({
      payload: { tableProductId, params },
    }: ReturnType<typeof actions.getTakeoutProduct>) {
      yield* getTakeoutProduct(tableProductId, params)
    }
  ),

  takeEvery(
    ActionTypes.GET_TAKEOUT_CART,
    function* ({
      payload: { restaurantId },
    }: ReturnType<typeof actions.getTakeoutCart>) {
      yield* getTakeoutCart(restaurantId)
    }
  ),

  takeEvery(
    ActionTypes.CREATE_TAKEOUT_ORDER_ITEM,
    function* ({
      payload: { restaurantId, orderId, params, onFinish },
    }: ReturnType<typeof actions.createTakeoutOrderItem>) {
      const { error } = yield* createTakeoutOrderItem(orderId, params)
      if (error) {
        return
      }
      yield* fork(getTakeoutCart, restaurantId)
      onFinish && onFinish()
      yield* put(ui.displayToastInfo(t('カートに追加しました')))
    }
  ),

  takeEvery(
    ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM,
    function* ({
      payload: { restaurantId, orderItemId, params, onFinish },
    }: ReturnType<typeof actions.updateTakeoutOrderItem>) {
      const { error } = yield* updateTakeoutOrderItem(orderItemId, params)
      if (error) {
        return
      }
      yield* fork(getTakeoutCart, restaurantId)
      onFinish && onFinish()
    }
  ),

  takeEvery(
    ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM,
    function* ({
      payload: { restaurantId, orderId, params, onFinish },
    }: ReturnType<typeof actions.destroyTakeoutOrderItem>) {
      const { error } = yield* destroyTakeoutOrderItem(orderId, params)
      if (error) {
        return
      }
      yield* fork(getTakeoutCart, restaurantId)
      onFinish && onFinish()
    }
  ),

  takeEvery(
    ActionTypes.REQUEST_TAKEOUT_ORDER,
    function* ({
      payload: { restaurantId, orderId, params, onFinish },
    }: ReturnType<typeof actions.requestTakeoutOrder>) {
      const { message, ...requestParams } = params
      {
        const { error } = yield* updateTakeoutOrder(orderId, { message })

        if (error) {
          return
        }
      }

      const { error } = yield* requestTakeoutOrder(orderId, requestParams)
      if (error) {
        return
      }
      yield* fork(getTakeoutCart, restaurantId)
      onFinish && onFinish()
      yield* put(ui.displayToastInfo(t('注文しました')))
    }
  ),

  takeEvery(
    ActionTypes.GET_TAKEOUT_ORDER,
    function* ({
      payload: { orderId, params },
    }: ReturnType<typeof actions.getTakeoutOrder>) {
      yield* getTakeoutOrder(orderId, params)
    }
  ),

  takeEvery(
    ActionTypes.UPDATE_TAKEOUT_ORDER,
    function* ({
      payload: { orderId, params, onFinish },
    }: ReturnType<typeof actions.updateTakeoutOrder>) {
      const { error } = yield* updateTakeoutOrder(orderId, params)
      if (error) return
      onFinish?.()
    }
  ),

  takeEvery(
    ActionTypes.GET_TAKEOUT_USER_ORDERS,
    function* ({
      payload: { params },
    }: ReturnType<typeof actions.getTakeoutUserOrders>) {
      yield* getTakeoutUserOrders(params)
    }
  ),
  takeEvery(
    ActionTypes.GET_TAKEOUT_RECEIPT,
    function* ({
      payload: { orderId, params, onFinish },
    }: ReturnType<typeof actions.getTakeoutReceipt>) {
      yield* getTakeoutReceipt(orderId, params, onFinish)
    }
  ),
  takeEvery(
    ActionTypes.CREATE_TAKEOUT_RECEIPT,
    function* ({
      payload: { orderId, params, onFinish },
    }: ReturnType<typeof actions.createTakeoutReceipt>) {
      yield* createTakeoutReceipt(orderId, params, onFinish)
    }
  ),
  takeEvery(
    ActionTypes.GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS,
    function* ({
      payload: { orderId, params, onFinish },
    }: ReturnType<typeof actions.createTakeoutReceipt>) {
      const { error } = yield* getTakeoutOrderReceiveTimeOptions(
        orderId,
        params
      )
      if (error) return

      onFinish?.()
    }
  ),
]

export default takeoutSagas
