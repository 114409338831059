import { takeEvery, put, select, call, fork } from './helpers/redux-saga'

import * as api from 'api'
import * as ActionTypes from 'ActionTypes'

import * as ui from 'actions/ui'

import { normalize } from 'normalizr'
import schemas from 'schema'

import { retryAPI } from './helpers'

import { handleApiError } from './shared'

import { t } from 'modules/i18n'

import * as actions from 'actions'

function* getRestaurantList(
  action: ReturnType<typeof actions.getRestaurantList>
) {
  const { slug, isPageRequest = false } = action.payload
  const auth = yield* select((state) => state.auth)

  yield* put(ui.startFetch())
  const { response, error } = yield retryAPI(api.getRestaurantList, auth, slug)

  if (response) {
    const { result, entities } = normalize(
      response.data,
      schemas.restaurantList
    )

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.GET_RESTAURANT_LIST_SUCCEEDED,
      payload: {
        slug: slug,
        restaurantListId: result,
      },
    })
  } else if (error) {
    yield* handleApiError(error, { abortOnError: isPageRequest })
  }
  yield* put(ui.endFetch())
}

function* purchaseRestaurantList(
  action: ReturnType<typeof actions.purchaseRestaurantList>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.purchaseRestaurantList,
    auth,
    action.restaurantListId
  )
  if (response) {
    const { entities } = normalize(response.data, schemas.restaurantList)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put(ui.displayToastInfo(t('フォローしました')))
  } else {
    yield* handleApiError(error)
  }
}

function* destroyImportedRestaurantList(
  action: ReturnType<typeof actions.destroyImportedRestaurantList>
) {
  const auth = yield* select((state) => state.auth)
  const { response, error } = yield* call(
    api.destroyImportedRestaurantList,
    auth,
    action.restaurantListId
  )
  if (response) {
    yield* fork(getRestaurantList, {
      type: ActionTypes.GET_RESTAURANT_LIST,
      payload: {
        slug: action.slug,
        isPageRequest: false,
      },
    })
    const { entities } = normalize(response.data, [
      schemas.importedRestaurantList,
    ])
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put(ui.displayToastInfo(t('フォローを解除しました')))
  } else {
    yield* handleApiError(error)
  }
}

const restaurantListSagas = [
  takeEvery(ActionTypes.GET_RESTAURANT_LIST, getRestaurantList),
  takeEvery(ActionTypes.PURCHASE_RESTAURANT_LIST, purchaseRestaurantList),
  takeEvery(
    ActionTypes.DESTROY_IMPORTED_RESTAURANT_LIST,
    destroyImportedRestaurantList
  ),
]

export default restaurantListSagas
