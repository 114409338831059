import * as ActionTypes from 'ActionTypes'

interface TakeoutState {
  menus?: any[]
  menuIndex: number
  receiveTimeOptions: string[]
  orderId?: string
  isRequesting: boolean
  orders?: any[]
}

const initialState: TakeoutState = {
  menus: null,
  menuIndex: 0,

  receiveTimeOptions: null,

  orderId: null,
  isRequesting: false,

  // ユーザのテイクアウト一覧ページ
  orders: null,
}

export default (state = initialState, action): TakeoutState => {
  switch (action.type) {
    case ActionTypes.GET_TAKEOUT_RESTAURANT_MENUS_SUCCEEDED:
      return {
        ...state,
        menus: action.payload.menus,
      }

    case ActionTypes.GET_TAKEOUT_CART_SUCCEEDED:
      return {
        ...state,
        orderId: action.payload.orderId,
      }

    case ActionTypes.CREATE_TAKEOUT_ORDER_ITEM:
    case ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM:
    case ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM:
    case ActionTypes.REQUEST_TAKEOUT_ORDER:
      return {
        ...state,
        isRequesting: true,
      }
    case ActionTypes.CREATE_TAKEOUT_ORDER_ITEM_SUCCEEDED:
    case ActionTypes.CREATE_TAKEOUT_ORDER_ITEM_FAILED:
    case ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM_SUCCEEDED:
    case ActionTypes.UPDATE_TAKEOUT_ORDER_ITEM_FAILED:
    case ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM_SUCCEEDED:
    case ActionTypes.DESTROY_TAKEOUT_ORDER_ITEM_FAILED:
    case ActionTypes.REQUEST_TAKEOUT_ORDER_SUCCEEDED:
    case ActionTypes.REQUEST_TAKEOUT_ORDER_FAILED:
      return {
        ...state,
        isRequesting: false,
      }
    case ActionTypes.GET_TAKEOUT_USER_ORDERS_SUCCEEDED:
      return {
        ...state,
        orders: action.payload.orders,
      }
    case ActionTypes.GET_TAKEOUT_ORDER_RECEIVE_TIME_OPTIONS_SUCCEEDED:
      return {
        ...state,
        receiveTimeOptions: action.payload.receiveTimeOptions,
      }
    default:
      return state
  }
}
