export default {
  // Extra small devices (phones)
  xs: 0,
  // Small devices (tablets)
  sm: 768,
  // Medium devices (desktops)
  md: 1050,
  // Large devices (large desktops)
  lg: 1200,
}
