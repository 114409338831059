import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import * as actions from 'actions'
import getCurrentPath from 'modules/getCurrentPath'
import { find, sum } from 'lodash'

import dayjs from 'dayjs'

import useNow from 'modules/useNow'
import Config from 'Config'
export const refreshInterval = 3000

export function isVerified(user) {
  return user.phone_number != null || user.has_card
}

export function useRequireSignIn() {
  const history = useHistory()
  const location = useLocation()

  return useCallback(() => {
    history.push({
      pathname: '/table/register',
      search: `?redirect_to=${encodeURIComponent(getCurrentPath(location))}`,
      state: { modal: true },
    })
  }, [history, location])
}

export function useStartTablePolling() {
  const dispatch = useDispatch()
  const orderId = useSelector((state) => state.table.orderId)

  useEffect(() => {
    if (orderId) {
      dispatch(actions.startTablePolling(orderId))
    }
    return () => {
      dispatch(actions.endTablePolling())
    }
  }, [dispatch, orderId])
}

export function useCartEmpty() {
  return useSelector((state) => state.table.tableOrderItems.length === 0)
}

export function useTableOrderBillPolling(orderId) {
  const dispatch = useDispatch()
  useEffect(() => {
    function run() {
      dispatch(actions.getTableOrderBills(orderId))
    }

    run()

    const timer = setInterval(run, refreshInterval)

    return () => {
      clearInterval(timer)
    }
  }, [dispatch, orderId])

  const fetched = useSelector((state) => state.table.tableBillsFetched)

  const bill = useSelector((state) => {
    const bills = state.table.tableBills
    return (
      bills &&
      find(
        bills.map((tableBillId) => state.entity.tableBills[tableBillId]),
        (bill) => bill?.status === 'pending'
      )
    )
  })
  return { bill, fetched }
}

export function useTableModalState({ tableOrder, user }) {
  const DELAY_BEFORE_MODAL_VISIBLE: [number, dayjs.OpUnitType] = [5, 'minutes']

  let delayBeforeVerifyModalVisible: [number, dayjs.OpUnitType] =
    DELAY_BEFORE_MODAL_VISIBLE

  if (Config.isTest) {
    delayBeforeVerifyModalVisible = [0, 'minutes']
  }

  const dispatch = useDispatch()

  const [now, setNow] = useState(dayjs())

  const isUnverified = useSelector((state) => state.table.isUnverified)
  const verifyModalClosedAt = useSelector((state) => {
    return state.table.verifyModalClosedAt
  })

  const isVerifyModalVisible =
    tableOrder &&
    user &&
    isUnverified &&
    !isVerified(user) &&
    dayjs(tableOrder.created_at).isBefore(
      now.subtract(...delayBeforeVerifyModalVisible)
    ) &&
    !verifyModalClosedAt

  const onCloseTableVerifyModal = useCallback(() => {
    dispatch(actions.closeTableVerifyModal(dayjs().format('')))
  }, [dispatch])
  const nameModalClosedAt = useSelector((state) => {
    return state.table.nameModalClosedAt
  })

  const isNameModalVisible =
    !isUnverified &&
    !isVerifyModalVisible &&
    tableOrder &&
    user &&
    !(user.reservation_first_name && user.reservation_last_name) &&
    dayjs(verifyModalClosedAt || tableOrder.created_at).isBefore(
      now.subtract(...DELAY_BEFORE_MODAL_VISIBLE)
    ) &&
    !nameModalClosedAt

  const onCloseTableNameModal = useCallback(() => {
    dispatch(actions.closeTableNameModal(dayjs().format('')))
  }, [dispatch])

  const emailModalClosedAt = useSelector((state) => {
    return state.table.emailModalClosedAt
  })

  const isEmailModalVisible =
    !isUnverified &&
    !isVerifyModalVisible &&
    !isNameModalVisible &&
    tableOrder &&
    user &&
    !user.email &&
    dayjs(
      nameModalClosedAt || verifyModalClosedAt || tableOrder.created_at
    ).isBefore(now.subtract(...DELAY_BEFORE_MODAL_VISIBLE)) &&
    !emailModalClosedAt

  const onCloseTableEmailModal = useCallback(() => {
    dispatch(actions.closeTableEmailModal(dayjs().format('')))
  }, [dispatch])

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(dayjs())
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return useMemo(
    () => ({
      isVerifyModalVisible,
      onCloseTableVerifyModal,
      isEmailModalVisible,
      onCloseTableEmailModal,
      isNameModalVisible,
      onCloseTableNameModal,
    }),
    [
      isVerifyModalVisible,
      onCloseTableVerifyModal,
      isEmailModalVisible,
      isNameModalVisible,
      onCloseTableEmailModal,
      onCloseTableNameModal,
    ]
  )
}

export function useTableOrderItemPlans(): any[] {
  const tableOrder = useSelector(
    (state) => state.entity.tableOrders[state.table.orderId]
  )

  return tableOrder?.table_order_item_plans ?? []
}

export function findLatestPlan(plans, planId) {
  if (!plans) return null

  const filteredPlans = plans
    .filter(({ plan }) => plan.id === planId)
    .sort((a, b) => (dayjs(a.expire_at).isBefore(dayjs(b.expire_at)) ? 1 : -1))

  if (filteredPlans.length > 0) {
    return filteredPlans[0]
  }
  return null
}

export function useCurrentPlan(tableUnlimitedOrderPlanId: string): {
  currentPlan: any
  isExpired: boolean
  remainingMinutes: number
} {
  const tableOrderItemPlans = useTableOrderItemPlans()

  const currentPlan =
    tableUnlimitedOrderPlanId &&
    findLatestPlan(tableOrderItemPlans, tableUnlimitedOrderPlanId)

  const now = useNow()

  const isExpired =
    currentPlan != null && dayjs(currentPlan.expire_at).isBefore(now)

  const remainingMinutes =
    currentPlan != null && dayjs(currentPlan.expire_at).diff(now, 'minute')

  return {
    currentPlan,
    isExpired,
    remainingMinutes,
  }
}

export function useCardRequiredBeforeOrder() {
  const user = useSelector((state) => state.entity.users[state.auth.id])

  const isCardRequired =
    user && !user.phone_number && user.email && !user.has_card

  return isCardRequired
}

export function useOrderReview(orderId) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.getTableUserOrderReviews(orderId))
  }, [dispatch, orderId])

  return useSelector((state) => {
    const reviews = state.table.reviewsByTableOrderId[orderId]

    if (reviews == null) {
      return {
        reviewFetched: false,
        review: null,
      }
    }

    return {
      reviewFetched: true,
      review: state.entity.reviews[reviews[0]],
    }
  })
}

export function idAttribute(value, parent, key) {
  const tableUnlimitedOrderPlanId =
    value.table_unlimited_order_plan_id || parent?.table_unlimited_order_plan_id

  const tableOrderId = value.table_order_id || parent?.table_order_id
  if (tableUnlimitedOrderPlanId) {
    return `unlimited-${tableUnlimitedOrderPlanId}#${value.id}`
  } else if (tableOrderId) {
    return `order-${tableOrderId}#${value.id}`
  }
  return value.id
}

export function processStrategy(value, parent, key) {
  if (parent && parent.table_unlimited_order_plan_id) {
    return {
      ...value,
      table_unlimited_order_plan_id: parent.table_unlimited_order_plan_id,
    }
  } else if (parent && parent.table_order_id) {
    return {
      ...value,
      table_order_id: parent.table_order_id,
    }
  }

  return value
}

export function getKey(
  id: string,
  {
    tableUnlimitedOrderPlanId,
    tableOrderId,
  }: {
    tableUnlimitedOrderPlanId?: string
    tableOrderId?: string
  }
) {
  if (tableUnlimitedOrderPlanId) {
    return `unlimited-${tableUnlimitedOrderPlanId}#${id}`
  }

  if (tableOrderId) {
    return `order-${tableOrderId}#${id}`
  }

  return id
}

export function getId(key: string) {
  return key.replace(/^(?:unlimited|order)-[^#]+#/, '')
}

export function useIsOnlineChargeEnabled() {
  return useSelector((state) => {
    return (
      state.entity.tableOrders[state.table.orderId]?.online_charge_enabled ??
      true
    )
  })
}

export function useCartOrderItems(): {
  numberOfCartOrderItems: number
  amountOfCartOrderItems: number
} {
  return useSelector((state) => {
    const numberOfCartOrderItems = sum(
      state.table.tableOrderItems
        .map(
          (tableOrderItemId) => state.entity.tableOrderItems[tableOrderItemId]
        )
        .map(({ quantity }) => quantity)
    )
    const amountOfCartOrderItems = sum(
      state.table.tableOrderItems
        .map(
          (tableOrderItemId) => state.entity.tableOrderItems[tableOrderItemId]
        )
        .map(({ amount_with_tax }) => amount_with_tax)
    )
    return {
      numberOfCartOrderItems,
      amountOfCartOrderItems,
    }
  })
}

export function useTableOrderSystemType(): 'prepayment' | 'postpayment' {
  const tableOrder = useSelector(
    (state) => state.entity.tableOrders[state.table.orderId]
  )

  return tableOrder?.system_type ?? 'postpayment'
}

export function useTableOrderPrepaymentServiceType(): 'seat' | 'food_court' {
  const tableOrder = useSelector(
    (state) => state.entity.tableOrders[state.table.orderId]
  )

  return tableOrder?.prepayment_service_type ?? 'seat'
}
