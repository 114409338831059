import * as ActionTypes from 'ActionTypes'

interface ErrorState {
  hasError: boolean
  error?: string
}

const initialState: ErrorState = {
  hasError: false,
  error: null,
}

export default (state = initialState, action): ErrorState => {
  switch (action.type) {
    case ActionTypes.SET_ERROR:
      return {
        ...state,

        hasError: action.payload.hasError,
        error: action.payload.error,
      }
    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        hasError: false,
        error: null,
      }

    default:
      return state
  }
}
