import * as ActionTypes from 'ActionTypes'
import {
  ConversationPartnerType,
  MessageItem,
  MessageListItem,
} from 'models/Message'
import { Restaurant } from 'models/Restaurant'

export const setupMessageChannel = (payload?: { messageListId?: string }) => {
  return {
    type: ActionTypes.SETUP_MESSAGE_CHANNEL,
    payload,
  }
}

export const messageChannelConnected = () => {
  return {
    type: ActionTypes.MESSAGE_CHANNEL_CONNECTED,
  }
}

export const messageChannelDisconnected = () => {
  return {
    type: ActionTypes.MESSAGE_CHANNEL_DISCONNECTED,
  }
}

export const updateMessageList = ({
  messageList,
}: {
  messageList: MessageListItem[]
}) => {
  return {
    type: ActionTypes.UPDATE_MESSAGE_LIST,
    payload: {
      messageList,
    },
  }
}

export const setIsMessageScreenFocused = (payload: {
  messageListId: string
}) => {
  return {
    type: ActionTypes.SET_IS_MESSAGE_SCREEN_FOCUSED,
    payload,
  }
}

export const getMessages = (payload: {
  messageListId?: string
  conversation_partner_id: number
  conversation_partner_type: ConversationPartnerType
}) => {
  return {
    type: ActionTypes.GET_MESSAGES,
    payload,
  }
}

export const setIsAppActive = ({ active }: { active: boolean }) => {
  return {
    type: ActionTypes.SET_IS_APP_ACTIVE,
    payload: {
      active,
    },
  }
}

export const sendMessage = ({
  conversation_partner_id,
  conversation_partner_type,
  body,
  image,
}: {
  conversation_partner_id: number
  conversation_partner_type: ConversationPartnerType
  body: string
  image?: string
}) => {
  return {
    type: ActionTypes.SEND_MESSAGE,
    payload: {
      conversation_partner_id,
      conversation_partner_type,
      body,
      image,
    },
  }
}

export const addNewMessages = ({
  message,
}: {
  message: MessageItem & { message_list_id: string }
}) => {
  return {
    type: ActionTypes.ADD_NEW_MESSAGES,
    payload: {
      message,
    },
  }
}

export const receiveNewMessage = ({
  message,
}: {
  message: MessageItem & { message_list_id: string }
}) => {
  return {
    type: ActionTypes.RECEIVE_NEW_MESSAGE,
    payload: {
      message,
    },
  }
}

export const updateMessage = ({
  messageListId,
  messages,
  hasNext,
  replace,
}: {
  messageListId: string
  messages: MessageItem[]
  hasNext: boolean
  replace?: boolean
}) => {
  return {
    type: ActionTypes.UPDATE_MESSAGE,
    payload: {
      messageListId,
      messages,
      hasNext,
      replace,
    },
  }
}

export const readMessage = ({ messageListId }: { messageListId: string }) => {
  return {
    type: ActionTypes.READ_MESSAGE,
    payload: {
      messageListId,
    },
  }
}

export const getMoreMessages = ({
  messageListId,
}: {
  messageListId: string
}) => {
  return {
    type: ActionTypes.GET_MORE_MESSAGES,
    payload: {
      messageListId,
    },
  }
}

export const setIsLoadingMoreMessages = ({
  messageListId,
}: {
  messageListId: string
}) => {
  return {
    type: ActionTypes.SET_IS_LOADING_MORE_MESSAGES,
    payload: {
      messageListId,
    },
  }
}

export const selectMessageRestaurant = ({
  messageListId,
  restaurant,
}: {
  messageListId?: string
  restaurant: Restaurant
}) => {
  return {
    type: ActionTypes.SELECT_MESSAGE_RESTAURANT,
    payload: {
      messageListId,
      restaurant,
    },
  }
}

export const setIsMessageLoading = ({
  messageListId,
  isLoading,
}: {
  messageListId: string
  isLoading: boolean
}) => {
  return {
    type: ActionTypes.SET_IS_LOADING_MESSAGES,
    payload: {
      messageListId,
      isLoading,
    },
  }
}

export const updateReadAt = (payload: {
  id: string
  conversation_partner_read_at: string
}) => {
  return {
    type: ActionTypes.UPDATE_READ_AT,
    payload,
  }
}

export const addMessageList = ({
  messageListItem,
}: {
  messageListItem: MessageListItem
}) => {
  return {
    type: ActionTypes.ADD_MESSAGE_LIST,
    payload: {
      messageListItem,
    },
  }
}

export const clearSelectMessageList = () => {
  return {
    type: ActionTypes.CLEAR_SELECT_MESSAGE_LIST,
  }
}
