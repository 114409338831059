import * as ActionTypes from 'ActionTypes'
import { History } from 'history'
import { VerifyTableUnverifiedUserParams } from 'models/TableUnverifiedUser'
import { WaiterCallType } from '../models/TableOrder'

export const openTable = ({ token, orderId }, history) => {
  return {
    type: ActionTypes.OPEN_TABLE,
    payload: {
      token,
      orderId,
      history,
    },
  } as const
}

export const getTableUserOrders = () => {
  return {
    type: ActionTypes.GET_TABLE_USER_ORDERS,
  } as const
}

export const getTableUserOrderReviews = (orderId) => {
  return {
    type: ActionTypes.GET_TABLE_USER_ORDER_REVIEWS,
    payload: { orderId },
  } as const
}

export const getTableSeat = (orderId) => {
  return {
    type: ActionTypes.GET_TABLE_SEAT,
    payload: { orderId },
  } as const
}

export const startTablePolling = (orderId) => {
  return {
    type: ActionTypes.START_TABLE_POLLING,
    payload: { orderId },
  } as const
}
export const endTablePolling = () => {
  return {
    type: ActionTypes.END_TABLE_POLLING,
  } as const
}

export const authenticateTable = ({
  token,
  type = 'seat_token',
  history,
}: {
  token: string
  type: 'seat_token' | 'onetime_token'
  history: History
}) => {
  return {
    type: ActionTypes.AUTHENTICATE_TABLE,
    payload: {
      token,
      type,
      history,
    },
  } as const
}

export const startTableCartPolling = () => {
  return {
    type: ActionTypes.START_TABLE_CART_POLLING,
  } as const
}

export const requestTableCartRefreshing = () => {
  return {
    type: ActionTypes.REQUEST_TABLE_CART_REFRESHING,
  } as const
}

export const endTableCartPolling = () => {
  return {
    type: ActionTypes.END_TABLE_CART_POLLING,
  } as const
}

export const updateTableOrderItem = (tableOrderItemId, params?) => {
  return {
    type: ActionTypes.UPDATE_TABLE_ORDER_ITEM,
    payload: {
      tableOrderItemId,
      params,
    },
  } as const
}

export const removeTableOrderItem = (tableOrderItemId) => {
  return {
    type: ActionTypes.REMOVE_TABLE_ORDER_ITEM,
    payload: {
      tableOrderItemId,
    },
  } as const
}

export const requestTableOrders = (orderId, history) => {
  return {
    type: ActionTypes.REQUEST_TABLE_ORDERS,
    payload: {
      orderId,
      history,
    },
  } as const
}

export const selectTableMenuIndex = (menuIndex) => {
  return {
    type: ActionTypes.SELECT_TABLE_MENU_INDEX,
    payload: {
      menuIndex,
    },
  } as const
}

export const selectTableMenuPage = (menuPage) => {
  return {
    type: ActionTypes.SELECT_TABLE_MENU_PAGE,
    payload: {
      menuPage,
    },
  } as const
}

export const addTableCartOrderItems = (
  orderId,
  orderItems,
  submit = false,
  history = null
) => {
  return {
    type: ActionTypes.ADD_TABLE_CART_ORDER_ITEMS,
    payload: { orderId, orderItems, submit, history },
  } as const
}

export const createTableWaiterCall = (orderId, callType: WaiterCallType) => {
  return {
    type: ActionTypes.CREATE_TABLE_WAITER_CALL,
    payload: { orderId, callType },
  } as const
}

export const getTableOrderMenu = (orderId, tableMenuId) => {
  return {
    type: ActionTypes.GET_TABLE_ORDER_MENU,
    payload: { orderId, tableMenuId },
  } as const
}
export const getTableMenu = (tableMenuId) => {
  return {
    type: ActionTypes.GET_TABLE_MENU,
    payload: { tableMenuId },
  } as const
}

export const getTableOrderMenus = (orderId) => {
  return {
    type: ActionTypes.GET_TABLE_SEAT_MENUS,
    payload: { orderId },
  } as const
}

export const createTableSeatOrder = (token) => {
  return {
    type: ActionTypes.CREATE_TABLE_SEAT_ORDER,
    payload: { token },
  } as const
}

export const getTableOrders = (status?) => {
  return {
    type: ActionTypes.GET_TABLE_ORDERS,
    payload: {
      status,
    },
  } as const
}

export const getTableOrder = (orderId) => {
  return {
    type: ActionTypes.GET_TABLE_ORDER,
    payload: { orderId },
  } as const
}

export const getTableOrderReviewProducts = (orderId) => {
  return {
    type: ActionTypes.GET_TABLE_ORDER_REVIEW_PRODUCTS,
    payload: {
      orderId,
    },
  }
}

type ThumbsState = 'like' | 'dislike' | null
type VotesState = Record<string, ThumbsState>

export const submitTableProductVotes = (
  tableOrderId,
  votes: VotesState,
  onFinish?: () => void
) => {
  return {
    type: ActionTypes.SUBMIT_TABLE_PRODUCT_VOTES,
    payload: {
      tableOrderId,
      votes,
      onFinish,
    },
  }
}
export const createTableOrderPayment = (
  orderId,
  params,
  complete?,
  onFinish?
) => {
  return {
    type: ActionTypes.CREATE_TABLE_ORDER_PAYMENT,
    payload: { orderId, params, complete, onFinish },
  } as const
}

export const getTableProduct = (tableProductId, params?) => {
  return {
    type: ActionTypes.GET_TABLE_PRODUCT,
    payload: { tableProductId, params },
  } as const
}

export const getTableReceipt = (tableOrderId) => {
  return {
    type: ActionTypes.GET_TABLE_RECEIPT,
    payload: { tableOrderId },
  } as const
}

export const createTableReceipt = (tableOrderId, params, onFinish) => {
  return {
    type: ActionTypes.CREATE_TABLE_RECEIPT,
    payload: {
      tableOrderId,
      params,
      onFinish,
    },
  } as const
}

export const createTableOrderBill = (tableOrderId, params, onFinish) => {
  return {
    type: ActionTypes.CREATE_TABLE_ORDER_BILL,
    payload: { tableOrderId, params, onFinish },
  } as const
}

export const removeTableOrderBill = (billId) => {
  return {
    type: ActionTypes.DELETE_TABLE_ORDER_BULL,
    payload: { billId },
  }
}

export const getTableOrderBills = (tableOrderId, params?, onFinish?) => {
  return {
    type: ActionTypes.GET_TABLE_ORDER_BILLS,
    payload: { tableOrderId, params, onFinish },
  } as const
}

export const getTableBill = (tableBillId, params, onFinish) => {
  return {
    type: ActionTypes.GET_TABLE_BILL,
    payload: { tableBillId, params, onFinish },
  } as const
}

export const updateTableBill = (tableBillId, params, onFinish) => {
  return {
    type: ActionTypes.UPDATE_TABLE_BILL,
    payload: { tableBillId, params, onFinish },
  } as const
}

export const openTablePay = ({ orderId }, history) => {
  return {
    type: ActionTypes.OPEN_TABLE_PAY,
    payload: { orderId, history },
  } as const
}
export const updateTableOrder = (tableOrderId, params?, onFinish?) => {
  return {
    type: ActionTypes.UPDATE_TABLE_ORDER,
    payload: { tableOrderId, params, onFinish },
  } as const
}

export const closeTableVerifyModal = (verifyModalClosedAt) => {
  return {
    type: ActionTypes.CLOSE_TABLE_VERIFY_MODAL,
    payload: {
      verifyModalClosedAt,
    },
  } as const
}

export const closeTableEmailModal = (emailModalClosedAt) => {
  return {
    type: ActionTypes.CLOSE_TABLE_EMAIL_MODAL,
    payload: {
      emailModalClosedAt,
    },
  } as const
}

export const closeTableNameModal = (nameModalClosedAt) => {
  return {
    type: ActionTypes.CLOSE_TABLE_NAME_MODAL,
    payload: {
      nameModalClosedAt,
    },
  } as const
}

export const selectTableMenuType = (menuType: 'default' | 'unlimited') => {
  return {
    type: ActionTypes.SELECT_TABLE_MENU_TYPE,
    payload: {
      menuType,
    },
  } as const
}

export const selectTableUnlimitedOrderPlan = (tableUnlimitedOrderPlanId) => {
  return {
    type: ActionTypes.SELECT_TABLE_UNLIMITED_ORDER_PLAN,
    payload: { tableUnlimitedOrderPlanId },
  }
}

export const getTableUnlimitedOrderPlan = (tableUnlimitedOrderPlanId) => {
  return {
    type: ActionTypes.GET_TABLE_UNLIMITED_ORDER_PLAN,
    payload: {
      tableUnlimitedOrderPlanId,
    },
  }
}

export const getTableMenuUnlimitedOrderPlans = (
  tableMenuId,
  params,
  onFinish
) => {
  return {
    type: ActionTypes.GET_TABLE_MENU_UNLIMITED_ORDER_PLANS,
    payload: {
      tableMenuId,
      params,
      onFinish,
    },
  }
}

export const createTableOrderOrderItemsUnlimitedOrderPlan = (
  tableOrderId,
  params?,
  onFinish?
) => {
  return {
    type: ActionTypes.CREATE_TABLE_ORDER_ORDER_ITEMS_UNLIMITED_ORDER_PLAN,
    payload: {
      tableOrderId,
      params,
      onFinish,
    },
  }
}

export const createTableProductVote = (params) => {
  return {
    type: ActionTypes.CREATE_TABLE_PRODUCT_VOTE,
    payload: { params },
  }
}

export const verifyTableUnverifiedUser = (
  params: VerifyTableUnverifiedUserParams
) => {
  return {
    type: ActionTypes.VERIFY_TABLE_UNVERIFIED_USER,
    payload: { params },
  }
}
