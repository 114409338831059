import * as ActionTypes from 'ActionTypes'

import { get } from 'lodash'

interface DeliveryState {
  deliveryProductsById: Record<number, number[]>
  deliveryProductsFetched: Record<number, boolean>
  deliveryTimes: Record<number, string[]>
  discountCampaigns: Record<any, any[]>
  cart?: number
  cartFetched: boolean
  currentStep: 'start' | 'form' | 'complete' | 'error'
  openDeliveryPurchaseModalLoading: boolean
  completeDeliveryPurchaseModalLoading: boolean
}

const initialState: DeliveryState = {
  deliveryProductsById: {},
  deliveryProductsFetched: {},

  deliveryTimes: {},
  discountCampaigns: {},

  cart: null,
  cartFetched: false,

  currentStep: 'start',
  openDeliveryPurchaseModalLoading: false,
  completeDeliveryPurchaseModalLoading: false,
}

export default (state = initialState, action): DeliveryState => {
  switch (action.type) {
    case ActionTypes.GET_DELIVERY_RESTAURANT_PRODUCTS:
      return {
        ...state,
        deliveryProductsFetched: {
          ...state.deliveryProductsFetched,
          [action.payload.restaurantId]: get(
            state.deliveryProductsFetched,
            [action.payload.restaurantId],
            false
          ),
        },
      }
    case ActionTypes.GET_DELIVERY_RESTAURANT_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        deliveryProductsById: {
          ...state.deliveryProductsById,
          [action.payload.restaurantId]: action.payload.deliveryProducts,
        },
        deliveryProductsFetched: {
          ...state.deliveryProductsFetched,
          [action.payload.restaurantId]: true,
        },
      }

    case ActionTypes.GET_DELIVERY_CART:
      return {
        ...state,
        // カートが存在する場合のみ、すでにロード済であればローディングを表示しない
        cartFetched: (state.cartFetched && Boolean(state.cart)) || false,
      }

    case ActionTypes.ADD_DELIVERY_CART_PRODUCT:
      return {
        ...state,
      }

    case ActionTypes.GET_DELIVERY_RESTAURANT_DELIVERY_TIMES_SUCCEEDED:
      return {
        ...state,
        deliveryTimes: {
          ...state.deliveryTimes,
          [action.payload.restaurantId]: action.payload.deliveryTimes,
        },
      }

    case ActionTypes.GET_DELIVERY_RESTAURANT_DISCOUNT_CAMPAIGNS_SUCCEEDED:
      return {
        ...state,
        discountCampaigns: {
          ...state.discountCampaigns,
          [action.payload.restaurantId]: action.payload.discountCampaigns,
        },
      }

    case ActionTypes.GET_DELIVERY_CART_SUCCEEDED:
    case ActionTypes.UPDATE_DELIVERY_CART_SUCCEEDED:
    case ActionTypes.ADD_DELIVERY_CART_PRODUCT_SUCCEEDED:
    case ActionTypes.REMOVE_DELIVERY_CART_PRODUCT_SUCCEEDED:
    case ActionTypes.REQUEST_DELIVERY_CART_SUCCEEDED:
      return {
        ...state,
        cartFetched: true,
        cart: action.payload.cart,
      }

    case ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL:
      return {
        ...state,
        openDeliveryPurchaseModalLoading: true,
        currentStep: 'start',
      }
    case ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_SUCCEEDED:
      return {
        ...state,
        openDeliveryPurchaseModalLoading: false,
        currentStep: 'form',
      }
    case ActionTypes.OPEN_DELIVERY_PURCHASE_MODAL_ERROR:
      return {
        ...state,
        openDeliveryPurchaseModalLoading: false,
      }

    case ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL:
      return {
        ...state,
        completeDeliveryPurchaseModalLoading: true,
      }
    case ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_SUCCEEDED:
      return {
        ...state,
        completeDeliveryPurchaseModalLoading: false,
        currentStep: 'complete',
      }
    case ActionTypes.COMPLETE_DELIVERY_PURCHASE_MODAL_ERROR:
      return {
        ...state,
        completeDeliveryPurchaseModalLoading: false,
      }

    default:
      return state
  }
}
