import callLog from './callLog'
import city from './city'
import deliveryRestaurant from './deliveryRestaurant'
import deliveryProduct from './deliveryProduct'
import deliveryOrder from './deliveryOrder'
import deliveryProductImage from './deliveryProductImage'
import deliveryProductOrder from './deliveryProductOrder'

import feed from './feed'
import homeSection from './homeSection'
import importedRestaurantList from './importedRestaurantList'
import prefecture from './prefecture'

import notification from './notification'
import reservation from './reservation'
import reservationUser from './reservationUser'
import reservationList from './reservationList'
import reservationElement from './reservationElement'
import restaurant from './restaurant'
import restaurantCategory from './restaurantCategory'
import restaurantCourse from './restaurantCourse'
import restaurantImage from './restaurantImage'
import restaurantList from './restaurantList'
import restaurantMenu from './restaurantMenu'
import restaurantMenuSection from './restaurantMenuSection'
import restaurantSeat from './restaurantSeat'
import review from './review'
import station from './station'
import user from './user'
import unionReservationAndReview from './unionReservationAndReview'
import shippingAddress from './shippingAddress'
import shopOrder from './shopOrder'
import shopProduct from './shopProduct'
import shopProductImage from './shopProductImage'
import shopProductOrder from './shopProductOrder'
import shopProductOrderReview from './shopProductOrderReview'
import shopRestaurant from './shopRestaurant'
import shopReceipt from './shopReceipt'

import tableMenuNavigation from './tableMenuNavigation'
import tableProduct from './tableProduct'
import tableOrder from './tableOrder'
import tableOrderItem from './tableOrderItem'
import tableOrderItemPlan from './tableOrderItemPlan'
import tableOrderItemOption from './tableOrderItemOption'
import tableMenu from './tableMenu'
import tableMenuPage from './tableMenuPage'
import tableProductOptionBox from './tableProductOptionBox'
import tableProductOption from './tableProductOption'
import tableSeat from './tableSeat'
import tableReceipt from './tableReceipt'
import tableBill from './tableBill'
import tableBillSplit from './tableBillSplit'
import tableUnlimitedOrderPlan from './tableUnlimitedOrderPlan'
import tableProductVote from './tableProductVote'

import takeoutOrder from './takeoutOrder'
import takeoutOrderItem from './takeoutOrderItem'
import takeoutReceipt from './takeoutReceipt'
import takeoutRestaurant from './takeoutRestaurant'
import takeoutRestaurantSetting from './takeoutRestaurantSetting'
import takeoutMenu from './takeoutMenu'
import takeoutMenuPage from './takeoutMenuPage'
import takeoutProduct from './takeoutProduct'
import takeoutProductOption from './takeoutProductOption'
import takeoutProductOptionBox from './takeoutProductOptionBox'

city.define({
  prefecture,
})
feed.define({
  restaurant_list: restaurantList,
  reservation_user: reservationUser,
  review: review,
})
reservation.define({
  restaurant,
  restaurant_seat: restaurantSeat,
})
reservationUser.define({
  reservation,
})
restaurant.define({
  restaurant_categories: [restaurantCategory],
  restaurant_lists: [restaurantList],
  reviews: [review],
  near_stations: [station],
  city,
  ranking_users: [user],
  restaurant_courses: [restaurantCourse],
  restaurant_food_sections: [restaurantMenuSection],
  restaurant_drink_sections: [restaurantMenuSection],
  restaurant_menu_images: [restaurantImage],
  shop_restaurant: shopRestaurant,
  delivery_restaurant: deliveryRestaurant,
})
restaurantList.define({
  user,
})
restaurantMenuSection.define({
  restaurant_menus: [restaurantMenu],
})
review.define({ user, restaurant, reservation })
user.define({
  my_lists: [restaurantList],
})
shopOrder.define({
  restaurant,
  shop_product_orders: [shopProductOrder],
  shipping_address: shippingAddress,
  receipt: shopReceipt,
})
shopProductOrder.define({
  shop_product: shopProduct,
  shop_product_order_review: shopProductOrderReview,
})
shopProductOrderReview.define({
  user,
})
shopProduct.define({
  shop_product_images: [shopProductImage],
  restaurant,
  reviews: [shopProductOrderReview],
})
deliveryOrder.define({
  restaurant,
  delivery_product_orders: [deliveryProductOrder],
})
deliveryProductOrder.define({
  delivery_product: deliveryProduct,
})
deliveryProduct.define({
  delivery_product_images: [deliveryProductImage],
  restaurant,
})

tableMenuNavigation.define({
  table_menu_page: tableMenuPage,
})

tableMenu.define({
  available_table_menu_navigations: [tableMenuNavigation],
  table_menu_navigations: [tableMenuNavigation],
})

tableOrder.define({
  required_order: {
    table_products: [tableProduct],
  },
  table_order_items: [tableOrderItem],
  table_seat: tableSeat,
  restaurant,
  table_receipt: tableReceipt,
})

tableOrderItem.define({
  table_product: tableProduct,
  table_product_options: [tableProductOption],
  table_order_item_options: [tableOrderItemOption],
})

tableOrderItemOption.define({
  table_product_option: tableProductOption,
})

tableOrderItemPlan.define({
  plan: tableUnlimitedOrderPlan,
})

tableProduct.define({
  table_product_option_boxes: [tableProductOptionBox],
})

tableProductOption.define({
  table_product_option_box: tableProductOptionBox,
})
tableProductOptionBox.define({
  table_product_options: [tableProductOption],
})

tableSeat.define({
  restaurant,
})

tableReceipt.define({
  restaurant,
})

tableBill.define({
  table_order: tableOrder,
})

tableUnlimitedOrderPlan.define({
  table_menu_pages: [tableMenuPage],
  table_menus: [tableMenu],
  table_products: [tableProduct],
})

takeoutOrder.define({
  restaurant,
  takeout_order_items: [takeoutOrderItem],
  takeout_receipt: takeoutReceipt,
})

takeoutOrderItem.define({
  table_product: tableProduct,
  takeout_product: takeoutProduct,
  takeout_product_options: [takeoutProductOption],
})

takeoutReceipt.define({
  restaurant,
})

takeoutRestaurant.define({
  takeout_restaurant_setting: takeoutRestaurantSetting,
})

takeoutProduct.define({
  takeout_product_option_boxes: [takeoutProductOptionBox],
})

takeoutProductOption.define({
  takeout_product_option_box: takeoutProductOptionBox,
})
takeoutProductOptionBox.define({
  takeout_product_options: [takeoutProductOption],
})

export default {
  callLog,
  city,
  deliveryRestaurant,
  deliveryProduct,
  deliveryProductOrder,
  deliveryOrder,
  deliveryProductImage,
  feed,
  homeSection,
  importedRestaurantList,
  prefecture,
  notification,
  reservation,
  reservationUser,
  reservationList,
  reservationElement,
  restaurant,
  restaurantCategory,
  restaurantList,
  restaurantSeat,
  review,
  station,
  user,
  unionReservationAndReview,

  shopProduct,
  shopOrder,
  shopProductImage,
  shopProductOrder,
  shopProductOrderReview,
  shopReceipt,

  tableProduct,
  tableOrder,
  tableOrderItem,
  tableOrderItemOption,
  tableMenu,
  tableMenuPage,
  tableProductOptionBox,
  tableProductOption,
  tableSeat,
  tableReceipt,
  tableBill,
  tableBillSplit,
  tableUnlimitedOrderPlan,
  tableProductVote,
  takeoutOrder,
  takeoutOrderItem,
  tableOrderItemPlan,
  takeoutReceipt,
  takeoutRestaurant,
  takeoutRestaurantSetting,
  takeoutMenu,
  takeoutMenuPage,
  takeoutProduct,
}
