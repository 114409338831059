import { AxiosResponse } from 'axios'
import axios from './axios'

export function setHeader({ token }) {
  axios.defaults.headers.common.Authorization = `Token token=${token}`

  if (token) {
    delete axios.defaults.headers.common['X-Use-Blank-User']
  } else {
    axios.defaults.headers.common['X-Use-Blank-User'] = '1'
  }
}

export type ResponseWrapper<T = any> = Promise<{
  response?: AxiosResponse<T>
  error?: any
}>

export function wrapResponse<T = any>(
  promise: Promise<AxiosResponse<T>>
): ResponseWrapper<T> {
  return promise
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
