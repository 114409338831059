import { all } from './helpers/redux-saga'

import acceptRequestSagas from './acceptRequest'
import authSagas from './auth'
import deliverySagas from './delivery'
import deliveryHomeSagas from './deliveryHome'
import homeSagas from './home'
import likeSagas from './like'
import notificationSagas from './notification'
import ownerSagas from './owner'
import requestReservationSagas from './requestReservation'
import reservationSagas from './reservation'
import restaurantSagas from './restaurant'
import restaurantListSagas from './restaurantList'
import sessionSagas from './session'
import shopSagas from './shop'
import tempUserSagas from './tempUser'
import topSagas from './top'
import uiSagas from './ui'
import userSagas from './user'
import reviewSagas from './review'
import messageSagas from './message'
import errorSagas from './error'
import tableSagas from './table'
import takeoutSagas from './takeout'

export default function* rootSaga() {
  yield* all([
    ...acceptRequestSagas,
    ...authSagas,
    ...deliverySagas,
    ...deliveryHomeSagas,
    ...homeSagas,
    ...likeSagas,
    ...notificationSagas,
    ...ownerSagas,
    ...requestReservationSagas,
    ...reservationSagas,
    ...restaurantSagas,
    ...restaurantListSagas,
    ...sessionSagas,
    ...shopSagas,
    ...tempUserSagas,
    ...topSagas,
    ...uiSagas,
    ...userSagas,
    ...reviewSagas,
    ...messageSagas,
    ...errorSagas,
    ...tableSagas,
    ...takeoutSagas,
  ])
}
