import { call, put, select, takeEvery } from './helpers/redux-saga'

import * as api from 'api'
import * as ActionTypes from 'ActionTypes'

import { normalize } from 'normalizr'
import schemas from 'schema'

import * as ui from 'actions/ui'
import { handleApiError } from './shared'

import * as tempUser from 'actions/tempUser'

import { t } from 'modules/i18n'

import { persistor } from 'store'

import {
  isExternalSignUp,
  getCurrentOrigin,
  getRedirectTo,
} from 'modules/widgets'

import * as actions from 'actions'

export function* syncUser(action: ReturnType<typeof actions.syncUser>) {
  const auth = yield* select((state) => state.auth)
  if (auth.token) {
    const { response, error } = yield* call(api.syncUser, auth)

    if (response) {
      const { result, entities } = normalize(response.data, schemas.user)
      yield* put({
        type: ActionTypes.SET_ENTITIES,
        payload: entities,
      })
      yield* put({
        type: ActionTypes.SYNC_USER_SUCCEEDED,
        payload: {
          id: result,
        },
      })

      yield* put({
        type: ActionTypes.SYNC_MESSAGE_STATUS,
        payload: {
          messageEnabled: response.data.message_enabled,
          unreadCount: response.data.unread_message_count,
          supportReadMessageAt: response.data.support_read_message_at,
        },
      })
    } else {
      yield* handleApiError(error, { abortOnError: true })
    }
  }
}

function* signIn(action: ReturnType<typeof actions.signIn>) {
  const { phoneNumber, password } = action.payload

  const { response, error } = yield* call(api.signIn, phoneNumber, password)
  if (response) {
    const { entities } = normalize(response.data, schemas.user)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.SIGN_IN_SUCCEEDED,
      payload: response.data,
    })

    yield* put(ui.displayToastInfo(t('ログインしました')))
  }
  if (error) {
    yield* handleApiError(error)
  }
}

function* signOut(action: ReturnType<typeof actions.signOut>) {
  const { redirectTo = '/' } = action.payload
  yield* put({
    type: ActionTypes.SIGN_OUT_SUCCEEDED,
  })

  yield* put(ui.displayToastInfo(t('ログアウトしました')))

  action.history.push(redirectTo)
}

function* signUp(action: ReturnType<typeof actions.signUp>) {
  const { redirectTo, history, from } = action
  const { response, error } = yield* call(api.createUser, action.params)
  if (response) {
    const { result, entities } = normalize(response.data, schemas.user)
    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })
    yield* put({
      type: ActionTypes.SIGN_UP_SUCCEEDED,
      payload: response.data,
    })

    yield* put(tempUser.destroyTempUser())

    yield* put(ui.displayToastInfo(t('アカウント登録しました')))

    if (isExternalSignUp()) {
      const CLOSE_CHECK_THRESHOLD = 2000

      window.opener.postMessage(
        {
          type: 'SIGN_IN',
          payload: {
            token: response.data.token,
            isSignUp: true,
          },
        },
        getCurrentOrigin()
      )

      yield persistor.flush()

      yield* call([window, window.close])

      setTimeout(() => {
        if (!window.closed) {
          // TODO: アプリ内ブラウザの場合の実装をする
          // アプリ内ブラウザで開いた場合など、ポップアップとして開かれていない場合は閉じることに失敗する
          // よって、その場合はここでリダイレクト処理を行う
          const redirectTo = getRedirectTo()
          window.location.href = redirectTo
        }
      }, CLOSE_CHECK_THRESHOLD)
      return
    }

    if (history) {
      history.push(redirectTo)
    }
  } else {
    yield* handleApiError(error)
  }
}

export function* signInWithToken(token) {
  const { response, error } = yield* call(api.syncUser, { token })
  if (response) {
    const { result, entities } = normalize(response.data, schemas.user)

    yield* put({
      type: ActionTypes.SET_ENTITIES,
      payload: entities,
    })

    yield* put({
      type: ActionTypes.SIGN_IN_SUCCEEDED,
      payload: { id: result, token },
    })
  }
  if (error) {
    yield* handleApiError(error)
  }
  return { error }
}

const authSagas = [
  takeEvery(ActionTypes.SYNC_USER, syncUser),
  takeEvery(ActionTypes.SIGN_IN, signIn),
  takeEvery(ActionTypes.SIGN_UP, signUp),
  takeEvery(ActionTypes.SIGN_OUT, signOut),
  takeEvery(
    ActionTypes.SIGN_IN_WITH_TOKEN,
    function* ({
      payload: { token, silent = false, isSignUp = false },
    }: ReturnType<typeof actions.signInWithToken>) {
      const { error } = yield* signInWithToken(token)
      if (error != null) return
      if (silent) return
      yield* put(
        ui.displayToastInfo(
          isSignUp ? t('アカウント登録しました') : t('ログインしました')
        )
      )
    }
  ),
]

export default authSagas
