import { axios, setHeader } from 'api/table'
import { wrapResponse } from 'api/shared'
import { User } from './User'
import { onError } from 'modules/swr'

interface TableUnverifiedUser {
  id: User['id']
  token: string
}

export interface VerifyTableUnverifiedUserParams {
  phone_number: string
  code: string
}

export async function createTableUnverifiedUser(
  token: string | null,
  params: {
    order_onetime_token: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableUnverifiedUser>(`/unverified_users`, params)
  )
  if (error) {
    onError(error)
  }
  return {
    tableUnverifiedUser: response?.data,
    error,
  }
}

export async function verifyTableUnverifiedUser(
  token: string | null,
  params: VerifyTableUnverifiedUserParams
) {
  setHeader({ token })

  const { response, error } = await wrapResponse(
    axios.post<User>(`/unverified_users/verify`, params)
  )
  if (error) {
    onError(error)
  }
  return {
    user: response?.data,
    error,
  }
}
