import React from 'react'

import { View } from 'react-native'

import { TouchableOpacity } from 'components/Shared/Touchables'

import useResponsive from 'modules/useResponsive'

import { Text } from '@hello-ai/ar_shared'

interface SimpleHeaderProps {
  onLayout?: any
  title?: string | null | React.ReactNode
  color?: string | null
  backgroundColor?: string | null
  isBackVisible?: boolean
  backType?: 'back' | 'close'
  onBackPress?: () => void
  showHeaderBorderBottom?: boolean
  headerRight?: React.ReactNode | null
  paddingHorizontal?: number
}

const largePadding = 24
const smallPadding = 16
const buttonWidth = 32

const headerHeight = 56

function SimpleHeader({
  onLayout,
  title = null,
  color = Constants.PRIMARY_COLOR,
  backgroundColor = 'white',
  isBackVisible = false,
  backType = 'back',
  onBackPress,
  showHeaderBorderBottom = true,
  headerRight = null,
  paddingHorizontal = null,
}: SimpleHeaderProps) {
  const { width, sm } = useResponsive()

  if (paddingHorizontal == null) {
    paddingHorizontal = width < sm ? smallPadding : largePadding
  }

  return (
    <View
      accessibilityRole="navigation"
      style={[
        GeneralStyles.inlineAlignCenter,
        {
          zIndex: 100,
          width: '100%',
          height: headerHeight,
          backgroundColor: backgroundColor,
          borderBottomWidth: 0.5,
          borderColor: showHeaderBorderBottom
            ? Constants.BORDER_COLOR
            : 'transparent',
        },
      ]}
      onLayout={onLayout}
    >
      {isBackVisible && (
        <TouchableOpacity
          onPress={onBackPress}
          style={{
            marginTop: 1,
            marginLeft: paddingHorizontal,
            width: buttonWidth,
          }}
          hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
        >
          <FontAwesome5Pro
            name={backType === 'back' ? 'chevron-left' : 'times'}
            {...(backType === 'back' ? { regular: true } : { light: true })}
            color={color}
            size={20}
          />
        </TouchableOpacity>
      )}
      {typeof title === 'string' || title === undefined || title === null ? (
        <View
          style={[
            GeneralStyles.container,
            {
              alignItems: 'center',
            },
          ]}
        >
          <Text
            numberOfLines={1}
            style={[
              {
                fontSize: 16,
                fontWeight: '600',
                color: color,
              },
            ]}
          >
            {title}
          </Text>
        </View>
      ) : (
        title
      )}
      {headerRight || (
        <View
          style={{
            width: isBackVisible ? buttonWidth : 0,
          }}
        />
      )}
      <View
        style={{
          marginRight: isBackVisible ? paddingHorizontal : 0,
        }}
      />
    </View>
  )
}

export default SimpleHeader
