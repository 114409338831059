import axios from './axios'
import { setHeader, ResponseWrapper } from './shared'

export const createAcceptRequest = (
  auth,
  restaurantId,
  content
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post(`/auto_call_user_requests`, {
      restaurant_id: restaurantId,
      content: content,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const updateAcceptRequest = (
  auth,
  acceptRequestId,
  content
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .patch(`/auto_call_user_requests/${acceptRequestId}`, {
      content: content,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
