import axios from './axios'
import { ResponseWrapper, setHeader } from './shared'

export const getRestaurantList = (auth, id): ResponseWrapper => {
  setHeader(auth)
  return axios
    .get(`/restaurant_lists/${id}`, { cache: { ignoreCache: false } })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const purchaseRestaurantList = (
  auth,
  restaurantListId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .post('/restaurant_lists/purchase', {
      restaurant_list_id: restaurantListId,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const destroyImportedRestaurantList = (
  auth,
  restaurantListId
): ResponseWrapper => {
  setHeader(auth)
  return axios
    .delete(`/restaurant_lists/${restaurantListId}/destroy_imported_list`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
